/* eslint-disable @typescript-eslint/ban-types */
// in this case we need a generic form of objects so the best way is to use 'object'
import React from 'react'
import getOr from 'lodash/fp/getOr'
import isNil from 'lodash/fp/isNil'

import { Error, changeValue } from '../../types'
import { SimpleTooltip } from '../../components'
import { FValueType } from '../../common/alterForms'

import { SimpleField } from './style'

interface IKnTField {
  name: string
  label?: string
  placeholder?: string
  type?: string
  required?: boolean
  readOnly?: boolean
  icon?: JSX.Element
  value: object
  // from src\common\alterForms.ts
  setValue: (path: string, value: changeValue, type?: FValueType) => void
  valueType?: FValueType
  error?: object
  className?: string
  materialStyle?: boolean
  tooltip?: string
  attrs?: { [s: string]: string | number }
}

export const KnTField: React.FC<IKnTField> = ({
  name,
  placeholder,
  label,
  type = 'text',
  required = true,
  icon,
  value,
  setValue,
  valueType,
  error,
  className,
  materialStyle = false,
  tooltip,
  readOnly = false,
  attrs = {},
}) => {
  const inputValue = getOr('', name, value)
  const inputError = getOr(null, name, error)

  const [focused, setFocused] = React.useState(false)

  React.useEffect(() => {
    // inputValue ? true : false
    if (materialStyle) {
      setFocused(!!inputValue)
    }
  }, [inputValue, materialStyle])

  return (
    <SimpleField
      className={`
      simple-field-knt
      ${materialStyle ? 'material-style' : ''}
      ${focused ? 'focus-input' : ''}
      ${className || ''}`}
    >
      {label && (
        <label htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && (
            <SimpleTooltip
              id={name}
              className="top-right-form"
              text={tooltip}
            />
          )}
        </label>
      )}
      <input
        onFocus={() => setFocused(materialStyle)}
        onBlur={() => setFocused(!!inputValue)}
        value={isNil(inputValue) ? '' : inputValue}
        onChange={({ currentTarget }) =>
          setValue(name, currentTarget.value, valueType)
        }
        required={required}
        readOnly={readOnly}
        className="outline-none"
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attrs}
      />
      {icon}
      {inputError && (
        <span className="error-knt fz-11 text-danger float-left mt-2">
          {inputError}
        </span>
      )}
      {materialStyle && !inputError && (
        <span className="error-knt fz-12 float-left mt-2 pl-1">
          {required ? '*Required' : 'Optional'}
        </span>
      )}
    </SimpleField>
  )
}

export const KnTFieldWrapper: React.FC = ({ children }) => (
  <SimpleField>{children}</SimpleField>
)

interface ISimpleKnTField {
  name: string
  label?: string
  placeholder?: string
  type?: string
  required?: boolean
  readOnly?: boolean
  icon?: JSX.Element
  value?: React.InputHTMLAttributes<HTMLInputElement>['value']
  defaultValue?: React.InputHTMLAttributes<HTMLInputElement>['defaultValue']
  error?: string | Error[]
  className?: string
  tooltip?: string
  attrs?: { [s: string]: string | number }
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void
  ref?: React.RefObject<HTMLInputElement>
}
export const SimpleKnTField: React.FC<ISimpleKnTField> = ({
  name,
  placeholder,
  label,
  type = 'text',
  required = true,
  icon,
  value,
  defaultValue,
  error,
  className = '',
  tooltip,
  readOnly = false,
  attrs = {},
  onChange,
  ref,
}) => {
  const getError = (errorList: Error[] = []) => {
    const errorMsg = errorList.find(err => err.key === name)
    return errorMsg ? errorMsg.message : undefined
  }
  const errorMsg = Array.isArray(error) ? getError(error) : error

  return (
    <SimpleField className={`simple-field-knt ${className}`}>
      {label && (
        <label htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && (
            <SimpleTooltip
              id={name}
              className="top-right-form"
              text={tooltip}
            />
          )}
        </label>
      )}
      <input
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        required={required}
        readOnly={readOnly}
        className="outline-none"
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attrs}
      />
      {icon}
      {errorMsg && (
        <span className="error-knt fz-11 text-danger float-left mt-2">
          {errorMsg}
        </span>
      )}
    </SimpleField>
  )
}
