import React, { lazy, useEffect, useRef, useState, Suspense } from 'react'
import ReactGA from 'react-ga4'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import LazyLoad from 'react-lazyload'
import { MdPeople } from 'react-icons/md'
import { Link } from 'react-router-dom'

import buildBook from '../../assets/images/4000.jpg'
import communications from '../../assets/images/communication.jpg'
import shareVibe from '../../assets/images/shareVibe.jpg'
import chartLeft from '../../assets/images/donut-chart-left.svg'
import chartRight from '../../assets/images/donut-chart-right.svg'
import { CakeIcon } from '../../components/customIcons'
import { salmon } from '../../styles/themeColors'
import { useOnScreen } from '../../common'

import { Benefit, Testimony, VideoBG } from './components'

import '../../styles/home.sass'

const TopSearches = React.lazy(() => import('./components/TopSearches'))

export const PublicHome: React.FC = () => {
  const intersectTarget = useRef<HTMLDivElement>(null)
  const [registerModal, openRegisterModal] = useState(false)
  const intersected = useOnScreen(intersectTarget)
  const [shouldLoadModal, setShouldLoadModal] = useState(false)

  useEffect(() => {
    if (!sessionStorage.getItem('reached') && !intersected) {
      sessionStorage.setItem('reached', 'reached')
      ReactGA.event({
        category: 'Scroll',
        action: 'The user reached the "makeout sessions" section',
      })
    }
  }, [intersected])

  const signupGA = (label: string): void => {
    openRegisterModal(true)
    if (!shouldLoadModal) {
      setShouldLoadModal(true)
    }
    ReactGA.event({
      category: 'Sign Up',
      label,
      action: 'User clicked the CTA button on the homepage',
    })
  }

  const ConversionlyModal = lazy(
    () => import('../../components/SigninSignup/ConversionlyModal')
  )

  return (
    <>
      <VideoBG
        childrenClassName="hero"
        poster="/img/poster.jpg"
        url="https://player.vimeo.com/progressive_redirect/playback/1034058339/rendition/720p/file.mp4?loc=external&signature=9141f30cdabbb02867df63d593d52870721f544831f740ca5dd72d901ba3f6c6"
        videography="Gallardo Films"
      >
        <h1 className="font-lf">How Dreamers Plan Paradise</h1>
        <h2>For Couples Who Go the Extra Mile</h2>
        <Button
          onClick={() => signupGA('Main Banner')}
          className="fwsb py-2 py-lg-3 rounded-pill"
        >
          Start Planning
        </Button>
      </VideoBG>
      <section className="public-container public-home">
        {/** this is to lazy load the modal component only when the button is clicked */}
        {shouldLoadModal && (
          <Suspense fallback={<></>}>
            <ConversionlyModal
              open={registerModal}
              onClose={() => openRegisterModal(false)}
            />
          </Suspense>
        )}

        <section className="benefits py-md-4">
          <h2 className="px-3">
            Hands-on or hands-off as you may be, we'll coach you every step of
            the way….for FREE!
          </h2>
          <span className="line" />
          <Row className="my-4">
            <Col xs={12} sm={4}>
              <Benefit data={0} />
            </Col>
            <Col xs={12} sm={4}>
              <Benefit data={1} />
            </Col>
            <Col xs={12} sm={4}>
              <Benefit data={2} />
            </Col>
          </Row>
          <Suspense
            fallback={<div className="text-center py-3">Loading...</div>}
          >
            <TopSearches />
          </Suspense>
        </section>
        <section className="general-info my-5 build-and-book-your-event">
          <div className="info m-auto">
            <h2 className="mb-3">4000 Venues & Counting...</h2>
            <p>
              You have the vision, and we have the resources, globally! From
              Mexico, the Caribbean, Europe and beyond, we have thousands of
              venues and vendors ready to make your dream wedding come true.
            </p>
            <Link to="/marketplace">
              <Button className="fwsb" style={{ width: '205px' }}>
                Search venues
              </Button>
            </Link>
          </div>
          <LazyLoad height={300} once>
            <Image
              src={buildBook}
              alt="Build and Book Your Event"
              fluid
              className="w99"
            />
          </LazyLoad>
        </section>
        <Testimony data={0} />
        <section className="general-info my-5 revolutionary-group">
          <LazyLoad height={300} once>
            <Image
              src={communications}
              alt="Curated Expertise & Coaching"
              style={{
                height: '560px',
                width: '780px',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </LazyLoad>
          <div className="info m-auto">
            <h2 className="mb-3">Curated Expertise & Coaching</h2>
            <p>
              Our coaching sessions, curated expertise and logistics help come
              free of charge, with no additional expense. Like everyone else in
              the business, we make our money from the hotels and venues. These
              are baked in prices whether you use us or not, so why not have a
              team on your side while planning the wedding of your dreams!
            </p>
            <Button
              onClick={() => signupGA('Curated Expertise & Coaching')}
              className="fwsb"
              style={{ width: '250px' }}
            >
              Schedule a Session
            </Button>
          </div>
        </section>
        <Testimony data={1} />
        <section className="general-info my-5 guest-list-group-travel">
          <div className="info m-auto">
            <h2 className="mb-3">Your Vibe is our Mission</h2>
            <p>
              Once we have your wedding vision; location, vibe, group size and
              budget, our goal is to show you 10 beautiful and spot on locations
              within 72 hours. More options needed? We got you!
            </p>
            <Button
              onClick={() => signupGA('Your Vibe is our Mission')}
              className="fwsb"
              style={{ width: '230px' }}
            >
              Share Your Vibe
            </Button>
          </div>
          <LazyLoad height={300} once>
            <Image src={shareVibe} alt="Your Vibe is our Mission" fluid />
          </LazyLoad>
        </section>
        <Testimony data={2} />
        <Container
          as="section"
          fluid
          className="charts mt-4 mt-md-0"
          ref={intersectTarget}
        >
          <Row>
            <Col className="wedding" xs={12} sm={6}>
              <CakeIcon width="30px" height="33px" />
              <h2>
                We <i className="text-primary">solve the pain points </i> that
                cause couples to give up on a destination wedding
              </h2>
              <img src={chartLeft} alt="Wedding chart" className="my-4" />
              <p>
                <b className="text-primary">40%</b> of couples consider a
                destination wedding
              </p>
              <p>
                <b className="text-primary">21%</b> actually book{' '}
              </p>
              <p>
                <b className="text-primary">19%</b> throw in the towel
              </p>
              <p className="pt-4 pb-2">
                Attendees of destination weddings are twice as likely to return!
              </p>
            </Col>
            <Col className="people" xs={12} sm={6}>
              <MdPeople size={35} color={salmon} />
              <h2>
                We offer guests what they need, plus give them a voice in the
                conversation
              </h2>
              <img src={chartRight} alt="Wedding chart" className="my-4" />
              <p>
                <span className="text-primary">92%</span> of guests ignore
                personal wedding website fluff and one sided communications,
                only accessing event details, accommodations links or gift
                registries.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
