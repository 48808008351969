import gql from 'graphql-tag'

import { GUEST_FIELDS_FRAG } from './eventQueries'

export const CREATE_GUEST = gql`
  mutation createGuest($eventId: ID!, $input: GuestInput!, $tierIds: [ID!]) {
    createGuest(eventId: $eventId, input: $input, tierIds: $tierIds) {
      result {
        ...GuestFields
      }
      errors {
        key
        message
      }
    }
  }
  ${GUEST_FIELDS_FRAG}
`

export const UPDATE_GUEST = gql`
  mutation updateGuest($id: ID!, $input: GuestInput!, $tierIds: [ID!]) {
    updateGuest(id: $id, input: $input, tierIds: $tierIds) {
      result {
        ...GuestFields
      }
      errors {
        key
        message
      }
    }
  }
  ${GUEST_FIELDS_FRAG}
`

export const DELETE_GUEST = gql`
  mutation deleteGuest($id: ID!) {
    deleteGuest(id: $id) {
      result {
        id
        invitedBy {
          id
        }
        event {
          id
        }
      }
      errors {
        key
        message
      }
    }
  }
`
