import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import toNumber from 'lodash/fp/toNumber'
import min from 'lodash/fp/min'
import round from 'lodash/round'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { loadStripe } from '@stripe/stripe-js'
import { useMutation } from '@apollo/react-hooks'
import { useStripe, Elements } from '@stripe/react-stripe-js'

import { Quote } from '../../../../types'
import { errorAlert, loaderAlert } from '../../../../common'
import { getEventTotal, getDeadline } from '../../eventFunctions'
import { FormatNumber } from '../../../../components'
import { INITIATE_HOST_PAYMENT } from '../../../../graphql'
import { STRIPE_KEY, STRIPE_KEY_DEV } from '../../../../common/constants'

interface IBalance {
  quotes: Quote[]
  paid: string
  providerId: string
  eventId: string
}

const BalanceForm: React.FC<IBalance> = React.memo(
  ({ quotes, paid, eventId, providerId }) => {
    const stripe = useStripe()
    const alreadyPaid = toNumber(paid || 0)
    const [amount, setAmount] = useState(0)
    const [errorAmount, setErrorAmount] = useState(false)
    const [initiatePayment, { loading }] = useMutation(INITIATE_HOST_PAYMENT)

    const total = getEventTotal(quotes)
    const defaultPayment = round(total * 0.1, 2) // 10%
    const totalAfterPayment = total - alreadyPaid

    useEffect(() => {
      if (amount <= 0 && alreadyPaid < total) {
        /**
         * e.g:
         * total: 10,000
         * paid: 9,500
         * defaultPayment: 1,000 (10% of the total)
         * totalAfterPayment: 500
         * so, the amount should be 500 instead of 1,000
         */
        const defaultAmount = min([totalAfterPayment, defaultPayment]) || 0
        setAmount(defaultAmount)
      }
    }, [alreadyPaid, amount, defaultPayment, total, totalAfterPayment])

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = toNumber(ev.currentTarget.value)
      if (value > totalAfterPayment) {
        setAmount(totalAfterPayment)
      } else if (value >= 0) {
        setAmount(value)
      }
    }

    const handleSubmit = (ev: React.FormEvent) => {
      ev.preventDefault()
      if (amount <= 0 || !stripe) return
      if (alreadyPaid <= 0 && amount < defaultPayment) {
        setErrorAmount(true)
        return
      }

      setErrorAmount(false)
      loaderAlert({ html: 'preparing your payment...' })

      const variables = { amount, eventId, providerId }
      initiatePayment({ variables })
        .then(({ data }) => {
          const pay = data?.initiateHostPayment
          Swal.close()
          if (pay?.errors.length > 0) {
            errorAlert(pay?.errors, 'there was an error preparing your payment')
          } else {
            stripe.redirectToCheckout({ sessionId: pay?.result?.id })
          }
        })
        .catch(error => errorAlert([], error.message))
    }

    return (
      <Card className="my-4 p-2 fz-14">
        <h5 className="mx-auto">Balance</h5>
        <table className="mb-2 mx-auto fwsb" style={{ width: 'fit-content' }}>
          <tbody>
            <tr>
              <td>Paid</td>
              <td className="px-2">/</td>
              <td>Total</td>
            </tr>
            <tr>
              <td>
                <FormatNumber n={alreadyPaid} />
              </td>
              <td className="px-2">/</td>
              <td>
                <FormatNumber n={total} />
              </td>
            </tr>
          </tbody>
        </table>
        {alreadyPaid > 0 && (
          <p className="mt-1 text-center">
            <FormatNumber n={total - alreadyPaid} /> due by{' '}
            {getDeadline(quotes)}
          </p>
        )}
        {total > 0 && (
          <form className="container mt-2" onSubmit={handleSubmit}>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                required
                aria-label="Amount to pay"
                placeholder="Amount to pay"
                className="reset-input-style"
                type="number"
                step=".01"
                min={0}
                max={total}
                value={`${amount}`}
                onChange={handleChange}
              />
              <InputGroup.Append>
                <Button
                  data-cy="pay-deposit"
                  type="submit"
                  className=""
                  disabled={amount <= 0 || loading}
                >
                  Pay
                </Button>
              </InputGroup.Append>
            </InputGroup>
            {errorAmount && (
              <p className="my-0 text-danger fz-13">
                the minimum payment to continue is 10% ({' '}
                <FormatNumber n={defaultPayment} /> )
              </p>
            )}
          </form>
        )}
      </Card>
    )
  }
)

// to use useStripe the component must be wrapped by the <Elements> component
const { NODE_ENV } = process.env
const stripePromise = loadStripe(
  NODE_ENV === 'development' ? STRIPE_KEY_DEV : STRIPE_KEY
)
export const Balance: React.FC<IBalance> = ({
  quotes,
  paid,
  eventId,
  providerId,
}) => (
  <Elements stripe={stripePromise}>
    <BalanceForm
      quotes={quotes}
      paid={paid}
      eventId={eventId}
      providerId={providerId}
    />
  </Elements>
)
