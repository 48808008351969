import React, { useState, useEffect, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import AppContext from '../../context'
import { SimpleLoader } from '../../components'
import { VERIFY_ACCOUNT } from '../../graphql'
import { saveSelectedEvent, simpleAlertWithPromise } from '../../common'
import { myHub } from '../../common/constants'

export const Verify: React.FC = () => {
  const history = useHistory()
  const { auth } = useContext(AppContext)
  const { token, event } = useParams<{ token: string; event?: string }>()
  const [verify] = useMutation(VERIFY_ACCOUNT)
  const [msg, setMsg] = useState('')
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (!checked) {
      const variables = { secret: token, event }
      verify({ variables }).then(({ data }) => {
        if (data.verifyAccount.errors.length <= 0) {
          setMsg('Account verified successfully')

          auth.signInWithoutRequest(data.verifyAccount.result.token)
          simpleAlertWithPromise({
            html: 'Account verified successfully',
            icon: 'success',
          }).then(() => {
            if (event) {
              saveSelectedEvent(event)
            }
            history.push(myHub)
          })
        } else {
          setMsg('Verification could not be completed, Invalid token.')
        }

        setChecked(true)
      })
    }
  }, [token, verify, checked, auth, history, event])

  return (
    <div className="py-5 my-5">
      <h2 className="px-3 text-center mt-5">
        {msg || 'Verifying account, please wait a moment...'}
      </h2>
      {msg && (
        <Link to="/">
          <Button className="btn-lg rounded-pill mx-auto d-block mt-4">
            Go to home
          </Button>
        </Link>
      )}
      {!msg && <SimpleLoader />}
    </div>
  )
}
