import React from 'react'
import getOr from 'lodash/fp/getOr'
import { useQuery } from '@apollo/react-hooks'
import { useLocation } from 'react-router-dom'

import { MyEvent } from '../../types'
import { Dropdown, DropdownItem } from '../Dropdown'
import { GET_ALL_EVENTS } from '../../graphql'
import { SmallLoader } from '../SimpleLoader'

export const MyEvents: React.FC = () => {
  const { pathname } = useLocation()
  const { data, loading } = useQuery(GET_ALL_EVENTS, {
    fetchPolicy: 'cache-and-network',
  })
  const list: MyEvent[] = getOr([], 'me.myEventList', data)

  if (loading) return <SmallLoader />
  if (list.length <= 0) return null

  // only do the search if we are managing an event
  const activeEvent = pathname.includes('/event/')
    ? list.find(ev => pathname.indexOf(`/event/${ev.id}`) === 0)
    : undefined

  const items: DropdownItem[] = list.map(ev => ({
    text: ev.name.trim(),
    link: `/event/${ev.id}/settings`,
    active: ev.id === activeEvent?.id,
  }))
  return <Dropdown items={items} title={activeEvent?.name || 'Manage Events'} />
}
