import styled from 'styled-components'

import { salmon, darkGray } from '../../styles/themeColors'

export const Header = styled.header`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  height: 420px;
  justify-content: center;
  padding-top: 2rem;
  text-align: center;
  width: 100%;
  & .title,
  & .subtitle {
    margin: 0 auto;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    width: 95%;
  }
  & .title {
    font-size: 50px;
    font-weight: 300;
    max-width: 800px;
    padding-bottom: 40px;
  }
  & .subtitle {
    font-size: 16px;
    font-weight: 600;
    max-width: 830px;
    line-height: 1.7;
  }
  @media (max-width: 768px) {
    & .title {
      font-size: 35px;
      padding-bottom: 20px;
    }
    & .subtitle {
      font-size: 16px;
    }
  }
`

export const PartnershipYoutube = styled.section`
  & .PartnershipYoutube {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    &__info {
      display: inline-block;
      max-width: 472px;
      padding-left: 20px;
      width: 100%;
      & h2 {
        font-size: 35px;
        font-weight: 300;
      }
      & p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 20px;
      }
      @media (max-width: 768px) {
        padding: 0 5px 20px 5px;
      }
    }
  }
`

export const Segments = styled.section`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
  max-width: 1300px;
  padding: 3rem 0;
  width: 100%;
  @media (max-width: 540px) {
    padding: 1.5rem 0;
  }
`

export const Player = styled.article`
  padding: 1rem 0;
  width: 525px;
  & h1 {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 38px;
    justify-content: flex-start;
  }
  @media (max-width: 540px) {
    padding: 1rem 10px;
    & h1 {
      font-size: 15px;
    }
  }
`

export const ContactBanner = styled.section`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  width: 94%;
  & img {
    height: auto;
    width: 40%;
  }
  & .contactBannerInfo {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    text-align: left;
    width: 60%;
    & h2,
    & p {
      margin: 9px auto;
      max-width: 587px;
      width: 100%;
    }
    & h2 {
      font-size: 24px;
      font-weight: 700;
    }
    & p {
      font-size: 16px;
      & a {
        font-family: 'LuckyFellas';
        font-size: 30px;
        font-weight: 600;
        line-height: 16px;
        padding-right: 5px;
      }
    }
  }
  @media (max-width: 700px) {
    & img {
      width: 100%;
    }
    & .contactBannerInfo {
      padding: 20px 10px;
      width: 100%;
    }
  }
  @media (max-width: 700px) {
    & .contactBannerInfo {
      text-align: center;
    }
  }
`

export const GridCard = styled.article`
  margin: 0 auto 20px auto;
  position: relative;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  width: 92%;
  & img {
    height: auto;
    width: 100%;
  }
  & .offer {
    align-content: center;
    background-color: rgba(255, 110, 120, 0.8);
    border-radius: 50%;
    color: white;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.3rem;
    font-weight: 600;
    height: 100px;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 10px;
    text-align: center;
    width: 100px;
    & span {
      display: block;
      font-weight: 400;
      width: 100%;
    }
  }
  & .info {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px 15px;
    width: 100%;
    & h3 {
      color: ${salmon};
      font-size: 20px;
      padding: 0 0 0 10px;
      & span {
        color: ${darkGray};
        display: block;
        font-size: 13px;
      }
    }
    & .price {
      color: ${darkGray};
      font-size: 16px;
      & img {
        height: 30px;
        width: 30px;
      }
      & span {
        font-weight: 600;
        padding: 0 5px;
      }
    }
  }
  & .extra-data {
    color: ${darkGray};
    padding: 0 20px;
  }
  &:hover {
    cursor: pointer;
    * {
      text-decoration: none;
    }
  }
`

export const GridSection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  & article:nth-child(4) {
    grid-column: auto / span 2;
    grid-row: auto / span 2;
    width: 96%;
  }
  & article:nth-child(11) {
    grid-column: 2 / span 2;
    grid-row: auto / span 2;
    width: 96%;
  }
  & article:nth-child(11),
  & article:nth-child(4) {
    & .offer {
      font-size: 1.7rem;
      height: 150px !important;
      width: 150px !important;
    }
  }
  justify-content: center;
  width: 100%;
`

export const TitleWithLine = styled.h2`
  overflow: hidden;
  text-align: center;
  &:before,
  &:after {
    background-color: rgba(0, 0, 0, 0.4);
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &:before {
    margin-left: -50%;
    right: 0.5em;
  }
  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
`
