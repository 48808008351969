import React, { useState } from 'react'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../../styles/Datepicker.sass'

import { useIsMobile } from '../../common'

interface IRangePicker {
  id?: string
  emitDate?: (
    startDate: moment.Moment | null,
    endDate: moment.Moment | null
  ) => void
  emitStartDate?: (date: moment.Moment | null) => void
  emitEndDate?: (date: moment.Moment | null) => void
  initStartDate?: moment.Moment
  initEndDate?: moment.Moment
  minDate?: moment.Moment
  maxDate?: moment.Moment
  className?: string
  style?: React.CSSProperties
}

export const RangePicker: React.FC<IRangePicker> = ({
  id,
  emitDate,
  emitEndDate,
  emitStartDate,
  initStartDate,
  initEndDate,
  className = '',
  minDate,
  maxDate,
  style,
}) => {
  const isMobile = useIsMobile()
  const [focused, setFocused] = useState<'startDate' | 'endDate' | null>(null)
  const [finalDate, setFinalDate] = useState<moment.Moment | null>(
    initEndDate || null
  )
  const [initialDate, setInitialDate] = useState<moment.Moment | null>(
    initStartDate || null
  )

  const setMinDate = minDate || moment().add(1, 'day').startOf('day')
  const setMaxDate = maxDate || moment().add(2, 'year').endOf('day')

  const isOutsideRange = (date: moment.Moment) => {
    return date.isBefore(setMinDate) || date.isAfter(setMaxDate)
  }

  return (
    <div className={className} style={style}>
      <DateRangePicker
        isOutsideRange={isOutsideRange}
        startDate={initialDate}
        startDateId={`start-date-${id || Math.random()}`}
        endDatePlaceholderText="select your check out date"
        startDatePlaceholderText="select your check in date"
        endDate={finalDate}
        endDateId={`end-date-${id || Math.random()}`}
        onDatesChange={({ startDate, endDate }) => {
          if (startDate) {
            setInitialDate(startDate)
          }
          if (endDate) {
            setFinalDate(endDate)
            setFocused('startDate')
          }

          if (emitDate) {
            emitDate(startDate, endDate)
          }
          if (emitStartDate) {
            emitStartDate(startDate)
          }
          if (emitEndDate) {
            emitEndDate(endDate)
            setFocused(null)
          }
        }}
        focusedInput={focused}
        onFocusChange={focusedInput => setFocused(focusedInput)}
        maxDate={setMaxDate}
        minDate={setMinDate}
        hideKeyboardShortcutsPanel
        readOnly
        keepOpenOnDateSelect
        numberOfMonths={isMobile ? 1 : 2}
      />
    </div>
  )
}
