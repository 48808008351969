import React from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { MdInfo } from 'react-icons/md'

import { mediumGray } from '../../styles/themeColors'

import { TooltipContainer } from './style'

interface ISimpleTooltip {
  color?: string
  text: string
  id: string
  className?: string
}

export const SimpleTooltip: React.FC<ISimpleTooltip> = ({
  color = mediumGray,
  text,
  className = '',
  id,
}) => {
  const delay = { show: 100, hide: 200 }

  return (
    <TooltipContainer className={className}>
      <OverlayTrigger
        delay={delay}
        overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
      >
        <MdInfo className="icon-eventRate cursor" color={color} />
      </OverlayTrigger>
    </TooltipContainer>
  )
}
