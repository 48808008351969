import React from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { MdCalendarToday } from 'react-icons/md'

import { EventComponent } from '../../../types'
import { salmon } from '../../../styles/themeColors'
import { PackageCustomizations } from '../../../components'
import { getCheckIn, orderPackageAddons } from '../../../common'

import { AddToCalendar } from './AddToCalendar'
import { EventComponentOffering } from './EventComponentOffering'

// I am creating a new component instead of using the existing one because that component already has enough logic to add more complexity
// so I prefer to have an extra component but simpler to understand/read
export const EventCardSelection: React.FC<{
  component: EventComponent
  eventName: string
}> = ({ component, eventName }) => {
  const { alias, offering, date, packageCustomization } = component
  const pc = orderPackageAddons(packageCustomization)

  return (
    <Card className="my-3">
      <Row>
        <Col xs={12} lg={5}>
          <EventComponentOffering
            className="mt-0 rounded-25-0-0-25"
            eventComponent={component}
            big
            hideText
          />
        </Col>
        <Col xs={12} lg={7}>
          <div className="border-b-1 d-flex justify-content-between flex-wrap flex-lg-nowrap px-3 px-lg-0 py-3">
            <h2 className="fwb fz-24">{alias || offering.name}</h2>
          </div>
          <div className="border-b-1 d-flex justify-content-between flex-wrap flex-lg-nowrap align-items-start px-3 px-lg-0 py-2">
            <div className="mr-auto">
              <span className="fz-18 fwsb d-block text-primary">
                {offering.provider.name}
              </span>

              <p className="fwsb">{offering.provider.location}</p>
            </div>
            <div className="w-100 d-block d-lg-none" />
            <div className="fwsb px-3">
              <div className="d-flex align-items-center">
                <MdCalendarToday size={36} color={salmon} className="m-2" />
                <span className="d-inline-block">
                  {getCheckIn(date, 'MMM Do, YYYY')} <br />
                  {getCheckIn(date, 'dddd')}
                </span>
              </div>
              <AddToCalendar
                hideIcon
                eventName={eventName}
                offering={offering}
                date={date}
                alias={alias}
                className="text-primary px-3"
              />
            </div>
          </div>
          {pc && <PackageCustomizations customizations={pc} />}
        </Col>
      </Row>
    </Card>
  )
}
