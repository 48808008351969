import React, { useState, Suspense } from 'react'
import ReactGA from 'react-ga4'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FaPinterestSquare } from 'react-icons/fa'
import { NavLink, Link, useLocation } from 'react-router-dom'

import { SiteLogo, FB, IG, TW } from '../../components/customIcons'
import footerBanner from '../../assets/images/footer-banner.jpg'
import ConversionlyModal from '../../components/SigninSignup/ConversionlyModal'
import { mediumGray } from '../../styles/themeColors'

import { FooterHome } from './style'

export const Footer: React.FC = () => {
  const location = useLocation()
  const [showConversionlyModal, setShowConversionlyModal] = useState(false)
  const [shouldLoadModal, setShouldLoadModal] = useState(false)

  const registerLink = (
    ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    ev.preventDefault()
    ReactGA.event({
      category: 'Sign Up',
      label: 'Footer link',
      action: 'User clicked the register link on the footer',
    })
    if (!shouldLoadModal) {
      setShouldLoadModal(true)
    }
    setShowConversionlyModal(true)
  }

  /**
   * Right now we only hide the footer on /locations
   * in case we need to hide it on other pages we can create an array of pages pathname and check with array.includes(location.pathname)
   * or we can move this logic to the Layout component
   */
  return (
    <FooterHome
      className={`pt-4 pb-3 ${
        location.pathname === '/locations' && 'd-none d-lg-block'
      }`}
    >
      {shouldLoadModal && (
        <Suspense fallback={<></>}>
          <ConversionlyModal
            open={showConversionlyModal}
            onClose={() => setShowConversionlyModal(false)}
          />
        </Suspense>
      )}
      <Container className="mt-3 pt-2">
        <Row className="fwsb">
          <Col xs={6} sm={4} lg={2} className="px-3 pr-sm-0">
            <p className="fz-13 tc-gray mb-1">EXPLORE</p>
            <NavLink className="d-block" to="/">
              Home
            </NavLink>
            <NavLink className="d-block" to="/" onClick={registerLink}>
              Plan an event
            </NavLink>
            <NavLink className="d-block" to="/partnerships">
              Partner with us
            </NavLink>
            <NavLink className="d-block" to="/engagement-trip">
              Free Engagement Trip
            </NavLink>
            <NavLink className="d-block" to="/about-us">
              About us
            </NavLink>
          </Col>
          <Col xs={6} sm={4} lg={2}>
            <p className="fz-13 tc-gray mb-1">MARKETPLACE</p>
            <NavLink className="d-block" to="/marketplace">
              Locations
            </NavLink>
            <NavLink className="d-block" to="/locations">
              Vendors
            </NavLink>
          </Col>
          <Col xs={12} sm={4} lg={2} className="pt-4 pt-sm-0">
            <p className="fz-13 tc-gray mb-1">SOCIAL</p>
            <div>
              <a
                href="https://business.facebook.com/DoYouKissAndTell"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FB />
              </a>
              <a
                href="https://www.instagram.com/doyoukissandtell"
                rel="noopener noreferrer"
                target="_blank"
              >
                <IG />
              </a>
              <a
                href="https://twitter.com/DoYouKissNTell"
                rel="noopener noreferrer"
                target="_blank"
              >
                <TW />
              </a>
              <a
                href="https://www.pinterest.com/DoYouKissAndTell/boards/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaPinterestSquare color="858484" size={22} className="mx-1" />
              </a>
            </div>
          </Col>
          <Col xs={12} lg={6} className="py-3 py-lg-0">
            <Link to="/referrals">
              <img src={footerBanner} alt="Referral" className="img-fluid" />
            </Link>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col className="fz-13 fwsb tc-lgray">
            <p className="d-block d-lg-inline-block">
              <SiteLogo width="27px" height="24px" color={mediumGray} />
              <span className="d-inline-block" style={{ width: '5px' }} />
              Copyright © {new Date().getFullYear()} KissandTell.com, a
              Subsidiary of Life Unflitered Media, Inc.
            </p>
            <NavLink
              className="d-inline-block terms ml-0 ml-lg-3"
              to="/terms-of-use"
            >
              Terms of Use
            </NavLink>
            <span className="mx-2">and</span>
            <NavLink className="d-inline-block terms" to="/privacy-policy">
              Privacy Policy
            </NavLink>
          </Col>
        </Row>
      </Container>
    </FooterHome>
  )
}
