import React, { useState } from 'react'
import get from 'lodash/fp/get'
import pick from 'lodash/fp/pick'
import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import replace from 'lodash/fp/replace'
import kebabCase from 'lodash/fp/kebabCase'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { MdOutlineContentCopy } from 'react-icons/md'

import {
  IGenericErrorObject,
  MyEvent,
  Event,
  Error,
  Maybe,
  GetAllEventsQuery,
  ISettingsCard,
  PickerResponse,
} from '../../../../types'
import { useForm } from '../../../../common/alterForms'
import { simpleAlert, useCurrentUser } from '../../../../common'
import {
  KnTField,
  InputSwitch,
  Textarea,
  UploadImg,
} from '../../../../components'
import { UPDATE_EVENT, DELETE_EVENT, GET_ALL_EVENTS } from '../../../../graphql'
import defaultPicture from '../../../../assets/images/rsvp/user.jpg'
import { APP_URL, myHub } from '../../../../common/constants'

import { EventPicture } from './style'

export const SettingsCard: React.FC<ISettingsCard> = ({
  event,
  setPreviewHelper,
  previewHelper,
}) => {
  const history = useHistory()
  const [showMsg, setShowMsg] = useState(false)
  const [showError, setShowError] = useState(false)
  const [copied, setCopied] = useState(false)
  const [isPrivate, setIsPrivate] = useState(() => event.isPrivate)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogError, setDialogError] = useState('')
  const [deleteEventName, setDeleteEventName] = useState('')
  const [UploadImageMsg, setUploadImageMsg] = useState('')
  const {
    model,
    errors,
    handleFieldChange,
    handleCheckboxChange,
    pushError,
    reset,
  } = useForm<
    Pick<
      MyEvent,
      | 'name'
      | 'defaultKidsAllowed'
      | 'defaultPlusOne'
      | 'description'
      | 'mainImage'
      | 'emailCustomText'
      | 'customUrl'
    >
  >(
    pick(
      [
        'name',
        'defaultKidsAllowed',
        'defaultPlusOne',
        'description',
        'mainImage',
        'emailCustomText',
        'customUrl',
      ],
      event
    )
  )
  const [updateEvent, { loading }] = useMutation(UPDATE_EVENT, {
    onError: () => {
      simpleAlert({
        html: 'Unauthorized access',
        icon: 'error',
      })
    },
  })
  const [handleDeleteEvent, { loading: loadingDelete }] = useMutation(
    DELETE_EVENT,
    {
      update(cache, { data: { deleteEvent } }) {
        if (deleteEvent.errors.length === 0) {
          const cached: Maybe<GetAllEventsQuery> = cache.readQuery({
            query: GET_ALL_EVENTS,
          })
          if (cached) {
            const myEvents = get('me.myEventList', cached) || []
            const otherEvents = get('me.eventList', cached) || []

            const myEventsFiltered = myEvents.filter(
              (ev: MyEvent) => ev.id !== deleteEvent.result.id
            )
            const otherEventsFiltered = otherEvents.filter(
              (ev: Event) => ev.id !== deleteEvent.result.id
            )

            const me = flow(
              set('me.myEventList', myEventsFiltered),
              set('me.eventList', otherEventsFiltered)
            )(cached)

            cache.writeQuery({
              query: GET_ALL_EVENTS,
              data: me,
            })
          }
        }
      },
      onError: () => {
        // in case a user different from :admin or :concierge tries to create an event
        simpleAlert({
          html: 'Unauthorized access',
          icon: 'error',
        })
      },
    }
  )
  const { userId } = useCurrentUser()

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const variables = {
      input: {
        ...model,
        isPrivate,
        customUrl: flow(
          replace(/[\\/]/g, ''),
          kebabCase
        )(model.customUrl as string),
      },
      id: event.id,
    }
    updateEvent({ variables }).then(({ data, errors: errorsGraphql }) => {
      if (errorsGraphql) return
      if (data?.updateEvent?.errors.length > 0) {
        data.updateEvent.errors.forEach(({ key, message }: Error) => {
          pushError(message, key)
        })
        setShowError(true)
        setTimeout(() => setShowError(false), 4000)
        return
      }

      setShowMsg(true)
      setTimeout(() => setShowMsg(false), 4000)
      setUploadImageMsg('')
      setPreviewHelper(previewHelper + 1)
    })
  }

  const callDeleteEvent = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    if (deleteEventName !== model.name) {
      setDialogError('It seems that the name is not correct...')
    } else {
      const variables = { id: event.id }
      handleDeleteEvent({ variables })
        .then(({ data }) => {
          if (data.deleteEvent.errors.length > 0) {
            data.deleteEvent.errors.forEach(({ message }: Error) =>
              setDialogError(message)
            )
          } else {
            setDeleteEventName('')
            setDialogError('')
            history.push(myHub)
          }
        })
        .catch(() => {
          setDialogError('Something went wrong, please try again later')
        })
    }
  }

  const onSuccess = (result: PickerResponse) => {
    const url: string = result?.filesUploaded[0]?.url || ''
    if (url) {
      reset({ ...model, mainImage: url })
      setUploadImageMsg('save to complete this change')
    }
  }

  return (
    <Container>
      <Modal show={showDialog} onHide={() => setShowDialog(false)}>
        <Modal.Body>
          <h5 className="font-weight-bold">Watch out!</h5>
          <p>
            this action is irreversible, enter the name of the event to continue
          </p>
          <input
            placeholder="Event name..."
            type="text"
            className="form-control"
            name="delete-my-event"
            onChange={ev => setDeleteEventName(ev.currentTarget.value)}
          />
          {dialogError && (
            <Form.Text className="text-muted">{dialogError}</Form.Text>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loadingDelete}
            onClick={callDeleteEvent}
            variant="primary"
          >
            Delete it
          </Button>
          <Button onClick={() => setShowDialog(false)} variant="secondary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Form onSubmit={handleSubmit} className="pt-4">
        <Card className="mt-3">
          <Card.Body>
            <KnTField
              error={errors}
              value={model}
              setValue={handleFieldChange}
              name="name"
              label="Event Name"
            />
            <KnTField
              error={errors}
              value={model}
              setValue={handleFieldChange}
              name="customUrl"
              label="Custom URL"
              required
              placeholder="e.g. my-event-2022 or my birthday party"
            />
            <span className="px-3 py-1 d-block">
              {APP_URL}/invite/{event.customUrl || '{your custom url}'}
              {event.customUrl && (
                <>
                  <MdOutlineContentCopy
                    size={16}
                    className="cursor ml-2"
                    title="copy to clipboard"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${APP_URL}/invite/${event.customUrl}`
                      )
                      setCopied(true)
                      setTimeout(() => setCopied(false), 2000)
                    }}
                  />
                  {copied && <span className="ml-2">Copied!</span>}
                </>
              )}
            </span>
            <Textarea
              rows={5}
              required={false}
              error={errors as IGenericErrorObject}
              value={model.description}
              change={handleFieldChange}
              name="description"
              label="Personalize a welcome message to your guests"
              className="custom-input-style-textarea"
              placeholder="This is the initial message sent to all guests and can change as your event planning and details progress. Feel free to address your guests with a personal message here."
            />
            <Textarea
              rows={5}
              required={false}
              error={errors as IGenericErrorObject}
              value={model.emailCustomText}
              change={handleFieldChange}
              name="emailCustomText"
              label="Personalize an email invite to your guests"
              className="custom-input-style-textarea"
            />

            <Row>
              <Col xs={12} md={6}>
                <KnTField
                  type="number"
                  error={errors}
                  value={model}
                  setValue={handleFieldChange}
                  valueType="INTEGER"
                  name="defaultPlusOne"
                  label="How many +1s allowed?"
                  className="custom-input-style-simple"
                  attrs={{ min: 0 }}
                />
                <InputSwitch
                  value={!!model.defaultKidsAllowed}
                  change={handleCheckboxChange}
                  name="defaultKidsAllowed"
                  label="Kids allowed by default?"
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Check
                  type="radio"
                  label="Private event"
                  name="isPrivate"
                  id="private-event"
                  checked={isPrivate}
                  className="mt-2 mt-md-4 fwsb fz-14 custom-radio"
                  onChange={() => setIsPrivate(true)}
                />

                <Form.Check
                  type="radio"
                  label="Public event"
                  name="isPrivate"
                  id="public-event"
                  checked={!isPrivate}
                  className="mt-2 mt-md-4 fwsb fz-14 custom-radio"
                  onChange={() => setIsPrivate(false)}
                />
              </Col>
            </Row>

            <hr />
            <EventPicture className="my-5">
              <img
                src={model.mainImage || defaultPicture}
                alt={model.name}
                className="event-picture-landing"
              />
              <UploadImg
                text="Edit Image"
                onSuccess={onSuccess}
                msg={UploadImageMsg}
                storeTo={{
                  container: 'kt.user.images',
                  location: 's3',
                  path: `/${userId}/events/${event.id}/`,
                  region: 'us-east-1',
                }}
                aspectRatio={4 / 3}
              />
            </EventPicture>

            {showMsg && (
              <Alert variant="success" className="fz-13">
                Settings updated successfully
              </Alert>
            )}
            {showError && (
              <Alert variant="danger" className="fz-13">
                It was not possible to update the settings
              </Alert>
            )}
          </Card.Body>
          <Card.Footer className="text-right">
            <Button
              onClick={() => setShowDialog(true)}
              className="fz-14 fwsb float-left"
              variant="dark"
            >
              Delete Event
            </Button>
            <Button
              disabled={loading}
              type="submit"
              variant="primary"
              className="fz-14 fwsb"
            >
              Save changes
            </Button>
          </Card.Footer>
        </Card>
      </Form>
    </Container>
  )
}
