import React, { Suspense } from 'react'

import { SmallLoader } from '../../components'

const Invite = React.lazy(() => import('./Invite'))

export const InvitePage: React.FC = () => {
  // this page is to lazy load the Invite because it's only used for the unauthenticated flow
  // and we don't want to load it for any other page
  return (
    <Suspense
      fallback={
        <div className="w-100 text-center py-5">
          <SmallLoader />
        </div>
      }
    >
      <Invite />
    </Suspense>
  )
}
