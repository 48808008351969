import React from 'react'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import kebabCase from 'lodash/fp/kebabCase'
import { Link } from 'react-router-dom'

import { IFilterInputs } from '../../../types'
import { makeSearchUrl } from '../LocationFunctions'

import { IMarketplaceCategory } from './temporaryStaticData'

import { LocationCard } from '.'

export const MarketplaceCategory: React.FC<{
  category: IMarketplaceCategory
}> = ({ category }) => {
  const { name, providers } = category
  const filters = { what: name } as IFilterInputs

  return (
    <Container as="section" className="mt-5 pb-4">
      <h2 id={kebabCase(name)} className="w-100">
        {category.name}
      </h2>
      <Row>
        {providers.map(el => (
          <LocationCard groupSize={20} key={el.id} data={el} />
        ))}
      </Row>
      <Link
        to={makeSearchUrl('/locations', filters)}
        className="d-inline-block"
        style={{ marginTop: '10px' }}
      >
        <Button className="rounded-pill px-4 btn-lg">
          Search {category.name}
        </Button>
      </Link>
    </Container>
  )
}
