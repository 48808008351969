import get from 'lodash/fp/get'
import { useHistory } from 'react-router-dom'

import {
  IaddToast,
  IrenderToast,
  IuseToast,
  SimpleNotificationBody,
} from '../types'
import knt from '../assets/images/icons/knt.png'

import { getImageUrl } from '.'

export const makeSimpleNotification = (
  payload: SimpleNotificationBody
): { text: string; link: string } => {
  const { type } = payload.meta
  if (type === 'PackageCustomization') {
    const eventId = get('meta.event_id', payload)
    const providerId = get('meta.provider_id', payload)
    return {
      text: payload.message,
      link: `/planning/${eventId}/${providerId}`,
    }
  }

  return { text: '', link: '' }
}

export const useToast = (): IuseToast => {
  const history = useHistory()

  const renderToast = ({ text, img, delay, link }: IrenderToast) => {
    const container = document.getElementById('toast-container') as HTMLElement
    const toastContainer = document.createElement('div')
    toastContainer.id = `toast-${Math.random().toString(36).substring(2, 10)}`
    toastContainer.className = 'custom-toast card fz-13 mt-3 shadow'
    const toastLink = document.createElement('a')
    toastLink.className = 'cursor d-flex align-items-center tc-gray h-100 w-100'

    if (img) {
      const imageToast = document.createElement('img')
      imageToast.src = img
      imageToast.className = 'mr-3'
      toastLink.append(imageToast)
      toastContainer.classList.add('pr-3')
    } else {
      toastContainer.classList.add('p-3')
    }

    const toastText = document.createTextNode(`${text}`)
    toastLink.append(toastText)

    if (link) {
      toastLink.onclick = ev => {
        ev.preventDefault()
        history.push(link)
      }
    }

    const closeIcon = document.createElement('span')
    closeIcon.innerHTML = 'x'
    closeIcon.className = 'close-notification'
    closeIcon.onclick = ev => {
      ev.preventDefault()
      toastContainer.remove()
    }

    toastContainer.append(toastLink)
    toastContainer.append(closeIcon)
    container.append(toastContainer)
    setTimeout(() => toastContainer.remove(), delay)
  }

  const toast = ({ content, delay = 5000 }: IaddToast): void => {
    const type = content.payload?.__typename
    if (type === 'NoneBody') return

    let text: string
    let link = ''
    let img = ''
    let SN: { text: string; link: string } // Simple Notification
    switch (type) {
      case 'EventComponentAddedBody':
        text = get('payload.message', content)
        link = `/event/${get('payload.eventId', content)}/event-builder`
        img = getImageUrl(get('payload.image', content), '90x90')
        break
      case 'SimpleNotificationBody':
        SN = makeSimpleNotification(content.payload as SimpleNotificationBody)
        text = SN.text
        link = SN.link
        img = knt
        break
      case 'RsvpBody':
        text = get('payload.eventComponentName', content)
        break
      default:
        text = ''
    }
    if (!text) return

    renderToast({
      text,
      link,
      img,
      delay,
    })
  }

  /**
   * toast is for notifications that use subscription
   * simpleToast is in case we want to render a notification from an component X without a Notification object
   */
  return { toast, simpleToast: renderToast }
}
