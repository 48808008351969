import React from 'react'
import map from 'lodash/fp/map'
import set from 'lodash/fp/set'
import join from 'lodash/fp/join'
import flow from 'lodash/fp/flow'
import split from 'lodash/fp/split'
import chunk from 'lodash/fp/chunk'
import getOr from 'lodash/fp/getOr'
import snakeCase from 'lodash/fp/snakeCase'
import findIndex from 'lodash/fp/findIndex'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { MdClear } from 'react-icons/md'
import { useMutation } from '@apollo/react-hooks'

import { useAppSelector } from '../../../hooks'
import { Error, FeedQuery, Maybe, PostInput } from '../../../types'
import { simpleAlert, smsLimit } from '../../../common'
import {
  CREATE_FEED_POST,
  GET_EVENT_FEEDS,
  UPDATE_POST,
} from '../../../graphql'

import { SmsPreviewContainer } from './style'

export const SMSpreview: React.FC<{
  mode: 'edit' | 'create'
  closeModal: () => void
  show: boolean
  postInput: PostInput
  postId?: string
  callback?: () => void
  pushError?: (error: string, path: string) => void
  tierIds: string[]
}> = ({
  show,
  mode,
  closeModal,
  postInput,
  postId,
  pushError,
  callback,
  tierIds,
}) => {
  const { currentEventId } = useAppSelector(state => state.site)

  const MUTATION = mode === 'edit' ? UPDATE_POST : CREATE_FEED_POST
  const [handleMutation, { loading }] = useMutation(MUTATION, {
    update(cache, { data }) {
      const response = mode === 'edit' ? data.updatePost : data.createPost

      if (response.errors.length === 0) {
        const cached: Maybe<FeedQuery> = cache.readQuery({
          query: GET_EVENT_FEEDS,
          variables: { eventIds: [currentEventId] },
        })
        if (!cached) return
        const posts = getOr([], 'feed.entries', cached)
        const postIndex = findIndex({ id: postId }, posts)
        const newData =
          mode === 'edit'
            ? set(`feed.entries[${postIndex}]`, response.result, cached) // replace post with updated post
            : set('feed.entries', [response.result, ...posts], cached) // add new post to cache

        cache.writeQuery({
          query: GET_EVENT_FEEDS,
          variables: { eventIds: [currentEventId] },
          data: newData,
        })
      }
    },
  })

  const sendSms = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    if (loading || !postInput.body) return
    const input = {
      ...postInput,
      sms: true,
    }
    const variables =
      mode === 'edit'
        ? { id: postId, input, tierIds }
        : { input, eventId: currentEventId, tierIds }

    handleMutation({ variables })
      .then(({ data }) => {
        const errors =
          mode === 'edit' ? data.updatePost.errors : data.createPost.errors
        if (errors?.length > 0) {
          data.updatePost.errors.forEach(({ key, message }: Error) => {
            pushError?.(message, key)
          })
        } else {
          callback?.()
        }
        closeModal()
      })
      .catch(err => {
        simpleAlert({
          html: err?.message || 'An error occurred while sending post',
          icon: 'error',
        })
      })
  }

  const chunkText = (text: string): string[] =>
    flow(
      txt => `${txt}\nhttps://ktell.io/xpsmpw`,
      split(''),
      chunk(smsLimit(text)),
      map(join(''))
    )(text)

  if (!show || !postInput.body) return null
  return (
    <Modal centered show={show} onHide={closeModal}>
      <Modal.Header className="py-2">
        <h2 className="fwsb fz-18 m-0">SMS Preview</h2>
        <MdClear
          size={35}
          className="top-right p-2 cursor"
          onClick={closeModal}
        />
      </Modal.Header>
      <Modal.Body className="pt-2">
        <p className="tc-lgray fz-12 mb-2">
          Preview shown is an estimate based on common device settings. Actual
          SMS delivery and formatting may vary slightly
        </p>
        <SmsPreviewContainer>
          {chunkText(postInput.body).map(text => {
            return (
              <div className="sms-box" key={snakeCase(text.slice(0, 25))}>
                {text}
              </div>
            )
          })}
        </SmsPreviewContainer>
      </Modal.Body>
      <Modal.Footer className="py-1">
        <Button
          variant="secondary"
          onClick={closeModal}
          className="mr-auto px-3"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={sendSms}
          className="ml-auto px-4"
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
