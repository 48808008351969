import React from 'react'
import Button from 'react-bootstrap/Button'
import pull from 'lodash/fp/pull'
import { FaTimesCircle } from 'react-icons/fa'

import { Tier } from '../../types'

import ItemDisplay from './ItemDisplay'

interface ITiersSelector {
  hideClearButton?: boolean
  tiers: Tier[]
  selection: string[]
  onSelect?: (ids: string[]) => void
  onSelectSingle?: (id: string) => void
}

const Selector: React.FC<ITiersSelector> = ({
  tiers,
  selection,
  onSelect,
  hideClearButton = false,
  onSelectSingle,
}) => {
  return (
    <>
      {tiers.map(tier => (
        <ItemDisplay
          key={tier.id}
          tier={tier}
          active={tier.id ? selection.includes(tier.id) : false}
          className="mb-1"
          onToggle={tierId => {
            if (onSelectSingle) {
              onSelectSingle(tierId)
            }

            if (onSelect) {
              onSelect(
                selection.includes(tierId)
                  ? pull(tierId, selection)
                  : [...selection, tierId]
              )
            }
          }}
        />
      ))}
      {onSelect && selection.length > 0 && !hideClearButton && (
        <Button
          className="shadow-none"
          variant="light"
          onClick={() => {
            onSelect([])
          }}
        >
          <FaTimesCircle className="mb-1" />
        </Button>
      )}
    </>
  )
}
export default Selector
