import React from 'react'
import get from 'lodash/fp/get'
import debounce from 'lodash/debounce'
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'

import { GridHeader, GridRow, SortConfig } from './types'

export const generatePageNumbers = (
  currentPage: number,
  totalPages: number
): number[] => {
  const maxPageButtons = 7
  if (totalPages <= maxPageButtons) {
    return [...Array.from({ length: totalPages }, (_, index) => index + 1)]
  }
  if (currentPage <= 3) {
    return [1, 2, 3, -1, totalPages]
  }
  if (currentPage >= totalPages - 2) {
    return [1, -1, totalPages - 2, totalPages - 1, totalPages]
  }
  return [1, -1, currentPage - 1, currentPage, currentPage + 1, -1, totalPages]
}

export const getIcon = (
  key: string,
  sortConfig?: SortConfig
): React.ReactElement => {
  const icons = {
    default: <FaSort size={18} color="rgba(0, 0, 0, 0.2)" />,
    asc: <FaSortUp size={18} />,
    desc: <FaSortDown size={18} />,
  }
  if (!sortConfig) return icons.default
  return key === sortConfig.key ? icons[sortConfig.direction] : icons.default
}

export const handleInputChange = debounce(
  (value?: string, onChange?: (val?: string) => void, resetPg?: () => void) => {
    // extra logic if needed
    onChange?.(value)
    resetPg?.()
  },
  1000
)

export const getColumnIcon = (
  column: GridHeader,
  item: GridRow
): React.ReactElement | null => {
  const icon = get(`${column.key}Icon`, item) as React.ReactElement
  if (icon) return icon
  return null
}
