import React from 'react'
import Slider from 'react-slick'
import get from 'lodash/fp/get'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

import { getImageUrl } from '../../common'
import { Image, NavButtonProps, ILocationMetadata } from '../../types'
import { darkGray } from '../../styles/themeColors'

import { LocationOverview } from '.'
import '../../styles/react-slick.css'
import '../../styles/slider-multiple-knt.sass'

const NextArrow = ({ className, onClick }: NavButtonProps) => (
  <MdKeyboardArrowRight
    size={100}
    color={darkGray}
    className={className}
    onClick={onClick}
  />
)
const PrevArrow = ({ className, onClick }: NavButtonProps) => (
  <MdKeyboardArrowLeft
    size={100}
    color={darkGray}
    className={className}
    onClick={onClick}
  />
)

interface IExpandedGallery {
  data: Image[]
  afterChange: React.Dispatch<React.SetStateAction<number>>
  current: number
  hideMeta?: boolean
}

export const ExpandedGallery: React.FC<IExpandedGallery> = ({
  data = [],
  afterChange,
  current,
  hideMeta = false,
}) => {
  const settings = {
    className: 'slider-multiple-knt',
    dots: false,
    infinite: true,
    slidesToShow: data.length < 4 ? data.length : 4,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    swipeToSlide: true,
    focusOnSelect: true,
    afterChange,
  }
  const metadata: ILocationMetadata = get(`[${current}].metadata`, data)
  const currentImage = getImageUrl(get(`[${current}]`, data), '900x600')
  return (
    <div className="px-3 pb-1">
      <div
        className="expanded-cover-gallery"
        style={{
          backgroundImage: `url(${currentImage})`,
        }}
      />
      {data.length > 1 && (
        <Slider {...settings}>
          {data.map(el => {
            const image = getImageUrl(el, '900x600')

            return (
              <div key={el.id}>
                <div
                  className="slide cursor"
                  style={{ backgroundImage: `url(${image})` }}
                />
              </div>
            )
          })}
        </Slider>
      )}
      {!hideMeta && <LocationOverview data={metadata} />}
    </div>
  )
}
