import React, { useEffect, useState } from 'react'
import reverse from 'lodash/fp/reverse'
import reduce from 'lodash/fp/reduce'
import { useParams } from 'react-router-dom'

import {
  useCurrentUser,
  getStandardDate,
  replaceLineBreaksWithBR,
} from '../../../../common'
import { Quote, NegotiationMessage, IgenericObject } from '../../../../types'
import {
  DefaultUserPicture,
  UserPicture,
  FormatNumber,
} from '../../../../components'

import { useMarkMessagesAsRead } from './chatFunctions'

interface IRenderMsg {
  messages: NegotiationMessage[]
  quotes: Quote[]
}

const messageText = (text: string) => {
  if (!text.includes(':USD')) {
    replaceLineBreaksWithBR(text)
  }

  const resp = text.split(':USD')
  // temporal tricky fix to format the new price
  return (
    <>
      {replaceLineBreaksWithBR(resp[0])}{' '}
      <b>
        <FormatNumber n={resp[1]} />
      </b>
    </>
  )
}

export const RenderMessages: React.FC<IRenderMsg> = React.memo(
  ({ messages, quotes }) => {
    const [marked, setMarked] = useState(false)
    const [components] = useState<{ [x: string]: string }>(() => {
      return reduce(
        (accum: IgenericObject, element: Quote) => {
          return {
            ...accum,
            [element.eventComponent.id]: element.eventComponent.offering.name,
          }
        },
        {},
        quotes
      )
    })
    const { userId, role } = useCurrentUser()
    const { eventId, providerId } = useParams<{
      eventId: string
      providerId: string
    }>()
    const [markMessagesAsRead, loading] = useMarkMessagesAsRead(
      eventId,
      providerId,
      role
    )
    const filteredMessages = reverse(messages)

    const msg = messages[0] // last Message - most recent message
    useEffect(() => {
      // if the last message is not mine and has seen === false, mark as read
      const user = msg?.user
      if (user?.id !== userId && msg?.seen === false && !loading && !marked) {
        markMessagesAsRead()
        /**
         * the cache is already updated in the mutation but before the cache is updated
         * this function is executed several times, so I set this flag (marked) to avoid it
         */
        setMarked(true)
      } else if (msg?.seen === true) {
        /**
         * if the query has already been executed and the message is already marked as seen,
         * it should be possible to execute the action again in case of a new message.
         */
        setMarked(false)
      }
    }, [loading, markMessagesAsRead, msg, userId, marked])

    return (
      <>
        {filteredMessages.map(el => {
          const date = getStandardDate({ date: el.insertedAt })
          return (
            <div key={el.id} className="msg">
              <div className="user-photo">
                {el.user.avatarUrl ? (
                  <UserPicture
                    name={el.user.firstName}
                    url={el.user.avatarUrl}
                    style={{
                      height: '55px',
                      width: '55px',
                    }}
                  />
                ) : (
                  <DefaultUserPicture size={38} />
                )}
              </div>
              <div className="body">
                {!el.item && messageText(el.body)}
                {el.item && (
                  <>
                    {components[el.item.eventComponentId]} has been updated
                    <span className="d-block fwsb text-capitalize">
                      {el.item.title} <FormatNumber n={el.item.price} />
                      {el.item.deleted ? ' removed' : ' added'}
                    </span>
                  </>
                )}
              </div>
              <p className="datetime-msg">
                <span>{`${el.user.firstName}`}</span> - {date}
              </p>
            </div>
          )
        })}
      </>
    )
  }
)
