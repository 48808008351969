import React from 'react'
import toInteger from 'lodash/fp/toInteger'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Details } from '../style'
import { Provider } from '../../../types'

import { LocationMap, LocationRates } from '.'

interface IGeneralProviderInfo {
  locationDetails: Provider
  groupSize: string
}

export const GeneralProviderInfo: React.FC<IGeneralProviderInfo> = ({
  locationDetails,
  groupSize,
}) => {
  const bestPackage = locationDetails.package
  const bestRoom = locationDetails.room
  const airportData = {
    code: locationDetails.airportCode,
    time: locationDetails.timeToAirport,
  }
  const cutOff = locationDetails.metadata?.cut_off

  return (
    <Row noGutters className="mt-0 mt-md-3 mb-5">
      <Col xs={12} lg={7}>
        <Details>
          <h1 className="fwb fz-24">
            <span className="text-primary fz-16 d-block fwsb">
              {locationDetails.location}
            </span>
            {locationDetails.name}
          </h1>
          {locationDetails.address && (
            <p className="fz-12 m-0">{locationDetails.address}</p>
          )}

          <LocationRates
            className="mt-4 mb-0 mb-md-3"
            bestPackage={bestPackage}
            bestRoom={bestRoom}
            airport={airportData}
            groupSize={toInteger(groupSize)}
            cutOff={cutOff}
          />

          <div
            className="location-details-description mt-2"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: locationDetails.description as string,
            }}
          />
        </Details>
      </Col>
      <Col xs={12} lg={5}>
        <LocationMap provider={locationDetails} />
      </Col>
    </Row>
  )
}
