import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import { Helmet } from 'react-helmet'
import { MdMailOutline, MdLockOutline } from 'react-icons/md'

import AppContext from '../../context'
import { KnTField } from '../../components'
import { useForm } from '../../common/alterForms'

export const VendorSignin: React.FC = () => {
  const { auth } = useContext(AppContext)
  const [errors, setErrors] = useState({ loginEmailVendor: '' })
  const { model, handleFieldChange } = useForm({
    loginEmailVendor: '',
    loginPasswordVendor: '',
  })

  const login = async (ev: React.FormEvent) => {
    setErrors({ loginEmailVendor: '' })
    ev.preventDefault()
    const { error } = await auth.signIn({
      email: model.loginEmailVendor,
      password: model.loginPasswordVendor,
    })
    if (error) {
      setErrors({ loginEmailVendor: 'either password or email is incorrect.' })
    }
  }

  return (
    <Card className="shadow p-4 mx-auto my-5" style={{ maxWidth: '500px' }}>
      <Helmet>
        <title>Kiss & Tell | Vendor Login</title>
        <meta
          name="description"
          content="Login to your vendor account on Kiss & Tell. Manage your offerings, chat with clients, and more."
        />
      </Helmet>
      <h1 className="text-center m-0 fz-50 fwl font-lf text-primary">
        Vendors Login
      </h1>
      <Form data-cy="signin-form-vendor" onSubmit={login}>
        <KnTField
          label="Email"
          value={model}
          error={errors}
          setValue={handleFieldChange}
          name="loginEmailVendor"
          placeholder="your-email@example.com"
          type="email"
          icon={<MdMailOutline size={24} />}
        />
        <KnTField
          label="Password"
          value={model}
          error={errors}
          setValue={handleFieldChange}
          name="loginPasswordVendor"
          placeholder="Enter your password"
          type="password"
          icon={<MdLockOutline size={24} />}
        />
        <Button className="float-right mt-4" type="submit">
          Login
        </Button>
      </Form>
    </Card>
  )
}
