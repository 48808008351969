import React, { useState } from 'react'
import get from 'lodash/fp/get'
import Table from 'react-bootstrap/Table'
import { MdMoreHoriz } from 'react-icons/md'

import { getFirstImage } from '../../../../common'
import { FormatNumber } from '../../../../components'
import { OfferingModal } from '../../../../components/Cards'
import { createOccupancyIcons } from '../../../Locations'
import { EventComponent } from '../../../../types'

import { getHostAndGuestPrices } from './eventBuilderFunctions'

interface ICommonRoomData {
  roomCardData: EventComponent
}

export const CommonRoomData: React.FC<ICommonRoomData> = ({
  roomCardData,
  children,
}) => {
  const [showModal, setShowModal] = useState(false)
  const offering = get('offering', roomCardData)
  const size = get('room.size', offering)

  const occupancy = get('room.occupancy', offering)
  const image = getFirstImage(get('offering.images', roomCardData), '450x390')
  const { guest, host } = getHostAndGuestPrices({
    guestPays: roomCardData.guestPays,
    items: roomCardData.items,
    defaultPrice: roomCardData.offering.price, // to use in selected status
  })

  return (
    <>
      <OfferingModal
        data={offering}
        show={showModal}
        setShowModal={setShowModal}
      />
      <div className="EventBuilderCard__image">
        <img src={image} alt={offering.name} className="img-fluid" />
        <span
          className="cursor shadow EventBuilderCard__open_modal"
          onClick={() => setShowModal(true)}
        >
          <MdMoreHoriz color="white" size={30} />
        </span>
      </div>
      <div className="EventBuilderCard__data">
        <p className="fwb fz-15 mb-1">{offering.name}</p>
        <Table size="sm" className="table-borderless">
          <thead>
            <tr>
              <th>Guest Pays</th>
              <th>Host Pays</th>
              <th>Occupancy</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {offering.price === '0' ? (
                <td colSpan={2}>Prices upon request</td>
              ) : (
                <>
                  <td>
                    <FormatNumber n={guest} /> /total
                  </td>
                  <td>
                    <FormatNumber n={host} />
                  </td>
                </>
              )}
              <td>{createOccupancyIcons(occupancy)}</td>
              <td>{size} sq. ft.</td>
            </tr>
            <tr>
              <td colSpan={4}>
                <FormatNumber n={roomCardData.offering.price} /> /nightly rate
                <span className="fwsb px-2">
                  ({roomCardData.duration - 1} nights)
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
        {children}
      </div>
    </>
  )
}
