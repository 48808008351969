import styled from 'styled-components'

import { salmon, lightSalmon } from '../../styles/themeColors'

export const VerticalProgress = styled.div`
  background-color: ${lightSalmon};
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
  width: 14px;
  & svg,
  & span {
    border-radius: 50%;
    left: -13px;
    position: relative;
  }
  & svg {
    background-color: ${salmon};
  }
  & span {
    align-items: center;
    background-color: white;
    border: 2px solid ${salmon};
    display: flex;
    font-size: 18px;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    width: 40px;
    &.active {
      background-color: ${salmon};
      border: 2px solid ${salmon};
      color: white;
    }
  }
`

export const TheClearCutForm = styled.form`
  border-radius: 5px;
  margin: 0 auto;
  max-width: 1100px;
  padding: 20px 0;
  width: 100%;
  & .send {
    border-radius: 30px;
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin: 0 auto;
    max-width: 330px;
    width: 90%;
  }
  & .simple-field-knt {
    background-color: white;
  }
`

export const ExclusiveTheClearCut = styled.div`
  display: flex;
  & > div {
    width: 50%;
  }
  & .list {
    font-size: 22px;
  }
  & .collage {
    left: -135px;
    position: relative;
    /* 
     * I have to use a few mediaqueries because the design forces me to use overflow and size without %
     * when the screen is <=992 I can remove those restrictions and set the size with %
    */
    @media (max-width: 1300px) {
      left: -120px;
      width: 720px;
    }
    @media (max-width: 1165px) {
      left: -110px;
      width: 650px;
    }
    @media (max-width: 1035px) {
      width: 600px;
    }
    @media (max-width: 992px) {
      display: block;
      left: 0;
      margin: 0 auto 20px auto;
      max-width: 700px;
      width: 98%;
    }
  }
  @media (max-width: 992px) {
    flex-wrap: wrap;
    padding: 0 0 40px 0;
    & > div {
      width: 100%;
      & img {
        margin: 0 auto;
      }
    }
  }
  @media (max-width: 600px) {
    & .list {
      font-size: 18px;
    }
  }
`

export const PromoVideo = styled.div`
  display: flex;
  & > div {
    width: 50%;
  }
  @media (max-width: 992px) {
    flex-wrap: wrap-reverse;
    & > div {
      width: 100%;
    }
  }
`

export const HeaderSection = styled.section`
  background-color: ${salmon};
  display: flex;
  position: relative;
  & .screenshotHeader {
    border-radius: 10px;
    box-shadow: 0 -12px 10px 0px rgba(0, 0, 0, 0.1);
    display: block;
    margin: 0 auto;
    max-width: 98%;
    position: relative;
    z-index: 4;
  }
  & h1,
  & div {
    width: 50%;
    &.w-60 {
      width: 60%;
    }
  }
  & span {
    bottom: -1px;
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
  }
  @media (max-width: 710px) {
    flex-wrap: wrap;
    & h1,
    & div {
      width: 100%;
    }
    & h1 {
      font-size: 80px !important;
    }
    & div img {
      max-width: 100%;
    }
    & .screenshotHeader {
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
      max-width: 94%;
    }
  }
  &.equallywed-header {
    max-height: 450px;
    & .screenshotHeader {
      max-width: 95%;
    }
    @media (max-width: 910px) {
      flex-wrap: wrap;
      max-height: unset;
      & h1,
      & div {
        width: 100%;
      }
      & .screenshotHeader {
        max-width: 98%;
        width: 600px;
      }
    }
    @media (max-width: 600px) {
      & h1 {
        font-size: 65px !important;
      }
      & .screenshotHeader {
        bottom: -10px;
        position: relative;
      }
    }
  }
`
