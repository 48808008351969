import React, { Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'

import { useMoveToTop } from '../../common'
import { SimpleLoader } from '../../components'

import { MobileLocationBanner, MarketplaceCategory } from './components'
import { categoriesData } from './components/temporaryStaticData'

// to reduce the initial load a little bit, since the json is quite big
const MarketplaceForm = lazy(() => import('./components/MarketplaceForm'))
const MarketplaceSlider = lazy(() => import('./components/MarketplaceSlider'))

export const MarketplaceHome: React.FC = () => {
  useMoveToTop()

  return (
    <>
      <Helmet>
        <title>Kiss & Tell | Destination Wedding Locations</title>
        <meta
          name="description"
          content="Discover the best wedding locations around the world at Kiss & Tell! Explore our marketplace and find the perfect destination for your wedding."
        />
      </Helmet>
      <MobileLocationBanner />

      <Suspense fallback={<SimpleLoader />}>
        <MarketplaceSlider categories={categoriesData} />
      </Suspense>
      {categoriesData.map(category => (
        <MarketplaceCategory
          key={`category-${category.name}`}
          category={category}
        />
      ))}
      <Suspense fallback={<SimpleLoader />}>
        <MarketplaceForm />
      </Suspense>
    </>
  )
}
