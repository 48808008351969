import React, { useEffect } from 'react'
import sumBy from 'lodash/fp/sumBy'
import isEmpty from 'lodash/fp/isEmpty'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'

import {
  getImages,
  useMoveToTop,
  useQueryParams,
  useCurrentUser,
} from '../../common'
import { Provider404 } from '../../components/Error404'
import { FullSlider, SimpleLoader } from '../../components'
import { Provider, MyEvent } from '../../types'
import { GET_LOCATION, GET_MY_EVENTS } from '../../graphql'

import {
  GeneralProviderInfo,
  NearbyProviders,
  Packages,
  Rooms,
} from './components'

export const ProviderDetails: React.FC = () => {
  useMoveToTop()
  const urlParams = useQueryParams()
  const { isAuthenticated } = useCurrentUser()
  const { locationURL } = useParams<{ locationURL: string }>()

  const [getMyEvents, { data: myEventsInfo }] = useLazyQuery(GET_MY_EVENTS, {
    fetchPolicy: 'cache-and-network',
  })
  const { data, loading } = useQuery(GET_LOCATION, {
    variables: { url: locationURL },
  })

  useEffect(() => {
    if (isAuthenticated && !myEventsInfo) {
      getMyEvents()
    }
  }, [getMyEvents, isAuthenticated, myEventsInfo])

  const locationDetails: Provider = data?.provider

  if (loading) return <SimpleLoader />
  if (isEmpty(locationDetails)) return <Provider404 />

  const groupSize = urlParams?.groupSize || '0'
  const rooms = locationDetails.rooms || []
  const packages = locationDetails.packages || []
  const images = getImages(locationDetails.images, '1280x720')
  const totalRooms = sumBy('quantity', rooms)
  const myEvents: MyEvent[] = myEventsInfo?.me.myEventList

  const seo = locationDetails?.metadata?.seo || {}
  const { cancellationPolicy } = locationDetails
  return (
    <>
      <Helmet>
        <meta name="keywords" content={seo?.keywords} />
        <meta name="description" content={seo?.description} />
        <title>
          {seo?.title
            ? seo.title
            : `${locationDetails.name} Weddings, ${locationDetails.location} | Kiss & Tell`}
        </title>
        <link
          rel="canonical"
          href={`https://kissandtell.com/locations/${locationDetails.url}`}
        />
      </Helmet>
      <FullSlider images={images} />
      <Container className="position-relative">
        <GeneralProviderInfo
          locationDetails={locationDetails}
          groupSize={groupSize}
        />

        <Tabs
          defaultActiveKey={packages.length > 0 ? 'events' : 'accommodations'}
          id="detailsPackages"
          className="detailsPackages mt-3"
        >
          <Tab eventKey="events" title="Event Packages">
            <Packages
              packagesList={packages}
              myEvents={myEvents}
              groupSize={groupSize}
            />
          </Tab>
          <Tab eventKey="accommodations" title="Guest Rooms">
            {rooms.length <= 0 && (
              <NearbyProviders provider={locationDetails} />
            )}
            <Rooms
              roomsList={rooms}
              totalRooms={totalRooms}
              myEvents={myEvents}
              cancellationPolicy={cancellationPolicy}
            />
          </Tab>
        </Tabs>
      </Container>
    </>
  )
}
