import styled from 'styled-components'

import BuildBookBackground from '../../../assets/images/BuildBookBackground.jpg'
import { darkGray, gray, lightGray } from '../../../styles/themeColors'

export const TabContainer = styled.section`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 90%;
  @media (max-width: 991px) {
    justify-content: space-evenly;
    width: 100%;
  }
`

export const Banner = styled.div`
  background-color: #ffffff;
  background-image: url(${BuildBookBackground});
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 5px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 25px;
  position: relative;
  width: 100%;
  & .closeBanner {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  & .steps,
  & p {
    max-width: 600px;
    width: 100%;
  }
  &.small {
    padding-left: 15px;
    & p {
      padding-bottom: 20px !important;
    }
    & .steps-container {
      & .steps {
        max-width: 450px;
      }
    }
  }
  @media (max-width: 767px) {
    background-image: unset;
    border-radius: 0;
    overflow: hidden;
    padding: 0;
    text-align: center;
    & .BuildBook {
      margin-top: -5px;
      height: 240px;
      object-fit: cover;
      width: 100%;
    }
    & .steps {
      background-color: white;
      padding: 10px 15px 0 15px;
      position: relative;
      top: -25px;
    }
    & p {
      margin: 0 auto;
    }
    & .closeBanner {
      right: 0px;
      top: 0px;
    }
  }
`

// Card
export const Card = styled.article`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  margin: 10px auto;
  max-width: 400px;
  padding: 0 0 10px 0;
  width: 100%;
  & .title-locationCard {
    position: relative;
    & h2 {
      min-height: 70px;
    }
    & .icons {
      position: absolute;
      right: 5px;
      top: 0;
    }
    @media (max-width: 415px) {
      & h2 {
        min-height: unset;
        padding: 10px 0;
      }
    }
  }
  & .mainPhoto {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 260px;
    position: relative;
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 0 0 2px 0;
  }
`

export const HideMap = styled.button`
  background-color: white;
  border: none;
  font-size: 16px;
  padding: 8px 10px;
  position: absolute;
  right: 70px;
  top: 10px;
  z-index: 1;
  &:hover {
    background-color: #ebebeb;
  }
`

export const UpdateMap = styled.button`
  background-color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  font-size: 18px;
  left: 50%;
  padding: 8px 20px;
  position: absolute;
  top: 10px;
  transform: translate(-50%, 0);
  z-index: 1;
  &:hover {
    background-color: #ebebeb;
  }
  @media (max-width: 991px) {
    top: 70px;
    width: fit-content;
  }
`

export const LocationRatesContainer = styled.div`
  align-items: flex-start;
  display: flex;
  color: ${darkGray};
  flex-wrap: wrap;
  font-size: 15px;
  font-weight: 600;
  justify-content: flex-start;
  &.listing-card {
    & .ml-50 {
      margin-left: 50%;
    }
  }
  & .tooltip-card {
    position: absolute;
    right: 10px;
    top: -5px;
    &.na {
      right: 35px;
    }
  }
  & > div {
    align-items: flex-start;
    border-left: 1px solid ${lightGray};
    display: flex;
    flex-wrap: wrap;
    max-width: 176px;
    padding: 5px 0 0 20px;
    & .title {
      width: 100%;
    }
    & > p {
      margin-bottom: 5px;
      width: 50%;
      & span {
        color: ${gray};
        display: block;
        font-size: 13px;
      }
    }
    &:first-child {
      border-left: none;
      padding-left: 5px;
    }
  }
  @media (max-width: 550px) {
    & > div {
      &:nth-child(3) {
        border-left: none;
        padding-left: 5px;
      }
    }
    &.full {
      & > div {
        max-width: 100%;
        width: 100% !important;
      }
    }
  }
  @media (max-width: 420px) {
    & > div {
      width: 50%;
      &:first-child {
        border-left: none;
        padding-left: 0;
      }
    }
  }
`

export const MarketplaceSliderContainer = styled.div`
  margin-top: 20px;
  & .card {
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    margin: 0.5rem auto 0 auto;
    width: 280px;
  }
  @media (max-width: 500px) {
    margin-top: 0;
    & .card {
      width: 100%;
    }
  }
`
