import styled from 'styled-components'

import {
  mediumGray,
  darkGray,
  grayBackground,
  lightBackground,
  lightGray,
  salmon,
  smsBackground,
} from '../../../styles/themeColors'

export const Img = styled.img`
  @media (max-width: 1199px) {
    margin-bottom: 20px;
  }
`

// Post Options
export const PostOption = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 0;
  top: -5px;
`

// Comments Options
export const CommentOption = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: -30px;
  top: 0;
  width: auto;
`

export const FormEditComment = styled.form`
  display: inline-block;
  position: relative;
  & .input-edit {
    align-items: center;
    background-color: ${grayBackground};
    border: 1px solid ${lightGray};
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    padding: 1px 20px 0 15px;
    position: relative;
    width: 100%;
    & textarea {
      background-color: transparent;
      border: none;
      display: inline-block;
      font-size: 13px;
      font-weight: 400;
      min-width: 250px;
      outline: none;
      overflow: hidden;
      resize: none;
      width: 100%;
    }
  }
  & .cancel {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 0;
    width: auto;
    &:hover {
      cursor: pointer;
    }
  }
`

// Poll
export const PollComposer = styled.div`
  border: 1px solid ${mediumGray};
  border-radius: 5px;
  margin: 0 auto 10px auto;
  padding: 20px 20px 25px 20px;
  position: relative;
  width: 96%;
  & *:focus {
    outline: none;
  }
  & .cancel {
    position: absolute;
    right: 10px;
    top: 15px;
  }
  & .option {
    font-size: 16px;
    margin: 10px 0;
    & input {
      border: 1px solid ${mediumGray};
      border-radius: 5px;
      max-width: 400px;
      padding: 5px 10px;
      width: 90%;
    }
    & svg {
      margin-left: 7px;
      position: relative;
      top: -1px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`

const background = 'rgba(255, 110, 120, 0.25)'
export const PollOptions = styled.div`
  width: 100%;
  & .single-option {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 10px 0;
    & .add-an-option,
    & .total-selections {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      padding: 8px 10px;
      width: 500px;
    }
    & .add-an-option {
      margin-left: 24px;
      &:focus {
        outline: none;
      }
    }
    & .user-selection label {
      &::before,
      &::after {
        cursor: pointer;
      }
    }
    & .total-selections {
      overflow: hidden;
      position: relative;
      & span {
        font-size: 15px;
        position: relative;
      }
      & .percentage-poll {
        background-color: ${background};
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
      }
      & .see-votes {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 0;
      }
    }
    & .user-list {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0 0 0 20px;
      & li {
        display: inline-block;
        margin-left: -10px;
        & img {
          border: 2px solid white;
          border-radius: 50%;
          height: 24px;
          width: 24px;
        }
        &:last-child {
          align-items: center;
          background-color: #d8d8d8;
          border-radius: 50%;
          display: flex;
          height: 22px;
          justify-content: center;
          margin-left: 0px;
          width: 22px;
          & span {
            font-size: 12px;
            display: none;
          }
        }
      }
      &:hover {
        & li:last-child {
          background-color: ${background};
          & span {
            display: inline-block;
          }
          & svg {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    & .single-option .total-selections {
      width: 100%;
    }
  }
`

export const CommentsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  & .comment,
  .actions {
    display: inline-block;
    vertical-align: middle;
  }
  & .comment {
    max-width: 85%;
    & .content-comment {
      background-color: ${lightBackground};
      border-radius: 20px;
      line-height: 1.6;
      padding: 5px 15px;
      position: relative;
      & .custom-options {
        display: none;
      }
    }
    & strong {
      color: ${darkGray};
      padding-right: 3px;
    }
    &:hover {
      & .content-comment .custom-options {
        display: flex;
      }
    }
  }
  & .actions {
    width: 100%;
    & small:last-child {
      color: ${mediumGray};
    }
  }
`

export const Swiperslider = styled.div`
  position: relative;
  padding-bottom: 40px;
  & .controls {
    align-items: center;
    bottom: 10px;
    display: flex;
    height: 25px;
    left: 0;
    justify-content: center;
    position: absolute;
    width: 100%;
    & .swiper-prev,
    & .swiper-next {
      align-items: center;
      background-color: white;
      border: none;
      border-radius: 50%;
      display: flex;
      height: 20px;
      justify-content: center;
      padding: 0;
      width: 20px;
    }
    & .swiper-pagination {
      align-items: center;
      display: flex;
      height: 8px;
      justify-content: center;
      padding: 0 10px;
      & .swiper-pagination-bullet {
        background: rgba(0, 0, 0, 0.4);
        border: none;
        border-radius: 50%;
        display: inline-block;
        height: 8px;
        margin: 0 3px;
        width: 8px;
      }
    }
  }
`

export const TableContainer = styled.div`
  overflow-x: auto;
  & table {
    & tr {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      &:first-child {
        border: none;
      }
      & td {
        font-size: 12px;
        padding: 5px 10px;
        &:first-child {
          min-width: 200px;
        }
        @media (max-width: 1300px) {
          font-size: 12px;
        }
      }
    }
    & .pink-text {
      color: ${salmon};
      font-size: 16px;
      font-weight: 600;
    }
  }
`

export const BookingRowsContainer = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    align-items: flex-start;
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0 0 7px 0;
    & .b-row:nth-child(even) {
      background-color: ${lightBackground};
    }
  }
  @media (max-width: 500px) {
    &.single {
      justify-content: center;
    }
  }
`

export const BookingRow = styled.div`
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 0;
  width: 90%;
  & .link {
    width: calc(100% - 250px);
    * {
      color: ${darkGray};
    }
    & a {
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      &:hover {
        text-decoration: none;
      }
    }
  }
  @media (max-width: 1199px) {
    & .link {
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    border-bottom: none;
    padding-top: 0;
    &.single {
      & .link {
        & a {
          & .offering-data {
            padding-right: 5px;
          }
          & .CheckInOut {
            display: inline-block;
            max-width: 184px;
          }
        }
      }
    }
    &:not(.single) {
      width: 200px;
      & .link {
        & a {
          flex-direction: column;
          padding: 0 10px;
          & .CheckInOut {
            display: inline-block;
            max-width: 184px;
          }
          & .offering-data {
            order: 2;
            padding-bottom: 15px;
          }
          & img {
            margin-bottom: 10px;
            order: 1;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    width: 200px;
    & .link {
      & a {
        flex-direction: column;
        padding: 0 10px;
        & .CheckInOut {
          display: inline-block;
          max-width: 184px;
        }
        & .offering-data {
          order: 2;
          padding-bottom: 15px;
        }
        & img {
          margin-bottom: 10px;
          order: 1;
        }
      }
    }
  }
`

export const EmbedVideoContainer = styled.div`
  margin: 0 auto 10px auto;
  &.embed-vimeo-video,
  &.embed-youtube-video {
    height: 0;
    max-width: 750px;
    padding-bottom: 46.25%;
    position: relative;
    overflow: hidden;
    width: 100%;
    & iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    @media (max-width: 575px) {
      padding-bottom: 56.25%;
    }
  }
  &.embed-instagram-reel {
    height: 640px;
    max-width: 400px;
    width: 100%;
    & iframe {
      height: 100%;
      width: 100%;
    }
    @media (max-width: 575px) {
      height: 0;
      overflow: hidden;
      padding-bottom: 154%;
      position: relative;
      width: 100%;
      & iframe {
        height: 100%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }
  }
`

export const PinPostButton = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
`

export const PinnedPostsContainer = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .pinned-post {
    flex: 1;
    margin-bottom: 20px;
    position: relative;
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
    &-overlay {
      background-color: transparent;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    &-count {
      bottom: 10px;
      left: 0;
      position: absolute;
    }
  }
`

export const SmsPreviewContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  .sms-box {
    background-color: ${smsBackground};
    border-radius: 10px;
    margin: 5px 0 0 0;
    padding: 10px;
    width: 80%;
  }
`
