import React from 'react'
import isNil from 'lodash/fp/isNil'
import { FaClock } from 'react-icons/fa'

import Bed from '../../../assets/images/icons/bed.svg'
import Airplane from '../../../assets/images/icons/airplane.svg'
import EventIcon from '../../../assets/images/icons/eventicon.svg'
import { SimpleTooltip } from '../../../components'
import { BestPackage, BestRoom, Maybe } from '../../../types'
import { getEventRoomRate } from '../LocationFunctions'
import { salmon } from '../../../styles/themeColors'

import { LocationRatesContainer } from './style'

const getTooltipText = (bestPackage?: Maybe<BestPackage>, groupSize = 0) => {
  const { minimumGuests = 0 } = bestPackage || {}

  if (groupSize <= 0) return 'based on 1 guest'
  if (groupSize < minimumGuests) return `${minimumGuests} guest minimum`
  return `based on ${groupSize} guests`
}

interface ILocationRates {
  bestPackage?: Maybe<BestPackage>
  bestRoom?: Maybe<BestRoom>
  airport?: {
    code: Maybe<string>
    time: Maybe<number>
  }
  groupSize: number
  className?: string
  id?: string
  cutOff?: string
}
export const LocationRates: React.FC<ILocationRates> = ({
  bestPackage,
  bestRoom,
  airport,
  className = '',
  id = '',
  groupSize,
  cutOff,
}) => {
  const tooltipText = getTooltipText(bestPackage, groupSize)
  const hasSleeps = !isNil(bestRoom?.sleeps)
  const hasEventRate = !isNil(bestPackage?.price)
  const eventRate = getEventRoomRate(bestPackage?.price)

  return (
    <>
      <LocationRatesContainer className={className}>
        <div className={hasEventRate ? '' : 'd-none'}>
          <div className="title">
            <img src={EventIcon} alt="Event" /> EVENT
          </div>
          <p>
            {bestPackage?.capacity} <span>CAPACITY</span>
          </p>
          {bestPackage?.price !== '-1' && (
            <p className="position-relative">
              {eventRate} <span>RATE</span>
              <SimpleTooltip
                id={`${id}-event-rate`}
                className="tooltip-card d-none d-md-inline-block"
                text={
                  eventRate === 'request' ? 'Price upon Request' : tooltipText
                }
              />
            </p>
          )}
          {bestPackage?.price === '-1' && (
            <p className="position-relative">
              N/A <span>RATE</span>
              <SimpleTooltip
                id={`${id}-event-rate`}
                className="tooltip-card d-none d-md-inline-block na"
                text="Over Capacity"
              />
            </p>
          )}
        </div>

        <div
          className={`
            ${hasSleeps ? '' : 'd-none'}
            ${hasEventRate ? '' : 'ml-50'}
          `}
        >
          <div className="title">
            <img src={Bed} alt="Beds" /> ROOMS
          </div>
          <p>
            {bestRoom?.sleeps} <span>SLEEPS</span>
          </p>
          {bestRoom?.price !== '-1' && (
            <p>
              {getEventRoomRate(bestRoom?.price)} <span>RATE</span>
            </p>
          )}
          {bestRoom?.price === '-1' && (
            <p className="position-relative">
              N/A <span>RATE</span>
              <SimpleTooltip
                id={`${id}-room-rate`}
                className="tooltip-card d-none d-md-inline-block na"
                text="Over Capacity"
              />
            </p>
          )}
        </div>

        <div className={airport?.code ? '' : 'd-none'}>
          <div className="title">
            <img src={Airplane} alt="Airport" /> AIRPORT
          </div>
          <p>
            {airport?.time} min <span>TIME</span>
          </p>
          <p>
            {airport?.code} <span>CODE</span>
          </p>
        </div>
        {cutOff && (
          <div style={{ paddingTop: '6px' }}>
            <div className="title">
              <FaClock size={18} color={salmon} className="mr-2 mb-2 mt-1" />
              CUT OFF
            </div>
            <p>{cutOff}</p>
          </div>
        )}
      </LocationRatesContainer>
      {bestPackage?.price !== '-1' ? (
        <span className="d-block d-md-none fz-12 w-100 pb-2 pl-2 tc-lgray">
          * {eventRate === 'request' ? 'Price upon Request' : tooltipText}
        </span>
      ) : (
        <span className="d-block d-md-none fz-12 w-100 pb-2 pl-2 tc-lgray">
          * Over Capacity
        </span>
      )}
    </>
  )
}
