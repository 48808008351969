import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { EventComponent, EventComponentStatus } from '../../../../types'

import { GuestPays } from './GuestPays'
import { CommonEventData } from './CommonEventData'
import { EventBuilderCard } from './style'
import {
  useCancelEventComponent,
  parseGuestPays,
} from './eventBuilderFunctions'

export const NegotiationEventCard: React.FC<{
  eventCardData: EventComponent
  eventId: string
}> = ({ eventCardData, eventId }) => {
  const [guestPays, setGuestPays] = useState('')
  const [handleCancelEventComponent, loadingCancel] = useCancelEventComponent(
    eventCardData.id,
    eventId
  )
  const { Available } = EventComponentStatus

  useEffect(() => {
    if (!guestPays) {
      setGuestPays(parseGuestPays(eventCardData.guestPays))
    }
  }, [guestPays, eventCardData.guestPays])

  const cancelBooking = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    if (loadingCancel) return
    handleCancelEventComponent()
  }

  return (
    <EventBuilderCard>
      <CommonEventData
        eventCardData={eventCardData}
        guests={eventCardData.capacity}
      >
        <p className="d-flex pt-3 m-0 fwsb fz-15">
          <span className="px-2 fwn">Date:</span>{' '}
          {moment(eventCardData.date).format('DD MMMM YYYY')}
          <span className="px-3 fwn">Group size:</span> {eventCardData.capacity}
        </p>

        {/** only the vendor should be able to remove a component after the first payment */}
        {eventCardData.status !== Available && (
          <a className="cancel-booking" href="!#" onClick={cancelBooking}>
            cancel booking
          </a>
        )}
        <GuestPays className="w-100 pt-3" evComponent={eventCardData} />
      </CommonEventData>
    </EventBuilderCard>
  )
}
