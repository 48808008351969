import React, { useState, Dispatch, SetStateAction } from 'react'
import set from 'lodash/fp/set'
import get from 'lodash/fp/get'
import some from 'lodash/fp/some'
import uniqBy from 'lodash/fp/uniqBy'
import Button from 'react-bootstrap/Button'
import moment, { Moment } from 'moment'
import { MdAdd, MdCancel, MdDelete } from 'react-icons/md'

import { confirmAlert } from '../../../common'
import { PollOptionInput } from '../../../types'
import { darkGray } from '../../../styles/themeColors'
import { SinglePicker } from '../../../components'

import { PollComposer as PollComposerContainer } from './style'

interface IPollUpdate {
  incompletePoll: Dispatch<SetStateAction<boolean>>
  deletePoll: Dispatch<SetStateAction<boolean>>
  setOptions: Dispatch<SetStateAction<PollOptionInput[]>>
  error: boolean
  oldOptions: PollOptionInput[]
  deadline?: string
  setDeadline: Dispatch<SetStateAction<string | undefined>>
}

export const PollUpdate: React.FC<IPollUpdate> = ({
  incompletePoll,
  setOptions,
  error,
  oldOptions,
  deletePoll,
  deadline,
  setDeadline,
}) => {
  const [options, setPollOptions] = useState(oldOptions)
  const [duplicateError, showError] = useState(false)

  const updatePollState = (optionList: PollOptionInput[]) => {
    const incomplete = some(option => option.name === '', optionList) // if any option==='' the poll is not complete
    const optionsWithoutDuplicates = uniqBy('name', optionList) // remove duplicate options
    incompletePoll(incomplete)

    if (!incomplete) {
      if (optionsWithoutDuplicates.length === optionList.length) {
        // Complete and different options
        showError(false)
        setOptions(optionList)
      } else {
        incompletePoll(true)
        showError(true)
      }
    }
  }

  const updateField = (
    ev: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const optionName = ev.currentTarget.value
    const optionId = ev.currentTarget.name || null
    const optionsUpdated = set(
      `[${idx}]`,
      { id: optionId, name: optionName },
      options
    )

    setPollOptions(optionsUpdated)
    updatePollState(optionsUpdated)
  }
  const addField = () => {
    setPollOptions([...options, { id: null, name: '' }])
    incompletePoll(true)
  }
  const deleteField = (idx: number) => {
    const currentOptions = [...options] // to prevent changing the original options object
    currentOptions.splice(idx, 1)
    setPollOptions(currentOptions)
    updatePollState(currentOptions)
  }

  const handleDeletePoll = () => {
    confirmAlert({
      title: 'delete poll?',
      html: 'If this poll is deleted, it will not be recoverable and the votes will be lost.',
      icon: 'warning',
    }).then(result => {
      if (get('value', result) === true) {
        deletePoll(true)
      }
    })
  }

  const handleDateChange = (date: Moment) => {
    setDeadline(date.format('YYYY-MM-DD 00:00:00'))
  }

  return (
    <>
      {error && (
        <span className="px-3 mb-1 d-block text-danger fz-12">
          It was not possible to add this poll
        </span>
      )}
      {duplicateError && (
        <span className="px-3 mb-1 d-block text-danger fz-12">
          You can't repeat options
        </span>
      )}
      <PollComposerContainer data-cy="updating-poll" className="m-0 w-100">
        <h5 className="title-newPoll pb-1 fwb">Update Poll</h5>
        {options.map((el, idx) => (
          <div className="option" key={el.id}>
            <input
              placeholder={`Option ${idx + 1}`}
              type="text"
              value={el.name}
              name={el.id as string}
              onChange={ev => updateField(ev, idx)}
            />
            {options.length > 2 && (
              <MdCancel
                color={darkGray}
                size={25}
                onClick={() => deleteField(idx)}
              />
            )}
          </div>
        ))}
        <Button
          variant="secondary"
          className="btn-lgray mt-2 mb-4"
          onClick={addField}
        >
          <MdAdd size={18} style={{ position: 'relative', top: '-1px' }} />
          Add option
        </Button>
        <div className="pb-3">
          <label className="w-100 fz-16 fwsb" htmlFor="single_picker_deadline">
            Deadline
          </label>
          <SinglePicker
            key={`picker-${deadline?.trim()}`}
            emitDate={handleDateChange}
            defaultDate={deadline ? moment(deadline) : undefined}
          />
          {deadline && (
            <span
              className="text-danger fz-12 w-100 d-block pt-2 cursor"
              onClick={() => setDeadline(undefined)}
            >
              remove deadline
            </span>
          )}
        </div>
        <div className="text-right">
          <Button
            variant="outline-danger"
            className="btn-lgray"
            onClick={handleDeletePoll}
          >
            <MdDelete size={18} style={{ position: 'relative', top: '-1px' }} />{' '}
            Delete poll
          </Button>
        </div>
      </PollComposerContainer>
    </>
  )
}
