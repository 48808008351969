import React from 'react'
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share'
import { IoLogoFacebook, IoLogoTwitter } from 'react-icons/io'
import { FaPinterestP } from 'react-icons/fa'

import { darkGray } from '../../styles/themeColors'

interface IBlogShare {
  shareImg?: string
  tags?: string[]
  shareTitle?: string
}

export const BlogShare: React.FC<IBlogShare> = ({
  shareImg,
  tags,
  shareTitle,
}) => {
  const shareUrl = window.location.href

  return (
    <section className="mb-4 social-media-links text-center w-100">
      <h2 className="text-center fwsb fz-15">SHARE:</h2>
      <FacebookShareButton
        className="mx-2"
        url={shareUrl}
        quote={shareTitle}
        hashtag={tags ? `#${tags[0]}` : undefined}
      >
        <IoLogoFacebook color={darkGray} size={25} />
      </FacebookShareButton>
      {shareImg && (
        <PinterestShareButton
          className="mx-2"
          url={shareUrl}
          media={shareImg}
          description=""
        >
          <FaPinterestP color={darkGray} size={22} />
        </PinterestShareButton>
      )}
      <TwitterShareButton
        className="mx-2"
        url={shareUrl}
        title={shareTitle}
        hashtags={tags}
      >
        <IoLogoTwitter color={darkGray} size={25} />
      </TwitterShareButton>
    </section>
  )
}
