import { darkGray } from '../styles/themeColors'
import { getCoords, getImageUrl } from '../common'
import { Provider, GoogleMap, GoogleMarker } from '../types'

const makeMapInfoWindow = (provider: Provider): string => {
  const image = getImageUrl(provider.mainImage, '300x168')
  const { location, name, address, url } = provider
  return `<a href="/locations/${url}" target="_blank" style="color: ${darkGray}; text-decoration: none;">
    <div class="pl-1">
      <h5 style="width: 300px">
        <span class="text-primary fz-12 d-block">${location}</span>
        ${name}
      </h5>
      <img src="${image}" alt="${name}" />
      <p style="width: 300px" class="pt-2 fz-14 m-0">${address}</p>
    </div>
  </a>
  `
}

const goToProvider = (id: string): void => {
  const element = document.getElementById(`card-${id}`)
  if (element) {
    window.scroll({
      top: element.offsetTop - 70,
      left: 0,
      behavior: 'smooth',
    })
  }
}

export const adjustMapZoom = ({
  map,
  providers,
}: {
  map?: GoogleMap
  providers: Provider[]
}): void => {
  if (!map) return
  const latlngbounds = new window.google.maps.LatLngBounds()
  providers.forEach(provider => {
    if (provider.coord) {
      const [lat, lng] = getCoords(provider.coord)
      latlngbounds.extend(new window.google.maps.LatLng({ lat, lng }))
    }
  })
  map.fitBounds(latlngbounds)
}

export const createMarkersWithInfoWindow = ({
  providers,
  map,
}: {
  providers: Provider[]
  map?: GoogleMap
}): GoogleMarker[] => {
  if (!map) return []

  const infowindow = new window.google.maps.InfoWindow()
  return providers.map(provider => {
    const [lat, lng] = getCoords(provider.coord as string)
    const marker = new window.google.maps.Marker({
      map,
      optimized: true,
      position: { lat, lng },
      title: provider.name,
    })

    marker.addListener('click', () => {
      infowindow.setContent(makeMapInfoWindow(provider))
      infowindow.open({ anchor: marker, map, shouldFocus: false })
      goToProvider(provider.id) // scroll to the card
    })

    return marker
  })
}
