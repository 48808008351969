import React, { useState } from 'react'
// import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import uniqBy from 'lodash/fp/uniqBy'
import find from 'lodash/fp/find'
import moment from 'moment'
import Button from 'react-bootstrap/Button'

import logo from '../../../../assets/images/settings/logo.png'
import socialIcons from '../../../../assets/images/settings/social.png'
import { MyEvent, Provider } from '../../../../types'
import {
  getImageUrl,
  replaceLineBreaksWithBR,
  useCurrentUser,
} from '../../../../common'

import { InvitationPreviewContainer } from './style'

const PreInvitation: React.FC<{ event: MyEvent }> = ({ event }) => {
  const [selected, setSelected] = useState<'pre' | 'inv'>('pre')
  const { firstName } = useCurrentUser()
  const { name: eventName, emailCustomText: customMsg } = event

  return (
    <>
      <div className="mx-auto w-fit-content mb-2">
        <Button
          className="mx-2"
          variant={selected === 'pre' ? 'primary' : 'outline-primary'}
          onClick={() => setSelected('pre')}
        >
          Pre Invitation
        </Button>
        <Button
          className="mx-2"
          variant={selected === 'inv' ? 'primary' : 'outline-primary'}
          onClick={() => setSelected('inv')}
        >
          Invitation
        </Button>
      </div>

      <div className="mail-body shadow-sm mt-3 pt-2">
        <img src={logo} alt="Kissanndtell" className="logo" />
        <div className="mt-4 px-3 px-lg-5 fz-16">
          <p>Hi {'{{GUEST_FIRST_NAME}}'}!</p>
          <p>
            {firstName} has created {eventName} with Kiss & Tell and would like
            you to connect and provide feedback during this planning process.
            <br />
            Your input is key to making {eventName} a success!
          </p>
          <span className="fwsb">Message from host:</span>
          <p>
            {customMsg
              ? replaceLineBreaksWithBR(customMsg)
              : '{{ personalized message }}'}
          </p>
          <p>Click below to start collaborating with {firstName}.</p>
          <button
            type="button"
            className="d-block mx-auto rounded-pill px-4 py-2 fz-24 bg-primary text-white border-0 mt-2 mb-3"
          >
            Go To {eventName}
          </button>

          <p className="tc-lgray text-center mb-0 mt-3 px-3">
            New to Kiss & Tell? <br />
            Log in with this email so you can access {eventName}
          </p>
        </div>

        <img src={socialIcons} alt="Kissanndtell" />
      </div>
    </>
  )
}

const Invitation: React.FC<{ event: MyEvent }> = ({ event }) => {
  const { firstName, lastName } = useCurrentUser()
  const { name: eventName, emailCustomText: customMsg, components = [] } = event
  const main = find({ main: true }, components) ?? components[0]
  const provider = main?.offering?.provider

  const providers: Provider[] = flow(
    map('offering.provider'),
    uniqBy('name')
  )(components)

  const lineHelper = () => (
    <span
      className="d-block my-3"
      style={{ height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
    />
  )

  return (
    <div className="mail-body shadow-sm mt-3 pt-2">
      <img src={logo} alt="Kissanndtell" className="logo" />

      <div className="mt-4 px-3 px-lg-5 fz-14">
        <p className="text-primary w-100 text-center fz-20 px-4 fwb">
          Get excited, you've been invited!
        </p>
        <p className="fwb text-center fz-24">
          {eventName} <br />
          at {provider.name}
        </p>
        <p className="fwsb text-center fz-14">{provider.address}</p>
        <img
          src={getImageUrl(provider?.mainImage, '600x300')}
          alt={provider.name}
          className="w-100 h-auto mb-3"
        />
        <p className="fwsb fz-18" style={{ lineHeight: 2 }}>
          Location: {provider.location} <br />
          When: {moment(main.date).format('dddd, Do MMMM, YYYY')} <br />
          Who: {firstName} {lastName}
        </p>
        <span className="my-3 d-block bg-secondary" style={{ height: '1px' }} />
        <p>
          {customMsg
            ? replaceLineBreaksWithBR(customMsg)
            : '{{ personalized message }}'}
        </p>
        <p>
          To ensure our whole group can celebrate together, we’re using a
          planning platform called Kiss & Tell, where everyone can book their
          rooms, communicate and RSVP in one place. You can log in via the View
          Invitation button. This helps us keep track of our headcount and
          allows the hotel to track our group.
        </p>
        <p>
          Note, you’ll need to register to Kiss & Tell to complete the booking
          in 3 simple steps:
        </p>
        <ol>
          <li>Use the email address where you received this invite</li>
          <li>Set your account details</li>
          <li>Verify you receive a Registration Confirmation email</li>
        </ol>
        <p className="mt-3">
          If you need assistance with your login or booking, contact:{' '}
          <span
            style={{ textDecoration: 'underline', color: '#15c' }}
            className="cursor"
          >
            team@kissandtell.com
          </span>
        </p>

        <p className="mb-1">See you in {provider.location}!</p>
        <span className="text-primary fwb fz-16"> Amy Jarczynski</span>

        <div className="w-100 pt-3 pb-2">
          <button
            type="button"
            className="d-block w-100 rounded-pill py-2 fz-20 bg-primary text-white border-0 mb-1"
          >
            View Invitation
          </button>
        </div>
        <span className="d-block w-100 fz-12">
          *A room block was reserved for their event and your room can be booked
          through this link. {providers.length}
        </span>

        {providers?.map(p => (
          <div key={p.name}>
            {lineHelper()}
            <p className="fz-16 fwb w-100">{p.name}</p>
            <img
              src={getImageUrl(p.mainImage, '600x300')}
              alt={p.name}
              className="w-100 h-auto mb-3"
            />
          </div>
        ))}

        {lineHelper()}
      </div>

      <p className="fz-12 fwb tc-lgray">Booking or RSVP needs?</p>
      <span className="fz-12 fwb tc-lgray">Contact</span>
      <span
        style={{ textDecoration: 'underline', color: '#15c' }}
        className="cursor fz-12"
      >
        team@kissandtell.com
      </span>
      <img src={socialIcons} alt="Kissanndtell" />
    </div>
  )
}

export const InvitationPreview: React.FC<{
  event: MyEvent
  className?: string
}> = ({ event, className = '' }) => {
  const { paidComponents: paidComponentsCount = 0 } = event

  return (
    <InvitationPreviewContainer className={className}>
      <div className="text-primary px-3 fz-16 mb-2">Your event invitation</div>
      {paidComponentsCount <= 0 && <PreInvitation event={event} />}
      {paidComponentsCount > 0 && <Invitation event={event} />}
    </InvitationPreviewContainer>
  )
}
