import React from 'react'
import get from 'lodash/fp/get'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'

import { IuseQueryParams } from '../../../types'

export const PaymentAlert: React.FC<{ urlParams: IuseQueryParams }> = ({
  urlParams,
}) => {
  const paymentMsg = {
    success: {
      type: 'success',
      msg: 'Your payment has been successfully completed.',
    },
    failure: {
      type: 'danger',
      msg: 'There was a problem completing your payment. Please try again.',
    },
  }

  if (!urlParams?.payment) {
    return <></>
  }

  return (
    <Row className="pt-3">
      <Col>
        <Alert
          className="text-center"
          variant={get(`[${urlParams.payment}].type`, paymentMsg)}
        >
          {get(`[${urlParams.payment}].msg`, paymentMsg)}
        </Alert>
      </Col>
    </Row>
  )
}
