import styled from 'styled-components'

import { gray } from '../../styles/themeColors'

export const UnderlineLink = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
  & .line {
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
    height: 2px;
    margin: 2px auto 5px auto;
    width: 40px;
  }
`

export const PostInfo = styled.div`
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1100px;
  padding: 10px 0;
  * {
    border: none !important;
    font-size: 14px;
  }
  & p {
    margin-bottom: 5px;
    text-align: center;
    width: 30%;
    & span {
      display: inline-block;
      width: 100%;
      &:nth-child(1) {
        padding: 10px 10px 2px 10px;
        font-weight: 600;
      }
      &:nth-child(2) {
        color: ${gray};
        padding: 2px 10px 7px 10px;
      }
    }
    @media (max-width: 991px) {
      width: 96%;
    }
  }
  & table {
    & tr {
      & td:nth-child(1) {
        text-align: right;
      }
      & td:nth-child(2) {
        color: ${gray};
      }
    }
  }
`

export const PostBody = styled.div`
  & img {
    height: auto;
    margin: 0 0 10px 0;
    max-width: 100%;
  }
`

export const WhiteBox = styled.div`
  background-color: white;
  padding: 1rem 0.25rem;
  margin: 0 auto;
  max-width: 360px;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  @media (max-width: 991px) {
    position: relative;
    top: 0;
  }
`
