import React, { useRef, useState } from 'react'
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md'

import { VideoBGContainer } from './style'

export const VideoBG: React.FC<{
  url: string
  className?: string
  childrenClassName?: string
  wave?: boolean
  waveColor?: string
  poster: string
  videography?: string
}> = ({
  children,
  url,
  className = '',
  childrenClassName = '',
  waveColor = '#ffffff',
  wave = true,
  poster,
  videography,
}) => {
  const [mute, setMute] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleMute = (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    ev.preventDefault()
    setMute(!mute)

    if (!videoRef.current) return // make sure the video exists and is loaded
    if (videoRef.current.volume !== 1 && !mute) {
      videoRef.current.volume = 1
    }
  }
  return (
    <VideoBGContainer className={className}>
      {!mute ? (
        <MdVolumeUp
          size={20}
          color="white"
          className="video-bg-volume cursor"
          onClick={handleMute}
        />
      ) : (
        <MdVolumeOff
          size={20}
          color="white"
          className="video-bg-volume cursor"
          onClick={handleMute}
        />
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        loop
        autoPlay
        src={url}
        playsInline
        muted={mute}
        ref={videoRef}
        preload="auto"
        poster={poster}
      />
      {wave && (
        <svg
          className="wave"
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillOpacity="1"
            fill={waveColor}
            d="M0,298L120,286C240,274,480,250,720,250.2C960,250,1200,274,1320,286L1440,298L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          />
        </svg>
      )}
      <div className="overlay" />
      <div className={`children ${childrenClassName}`}>{children}</div>
      {videography && (
        <span className="credit">
          Kiss & Tell wedding shot by {videography}
        </span>
      )}
    </VideoBGContainer>
  )
}
