import React, { Suspense, lazy } from 'react'

import { Template, TemplateProps } from '../../../../types'

/**
 * Lazy load the templates to reduce the initial bundle size
 * and only load the template that is needed
 */
const templates: Record<
  string,
  React.LazyExoticComponent<React.FC<TemplateProps>>
> = {
  template_1: lazy(() => import('./Template1')),
  template_2: lazy(() => import('./Template2')),
  template_3: lazy(() => import('./Template3')),
  template_4: lazy(() => import('./Template4')),
}

export const RenderTemplate: React.FC<{
  props: TemplateProps
  eventTemplate: Template
}> = ({ props, children, eventTemplate }) => {
  const SelectedTemplate = templates[eventTemplate] || templates.template_4

  return (
    <Suspense fallback={<div>Loading template...</div>}>
      <SelectedTemplate {...props}>{children}</SelectedTemplate>
    </Suspense>
  )
}
