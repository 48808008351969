import { configureStore } from '@reduxjs/toolkit'

import siteReducer from './features/siteSlice'
import bookingReducer from './features/bookingSlice'
import publicBookingReducer from './features/publicBookingSlice'

export const store = configureStore({
  reducer: {
    booking: bookingReducer,
    publicBooking: publicBookingReducer,
    site: siteReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
