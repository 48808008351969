import styled from 'styled-components'

import { gray, salmon } from '../../../styles/themeColors'

export const RoomDetailsContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  justify-content: space-evenly;
  padding: 1rem 0 1.8rem 0;
  text-align: center;
  width: 100%;
`

export const EventPricesContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  max-width: 450px;
  padding: 0 1.3rem 0.5rem 1.3rem;
  & p {
    margin: 0;
    width: fit-content;
  }
  & .eventcard__guest {
    margin-top: -5px;
    padding-left: 1px;
  }
  & .eventcard__avg {
    align-items: center;
    color: ${gray};
    display: flex;
    font-size: 13px;
    & span {
      color: ${salmon};
      font-size: 24px;
      font-weight: 700;
    }
  }
`
