import styled from 'styled-components'

export const SwipeControlContainer = styled.div`
  height: 50px;
  position: relative;
  touch-action: pan-y;
  width: 100%;
  &:after {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    top: 60%;
    transform: translate(-50%, -50%);
    width: 40%;
  }
  & span {
    position: absolute;
    text-align: center;
    width: 100%;
    & svg {
      position: relative;
      top: -1px;
    }
  }
`
