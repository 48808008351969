import gql from 'graphql-tag'

export const CREATE_MARKETING_FORM = gql`
  mutation createMarketingForm($input: MarketingFormInput!) {
    createMarketingForm(input: $input) {
      result {
        id
      }
      errors {
        key
        message
      }
    }
  }
`

const NOTIFICATION_FIELDS_FRAG = gql`
  fragment NotificationFields on Notification {
    dismissed
    id
    payload {
      ... on EventComponentAddedBody {
        eventComponentId
        eventId
        image {
          assocId
          assocType
          ext
          id
        }
        message
      }
      ... on NoneBody {
        body
      }
      ... on RsvpBody {
        eventComponentId
        eventComponentName
        guestId
        guestName
      }
      ... on SimpleNotificationBody {
        message
        meta
      }
    }
    seen
    volatile
  }
`

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription OnNotificationsPush {
    notificationsPush {
      ...NotificationFields
    }
  }
  ${NOTIFICATION_FIELDS_FRAG}
`

export const GET_NOTIFICATIONS = gql`
  query getNotifications($cursor: String, $limit: Int = 5) {
    notifications(cursor: { cursor: $cursor, limit: $limit }) {
      cursor
      entries {
        ...NotificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS_FRAG}
`

export const NOTIFICATION_SEEN = gql`
  mutation notificationSeen($id: ID!) {
    notificationSeen(id: $id) {
      errors {
        key
        message
      }
      result {
        ...NotificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS_FRAG}
`

export const NOTIFICATION_DISMISSED = gql`
  mutation notificationDismissed($id: ID!) {
    notificationDismissed(id: $id) {
      errors {
        key
        message
      }
      result {
        ...NotificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS_FRAG}
`

export const TRACK_VIEW = gql`
  mutation trackView(
    $guestId: ID
    $eventId: ID
    $source: String!
    $destination: String!
  ) {
    trackView(
      guestId: $guestId
      eventId: $eventId
      source: $source
      destination: $destination
    ) {
      result {
        id
      }
      errors {
        key
        message
      }
    }
  }
`
