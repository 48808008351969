import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import get from 'lodash/fp/get'
import { useMutation } from '@apollo/react-hooks'
import { MdAttachMoney, MdTitle, MdAddCircle } from 'react-icons/md'

import { CREATE_NEGOTIATION_ITEM } from '../../../../graphql'
import { useForm } from '../../../../common/alterForms'
import { KnTField } from '../../../../components'
import { gray, salmon } from '../../../../styles/themeColors'
import { Error } from '../../../../types'

export const AddNegotiationItem: React.FC<{
  eventComponentId: string
  discountLimit: number
}> = ({ eventComponentId, discountLimit }) => {
  const [showModal, setShowModal] = useState(false)
  const [createItem, { loading }] = useMutation(CREATE_NEGOTIATION_ITEM)

  const initModel = { title: '', price: '' }
  const { model, handleFieldChange, errors, pushError, reset } =
    useForm(initModel)

  const handleAddItem = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    if (loading) return

    const variables = {
      eventComponentId,
      input: model,
    }
    createItem({ variables }).then(({ data }) => {
      if (data.createNegotiationItem.errors.length > 0) {
        data.createNegotiationItem.errors.forEach(({ key, message }: Error) =>
          pushError(message, key)
        )
      } else {
        setShowModal(false)
        reset(initModel)
      }
    })
  }

  const componentError: string = get('component', errors)
  return (
    <>
      <Modal
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
        className="youtube-modal"
      >
        <h3 className="m-0 px-5 pt-5">Create negotiation item</h3>
        <form onSubmit={handleAddItem} className="px-5 pb-5">
          {componentError && (
            <Alert className="mt-2" variant="danger">
              {componentError}
            </Alert>
          )}
          <KnTField
            value={model}
            error={errors}
            setValue={handleFieldChange}
            name="title"
            placeholder="Item name or description"
            icon={<MdTitle size={24} color={gray} />}
          />
          <KnTField
            value={model}
            error={errors}
            setValue={handleFieldChange}
            valueType="FLOAT"
            name="price"
            placeholder="Enter item price"
            type="number"
            attrs={{ step: 0.01, min: discountLimit * -1 }}
            icon={<MdAttachMoney size={24} color={gray} />}
          />
          <Button disabled={loading} className="float-right mt-3" type="submit">
            Add Item
          </Button>
        </form>
      </Modal>

      <button
        type="button"
        className="float-left small-btn-outline"
        onClick={() => setShowModal(true)}
      >
        <MdAddCircle color={salmon} size={16} className="svg-top1" /> Add item
      </button>
    </>
  )
}
