import React from 'react'

import { offeringTypes } from '../../../common/constants'
import { IOfferingModalDetails } from '../../../types'

import { EventPrices } from '.'

export const OfferingModalDetails: React.FC<IOfferingModalDetails> = ({
  data,
  guests = 0,
  pricePerPerson = 0,
  packagePrice = 0,
  hideDescription = false,
}) => {
  const info = data?.description
  const { roomOffering } = offeringTypes

  if (data.__typename === roomOffering) {
    return (
      <div className="pb-3 px-2 px-md-4 mt-3">
        {!hideDescription && (
          <div
            className={info ? '' : 'text-center fz-12 font-italic'}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: info || 'Pending description' }}
          />
        )}
      </div>
    )
  }

  return (
    <div className="pb-3 px-2 px-md-4">
      {!hideDescription && (
        <>
          <div className="expanded-data mb-0 pt-3">
            <EventPrices
              packagePrice={packagePrice}
              pricePerPerson={pricePerPerson}
              guests={guests}
              className="mb-2 px-0"
            />
          </div>
          <div
            className={info ? '' : 'text-center fz-12 font-italic'}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: info || 'Pending description' }}
          />
        </>
      )}
    </div>
  )
}
