import styled from 'styled-components'

import headsPlitting from '../../../assets/images/Landingpages/headsplitting.png'
import happyPlanning from '../../../assets/images/Landingpages/happy_planning.png'

export const MarketingFormContainer = styled.form`
  border-radius: 5px;
  margin: 0 auto;
  max-width: 1100px;
  padding: 20px 0;
  width: 100%;
  & .send {
    border-radius: 30px;
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin: 0 auto;
    max-width: 330px;
    width: 90%;
  }
  & .simple-field-knt {
    background-color: white;
  }
`

export const Comparison = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  & .problems,
  & .what-you-get {
    align-items: flex-start;
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    justify-content: center;
    margin: -88px 0 -115px 0;
    padding: 180px 10px;
    position: relative;
    width: 50%;
    z-index: 1;
    & dl {
      position: relative;
      max-width: 500px;
      z-index: 2;
      & dt,
      & dd {
        text-align: center;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }
      & dt {
        font-size: 1.3rem;
        font-weight: 700;
      }
      & dd {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 0;
        padding-left: 0;
      }
    }
    &:before {
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
  & .problems {
    background-image: url(${headsPlitting});
    &:before {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  & .what-you-get {
    background-image: url(${happyPlanning});
    &:before {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  @media (max-width: 1000px) {
    & .problems,
    & .what-you-get {
      margin-bottom: -90px;
      margin-top: -80px;
    }
  }
  @media (max-width: 880px) {
    & .problems,
    & .what-you-get {
      margin-top: -60px;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    & .problems,
    & .what-you-get {
      width: 100%;
    }
    & .problems {
      margin-top: -50px;
      margin-bottom: 0;
    }
    & .what-you-get {
      margin-top: 0;
      margin-bottom: -75px;
    }
  }
  @media (max-width: 500px) {
    & .problems {
      margin-top: -40px;
    }
    & .problems,
    & .what-you-get {
      padding: 70px 10px;
    }
  }
  @media (max-width: 470px) {
    & .what-you-get {
      margin-bottom: -65px;
    }
  }
`
