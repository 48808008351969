import React, { useState } from 'react'
import { MdClear } from 'react-icons/md'

import SearchVenues from '../../../components/BannersModule/SearchVenues'
import banner from '../../../assets/images/rsvp/banner.jpg'
import { darkGray } from '../../../styles/themeColors'

import { RSVPbannerContainer } from './style'

export const RSVPbanner: React.FC = () => {
  const [searchVenues, setSearchVenues] = useState(false)
  const [hideBanner, setHideBanner] = useState(
    sessionStorage.getItem('event-builder-banner-chat')
  )

  const closeModal = () => {
    setHideBanner('hide')
    sessionStorage.setItem('event-builder-banner-chat', 'hide')
  }

  if (hideBanner) return <></>
  return (
    <RSVPbannerContainer>
      <MdClear
        size={35}
        color={darkGray}
        className="close-banner cursor"
        onClick={closeModal}
      />
      <div onClick={() => setSearchVenues(true)} role="button" tabIndex={0}>
        <img
          src={banner}
          alt="Instructions"
          className="instructions img-fluid shadow-sm cursor"
        />
      </div>
      <SearchVenues
        create
        show={searchVenues}
        onClose={() => setSearchVenues(false)}
        title="Find your dream location!"
      />
    </RSVPbannerContainer>
  )
}
