import gql from 'graphql-tag'

import { RSVP_EC, FLIGHT_DATA } from './rsvpQueries'
import { IMAGE_FIELDS_FRAG } from './general'

const USER_PROFILE = gql`
  fragment userProfile on UserProfile {
    avatarUrl
    firstName
    id
    lastName
  }
`

const COMMENTS_FIELDS = gql`
  fragment commentsFields on Comment {
    body
    id
    insertedAt
    user {
      ...userProfile
    }
  }
  ${USER_PROFILE}
`

const POSTS_FIELDS = gql`
  fragment postsFields on Post {
    body
    commentCount
    commentsPreview {
      ...commentsFields
    }
    eventId
    id
    insertedAt
    user {
      ...userProfile
    }
    tiers {
      id
      name
    }
    deadline
    video
    pinned
    poll {
      multi
      options {
        id
        name
        percent
        voted
      }
    }
  }
  ${COMMENTS_FIELDS}
  ${USER_PROFILE}
`

export const GET_EVENT_FEEDS = gql`
  query feed($eventIds: [ID!], $cursor: CursorInput) {
    feed(eventIds: $eventIds, cursor: $cursor) {
      entries {
        ...postsFields
      }
      cursor
    }
    me {
      id
      firstName
      lastName
      avatarUrl
    }
  }
  ${POSTS_FIELDS}
`

export const GET_COMMENTS = gql`
  query comments($postId: ID!, $cursor: String) {
    comments(postId: $postId, cursor: { cursor: $cursor, limit: 6 }) {
      entries {
        ...commentsFields
      }
      cursor
    }
  }
  ${COMMENTS_FIELDS}
`

export const CREATE_FEED_POST = gql`
  mutation createPost($eventId: ID!, $tierIds: [ID!]!, $input: PostInput!) {
    createPost(eventId: $eventId, tierIds: $tierIds, input: $input) {
      result {
        ...postsFields
      }
      errors {
        key
        message
      }
    }
  }
  ${POSTS_FIELDS}
`

export const CREATE_COMMENT_POST = gql`
  mutation createCommentPost($postId: ID!, $input: CommentInput!) {
    createComment(postId: $postId, input: $input) {
      result {
        ...commentsFields
      }
      errors {
        key
        message
      }
    }
  }
  ${COMMENTS_FIELDS}
`

export const ADD_FEED_SUBSCRIPTION = gql`
  subscription postAdded($eventId: ID!) {
    postAdded(eventId: $eventId)
  }
`

export const DELETE_POST = gql`
  mutation deletePost($id: ID!) {
    deletePost(id: $id) {
      result {
        id
      }
      errors {
        message
      }
    }
  }
`

export const DELETE_COMMENT = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      result {
        ...commentsFields
      }
      errors {
        message
      }
    }
  }
  ${COMMENTS_FIELDS}
`

export const UPDATE_POST = gql`
  mutation updatePost($id: ID!, $input: PostInput!, $tierIds: [ID!]!) {
    updatePost(id: $id, input: $input, tierIds: $tierIds) {
      result {
        ...postsFields
      }
      errors {
        key
        message
      }
    }
  }
  ${POSTS_FIELDS}
`

export const PIN_POST = gql`
  mutation pinPost($id: ID!) {
    pinPost(id: $id) {
      result {
        ...postsFields
      }
      errors {
        key
        message
      }
    }
  }
  ${POSTS_FIELDS}
`

export const UNPIN_POST = gql`
  mutation unpinPost($id: ID!) {
    unpinPost(id: $id) {
      result {
        ...postsFields
      }
      errors {
        key
        message
      }
    }
  }
  ${POSTS_FIELDS}
`

export const UPDATE_COMMENT = gql`
  mutation updateComment($id: ID!, $input: CommentInput!) {
    updateComment(id: $id, input: $input) {
      result {
        ...commentsFields
      }
      errors {
        key
        message
      }
    }
  }
  ${COMMENTS_FIELDS}
`

export const SEND_VOTE = gql`
  mutation vote($postId: ID!, $optionId: ID!) {
    vote(postId: $postId, optionId: $optionId) {
      result {
        id
        name
        percent
        voted
      }
      errors {
        key
        message
      }
    }
  }
`

export const GET_OPTION_VOTES = gql`
  query votes($optionId: ID!, $postId: ID!) {
    votes(optionId: $optionId, postId: $postId) {
      avatarUrl
      firstName
      lastName
    }
  }
`

// this query is for the feed components slider, I'm using the "invitation" property because even if it's my event I'm a "guest" by default
export const GET_BOOKED_EVENT_COMPONENTS = gql`
  query getBookedEventComponents($eventId: ID!) {
    me {
      id
      ... on Customer {
        myEventList {
          id
        }
        eventInfo(id: $eventId) {
          ...getBookedEventComponentsCommonEvent
        }
        myBookings(eventId: $eventId) {
          ...getBookedEventComponentsCommonBooking
        }
        invitation(eventId: $eventId) {
          id
          components {
            ...EventComponentFields
          }
        }
      }
      ... on Concierge {
        myEventList {
          id
        }
        eventInfo(id: $eventId) {
          ...getBookedEventComponentsCommonEvent
        }
        myBookings(eventId: $eventId) {
          ...getBookedEventComponentsCommonBooking
        }
        invitation(eventId: $eventId) {
          id
          components {
            ...EventComponentFields
          }
        }
      }
      ... on Admin {
        myEventList {
          id
        }
        eventInfo(id: $eventId) {
          ...getBookedEventComponentsCommonEvent
        }
        myBookings(eventId: $eventId) {
          ...getBookedEventComponentsCommonBooking
        }
        invitation(eventId: $eventId) {
          id
          components {
            ...EventComponentFields
          }
        }
      }
    }
  }
  fragment getBookedEventComponentsCommonEvent on Event {
    id
    guestProfile {
      id
      flightInformation {
        ...flightData
      }
    }
  }
  fragment getBookedEventComponentsCommonBooking on Booking {
    id
    status
    date
    duration
    guest {
      id
    }
    bookingAddOn {
      id
      offeringAddOn {
        metadata
      }
      offeringAddOnOption {
        quantity
      }
    }
    eventComponent {
      id
      alias
      date
      duration
      bookingCount
      capacity
      booking {
        id
        status
      }
      offering {
        __typename
        ... on PackageOffering {
          id
          name
          mainImage {
            ...ImageFields
          }
          provider {
            airportCode
            name
            id
            mainImage {
              ...ImageFields
            }
            images {
              ...ImageFields
            }
          }
        }
        ... on RoomOffering {
          id
          name
          mainImage {
            ...ImageFields
          }
          provider {
            airportCode
            name
            id
            mainImage {
              ...ImageFields
            }
            images {
              ...ImageFields
            }
          }
        }
      }
    }
  }
  ${FLIGHT_DATA}
  ${RSVP_EC}
  ${IMAGE_FIELDS_FRAG}
`
