import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { Helmet } from 'react-helmet'
import { HiOutlineCheck } from 'react-icons/hi'

import { salmon } from '../../styles/themeColors'
import { useMoveToTop, IframeYoutube } from '../../common'
import vendorCollage from '../../assets/images/Partnership/vendor-collage.jpg'
import contactUsBanner from '../../assets/images/contact-us-banner.jpg'

import { Header, PartnershipYoutube, ContactBanner } from './style'
import { YoutubeList } from './YoutubeList'
import '../../styles/partnership.sass'

export const Partnership: React.FC = () => {
  useMoveToTop()

  return (
    <section className="public-container public-page partnership-info">
      <Helmet>
        <title>
          Kiss & Tell | Destination Wedding Location and Vendor Partnerships
        </title>
        <meta
          name="description"
          content="Kiss & Tell Destination Wedding Location and Vendor Partnerships"
        />
      </Helmet>
      <Header>
        <h1 className="title">
          A better way to connect with couples and their guests.
        </h1>
        <h3 className="subtitle">
          With all of the side work involved in event deal flow, it's no wonder
          you're feeling abandoned by technology! We've traveled the world
          listening to your needs and now ask for your hand in our commitment to
          revolutionize weddings & group travel.
        </h3>
      </Header>
      <section className="text-center py-5">
        <h2 className="text-primary font-lf fz-50 after-line">
          Be the Change Needed in Weddings!
        </h2>
        <p className="fz-35 fz-sm-25 fwl max-1100">
          Kiss fragmented communications goodbye with an all in one solution
          connecting you to customers, other vendors and event details
        </p>
      </section>
      <img
        src={vendorCollage}
        alt="Vendor Collage"
        className="img-fluid mx-auto d-block"
      />

      <div className="partnerships-list pb-4">
        <p className="partnerships-list__item">
          <HiOutlineCheck color={salmon} size={60} />
          <span>Avoid being flooded with inaccurate leads</span>
        </p>
        <p className="partnerships-list__item">
          <HiOutlineCheck color={salmon} size={60} />
          <span>
            Attract the right customer matches by displaying your packages and
            rates
          </span>
        </p>
        <p className="partnerships-list__item">
          <HiOutlineCheck color={salmon} size={60} />
          <span>Reject never-ending upfront fees</span>
        </p>
        <p className="partnerships-list__item">
          <HiOutlineCheck color={salmon} size={60} />
          <span>Welcome fair commissions on bookings</span>
        </p>
        <p className="partnerships-list__item">
          <HiOutlineCheck color={salmon} size={60} />
          <span>Automated group deal flow, admin and billing</span>
        </p>
        <p className="partnerships-list__item">
          <HiOutlineCheck color={salmon} size={60} />
          <span>
            Save yours and your customer’s precious time with customizable
            packages
          </span>
        </p>
        <div className="partnerships-list__cta">
          <a href="mailto:team@kissandtell.com?subject=Sign my business up for the Kiss & Tell Marketplace">
            <Button>Sign Up</Button>
          </a>
          <p>Let those other platforms be a distant memory</p>
        </div>
      </div>
      <ContactBanner className="my-5 shadow">
        <img src={contactUsBanner} alt="Contact us" />
        <div className="contactBannerInfo">
          <h2>
            Want to earn more on your next booking and save on the journey
            there?
          </h2>
          <p>
            <a href="mailto:team@kissandtell.com?subject=I’m interested in becoming a Kiss & Tell brand ambassador">
              Ask us
            </a>{' '}
            about our Brand Ambassador program for photographers, planners, or
            any event pros on the go.
          </p>
        </div>
      </ContactBanner>
      <PartnershipYoutube className="pt-0 pt-lg-5">
        <Row>
          <Col xs={12} md={6} className="PartnershipYoutube">
            <div className="PartnershipYoutube__info">
              <h2>We’re building a community with perks</h2>
              <p>
                Because communities are all about giving back, join ours and
                reserve your mention on our next tv segment.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <IframeYoutube videoId="SaQtAy0Bz6E" />
          </Col>
        </Row>
      </PartnershipYoutube>
      <YoutubeList />
    </section>
  )
}
