import gql from 'graphql-tag'

export const CREATE_TIER = gql`
  mutation createTier($eventId: ID!, $input: TierInput!) {
    createTier(eventId: $eventId, input: $input) {
      result {
        id
        name
      }
      errors {
        key
        message
      }
    }
  }
`

export const UPDATE_TIER = gql`
  mutation updateTier($id: ID!, $input: TierInput!) {
    updateTier(id: $id, input: $input) {
      result {
        id
        name
      }
      errors {
        key
        message
      }
    }
  }
`

export const DELETE_TIER = gql`
  mutation deleteTier($id: ID!) {
    deleteTier(id: $id) {
      result {
        id
      }
      errors {
        key
        message
      }
    }
  }
`
