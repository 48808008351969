import React, { useEffect } from 'react'
import get from 'lodash/fp/get'
import set from 'lodash/fp/set'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import findIndex from 'lodash/fp/findIndex'
import filter from 'lodash/fp/filter'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useQuery } from '@apollo/react-hooks'

import { getImageUrl } from '../../../../common'
import { UserPicture, DefaultUserPicture } from '../../../../components'
import {
  NEGOTIATION_BALANCE,
  GET_NEGOTIATION_QUOTES,
  GET_NEGOTIATION_QUOTES_VENDOR,
  EVENT_COMPONENT_SUBSCRIPTION,
  EVENT_COMPONENT_SUBSCRIPTION_DELETE,
} from '../../../../graphql'
import {
  Quote,
  Image,
  IMessages,
  Event,
  ProviderPreview,
  PackageAddons,
  EventComponentStatus,
  Role,
} from '../../../../types'

import { ProviderCard } from './style'
import QuotesList from './QuotesList'

import { Balance } from '.'

const Quotes: React.FC<IMessages> = ({ eventId, providerId, path, role }) => {
  const { Vendor, Customer } = Role

  const QUERY =
    role === Vendor ? GET_NEGOTIATION_QUOTES_VENDOR : GET_NEGOTIATION_QUOTES

  const { data, subscribeToMore, refetch } = useQuery(QUERY, {
    variables: { eventId, providerId, cursor: null },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    subscribeToMore({
      document: NEGOTIATION_BALANCE,
      variables: { eventId, providerId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev
        }
        const NEW = getOr('0', 'data.balanceUpdates.balance', subscriptionData)
        return set(`${path}.balance`, NEW, prev)
      },
    })

    subscribeToMore({
      document: EVENT_COMPONENT_SUBSCRIPTION,
      variables: { eventId },
      updateQuery: (prev, { subscriptionData }) => {
        const pc: PackageAddons = subscriptionData.data.eventComponentsUpdate
        const idx = findIndex(
          el => el.eventComponent.id === pc.eventComponentId,
          getOr([], `${path}.quotes`, prev) as Quote[]
        )

        if (!get(`${path}.quotes[${idx}].items`, prev)) {
          /**
           * there is a strange bug that makes the cache doesn't contain the items[]
           * so I must use refetch but if the cache already contains the items[]
           * there is no need to do refetch and the cache works normally
           */
          refetch({ eventId, providerId, cursor: null })
          return prev
        }

        const customization: PackageAddons[] = getOr(
          [],
          `${path}.quotes[${idx}].eventComponent.packageCustomization`,
          prev
        )
        const customizationIdx = findIndex(el => el.id === pc.id, customization)

        // adding
        if (customizationIdx < 0) {
          return set(
            `${path}.quotes[${idx}].eventComponent.packageCustomization`,
            [pc, ...customization],
            prev
          )
        }

        // editing
        return set(
          `${path}.quotes[${idx}].eventComponent.packageCustomization[${customizationIdx}]`,
          pc,
          prev
        )
      },
    })

    subscribeToMore({
      document: EVENT_COMPONENT_SUBSCRIPTION_DELETE,
      variables: { eventId },
      updateQuery: (prev, { subscriptionData }) => {
        const pc = subscriptionData.data.eventComponentsAddonsDelete
        const idx = findIndex(
          el => el.eventComponent.id === pc.eventComponentId,
          getOr([], `${path}.quotes`, prev) as Quote[]
        )

        if (!get(`${path}.quotes[${idx}].items`, prev)) {
          /**
           * there is a strange bug that makes the cache doesn't contain the items[]
           * so I must use refetch but if the cache already contains the items[]
           * there is no need to do refetch and the cache works normally
           */
          refetch({ eventId, providerId, cursor: null })
          return prev
        }

        const updatedPackageCustomization = flow(
          get(`${path}.quotes[${idx}].eventComponent.packageCustomization`),
          filter((el: PackageAddons) => el.id !== pc.id)
        )(prev)

        return set(
          `${path}.quotes[${idx}].eventComponent.packageCustomization`,
          updatedPackageCustomization,
          prev
        )
      },
    })
  }, [eventId, path, providerId, subscribeToMore, refetch])

  const paid: string = get(`${path}.balance`, data)
  const quotes: Quote[] = getOr([], `${path}.quotes`, data)
  const filteredQuotes: Quote[] = quotes.filter(
    o => o.eventComponent.status !== EventComponentStatus.Rejected
  )
  const event: Event = get(`${path}.event`, data)
  const provider: ProviderPreview = get(`${path}.provider`, data)
  const mainImage = flow(get(`${path}.provider.mainImage`), (el: Image) =>
    getImageUrl(el, '600x330')
  )(data)

  const returnLink = role === Vendor ? `/` : `/event/${eventId}/event-builder`

  const avatarUrl =
    role === Vendor ? event?.host?.avatarUrl : provider?.vendor.avatarUrl
  return (
    <>
      <Link to={returnLink} data-cy="return-to-builder">
        <Button className="btn-outline-salmon-simple fwsb mb-3">
          <MdKeyboardArrowLeft className="svg-top1" size={20} />
          Return {role === Customer && 'to event builder'}
        </Button>
      </Link>
      <ProviderCard
        className="shadow-sm"
        style={{ backgroundImage: `url(${mainImage})` }}
      >
        {avatarUrl ? (
          <UserPicture
            name={event?.name}
            url={avatarUrl}
            className="shadow"
            style={{
              height: '65px',
              width: '65px',
            }}
          />
        ) : (
          <DefaultUserPicture className="shadow" size={44} />
        )}
        <h2>{event?.name}</h2>
        <h3>
          {role === Vendor && event?.host
            ? `${event?.host.firstName} ${event?.host.lastName}`
            : provider?.name}
        </h3>
      </ProviderCard>
      <QuotesList quotes={filteredQuotes} />
      {role === Customer && (
        <Balance
          quotes={quotes}
          paid={paid}
          eventId={eventId}
          providerId={providerId}
        />
      )}
    </>
  )
}

export default React.memo(Quotes)
