import gql from 'graphql-tag'

export const GET_FORM_BY_ASSOC = gql`
  query marketingFormByAssocId($assocId: ID!, $formName: String!) {
    me {
      ... on Customer {
        eventList {
          id
        }
      }
      ... on Concierge {
        eventList {
          id
        }
      }
      ... on Admin {
        eventList {
          id
        }
      }
    }
    marketingFormByAssocId(assocId: $assocId, formName: $formName) {
      assocId
      formData
      formName
    }
  }
`

export const CREATE_MARKETING_FORM_EXISTING_USER = gql`
  mutation createMarketingFormExistingUser($input: MarketingFormInput!) {
    createMarketingFormExistingUser(input: $input) {
      result {
        id
      }
      errors {
        key
        message
      }
    }
  }
`
