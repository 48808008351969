import React from 'react'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button'
import { useMutation } from '@apollo/react-hooks'

import { ICancelBooking } from '../../../types'
import { CANCEL_BOOKING } from '../../../graphql'
import {
  confirmAlert,
  errorAlert,
  loaderAlert,
  simpleAlert,
} from '../../../common'

export const CancelBooking: React.FC<ICancelBooking> = ({
  bookingId,
  text = 'Cancel Booking',
  callbackAfterCancel,
}) => {
  const [cancelBooking, { loading }] = useMutation(CANCEL_BOOKING)

  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (loading) return

    confirmAlert({
      title: 'Cancel RSVP',
      html: `Are you sure you'd like to cancel your RSVP?`,
    }).then(resp => {
      if (!resp.value) return

      loaderAlert({ html: 'canceling...' })
      const variables = { id: bookingId }
      cancelBooking({ variables })
        .then(({ data }) => {
          const response = data?.cancelBooking
          Swal.close()

          if (response?.errors.length > 0) {
            errorAlert(
              response?.errors,
              `an error occurred while canceling your reservation`
            )
          } else {
            simpleAlert({
              html: 'RSVP was successfully cancelled.',
              icon: 'success',
            })
            callbackAfterCancel?.() // in case we need some action after canceling
          }
        })
        .catch(() => {
          simpleAlert({
            html: "it wasn't possible to cancel this RSVP. Please try again later.",
            icon: 'error',
          })
        })
    })
  }

  return (
    <Button
      variant="outline-danger"
      className="py-1 d-block mt-2 fz-12"
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}
