import React from 'react'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

import { Booking, BookingStatus } from '../../../types'

export const EditBookingCTA: React.FC<{
  booking: Booking
  className?: string
  text?: string
}> = ({ booking, className = 'py-1 px-4', text = 'Edit' }) => {
  // If the booking is not booked it can't be declined
  if (booking.status !== BookingStatus.Booked) return null

  // right now we only do a redirect to the booking page edit
  // if we need to add more logic we can do it here
  return (
    <Link to={`/booking/${booking.id}/edit-my-booking`}>
      <Button className={className}>{text}</Button>
    </Link>
  )
}
