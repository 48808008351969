import React from 'react'
import moment from 'moment'
import get from 'lodash/fp/get'
import { MdCalendarToday } from 'react-icons/md'

import { checkEnv } from '../../../common'
import {
  CALENDAR_DEV_KEY,
  CALENDAR_PROD_KEY,
  CALENDAR_DEV_CLIENT_ID,
  CALENDAR_PROD_CLIENT_ID,
} from '../../../common/constants'
import { IAddToCalendar } from '../../../types'
import { salmon } from '../../../styles/themeColors'

export const AddToCalendar: React.FC<IAddToCalendar> = ({
  hideIcon = false,
  className = 'd-block mt-3',
  alias,
  eventName,
  offering,
  date,
  duration = 1,
}) => {
  const { develop } = checkEnv()
  const { gapi } = window
  const clientId = develop ? CALENDAR_DEV_CLIENT_ID : CALENDAR_PROD_CLIENT_ID
  const apiKey = develop ? CALENDAR_DEV_KEY : CALENDAR_PROD_KEY
  const discoveryDocs = [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ]
  const scope = 'https://www.googleapis.com/auth/calendar.events'

  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (!gapi) return

    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey,
        clientId,
        discoveryDocs,
        scope,
      })

      gapi.client.load('calendar', 'v3')

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const providerAddress = offering.provider.address
          const offeringName = alias || offering.name
          const startDate = moment(date).startOf('day').toISOString()
          const endDate =
            duration > 1
              ? moment(date)
                  .add(duration - 1, 'days')
                  .endOf('day')
                  .toISOString()
              : moment(date).endOf('day').toISOString()

          const event = {
            summary: eventName,
            location: providerAddress,
            description: offeringName,
            start: {
              dateTime: startDate,
            },
            end: {
              dateTime: endDate || startDate,
            },
          }

          const calendar = get('client.calendar', gapi)
          const request = calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          })

          request.execute((resp: { htmlLink: string }) => {
            window.open(resp.htmlLink)
          })
        })
    })
  }

  if (!gapi) {
    return <></>
  }

  return (
    <a
      className={`tc-lgray cursor d-flex align-items-center ${className}`}
      onClick={handleClick}
    >
      {!hideIcon && (
        <MdCalendarToday size={30} className="mr-2" color={salmon} />
      )}
      Add to Calendar
    </a>
  )
}
