import React, { useState } from 'react'
import some from 'lodash/fp/some'
import flow from 'lodash/fp/flow'
import head from 'lodash/fp/head'
import filter from 'lodash/fp/filter'
import Button from 'react-bootstrap/Button'
import { MdAdd } from 'react-icons/md'

import { DefaultUserPicture, UserPicture } from '../../components'
import { useAppSelector } from '../../hooks'
import { useCurrentUser } from '../../common'
import { IDefaultCards, MyEvent, Event } from '../../types'
import SearchVenues from '../../components/BannersModule/SearchVenues'
import VenueFacade from '../../assets/images/icons/VenueFacade.png'

import { RSVPCard } from './style'

export const DefaultCards: React.FC<IDefaultCards> = ({
  events = [],
  feedComponentGroups,
}) => {
  const { currentEventId } = useAppSelector(state => state.site)
  const currentUser = useCurrentUser()
  const [selectedEvent, setSelectedEvent] = useState('')
  const [searchVenues, setSearchVenues] = useState(false)

  const event = flow(
    filter((ev: MyEvent | Event) => {
      return (
        ev.id === currentEventId &&
        !some(component => component.event.id === ev.id, feedComponentGroups)
      )
    }),
    head
  )(events)

  // priority order: eventImage > avatarUrl > defaultUserPicture
  const isMyEvent = event?.__typename !== 'Event'
  const avatarUrl = isMyEvent ? currentUser.avatarUrl : event?.host?.avatarUrl
  const eventImage = event?.mainImage || avatarUrl
  return (
    <>
      {event && (
        <RSVPCard className="rsvp-card" key={`default-rsvp-${event.id}`}>
          {eventImage ? (
            <UserPicture name={`${event.name}`} url={eventImage} />
          ) : (
            <DefaultUserPicture />
          )}
          <h2 className="fz-15 mb-0 pt-2 fwsb">{event.name}</h2>

          <div className="default-card-info">
            <img
              src={VenueFacade}
              alt="VenueFacade"
              className="d-block mx-auto"
            />

            {isMyEvent ? (
              <Button
                className="d-block mx-auto fz-14 mt-3"
                onClick={() => {
                  setSelectedEvent(event.id)
                  setSearchVenues(true)
                }}
              >
                <MdAdd size={20} className="svg-top1" /> Search Venues
              </Button>
            ) : (
              <p className="text-white mt-2 mb-0">Location TBD</p>
            )}
          </div>
        </RSVPCard>
      )}
      <SearchVenues
        defaultCard
        selectedEvent={selectedEvent}
        show={searchVenues}
        onClose={() => setSearchVenues(false)}
        title="Find your dream location!"
      />
    </>
  )
}
