import React from 'react'

import { useCurrentUser } from '../../common'
import { Role } from '../../types'

import { VendorNarbar, CustomerNavbar } from '.'

const NavBar: React.FC = () => {
  const { role } = useCurrentUser()
  const { Vendor } = Role

  if (role === Vendor) {
    return <VendorNarbar />
  }

  return <CustomerNavbar />
}

export default React.memo(NavBar)
