import React, { useState } from 'react'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import get from 'lodash/fp/get'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { MdNotifications, MdClose, MdNotificationsActive } from 'react-icons/md'

import {
  getImageUrl,
  errorAlert,
  makeSimpleNotification,
} from '../../../common'
import { gray, salmon } from '../../../styles/themeColors'
import { NotificationCart } from '../style'
import { SiteLogo } from '../../../components/customIcons'
import { Notification, SimpleNotificationBody } from '../../../types'
import { NOTIFICATION_SEEN, NOTIFICATION_DISMISSED } from '../../../graphql'

export const makeSimpleNotificationLink = (
  notification: Notification,
  handleClick?: (notificationId: string) => void
): React.ReactElement => {
  const { text, link } = makeSimpleNotification(
    notification.payload as SimpleNotificationBody
  )

  return (
    <Link to={link} onClick={() => handleClick && handleClick(notification.id)}>
      <SiteLogo width="21px" height="16px" color={salmon} className="mr-1" />
      {text}
    </Link>
  )
}

const makeECLink = (
  notification: Notification,
  handleClick: (notificationId: string) => void
) => {
  const eventId = get('payload.eventId', notification)
  return (
    <Link
      to={`/event/${eventId}/event-builder`}
      onClick={() => handleClick(notification.id)}
    >
      <img
        src={getImageUrl(get('payload.image', notification), '35x35')}
        alt="Event component"
      />
      {get('payload.message', notification)}
    </Link>
  )
}

export const Notifications: React.FC<{ list: Notification[] }> = React.memo(
  ({ list }) => {
    const [show, setShow] = useState(false)
    const [seen, { loading: loadingSeen }] = useMutation(NOTIFICATION_SEEN)
    const [dismissed, { loading: loadingDismissed }] = useMutation(
      NOTIFICATION_DISMISSED
    )
    const handleSeen = (id: string) => {
      if (loadingSeen || !id) return

      seen({ variables: { id } }).then(({ data }) => {
        if (data.notificationSeen.errors.length > 0) {
          errorAlert(
            data.notificationSeen.errors,
            'There was a problem updating this notification'
          )
        }
      })
    }

    const handleDismissed = (id: string) => {
      if (loadingDismissed || !id) return

      dismissed({ variables: { id } }).then(({ data }) => {
        if (data.notificationDismissed.errors.length > 0) {
          errorAlert(
            data.notificationDismissed.errors,
            'There was a problem updating this notification'
          )
        }
      })
    }

    const handleClick = (notificationId: string) => {
      handleSeen(notificationId)
      setShow(false)
    }

    const hasNew = localStorage.getItem('newNotification')
    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        rootClose
        onToggle={ev => setShow(ev)}
        show={show}
        overlay={
          <Popover id="cart-detail-list">
            <Popover.Title as="h3">
              Notifications
              <Link className="float-right" to="/notifications">
                View all
              </Link>
            </Popover.Title>
            <Popover.Content>
              {list.map(notification => {
                const type = notification.payload?.__typename
                return (
                  <div className="notification" key={notification.id}>
                    <div className="notification-body">
                      {type === 'EventComponentAddedBody' &&
                        makeECLink(notification, handleClick)}

                      {type === 'RsvpBody' &&
                        get('payload.eventComponentName', notification)}

                      {type === 'SimpleNotificationBody' &&
                        makeSimpleNotificationLink(notification, handleClick)}
                    </div>
                    <div className="notification-actions">
                      {!notification.seen && (
                        <span
                          className="seen cursor"
                          onClick={() => handleSeen(notification.id)}
                        />
                      )}
                      {!notification.dismissed && notification.seen && (
                        <MdClose
                          onClick={() => handleDismissed(notification.id)}
                          size={14}
                          className="cursor"
                        />
                      )}
                    </div>
                  </div>
                )
              })}

              {list.length <= 0 && (
                <div className="notification justify-content-center">
                  <div className="notification-body">No new notifications</div>
                </div>
              )}
            </Popover.Content>
          </Popover>
        }
      >
        <NotificationCart
          className={`${hasNew}`}
          onClick={() => localStorage.removeItem('newNotification')}
        >
          <span className="aux-shadow" />
          {hasNew ? (
            <MdNotificationsActive size={24} color={gray} className="cursor" />
          ) : (
            <MdNotifications size={24} color={gray} className="cursor" />
          )}
        </NotificationCart>
      </OverlayTrigger>
    )
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.list) === JSON.stringify(nextProps.list)
  }
)
