import React, { useState, Dispatch, SetStateAction } from 'react'
import set from 'lodash/fp/set'
import some from 'lodash/fp/some'
import uniq from 'lodash/fp/uniq'
import Button from 'react-bootstrap/Button'
import FormCheck from 'react-bootstrap/FormCheck'
import moment, { Moment } from 'moment'
import { MdAdd, MdCancel } from 'react-icons/md'

import { PollOptionInput } from '../../../types'
import { SinglePicker } from '../../../components'
import { darkGray } from '../../../styles/themeColors'

import { PollComposer as PollComposerContainer } from './style'

interface INewPoll {
  incompletePoll: Dispatch<SetStateAction<boolean>>
  setShowPollComposer: Dispatch<SetStateAction<boolean>>
  setOptions: Dispatch<SetStateAction<PollOptionInput[]>>
  setMulti: Dispatch<SetStateAction<boolean>>
  error: boolean
  deadline?: string
  setDeadline: Dispatch<SetStateAction<string | undefined>>
}

export const PollComposer: React.FC<INewPoll> = ({
  incompletePoll,
  setShowPollComposer,
  setMulti,
  setOptions,
  error,
  setDeadline,
  deadline,
}) => {
  const [options, setPollOptions] = useState<string[]>(['', '', ''])
  const [multipleOptions, setMultipleOptions] = useState(false)
  const [duplicateError, showError] = useState(false)

  const updatePollState = (optionList: string[]) => {
    const incomplete = some(option => option === '', optionList) // if any option==='' the poll is not complete
    const optionsWithoutDuplicates = uniq(optionList) // remove duplicate options
    incompletePoll(incomplete)

    if (!incomplete) {
      if (optionsWithoutDuplicates.length === optionList.length) {
        // Complete and different options
        showError(false)
        const sendPollOptions: PollOptionInput[] = optionList.map(el => ({
          name: el,
          id: null,
        }))
        setOptions(sendPollOptions)
      } else {
        incompletePoll(true)
        showError(true)
      }
    }
  }

  const updateField = (
    ev: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const optionsUpdated = set(`[${idx}]`, ev.currentTarget.value, options)
    setPollOptions(optionsUpdated)
    updatePollState(optionsUpdated)
  }
  const addField = () => {
    setPollOptions([...options, ''])
    incompletePoll(true)
  }
  const deleteField = (idx: number) => {
    const currentOptions = [...options] // to prevent changing the original options object
    currentOptions.splice(idx, 1)
    setPollOptions(currentOptions)
    updatePollState(currentOptions)
  }

  const handleDateChange = (date: Moment) => {
    setDeadline(date.format('YYYY-MM-DD 00:00:00'))
  }

  return (
    <>
      {error && (
        <span className="px-3 mb-1 d-block text-danger fz-12">
          It was not possible to add this poll
        </span>
      )}
      {duplicateError && (
        <span className="px-3 mb-1 d-block text-danger fz-12">
          You can't repeat options
        </span>
      )}
      <PollComposerContainer data-cy="creating-poll">
        <MdCancel
          color={darkGray}
          size={35}
          className="cancel cursor"
          onClick={() => setShowPollComposer(false)}
        />
        <h5 className="title-newPoll pb-1 fwb">Add Poll</h5>
        {options.map((el, idx) => (
          <div className="option" key={`option-${idx + 1}`}>
            <input
              placeholder={`Option ${idx + 1}`}
              type="text"
              value={el}
              onChange={ev => updateField(ev, idx)}
            />
            {options.length > 2 && (
              <MdCancel
                color={darkGray}
                size={25}
                onClick={() => deleteField(idx)}
              />
            )}
          </div>
        ))}
        <Button
          variant="secondary"
          className="btn-lgray mt-2 mb-4"
          onClick={addField}
        >
          <MdAdd size={18} style={{ position: 'relative', top: '-1px' }} /> Add
          option
        </Button>

        <div>
          <p className="title-poll-options my-2 fz-16 fwsb">Poll Options</p>
          <FormCheck
            checked={multipleOptions}
            onChange={() => {
              setMultipleOptions(!multipleOptions)
              setMulti(!multipleOptions)
            }}
            custom
            type="checkbox"
            id="check-multipleOptions"
            label="Allow people to choose multiple answers"
          />
        </div>
        <div>
          <label
            className="w-100 fz-16 fwsb m-1 mt-3"
            htmlFor="single_picker_deadline"
          >
            Deadline
          </label>
          <SinglePicker
            key={`picker-${deadline?.trim()}-composer`}
            emitDate={handleDateChange}
            defaultDate={deadline ? moment(deadline) : undefined}
          />
          {deadline && (
            <span
              className="text-danger fz-12 w-100 d-block pt-2 cursor"
              onClick={() => setDeadline(undefined)}
            >
              remove deadline
            </span>
          )}
        </div>
      </PollComposerContainer>
    </>
  )
}
