import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import { MdChatBubble, MdThumbUp } from 'react-icons/md'

import { fromNow, orderTiers, useCurrentUser } from '../../common'
import { IPostProps } from '../../types'
import { RenderTiers } from '../../components/Tiers/RenderTiers'
import { DefaultUserPicture, UserPicture } from '../../components'

import { PostOptions, PollResult, Comments, VideoPost } from './components'
import { HeadPost, MainActions, Postcontent } from './style'

const PostComponent: React.FC<IPostProps> = ({ post, events }) => {
  const [showReply, setShowReply] = useState(false)
  const { userId } = useCurrentUser()
  const myEvent = userId === post?.user?.id
  const userName = `${post?.user?.firstName} ${post?.user?.lastName}`
  const avatarUrl = post?.user?.avatarUrl

  const event = events?.find(e => e.id === post?.eventId)
  return (
    <Card
      id={`post-card-${post.id}`}
      data-cy={post.poll ? 'post-card-poll' : 'post-card'}
      className="shadow-sm rounded mb-2 feed-post"
    >
      <Card.Body className="p-2 p-md-3">
        <HeadPost>
          {myEvent && <PostOptions key={post.id} post={post} />}

          {avatarUrl ? (
            <UserPicture name={userName} url={avatarUrl} />
          ) : (
            <DefaultUserPicture />
          )}
          <div className="info">
            <h5 className="mb-0 fz-16">
              {userName} in {event?.name}
            </h5>
            <small className="text-muted">{fromNow(post.insertedAt)}</small>
          </div>
          <RenderTiers
            tags={orderTiers(post.tiers)}
            eventId={post.eventId}
            events={events}
          />
        </HeadPost>
        <Postcontent
          className="mb-1 pb-3 pt-1 px-1"
          dangerouslySetInnerHTML={{ __html: post.body }}
        />
        {post.video && <VideoPost video={post.video} />}
        {post.poll && <PollResult owned={myEvent} post={post} />}

        <MainActions className="fz-13 fwsb">
          <span className="cursor p-2 d-none">
            <MdThumbUp size={16} /> Like
          </span>
          <span onClick={() => setShowReply(true)} className="cursor p-2">
            <MdChatBubble size={16} /> Comment
          </span>
        </MainActions>
        <Comments
          reply={showReply}
          count={post.commentCount}
          commentsPreview={post.commentsPreview}
          postId={post.id}
        />
      </Card.Body>
    </Card>
  )
}

export default PostComponent
