import React from 'react'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'

import { useMoveToTop } from '../common'

export const Terms: React.FC = () => {
  useMoveToTop()

  return (
    <Container className="py-4">
      <Helmet>
        <title>Kiss & Tell | Terms of Service</title>
        <meta
          name="description"
          content="Kiss & Tell | Terms of Service: Understand our guidelines for a safe and enjoyable experience."
        />
      </Helmet>
      <h1 className="w-100 text-center fwb mb-4 px-2">
        Kiss & Tell Terms of Service
      </h1>

      <h4>Welcome to Kiss & Tell!</h4>

      <div className="text-justify">
        <p>
          These Terms of Service (“<b>Terms</b>”) are a binding legal agreement
          between you and Life Unfiltered Media, Inc. that govern your use of
          the websites, applications, and other offerings from Kiss & Tell.
          (collectively, the “<b>Kiss & Tell Platform</b>”). When used in these
          Terms, “<b>Kiss & Tell,</b>” “<b>we,</b>” “<b>us,</b>” or “<b>our</b>”
          refers to the Kiss & Tell entity with whom you are contracting.
        </p>
        <p>
          The Kiss & Tell Platform offers an online private social network for
          groups of users (“<b>Groups</b>”) to communicate, plan gatherings,
          negotiate and book venue, overnight accommodation, event and travel
          services. Users who offer bookable services are “<b>Vendors</b>”,
          users who create events, invite and update their group, search for,
          build and book event and group stay packages and services are “
          <b>Hosts.</b>” Users who join groups, communicate with other members
          of the group, manage their attendance and book their individual travel
          and activities are “<b>Guests</b>”.{' '}
        </p>
        <p>
          All users must register to participate in group conversations, updates
          and bookings however Kiss & Tell registration is free to all users and
          we do offer a limited user experience to non-registered users, namely
          marketplace browsing, inspirational global event content and simple
          landing pages where you can RSVP to events via your emailed
          invitation.
        </p>
        <p>
          Kiss & Tell is a technology platform provider, and does not own,
          control, offer or manage any Listings or Services in our marketplace
          and is not a party to the contracts entered directly between Vendors,
          Hosts or Guests. As a third party, we retain a 10% fee from all venue
          and group accommodation bookings on our platform and 5% for service
          providers and creatives.
        </p>
        <p>
          Vendors are responsible for delivering the exact services that were
          booked by the Host or Guest through the Kiss & Tell platform, on the
          agreed upon date and time and per the agreed upon payment schedule.
          Bookings are officially reserved once the deposit is paid through the
          Kiss & Tell platform and no additional fees may be applied to bookings
          after a deposit is paid unless they are approved by the Host or Guest
          consumer. Any payments collected directly from the customer (Host or
          Guest) must notified in writing via email to{' '}
          <a href="mailto:team@kissandtell.com">team@kissandtell.com</a> CCing
          the customer and Vendor will be responsible to provide Kiss & Tell
          their 10% within 30 days after the event has ended or the guests have
          checked out.
        </p>
        <p>
          Hosts secure their bookings of vendors by agreeing to a payment
          schedule and placing a deposit (the agreed upon first payment) through
          the Kiss & Tell system. All payments must be made through Kiss & Tell,
          so your event and group stay details will be automatically connected
          to each of your guests for that group or subgroup. In the event that a
          direct payment is made to a vendor offline, this must be communicated
          via email to{' '}
          <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>, CCing
          the vendor. Failure to book vendors directly through the Kiss & Tell
          marketplace will disable your group’s automated booking and logistics
          features for that vendor.
        </p>
        <p>
          Guests seamlessly receive automated event detail updates as venues and
          activities of interest are booked by the host and the host can
          manually update the group with any detail. Event attendance can be
          managed to multiple events within one login and guests can select and
          book their own guest room for the group stay, regardless of the
          property type: hotel, villa, private estate, yacht, etc… including
          selecting extended stays when available.
        </p>
        <p>
          At Kiss & Tell we uniquely ease the burden of all parties involved in
          weddings, gatherings and group trips by automating the entire group
          deal flow and allowing every group member to have a say and pay their
          way. Honoring the integrity of our user rules throughout the lifecycle
          of your planning and booking journey will ensure that you have a
          headache free group planning experience.
        </p>
        <p>
          Although the Kiss & Tell platform contains a highly curated
          marketplace of top venues, accommodations and service providers
          globally, verified by our extensive network, neither we nor any third
          parties provide warranty or guarantee as to the timeliness,
          performance, completeness or suitability of the vendor services
          offered on our platform. Vendors who do not fulfill their agreed upon
          booking contracts with Kiss & Tell customers will be penalized and
          potentially removed from the platform however we expressly exclude
          liability for any such vendor errors or disputes to the fullest extent
          permitted by law. We highly encourage the hiring of an event planner
          local to or highly knowledgeable about your destination, who can
          co-plan your event together on Kiss & Tell and help you navigate any
          unforeseen challenges. Allow us to recommend one of our wedding
          planner brand ambassadors who is vetted for your destination.
        </p>
        <p>
          All content and features on Kiss & Tell are produced and owned by or
          licensed to Life Unfiltered Media, Inc. for the express use of group
          communication, wedding, event, group travel and overnight stay
          planning and booking and logistics and the sharing of inspiration to
          bring people together and gather again. This includes but is not
          limited to unique group communication, booking and deal flow tools,
          content, branding and all intellectual property. Reproduction is
          prohibited other than in accordance with legal copyright notice.
        </p>

        <h4>Google agreement</h4>
        <p className="mb-0">
          Kiss & Tell uses Google APIs to connect users’ events with their
          personal calendars and to power map features on our search and wedding
          and event locations pages.
        </p>
        <p>
          Kiss & Tell's use and transfer to any other app of information
          received from Google APIs will adhere to{' '}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements.
        </p>

        <p>
          Unauthorized use of the Kiss & Tell platform may give rise to a claim
          for damages and / or be a criminal offense. <br />
          This Agreement shall be interpreted under the laws of the State of
          Delaware, without regard to or application of choice of law rules or
          principles.
        </p>
      </div>
    </Container>
  )
}
