import get from 'lodash/fp/get'
import set from 'lodash/fp/set'
import { useMutation } from '@apollo/react-hooks'

import { errorAlert } from '../../../../common'
import {
  Maybe,
  GetNegotiationMessagesQuery,
  NegotiationMessage,
  Role,
} from '../../../../types'
import {
  MARK_MESSAGES,
  GET_NEGOTIATION_MESSAGES,
  GET_NEGOTIATION_MESSAGES_VENDOR,
} from '../../../../graphql'

export const useMarkMessagesAsRead = (
  eventId: string,
  providerId: string,
  role: string
): [() => void, boolean] => {
  const { Vendor } = Role

  const [handleMarkMessages, { loading }] = useMutation(MARK_MESSAGES, {
    update(cache, { data: { markMessagesAsSeen } }) {
      if (markMessagesAsSeen.errors.length === 0) {
        const QUERY =
          role === Vendor
            ? GET_NEGOTIATION_MESSAGES_VENDOR
            : GET_NEGOTIATION_MESSAGES

        const cached: Maybe<GetNegotiationMessagesQuery> = cache.readQuery({
          query: QUERY,
          variables: { eventId, providerId, cursor: null },
        })

        if (cached) {
          const path = 'me.negotiation.messages.entries'
          const messages: NegotiationMessage[] = get(path, cached) || []
          const updatedMessages = messages.map(message => ({
            ...message,
            seen: true,
          }))
          cache.writeQuery({
            query: QUERY,
            data: set(path, updatedMessages, cached),
            variables: { id: eventId },
          })
        }
      }
    },
  })

  const updateComponent = (): void => {
    const variables = { eventId, providerId }
    handleMarkMessages({ variables }).then(({ data }) => {
      if (data.markMessagesAsSeen.errors.length > 0) {
        errorAlert(
          data.markMessagesAsSeen.errors,
          'There was a problem updating this item'
        )
      }
    })
  }

  return [updateComponent, loading]
}
