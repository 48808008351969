import React, { useState, useEffect } from 'react'
import get from 'lodash/fp/get'
import set from 'lodash/fp/set'
import trim from 'lodash/fp/trim'
import mapValues from 'lodash/fp/mapValues'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { PhoneInput } from 'react-international-phone'
import { MdMailOutline, MdPerson, MdCancel } from 'react-icons/md'

import Tiers from '../../../../components/Tiers'
import { simpleAlert } from '../../../../common'
import { gray, salmon } from '../../../../styles/themeColors'
import { useForm } from '../../../../common/alterForms'
import {
  UPDATE_GUEST,
  CREATE_GUEST,
  GET_GUESTLIST_WITH_BOOKINGS,
} from '../../../../graphql'
import { Drawer, KnTField, InputSwitch } from '../../../../components'
import { SiteLogo } from '../../../../components/customIcons'
import {
  IAddEditGuest,
  Tier,
  Error,
  Maybe,
  GetMyEventQuery,
} from '../../../../types'
import { useValidatePhone } from '../../../../components/SigninSignup/components'

export const AddEditGuest: React.FC<IAddEditGuest> = ({
  event,
  show,
  onClose,
  selectedGuest,
}) => {
  const { defaultKidsAllowed, defaultPlusOne } = event
  const QUERY = selectedGuest ? UPDATE_GUEST : CREATE_GUEST
  const [handleMutation, { loading }] = useMutation(QUERY, {
    update(cache, { data }) {
      if (!selectedGuest) {
        if (data.createGuest.errors.length === 0) {
          const cached: Maybe<GetMyEventQuery> = cache.readQuery({
            query: GET_GUESTLIST_WITH_BOOKINGS,
            variables: { id: event.id, tierIds: [] },
          })
          if (cached) {
            const guests = get('me.myEventInfo.guests', cached) || []
            cache.writeQuery({
              query: GET_GUESTLIST_WITH_BOOKINGS,
              data: set(
                'me.myEventInfo.guests',
                [...guests, data.createGuest.result],
                cached
              ),
              variables: { id: event.id, tierIds: [] },
            })
          }
        }
      }
    },
  })
  const [phone, setPhone] = useState<string>()
  const [phoneError, setPhoneError] = useState('')
  const { validatePhone } = useValidatePhone()
  const [firstGuestModal, openFirstGuestModal] = useState(false)
  const [tierIds, updateTierIds] = useState<string[]>([])
  const initModel = {
    canPost: false,
    firstName: '',
    lastName: '',
    email: '',
    kidsAllowed: defaultKidsAllowed,
    plusOne: defaultPlusOne,
  }
  const {
    model,
    handleFieldChange,
    handleCheckboxChange,
    reset,
    pushError,
    errors,
  } = useForm(initModel)

  useEffect(() => {
    reset({
      canPost: selectedGuest?.canPost as boolean,
      firstName: selectedGuest?.firstName ?? '',
      lastName: selectedGuest?.lastName ?? '',
      email: selectedGuest?.email ?? '',
      kidsAllowed: selectedGuest
        ? selectedGuest.kidsAllowed
        : defaultKidsAllowed,
      plusOne: selectedGuest?.plusOne ?? defaultPlusOne,
    })
    setPhone(selectedGuest?.phone ?? '')

    const assignedTiers = selectedGuest?.tiers.map((t: Tier) => t.id) ?? []
    updateTierIds(assignedTiers)
    // if I add reset to the dependencies there is an infinite loop even if I use useMemo or useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultKidsAllowed, defaultPlusOne, selectedGuest])

  const sendGuestData = (keepOpen: boolean) => {
    // ignore the country code we check only the phone number
    const phoneField = phone?.split(' ')[1] ? phone : undefined
    if (!validatePhone({ phone: phoneField, setPhoneError, required: false })) {
      return
    }

    if (loading) return
    const commonVariables = {
      input: mapValues(v => (typeof v === 'string' ? trim(v) : v), {
        ...model,
        phone: phoneField,
      }), // to remove empty spaces from all the string values
      tierIds,
    }

    const handleError = ({ key, message }: Error) => {
      if (key === 'email' && message.includes('taken')) {
        simpleAlert({
          html: 'this email is already being used by another guest',
          icon: 'warning',
        })
      }
      pushError(message, key)
    }

    if (selectedGuest) {
      const variables = { ...commonVariables, id: selectedGuest.id }
      handleMutation({ variables }).then(({ data }) => {
        if (data.updateGuest.errors.length > 0) {
          data.updateGuest.errors.forEach((error: Error) => handleError(error))
        } else {
          reset(initModel)
          updateTierIds([])
          onClose()
        }
      })
    } else {
      const variables = { ...commonVariables, eventId: event.id }
      handleMutation({ variables }).then(({ data }) => {
        if (data.createGuest.errors.length > 0) {
          data.createGuest.errors.forEach((error: Error) => handleError(error))
        } else if (!keepOpen || selectedGuest) {
          reset(initModel)
          updateTierIds([])
          onClose()
        } else if (keepOpen) {
          reset(initModel)
          updateTierIds([])

          // it is only focus, it is not necessary to do it in a more complicated way
          const element = document.querySelector(
            '.edit-add-guest #firstName'
          ) as HTMLInputElement
          element?.focus()
        }

        if (data.createGuest.errors.length <= 0 && event.guests.length <= 1) {
          // because by default the host is a guest now
          openFirstGuestModal(true)
        }
      })
    }
    setPhoneError('')
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    sendGuestData(true)
  }

  return (
    <>
      <Modal
        centered
        show={firstGuestModal}
        onHide={() => openFirstGuestModal(false)}
        className="modal-rounded-0 first-guest-modal"
      >
        <Modal.Body className="text-center pt-4">
          <SiteLogo width="57px" height="40px" color={salmon} />
          <h3 className="mt-2 text-primary">Great! You’ve added a guest!</h3>
          <p className="fz-14 py-2">
            Add more guests or return to your feed to start sharing details.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button className="btn-outline-salmon-simple">Go to Feed</Button>
          </Link>
          <Button variant="primary" onClick={() => openFirstGuestModal(false)}>
            Add more guests
          </Button>
        </Modal.Footer>
      </Modal>
      <Drawer show={show} closeDrawer={() => onClose()}>
        <div style={{ maxWidth: '550px' }} className="position-relative">
          <MdCancel
            color={salmon}
            size={35}
            onClick={() => onClose()}
            className="add-guest-close-icon"
          />
          <h3 className="fwb fz-24">
            {selectedGuest ? 'Edit' : 'Add a'} guest
          </h3>
          <p className="fwsb fz-13 m-0">
            {selectedGuest
              ? `Update your guest's details for more accurate attendance and communication. Don't forget to add their email to keep them in the loop!`
              : `Enter your guest's name below and they'll be added to your master list. We'll notify them automatically once you fill in their email.`}
          </p>
          <Form onSubmit={handleSubmit} className="pt-3 edit-add-guest">
            <KnTField
              materialStyle
              label="First Name"
              value={model}
              error={errors}
              setValue={handleFieldChange}
              name="firstName"
              icon={<MdPerson size={24} color={gray} />}
            />
            <KnTField
              materialStyle
              label="Last Name"
              value={model}
              error={errors}
              setValue={handleFieldChange}
              name="lastName"
              icon={<MdPerson size={24} color={gray} />}
            />
            <KnTField
              materialStyle
              label="Email"
              value={model}
              error={errors}
              setValue={handleFieldChange}
              name="email"
              type="email"
              required={false}
              icon={<MdMailOutline size={24} color={gray} />}
            />
            <PhoneInput
              placeholder="Your phone number"
              defaultCountry="us"
              value={phone}
              onChange={ev => setPhone(ev)}
              className="international-phone-container label mt-5"
              inputClassName="international-phone-input"
              countrySelectorStyleProps={{
                buttonClassName: 'international-phone-button',
              }}
            />
            {phoneError && (
              <span className="pl-4 fz-12 text-danger d-block">
                {phoneError}
              </span>
            )}
            <span className="fz-12 px-4 d-inline-block position-relative mt-2">
              If you enter your number you agree to receive SMS messages when
              posting or commenting on posts where you are included.
            </span>
            <h5 className="fwb my-4">Set permissions for this guest:</h5>
            <InputSwitch
              value={!!model.canPost}
              change={handleCheckboxChange}
              name="canPost"
              label="Can create posts:"
            />
            <InputSwitch
              value={!!model.kidsAllowed}
              change={handleCheckboxChange}
              name="kidsAllowed"
              label="Children permitted:"
            />
            <KnTField
              type="number"
              value={model}
              error={errors}
              valueType="INTEGER"
              setValue={handleFieldChange}
              name="plusOne"
              className="custom-input-style-simple"
              label="Number of +1s for this guest"
              tooltip="How many guests this guest can bring."
              attrs={{ min: 0 }}
            />
            <h5 className="fwb">Select sub-lists for this guest:</h5>
            <p className="fz-13 tc-lgray">
              Segment your group communication by priority, event,
              accommodations or special role. Customize and tag sub-lists to
              your guest starting here.
            </p>
            <Tiers.Container
              event={event}
              selection={tierIds}
              onSelect={updateTierIds}
            />
            <div className="mt-3 pb-2">
              <Button variant="primary" type="submit" className="mr-1">
                {!selectedGuest ? 'Add and continue' : 'Save'}
              </Button>
              {!selectedGuest && (
                <Button
                  variant="outline-primary"
                  className="mr-1"
                  onClick={() => sendGuestData(false)}
                >
                  Add and exit
                </Button>
              )}
              <Button variant="outline-primary" onClick={() => onClose()}>
                Cancel
              </Button>
            </div>
            <p className="fz-13 tc-lgray">
              Invite guests when the time is right via your guest list.
            </p>
          </Form>
        </div>
      </Drawer>
    </>
  )
}
