import React from 'react'
import Container from 'react-bootstrap/Container'
import { Route } from 'react-router-dom'

import { ChatWithVendor } from '../Event/ChatWithVendor'

export const MyOfferingsChats: React.FC = () => {
  return (
    <Container className="py-3">
      <Route
        exact
        path="/my-offerings-chats/chat/:eventId/:providerId/"
        component={ChatWithVendor}
      />
    </Container>
  )
}
