/* eslint-disable react/no-unused-prop-types */
// for some reason, eslint is not recognizing the props used in the components

import React, { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md'

import { VideoContainer } from '../styles/commonStyles'

interface IframeYoutubeProps {
  videoId?: string
  videoIds?: string[]
  height?: string
  width?: string
  type?: 'horizontal' | 'vertical'
  light?: boolean | string
  playing?: boolean
  controls?: boolean
  mobileFrame?: boolean
  loop?: boolean
  volume?: number
  customVolumeControl?: boolean
  muted?: boolean
}

const Player: React.FC<IframeYoutubeProps> = ({
  videoId,
  videoIds = [],
  height,
  width = '100%',
  type = 'horizontal',
  light = false,
  playing = false,
  controls = true,
  loop = true,
  volume = 1,
}) => (
  <div className={`youtube-video-container-${type}`}>
    <ReactPlayer
      className="knt-youtube-video"
      controls={controls}
      light={light}
      loop={loop}
      playing={playing}
      height={height}
      width={width}
      id={videoId ?? `videoplayer_${videoIds.length}`}
      volume={volume}
      key={`videoplayer_${videoId ?? videoIds.length}`}
      url={
        videoId
          ? `https://www.youtube.com/watch?v=${videoId}`
          : videoIds.map(el => `https://www.youtube.com/watch?v=${el}`)
      }
    />
  </div>
)

export const IframeYoutube: React.FC<IframeYoutubeProps> = ({
  videoId,
  videoIds,
  height,
  width = '100%',
  type = 'horizontal',
  light = false,
  playing = false,
  controls = true,
  mobileFrame = false,
  customVolumeControl = false,
  muted = false,
}) => {
  const [mute, setMute] = useState(() => muted)

  if (mobileFrame) {
    return (
      <VideoContainer>
        <Player
          controls={controls}
          light={light}
          loop
          type={type}
          playing={playing}
          height={height}
          width={width}
          videoId={videoId}
          videoIds={videoIds}
          volume={mute ? 0 : 1}
        />
        {customVolumeControl && !mute && (
          <MdVolumeUp
            size={26}
            color="white"
            className="custom-volume-control cursor shadow-sm"
            onClick={() => setMute(true)}
          />
        )}
        {customVolumeControl && mute && (
          <MdVolumeOff
            size={26}
            color="white"
            className="custom-volume-control cursor shadow-sm"
            onClick={() => setMute(false)}
          />
        )}
      </VideoContainer>
    )
  }
  return (
    <Player
      controls={controls}
      light={light}
      loop
      type={type}
      playing={playing}
      height={height}
      width={width}
      videoId={videoId}
      videoIds={videoIds}
      volume={mute ? 0 : 1}
    />
  )
}
