import styled from 'styled-components'

import { grayBackground, salmon, gray } from '../../styles/themeColors'

export const SignInUpContainer = styled.div`
  background-color: white;
  display: block;
  & .closeModal {
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    top: -12px;
    @media (max-width: 600px) {
      right: -5px;
      top: -5px;
    }
  }
  & .nav-tabs {
    border: none;
    flex-direction: row;
    justify-content: flex-start;
    & a {
      background-color: ${grayBackground};
      border: none;
      color: ${gray};
      display: inline-block;
      font-size: 17px;
      font-weight: 700;
      margin: 0;
      outline: none;
      padding-bottom: 10px;
      text-align: center;
      width: 50%;
      &.active {
        background-color: white;
        color: ${salmon};
      }
    }
  }
  & .card-header-tabs {
    margin-left: 0;
    margin-right: 0;
  }
  & .tab-content {
    padding-top: 20px;
    & form {
      margin: 0 auto;
      max-width: 360px;
      width: 96%;
    }
  }
  &.validations {
    & .nav-tabs {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`
