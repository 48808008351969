import React, { useEffect } from 'react'
import get from 'lodash/fp/get'

import { FormatNumber } from '../../../components'
import { setTotalPrice } from '../../../features/bookingSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { Booking, IcurrentAddons } from '../../../types'

import {
  extraGuests,
  extraAddons,
  useCalculateBookingTotals,
  getPercentageBreakdown,
} from './bookingFunctions'

export const PackageBookingBreakdown: React.FC<{
  currentAddons: IcurrentAddons
  booking: Booking
}> = ({ currentAddons, booking }) => {
  const dispatch = useAppDispatch()
  const {
    paidGuests = [],
    customBookingDuration,
    availableAddons: { extraGuestAddon },
  } = useAppSelector(state => state.booking)

  const { basePrice, total, tax, offeringPrice } = useCalculateBookingTotals(
    booking,
    customBookingDuration || 1,
    currentAddons
  )

  const { noTaxables, taxables } = currentAddons

  useEffect(() => {
    dispatch(setTotalPrice(total))
  }, [dispatch, total])

  const basic = get('eventComponent.offering.package.basic', booking) ?? 1
  const taxPercentage = getPercentageBreakdown(
    get('eventComponent.offering.provider.venueTaxRate', booking)
  )
  if (total <= 0) return null
  return (
    <div className="px-2 py-3">
      <table className="w-100" data-cy="breakdown">
        <tbody>
          <tr className="fz-15">
            <td data-cy="book-duration">Total:</td>
            <td className="pl-3" data-cy="total-room">
              <FormatNumber n={total} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <hr className="my-2" />
            </td>
          </tr>
          <tr className="fz-13">
            <td>
              Base Price ({basic} guest{basic > 1 ? 's' : ''}):
            </td>
            <td className="pl-3">
              <FormatNumber n={offeringPrice} />
            </td>
          </tr>

          {paidGuests.map(el =>
            extraGuests({
              guest: el,
              addon: extraGuestAddon,
              hideFrequency: true,
            })
          )}

          <tr>
            <td className="aux py-1" colSpan={2} />
          </tr>

          {taxables.map(el => extraAddons(el, basePrice))}
          <tr className="fz-13">
            <td>{taxPercentage}</td>
            <td className="pl-3">
              <FormatNumber n={tax} />
            </td>
          </tr>
          <tr>
            <td className="aux py-1" colSpan={2} />
          </tr>
          {noTaxables.map(el => extraAddons(el, basePrice))}
        </tbody>
      </table>
    </div>
  )
}
