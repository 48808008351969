import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Table from 'react-bootstrap/Table'

import { EventComponent, EventComponentStatus } from '../../../../types'

import { GuestPays } from './GuestPays'
import { CommonRoomData } from './CommonRoomData'
import { EventBuilderCard } from './style'
import {
  useCancelEventComponent,
  parseGuestPays,
} from './eventBuilderFunctions'

export const NegotiationRoomCard: React.FC<{
  roomCardData: EventComponent
  eventId: string
}> = ({ roomCardData, eventId }) => {
  const [guestPays, setGuestPays] = useState('')
  const [handleCancelEventComponent, loadingCancel] = useCancelEventComponent(
    roomCardData.id,
    eventId
  )
  const { Available } = EventComponentStatus

  const cancelBooking = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    if (loadingCancel) return
    handleCancelEventComponent()
  }

  useEffect(() => {
    if (!guestPays) {
      setGuestPays(parseGuestPays(roomCardData.guestPays))
    }
  }, [guestPays, roomCardData.guestPays])

  return (
    <EventBuilderCard>
      <CommonRoomData roomCardData={roomCardData}>
        <span className="fwsb">Your reservation details:</span>
        <Table className="table-borderless">
          <thead>
            <tr>
              <th>Date</th>
              <th>Selected rooms</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{moment(roomCardData.date).format('DD MMMM YYYY')}</td>
              <td>{roomCardData.capacity}</td>
              <td>{roomCardData.duration}</td>
            </tr>
          </tbody>
        </Table>

        {/** only the vendor should be able to remove a component after the first payment */}
        {roomCardData.status !== Available && (
          <a className="cancel-booking" href="!#" onClick={cancelBooking}>
            cancel booking
          </a>
        )}
        <GuestPays className="w-100 pb-3" evComponent={roomCardData} />
      </CommonRoomData>
    </EventBuilderCard>
  )
}
