import React, { useEffect, useState } from 'react'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom'

import { getImageUrl } from '../../common'
import { Image as ImageType } from '../../types'

import { makeGroups } from './components/BlogFunctions'

const Column: React.FC<{ group: ImageType[]; id: string }> = ({
  group,
  id,
}) => (
  <Col className="px-2">
    {group.map((el: ImageType) => {
      const path = getImageUrl(el, '400xauto')
      const altText = get('metadata.alt', el)
      const caption = get('metadata.caption', el)

      const moveToTop = () => {
        window.scrollTo(0, 0)
      }

      return (
        <Link
          key={el.id}
          onClick={moveToTop}
          className="post-photo d-block mb-3"
          to={`/blog/photo/${id}/${el.id}`}
        >
          <Image src={path} alt={altText || caption} fluid />
        </Link>
      )
    })}
  </Col>
)

export const BlogPhotoGrid: React.FC<{ list: ImageType[]; id: string }> = ({
  list,
  id,
}) => {
  const [groups, setGroups] = useState<ImageType[][]>([])

  useEffect(() => {
    setGroups(makeGroups(list))
  }, [list])

  if (isEmpty(groups)) {
    return <></>
  }

  return (
    <Row className="px-5">
      <Column group={groups[0]} id={id} />
      <Column group={groups[1]} id={id} />
      <Column group={groups[2]} id={id} />
    </Row>
  )
}
