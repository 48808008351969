import React, { FormEvent, KeyboardEvent, useState } from 'react'
import trim from 'lodash/fp/trim'
import Form from 'react-bootstrap/Form'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { useHistory } from 'react-router-dom'

import { useForm } from '../../common/alterForms'
import {
  EventInput,
  Event,
  FormControlElement,
  IGenericErrorObject,
} from '../../types'
import { Drawer, InputSwitch, Textarea, KnTField } from '../../components'
import { useCreateEvent } from '../Event/eventFunctions'
import { myHub } from '../../common/constants'
import { useIsAgent } from '../../common'

interface ICreateEventPops {
  show: boolean
  onClose: (event?: Event) => void
  redirectToHome?: boolean
}

const CreateEvent: React.FC<ICreateEventPops> = ({
  show,
  onClose,
  redirectToHome = false,
}) => {
  const isAgent = useIsAgent()
  const history = useHistory()
  const initModel: EventInput = {
    name: '',
    defaultPlusOne: 1,
    defaultKidsAllowed: true,
    tiers: [],
    guestCanPost: true,
    description: '',
    mainImage: '',
    emailCustomText: null,
    isPrivate: true,
    customUrl: null,
  }
  const {
    model,
    addAt,
    removeAt,
    errors,
    handleFieldChange,
    handleCheckboxChange,
    pushError,
    reset,
  } = useForm(initModel)

  const callback = (event?: Event) => {
    onClose(event)
    reset(initModel)

    if (redirectToHome) {
      history.push(myHub)
    }
  }
  const [createEvent, loadingCreateEvent] = useCreateEvent(pushError, callback)

  const [newTierName, setNewTierName] = useState('')

  if (!isAgent) return null // only agents can create events

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    createEvent(model)
  }
  const closing = () => {
    onClose()
    reset(initModel)
  }

  const addTier = () => {
    const name = trim(newTierName)
    const tiers = model.tiers || []
    if (name !== '' && !tiers.some(tier => tier.name === name)) {
      addAt({ name }, `tiers[${tiers.length}]`)
      setNewTierName('')
    }
  }

  const addTierOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      addTier()
    }
  }
  return (
    <Drawer show={show} closeDrawer={closing}>
      <h3 className="fwb fz-24">Create an Event</h3>
      <p className="fwsb mb-4 pb-3">
        Once you create an event, you will be able to invite guests and
        communicate on your own event feed.
      </p>

      <Form onSubmit={handleSubmit}>
        <KnTField
          label="Event Name"
          value={model}
          error={errors}
          setValue={handleFieldChange}
          name="name"
          placeholder="Stephanie and Bradley’s Wedding, Smith Family Reunion"
        />
        <Textarea
          rows={4}
          error={errors as IGenericErrorObject}
          value={model.description as string}
          change={handleFieldChange}
          name="description"
          label="Personalize a welcome message to your guests"
          className="custom-input-style-textarea"
          placeholder="This is the initial message sent to all guests and can change as your event planning and details progress. Feel free to address your guests with a personal message here."
        />
        <KnTField
          type="number"
          value={model}
          error={errors}
          setValue={handleFieldChange}
          valueType="INTEGER"
          name="defaultPlusOne"
          label="Number of +1s per guest by default"
          tooltip="How many guests each guest can bring. Customizable per guest later."
          className="custom-input-style-simple"
        />
        <InputSwitch
          value={!!model.defaultKidsAllowed}
          change={handleCheckboxChange}
          name="defaultKidsAllowed"
          label="Kids allowed by default?"
        />

        <h5 className="mt-4 fwb fz-18">Select sub-lists for this event:</h5>
        <p className="tc-lgray fz-13">
          Sub-lists are useful for organazing guests into groups to fine tune
          access to different activities within your event. You can add more or
          remove sub-lists at any time later.
        </p>
        <InputGroup>
          <Form.Control
            placeholder="Bridal party, Rehearsal dinner, Villa guests"
            aria-label="Bridal party, Rehearsal dinner, Villa guests"
            value={newTierName}
            onChange={(e: FormEvent<FormControlElement>) =>
              setNewTierName(e.currentTarget.value || '')
            }
            onKeyDown={addTierOnEnter}
          />
          <InputGroup.Append>
            <Button variant="outline-secondary" onClick={addTier}>
              Add
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <div className="mt-2">
          {model?.tiers?.map((tier, i) => (
            <Badge
              pill
              variant="primary"
              key={tier.name}
              className="mr-1 px-2 py-1 fz-12"
            >
              {tier.name}
              <span
                className="ml-1 text-light cursor"
                onClick={() => removeAt('tiers', i)}
              >
                x
              </span>
            </Badge>
          ))}
        </div>

        <div className="mt-3">
          <Button variant="primary" type="submit" disabled={loadingCreateEvent}>
            Create Event
          </Button>
          <Button
            variant="outline-primary"
            className="ml-1"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default CreateEvent
