import React from 'react'
import isEqual from 'lodash/isEqual'
import Form from 'react-bootstrap/Form'

import { IGenericErrorObject, changeValue, Maybe } from '../../types'
import { FValueType } from '../../common/alterForms'

interface IcommonProps {
  change?: (path: string, value: changeValue, type?: FValueType) => void
  value?: string | Maybe<string>
  error: IGenericErrorObject
  name: string
  label?: string
  desc?: string
  className?: string
  required?: boolean
  placeholder?: string
}
interface TextareaProps extends IcommonProps {
  refInput?: React.RefObject<HTMLTextAreaElement>
  border?: string
  rows?: number
  inputClassName?: string
}
interface InputcheckProps {
  change: (
    path: string
  ) => ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => void
  value?: boolean
  name: string
  id?: string
  label?: string
}

const MemoTextarea: React.FC<TextareaProps> = ({
  change,
  value,
  error,
  name,
  label,
  desc,
  placeholder,
  refInput,
  border = '',
  required = true,
  className = '',
  rows = 3,
  inputClassName = '',
}) => (
  <Form.Group controlId={`CI-${name}`} className={className}>
    {label && (
      <Form.Label>
        {label}
        {required && <span className="required">*</span>}
      </Form.Label>
    )}
    <Form.Control
      required={required}
      as="textarea"
      rows={rows}
      ref={refInput}
      className={`${inputClassName}`}
      name={name}
      placeholder={placeholder}
      onChange={({ currentTarget }) =>
        change && change(name, currentTarget.value)
      }
      value={value || undefined}
      isInvalid={!!error[name]}
      style={{ border }}
    />
    {desc && <Form.Text className="text-muted">{desc}</Form.Text>}
    <Form.Control.Feedback className="px-3" type="invalid">
      {error[name]}
    </Form.Control.Feedback>
  </Form.Group>
)

export const Textarea = React.memo(MemoTextarea, (prevProps, props) => {
  return (
    prevProps.value === props.value && isEqual(prevProps.error, props.error)
  )
})

const MemoInputSwitch: React.FC<InputcheckProps> = ({
  change,
  value,
  name,
  label,
  id,
}) => (
  <Form.Group controlId={`CI-${id || name}`}>
    <label className="fwsb d-inline-block mr-2" htmlFor="kidsAllowed">
      {label}
    </label>
    <Form.Check
      className="d-inline-block custom-switch-knt"
      type="switch"
      id={id || name}
      name={name}
      checked={value}
      label=" "
      onChange={change(name)}
    />
  </Form.Group>
)

export const InputSwitch = React.memo(MemoInputSwitch, (prevProps, props) => {
  return prevProps.value === props.value
})
