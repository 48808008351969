import React, { useState, useEffect } from 'react'
import toInteger from 'lodash/fp/toInteger'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import FormLabel from 'react-bootstrap/FormLabel'
import FormGroup from 'react-bootstrap/FormGroup'
import { MdSync } from 'react-icons/md'

import { IGuestPays, EventComponentStatus } from '../../../../types'
import { SimpleTooltip } from '../../../../components'
import { salmon } from '../../../../styles/themeColors'
import { offeringTypes } from '../../../../common/constants'

import {
  useUpdateEventComponent,
  parseGuestPays,
} from './eventBuilderFunctions'

export const GuestPays: React.FC<IGuestPays> = ({ className, evComponent }) => {
  const [guestPays, setGuestPays] = useState('')
  const [handleUpdateEventComponent, loading] = useUpdateEventComponent()

  useEffect(() => {
    if (!guestPays) {
      setGuestPays(parseGuestPays(evComponent.guestPays))
    }
  }, [guestPays, evComponent.guestPays])

  const updateEventComponent = () => {
    const { id, date, capacity, duration } = evComponent

    const variables = {
      id,
      input: {
        capacity,
        guestPays: toInteger(guestPays) / 100, // because it should be a number between 0 and 1
        duration,
        date,
      },
    }
    handleUpdateEventComponent(variables)
  }

  const { status, offering } = evComponent
  const { Negotiating } = EventComponentStatus
  const { packageOffering, roomOffering } = offeringTypes

  return (
    <div className={className}>
      <FormGroup controlId="guestPaysRange" className="m-0">
        <FormLabel className="m-0 position-relative pr-3">
          {offering.__typename === packageOffering && 'Guests Pay: '}
          {offering.__typename === roomOffering && 'Guest Pays: '}
          {guestPays}%
          <SimpleTooltip
            id="GuestPaysRange-tooltip"
            className="top-right"
            text="This is the percentage that the guest has to pay, you pay the rest"
          />
        </FormLabel>
        {status === Negotiating ? (
          <>
            <Button
              className="float-right btn-sm btn-outline-salmon-simple"
              onClick={updateEventComponent}
              disabled={loading}
            >
              <MdSync size={20} className="svg-top1" color={salmon} /> Update
            </Button>
            <FormControl
              type="range"
              value={guestPays}
              onChange={e => setGuestPays(e.target.value)}
            />
          </>
        ) : (
          <FormControl type="range" value={guestPays} readOnly />
        )}
      </FormGroup>
    </div>
  )
}
