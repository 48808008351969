import React from 'react'
import { useLocation } from 'react-router-dom'

import { FormatNumber } from '../../../components'

import { EventPricesContainer } from './style'

interface IEventPrices {
  packagePrice: number
  guests: number
  pricePerPerson: number
  className?: string
}

export const EventPrices: React.FC<IEventPrices> = ({
  packagePrice,
  guests,
  pricePerPerson,
  className,
}) => {
  const { pathname } = useLocation()

  return (
    <EventPricesContainer className={className || ''}>
      <div>
        <p className="eventcard__avg w-100">
          <span className="mr-1 mb-1">
            <FormatNumber n={packagePrice} />
          </span>
          {pathname.includes('locations') ? 'Base' : 'Total'}
        </p>
        <p className="tc-gray eventcard__guest">
          <span className="mr-1 fwb fz-20">{guests}</span>
          Guests
        </p>
      </div>
      <p className="tc-gray pt-1">
        <span className="mr-1 fwb fz-20">
          <FormatNumber n={pricePerPerson} />
        </span>
        / Person
      </p>
    </EventPricesContainer>
  )
}
