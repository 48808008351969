import styled from 'styled-components'

import { gray, darkGray, salmon } from '../../../../styles/themeColors'

export const ProviderCard = styled.div`
  background-size: cover;
  border-radius: 5px;
  color: white;
  margin-top: 25px;
  padding: 0 0 20px 0;
  position: relative;
  text-align: center;
  &::before {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
  }
  & h2 {
    font-size: 18px;
    margin: -5px 0 5px 0;
  }
  & h3 {
    font-size: 14px;
    margin: 0;
  }
  & h2,
  & h3 {
    font-weight: 600;
  }
  & a {
    font-weight: 600;
    font-size: 14px;
  }
  & .default-user-profile,
  & .user-profile {
    margin-bottom: -10px;
    top: -30px;
  }
  & h2,
  & h3,
  & .default-user-profile,
  & .user-profile {
    position: relative;
  }
`

export const QuoteListContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  & table {
    & thead {
      text-align: right;
      & td {
        border-bottom: 1px solid #dee2e6;
        border-top: none;
        padding: 0.2rem !important;
      }
    }
    & tbody * {
      border: none;
      & td {
        font-size: 13px;
        padding: 0.5rem;
        &:first-child {
          font-weight: 600;
        }
      }
    }
    & tfoot {
      font-weight: 600;
      & td {
        border-top: 2px solid ${darkGray};
        padding: 0.5rem !important;
        &:last-child {
          font-weight: 700;
        }
      }
    }
  }
`

export const MessagesContainer = styled.div`
  height: 500px;
  margin-bottom: 20px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 10px;
  & .msg {
    align-self: flex-start;
    display: flex;
    padding-bottom: 20px;
    position: relative;
    width: 95%;
    & .body {
      order: 2;
      padding: 25px 10px 0 10px;
      width: 100%;
    }
    & .datetime-msg {
      color: ${gray};
      font-size: 11px;
      left: 70px;
      position: absolute;
      top: 0;
      width: fit-content;
      & span {
        font-size: 15px;
        font-weight: 600;
        color: ${darkGray};
      }
    }
    & .user-photo {
      order: 1;
      padding: 0 5px 0 0;
    }
  }
`

export const MessagesInput = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  & textarea {
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    margin-left: auto;
    padding: 10px 35px 10px 10px;
    width: 90%;
    &:focus {
      outline: none;
    }
  }
  & .send-msg {
    align-items: center;
    background-color: ${salmon};
    border-radius: 50%;
    bottom: 8px;
    display: flex;
    height: 30px;
    justify-content: center;
    position: absolute;
    right: 10px;
    width: 30px;
  }
`

export const EventBuilderBanner = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 20px auto 0 auto;
  max-width: 1270px;
  padding: 30px 30px;
  position: relative;
  width: 98%;
  & * {
    position: relative;
    z-index: 1;
  }
  & .booking-banner-bg {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  & h2 {
    font-size: 24px;
    font-weight: 700;
  }
  & p {
    font-size: 16px;
    max-width: 555px;
  }
  & .close-banner {
    position: absolute;
    right: 0;
    top: 0;
  }
  @media (max-width: 1100px) {
    border-radius: 0;
    box-shadow: unset !important;
    margin: 0 auto;
    padding: 0 0 20px 0;
    text-align: center;
    width: 100%;
    & p,
    & h2 {
      margin: 10px auto;
      width: 95%;
    }
    & .booking-banner-bg {
      height: 240px;
      margin-bottom: 0;
      object-fit: cover;
      object-position: right center;
      position: relative;
      width: 100%;
    }
    & h2 {
      font-size: 20px;
    }
    & p {
      font-size: 15px;
    }
    &.EventBuilderBanner {
      & .booking-banner-bg {
        object-position: center center;
      }
    }
  }
`

export const ChatContainer = styled.div`
  position: sticky;
  top: 70px;
`

export const CustomizeAddonTable = styled.table`
  margin: 0 auto;
  width: 96%;
  * {
    outline: none;
  }
  tr td:not(:first-child) {
    padding: 8px 5px 0 5px;
  }
  & select,
  & input {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    height: 28px;
  }
  & button {
    & svg {
      margin: 0 2px 0 0;
    }
  }
  & select {
    width: 190px;
  }
  & input {
    width: 100px;
  }
`
