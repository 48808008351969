import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'

import { ValidationModal } from '../../../components'
import { offeringTypes } from '../../../common/constants'
import { EventIcon, RoomIcon } from '../../../components/customIcons'

export const ValidationCTA: React.FC<{
  type?: string
  className?: string
  childrenBelow?: React.ReactNode
}> = ({ type, childrenBelow = null, className = 'px-3 py-0 mt-1' }) => {
  const [showModal, setShowModal] = useState(false)

  const { roomOffering } = offeringTypes
  const title = 'Join the event'
  const text = `You must join the event to be able to ${
    type === roomOffering ? 'book' : 'RSVP'
  }`
  return (
    <>
      <ValidationModal
        title={title}
        text={text}
        show={showModal}
        onHide={() => setShowModal(false)}
      />

      <Button className={className} onClick={() => setShowModal(true)}>
        {type === roomOffering ? (
          <>
            <RoomIcon color="white" width="25px" height="25px" />
            Book
          </>
        ) : (
          <>
            <EventIcon color="white" width="25px" height="25px" />
            RSVP
          </>
        )}
        {childrenBelow}
      </Button>
    </>
  )
}
