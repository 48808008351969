import React from 'react'

import { salmon } from '../styles/themeColors'

export const Wave: React.FC<{
  color?: string
  style?: React.CSSProperties
}> = ({ color = salmon, style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" style={style} viewBox="0 0 1440 106">
    <g>
      <title>Wave</title>
      <path
        stroke="null"
        d="m0,70.91188l60,-14.75855c60,-14.53745 180,-44.3862 300,-53.06444c120,-9.0099 240,3.1507 360,17.68815c120,14.75855 240,32.4467 360,38.36117c120,5.69337 240,0.16583 300,-2.98487l60,-2.9296l0,53.06444l-60,0c-60,0 -180,0 -300,0c-120,0 -240,0 -360,0c-120,0 -240,0 -360,0c-120,0 -240,0 -300,0l-60,0l0,-35.3763z"
        fill={color}
      />
    </g>
  </svg>
)
