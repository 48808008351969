import React, { useContext } from 'react'
import get from 'lodash/fp/get'
import Dropdown from 'react-bootstrap/Dropdown'
import { useHistory, Link } from 'react-router-dom'
import { MdAccountCircle } from 'react-icons/md'

import AppContext from '../../context'
import { salmon } from '../../styles/themeColors'

import { UserOptionsNav } from './style'

export const UserOptions: React.FC = () => {
  const history = useHistory()
  const { state, auth } = useContext(AppContext)
  const firstName = get('currentUser.firstName', state)
  const lastName = get('currentUser.lastName', state)
  const avatarUrl = get('currentUser.avatarUrl', state)

  const handleLogout = () => {
    auth.signOut()
    history.push('/')
  }

  return (
    <UserOptionsNav>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-manage-profile"
          variant="outline-primary"
          className="line-1"
        >
          {avatarUrl ? (
            <img
              src={avatarUrl}
              alt={`${firstName} ${lastName}`}
              style={{
                borderRadius: '50%',
                height: '22px',
                marginRight: '0.25rem',
                width: '22px',
              }}
            />
          ) : (
            <MdAccountCircle size={22} color={salmon} className="mr-1" />
          )}
          {firstName} {lastName}
        </Dropdown.Toggle>

        <Dropdown.Menu id="dropdown-manage-profile-body">
          <Dropdown.Item as={Link} to="/manage-profile">
            Manage profile
          </Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </UserOptionsNav>
  )
}
