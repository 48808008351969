import React from 'react'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import head from 'lodash/fp/head'
import minBy from 'lodash/fp/minBy'
import orderBy from 'lodash/fp/orderBy'
import multiply from 'lodash/fp/multiply'
import toNumber from 'lodash/fp/toNumber'
import Container from 'react-bootstrap/Container'
import { IoMdCalendar } from 'react-icons/io'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MdCalendarToday, MdOutlineAirplanemodeActive } from 'react-icons/md'

import {
  Provider,
  GuestBasic,
  IEventSegment,
  PackageAddons,
  EventComponent,
  IEventSegments,
  Booking,
} from '../../../types'
import {
  getCheckIn,
  getImageUrl,
  getCheckOut,
  orderPackageAddons,
} from '../../../common'
import { RSVPctaLink, RSVPcta } from '../../SocialPanel/components'
import { FormatNumber, PackageCustomizations } from '../../../components'
import { offeringTypes, guestStatuses } from '../../../common/constants'

import { PublicCTA } from './PublicCTA'
import { SegmentCardContainer, SegmentTitle } from './style'

import { ValidationCTA, groupComponentsByDate, sortPackagesFirst } from '.'

type ECorList = (EventComponent | { date: string; rooms: EventComponent[] })[]

const getCheapest = (components: EventComponent[]) =>
  flow(
    map((el: EventComponent) => ({
      component: el,
      price: multiply(toNumber(el.offering.price), toNumber(el.guestPays)),
    })),
    minBy('price'),
    get('component')
  )(components) as EventComponent

const getLongestStay = (components: EventComponent[]) =>
  flow(orderBy(['duration'], ['desc']), head)(components) as EventComponent

const CTA: React.FC<{
  guest?: GuestBasic
  publicLink?: boolean
  roomCard?: boolean
  eventComponent: EventComponent
  myBookings?: Booking[]
}> = ({ guest, publicLink, eventComponent, roomCard, myBookings = [] }) => {
  const rate = roomCard && (
    <span className="fz-18 d-block">
      <FormatNumber
        decimalScale={0}
        n={multiply(
          toNumber(eventComponent.offering.price),
          toNumber(eventComponent.guestPays)
        )}
      />{' '}
      / night
    </span>
  )
  const ctaClass = 'mx-auto d-block px-5 py-1 mt-4 fz-18 rounded-pill'

  if (publicLink) {
    return (
      <PublicCTA
        eventComponent={eventComponent}
        className={ctaClass}
        childrenBelow={rate}
      />
    )
  }

  const { accepted } = guestStatuses
  if (guest?.status !== accepted) {
    return (
      <ValidationCTA
        type={eventComponent.offering.__typename}
        className={ctaClass}
        childrenBelow={rate}
      />
    )
  }

  const { id, customTexts } = eventComponent
  const booking = find({ eventComponent: { id } }, myBookings)
  const customCTAtext =
    customTexts?.status?.[booking?.status as string] || customTexts?.timeline
  if (booking) {
    return (
      <RSVPctaLink
        booking={booking}
        className={ctaClass}
        RsvpCustomText={customCTAtext}
        childrenBelow={rate}
      />
    )
  }
  return (
    <RSVPcta
      className={ctaClass}
      RsvpCustomText={customCTAtext}
      eventComponent={eventComponent}
      childrenBelow={rate}
    />
  )
}

const SegmentsSlider: React.FC<{
  segments: React.ReactElement[]
  className?: string
}> = React.memo(({ segments = [], className = '' }) => {
  if (segments.length <= 0) return null
  return (
    <Swiper
      navigation
      spaceBetween={10}
      style={{ width: '100%' }}
      className={`swiper-rsvp-slider ${className}`}
    >
      {segments.map((el, idx) => (
        <SwiperSlide key={`slide-rooms-${idx.toString()}`}>{el}</SwiperSlide>
      ))}
    </Swiper>
  )
})

const SegmentCard: React.FC<{
  bg: string
  title: string
  cover?: boolean
  guest?: GuestBasic
  provider: Provider
  publicLink?: boolean
  component: EventComponent
  customizations?: PackageAddons[]
  startPrice?: React.ReactNode
  myBookings?: Booking[]
}> = ({
  bg,
  guest,
  cover,
  title,
  provider,
  component,
  publicLink,
  customizations,
  myBookings,
  startPrice,
}) => {
  const { roomOffering } = offeringTypes
  const { date, offering, duration } = component
  const isRoom = offering.__typename === roomOffering

  const renderDate = isRoom ? (
    <>
      <div className="card-date">
        <span className="m-0 card-date-icon">
          <IoMdCalendar size={24} className="mb-1" color="white" /> Check In
        </span>
        {getCheckIn(date)}
      </div>
      <div className="card-date">
        <span className="m-0 card-date-icon">
          <IoMdCalendar size={24} className="mb-1" color="white" /> Check Out
        </span>
        {getCheckOut(date, duration)}
      </div>
    </>
  ) : (
    <div className="card-date">
      <span className="m-0 card-date-icon">
        <MdCalendarToday size={24} color="white" className="mr-2 mb-1" /> Date
      </span>
      {getCheckIn(date, 'ddd, MMM DD, YYYY')}
    </div>
  )

  const classN = isRoom ? 'room-segment' : 'package-segment'
  return (
    <SegmentCardContainer
      className={`mw-900 rounded-10 ${classN}`}
      bgImage={bg}
    >
      <div className="card-title text-center">
        <h3 className="fwsb text-shadow px-2">{title}</h3>
        <div className="fwsb fz-16-20 text-shadow px-2">{provider.name}</div>
        {isRoom && <div className="fz-14 text-shadow">{provider.location}</div>}
      </div>

      {cover ? (
        <h3 className="fwsb text-shadow text-center p-0">
          Start at {startPrice} / night
        </h3>
      ) : (
        <CTA
          guest={guest}
          publicLink={publicLink}
          eventComponent={component}
          roomCard={isRoom}
          myBookings={myBookings}
        />
      )}

      <div className="content">
        {renderDate}

        {isRoom && (
          <div className="card-airport">
            <MdOutlineAirplanemodeActive
              size={36}
              color="white"
              className="mb-1"
            />
            <span className="d-inline-block text-left pl-2">
              {provider.timeToAirport} min
              <br />
              {provider.airportCode}
            </span>
          </div>
        )}
        {!isRoom && customizations && (
          <PackageCustomizations
            customizations={customizations}
            className="card-customizations"
            compact
          />
        )}
        {!isRoom && (
          <div className="card-location">
            {provider.location?.split(',').map((el, idx) => (
              <span key={`location-${idx.toString()}`}>{el?.trim()}</span>
            ))}
          </div>
        )}
      </div>
    </SegmentCardContainer>
  )
}

export const Segment: React.FC<IEventSegment> = ({
  components,
  guest,
  publicLink,
  myBookings,
}) => {
  if (components.length <= 0) return null

  const { packageOffering } = offeringTypes
  const { provider, __typename } = components[0].offering
  const isPackage = __typename === packageOffering

  if (isPackage && components.length === 1) {
    const component = components[0]
    const { offering, alias, packageCustomization } = component
    const pc = orderPackageAddons(packageCustomization)
    const firstOAImage = get('[0].offeringAddOn.images[0]', pc)
    const imageBG = getImageUrl(firstOAImage || offering.mainImage, '900x450')
    return (
      <SegmentCard
        bg={imageBG}
        guest={guest}
        provider={provider}
        customizations={pc}
        component={component}
        myBookings={myBookings}
        title={alias || offering.name}
        publicLink={publicLink}
      />
    )
  }

  const mainImgProvider = getImageUrl(provider.mainImage, '900x450')
  const longestStay = getLongestStay(components)
  const cheapest = getCheapest(components)
  const startingPrice = (
    <FormatNumber
      decimalScale={0}
      n={multiply(
        toNumber(cheapest.offering.price),
        toNumber(cheapest.guestPays)
      )}
    />
  )
  const cover = (
    <SegmentCard
      cover
      bg={mainImgProvider}
      provider={provider}
      title="Guest Rooms"
      component={longestStay}
      startPrice={startingPrice}
    />
  )

  const list = components.map(el => {
    const image = getImageUrl(el.offering.mainImage, '900x450')
    return (
      <SegmentCard
        bg={image}
        key={el.id}
        guest={guest}
        component={el}
        provider={provider}
        myBookings={myBookings}
        publicLink={publicLink}
        title={el.offering.name}
      />
    )
  })
  const slides = [cover, ...list]
  return <SegmentsSlider segments={slides} className="mw-900 mx-auto" />
}

export const EventSegments: React.FC<IEventSegments> = ({
  guest,
  myBookings,
  eventComponents,
  eventTemplate = 'template_1',
}) => {
  if (eventComponents.length <= 0) {
    return (
      <Container className="text-center mt-5 py-5">
        <h2>
          No rooms or events available at the moment. Please check back later!
        </h2>
      </Container>
    )
  }
  const groupedByDate = groupComponentsByDate(eventComponents)
  const titleLayout = ['template_3', 'template_4'].includes(eventTemplate)
    ? 'font-playfair gold'
    : 'pt-5 mb-5'
  return (
    <>
      <SegmentTitle className={titleLayout}>
        Wedding Weekend Details
      </SegmentTitle>

      {groupedByDate.map((group: ECorList, idx) => {
        // to render the packages first
        const groupSorted = sortPackagesFirst(group)
        return (
          <Container key={`group-${idx.toString()}`}>
            {groupSorted.map(g => {
              if ('rooms' in g) {
                // all rooms
                return (
                  <Segment
                    key={`rooms-row-${g.rooms[0].id}`}
                    guest={guest}
                    components={g.rooms}
                  />
                )
              }

              return (
                <Segment
                  key={`row-${g.id}`}
                  guest={guest}
                  components={[g]}
                  myBookings={myBookings}
                />
              )
            })}
          </Container>
        )
      })}
    </>
  )
}
