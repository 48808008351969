import React, { useState, useEffect } from 'react'
import find from 'lodash/fp/find'
import Col from 'react-bootstrap/Col'

import { Article, IvideoCardPosition, IBlogVideo } from '../../types'

import { VimeoCard } from './components'
import { makeVideoCardPositions } from './components/BlogFunctions'

import '../../styles/blog.scss'
import { PostCard } from '.'

interface IBlogHomeRow {
  articles: Article[]
  videoList: IBlogVideo[]
  index: number
}

export const BlogHomeRow: React.FC<IBlogHomeRow> = ({
  articles,
  index,
  videoList,
}) => {
  const [videoCardPosition, setVideoCardPosition] = useState<
    IvideoCardPosition[]
  >([])

  useEffect(() => {
    setVideoCardPosition(makeVideoCardPositions(videoList.length))
  }, [videoList.length])

  const findVideoCardPosition = find({ id: `video${index}` }, videoCardPosition)

  if (findVideoCardPosition) {
    if (findVideoCardPosition.align === 'right') {
      return (
        <>
          <Col xs={12} lg={4}>
            {articles.map(el => (
              <PostCard data={el} key={el.id} />
            ))}
          </Col>
          <Col xs={12} lg={8}>
            <VimeoCard
              video={videoList[findVideoCardPosition.idx]}
              width="780px"
            />
          </Col>
        </>
      )
    }

    return (
      <>
        <Col xs={12} lg={8}>
          <VimeoCard
            video={videoList[findVideoCardPosition.idx]}
            width="780px"
          />
        </Col>
        <Col xs={12} lg={4}>
          {articles.map(el => (
            <PostCard data={el} key={el.id} />
          ))}
        </Col>
      </>
    )
  }

  return (
    <>
      {articles.map(el => (
        <Col xs={12} lg={4} key={el.id}>
          <PostCard data={el} />
        </Col>
      ))}
    </>
  )
}
