import axios from 'axios'
import get from 'lodash/fp/get'
import unset from 'lodash/fp/unset'
import isEmpty from 'lodash/fp/isEmpty'
import flow from 'lodash/fp/flow'
import replace from 'lodash/fp/replace'

import { IgenericObject } from '../types'

import { checkEnv } from './general'
import { MAPS_DEV_API_KEY, MAPS_PROD_API_KEY } from './constants'

export const getGeoLocation = async (where: string): Promise<string> => {
  const { develop } = checkEnv()
  const key = develop ? MAPS_DEV_API_KEY : MAPS_PROD_API_KEY

  if (isEmpty(where)) {
    return ''
  }

  const url = 'https://maps.googleapis.com/maps/api/geocode/json'
  try {
    const response = await axios.get(url, {
      params: {
        address: where,
        key,
      },
    })
    const lat = get('data.results[0].geometry.location.lat', response) || 0
    const lng = get('data.results[0].geometry.location.lng', response) || 0

    return `Point(${lng},${lat})`
  } catch (error) {
    return ''
  }
}

export const cleanParams = (urlParams: IgenericObject): IgenericObject =>
  flow(
    el => JSON.stringify(el),
    replace('[38]', '&'),
    el => JSON.parse(el)
  )(urlParams)

export const processLocationParams = async (
  currentParams: IgenericObject
): Promise<IgenericObject> => {
  // we can't pass & as a parameter in the url so I used [38] and here I replace it
  const params = cleanParams(currentParams)

  if (params.where !== '' && !params?.where?.includes('Point')) {
    const geo = await getGeoLocation(params.where)
    if (geo !== '') {
      return { ...params, where: geo }
    }
    return unset('where', params) // bacause the API returns error if where is ''
  }
  return params
}
