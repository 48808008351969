import React from 'react'

import { Booking } from '../../../../types'
import { FormatNumber } from '../../../../components'
import { addonMetadata } from '../../../../common/constants'
import { getCheckInOut, getImageUrl } from '../../../../common'
import { BookingGuestsTable } from '../../../Booking/components'

import { BookingsContainer } from './style'

export const RoomBookingsModal: React.FC<{
  bookings: Booking[]
}> = ({ bookings = [] }) => {
  const className = bookings.length === 1 ? 'single' : ''

  return (
    <BookingsContainer className={className}>
      {bookings.map(el => {
        const { nightsAfter, nightsBefore } = addonMetadata
        const { eventComponent: ec, bookingAddOn } = el
        const guests = bookingAddOn.filter(b => b.bookingGuest)

        // enb = Extra Nights Before - ena = Extra Nights After
        const enb = bookingAddOn.find(
          b => b.offeringAddOn.metadata?.subtype === nightsBefore
        )
        const ena = bookingAddOn.find(
          b => b.offeringAddOn.metadata?.subtype === nightsAfter
        )

        return (
          <div key={`row-${el.id}`} className={`b-row ${className}`}>
            <div className="offering-data">
              <p className="fz-18 fwsb px-3 px-md-0">
                {ec?.alias ?? ec.offering.name}
                <span className="d-block fz-14 text-primary">
                  {ec.offering.provider.name}
                </span>
              </p>
              <div className="fz-16 fwsb px-3 px-md-0">
                Total: <FormatNumber n={el.guestPrice.total} />
              </div>
              <span className="CheckInOut px-3 px-md-0 pb-2 d-block">
                {getCheckInOut({
                  prefixCheckIn: 'Check In: ',
                  prefixCheckOut: 'Check Out: ',
                  date: el.date ?? ec.date,
                  duration: el.duration ?? ec.duration,
                  dateFormat: 'MM-DD-YYYY',
                  extraNights: {
                    before: enb?.offeringAddOnOption?.quantity,
                    after: ena?.offeringAddOnOption?.quantity,
                  },
                })}
              </span>

              <BookingGuestsTable guests={guests} />
            </div>
            <img
              src={getImageUrl(ec.offering.mainImage, '400x250')}
              alt={ec.offering.name}
            />
          </div>
        )
      })}
    </BookingsContainer>
  )
}
