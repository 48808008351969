import React, { lazy, Suspense } from 'react'
import find from 'lodash/fp/find'
import Container from 'react-bootstrap/Container'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { useCurrentUser, useQueryParams } from '../../common'
import { GET_BOOKING_DATA } from '../../graphql'
import { Common404 } from '../../components/Error404'
import { offeringTypes } from '../../common/constants'
import { SimpleLoader } from '../../components'
import { Booking as BookingType, Event, MyEvent } from '../../types'

import { PaymentAlert } from './components'

const BookingRoom = lazy(() => import('./BookingRoom'))
const BookingPackage = lazy(() => import('./BookingPackage'))

/**
 * since we will now have the stripe payment integrated within our website we must separate the logic
 * this view is to customize the booking, check details and add addons
 */
export const BookingPage: React.FC = () => {
  const urlParams = useQueryParams()
  const { userId } = useCurrentUser()
  const { bookingId } = useParams<{ bookingId: string }>()
  const { data, loading } = useQuery(GET_BOOKING_DATA, {
    variables: { id: bookingId, userId },
    fetchPolicy: 'cache-and-network',
  })

  if (!data && loading) return <SimpleLoader />
  if (!data && !loading) {
    return <Common404 text="Booking data not found" link="/" />
  }

  const { packageOffering, roomOffering } = offeringTypes

  const booking: BookingType = data?.booking
  const otherEvents: Event[] = data?.me.eventList || []
  const myEvents: MyEvent[] = data?.me.myEventList || []
  const events: (MyEvent | Event)[] = [...myEvents, ...otherEvents]

  const { offering, eventId } = booking.eventComponent
  const isMyEvent = find({ id: eventId }, myEvents)

  return (
    <Container className="pt-4">
      <PaymentAlert urlParams={urlParams} />
      {offering.__typename === roomOffering && (
        <Suspense fallback={<SimpleLoader />}>
          <BookingRoom booking={booking} events={events} />
        </Suspense>
      )}

      {offering.__typename === packageOffering && (
        <Suspense fallback={<SimpleLoader />}>
          <BookingPackage
            booking={booking}
            events={events}
            isMyEvent={isMyEvent}
          />
        </Suspense>
      )}
    </Container>
  )
}
