import { useContext } from 'react'
import pick from 'lodash/fp/pick'

import AppContext from '../context'
import { Guest, IcurrentUser, IuseCurrentUser } from '../types'

export const useCurrentUser = (): IuseCurrentUser => {
  const { auth, state } = useContext(AppContext)
  const { currentUser } = state
  const { isAuthenticated } = auth

  const userData = pick(
    ['firstName', 'lastName', 'userId', 'avatarUrl', 'role', 'email'],
    currentUser
  ) as IcurrentUser

  return { isAuthenticated, ...userData }
}

/**
 * Checks if a guest profile has any of the specified roles
 * If no roles are specified (all false), returns true
 *
 * @param guestProfile - Guest profile object containing role information
 * @param concierge - Allow concierge role
 * @param admin - Allow admin role
 * @param guest - Allow guest role
 * @param host - Allow host role
 * @returns true if user has any of the allowed roles (or if no roles specified)
 *
 * @example
 * checkGuestRole({ guestProfile, host: true, concierge: true }) - Check if user is either host or concierge
 */
export const checkGuestRole = ({
  guestProfile,
  concierge = false,
  admin = false,
  guest = false,
  host = false,
}: {
  guestProfile: Guest
  concierge?: boolean
  admin?: boolean
  guest?: boolean
  host?: boolean
}): boolean => {
  if (!guestProfile?.role) return false

  /*
   * Creates an array of allowed roles using logical AND (&&):
   * - If a role parameter is true: adds the role name to array
   * - If a role parameter is false: adds false to array
   * filter(Boolean) removes all false values, leaving only allowed role names
   * Example: { host: true, admin: true } => ['host', 'admin']
   */
  const allowedRoles = [
    concierge && 'concierge',
    admin && 'admin',
    guest && 'guest',
    host && 'host',
  ].filter(Boolean)

  const userRole = guestProfile.role.name.toLowerCase()
  return allowedRoles.includes(userRole)
}
