import React, { HTMLAttributes } from 'react'
import Button from 'react-bootstrap/Button'
import { MdDone } from 'react-icons/md'

import { Tier } from '../../types'

type ITiersItemDisplay = {
  tier: Tier
  active: boolean
  onToggle: (id: string) => void
} & HTMLAttributes<HTMLDivElement>

const ItemDisplay: React.FC<ITiersItemDisplay> = ({
  tier,
  active,
  onToggle,
  className,
}) => {
  return (
    <Button
      className={`px-3 fz-13 py-1 mr-2 shadow-none ${className} ${
        active ? 'btn-salmon' : 'btn-gray fwsb'
      }`}
      active={active}
      onClick={() => tier.id && onToggle(tier.id)}
    >
      {active && (
        <MdDone style={{ position: 'relative', bottom: '1px', right: '2px' }} />
      )}
      {tier.name}
    </Button>
  )
}
export default ItemDisplay
