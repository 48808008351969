/* eslint-disable @typescript-eslint/ban-types */
// in this case we need a generic form of objects so the best way is to use 'object'

/* eslint-disable no-restricted-syntax */
// this error is because we usually don't want to go through the entire object but in this case we do want to
import { useReducer, ChangeEvent } from 'react'
import set from 'lodash/fp/set'
import get from 'lodash/fp/get'
import pullAt from 'lodash/fp/pullAt'
import toNumber from 'lodash/fp/toNumber'
import toInteger from 'lodash/fp/toInteger'

type FValue = string | number | boolean
export type FValueType = 'INTEGER' | 'FLOAT' | 'STRING' | 'BOOLEAN'

type FErrorsInner<D> = D extends object
  ? FErrors<D>
  : D extends object[]
  ? FErrors<D[number]>[]
  : string | null

export type FErrors<T> = {
  [P in keyof T]: FErrorsInner<T[P]>
}

export function buildWrapper<T>(source: T): FErrors<T> {
  const res = {} as FErrors<T>
  for (const key in source) {
    const field = source[key]
    if (field instanceof Array) {
      res[key] = field.map(buildWrapper) as FErrorsInner<typeof field>
    } else if (typeof field === 'object' && field) {
      // typeof null === object so I added a new condition no falsy value
      res[key] = buildWrapper(field) as FErrorsInner<typeof field>
    } else {
      res[key] = null as FErrorsInner<typeof field>
    }
  }
  return res
}

interface FUpdateField<T> {
  type: 'UPDATE_FIELD'
  path: string
  value: T
}

interface FState<T> {
  model: T
  errors: FErrors<T>
  isError: boolean
}

interface FClearErrors {
  type: 'CLEAR_ERRORS'
}

interface FAddError {
  type: 'ADD_ERROR'
  path: string
  error: string
}

interface FAddToCollection {
  type: 'ADD_TO_COLLECTION'
  path: string
  obj: object
}

interface FRemoveFromCollection {
  type: 'REMOVE_FROM_COLLECTION'
  path: string
  index: number
}

interface FReset<T> {
  type: 'RESET'
  model: T
}

type changeValue = string | number | undefined

type FAction<T, D> =
  | FUpdateField<T>
  | FAddError
  | FClearErrors
  | FAddToCollection
  | FRemoveFromCollection
  | FReset<D>

const createReducer =
  <T extends object, D>() =>
  (state: FState<T>, action: FAction<D, T>): FState<T> => {
    switch (action.type) {
      case 'UPDATE_FIELD':
        return {
          ...state,
          model: set(action.path, action.value, state.model),
        }
      case 'CLEAR_ERRORS':
        return {
          ...state,
          errors: buildWrapper(state.model),
          isError: false,
        }
      case 'ADD_ERROR':
        return {
          ...state,
          isError: true,
          errors: set(action.path, action.error, state.errors),
        }
      case 'ADD_TO_COLLECTION':
        return {
          ...state,
          model: set(action.path, action.obj, state.model),
        }
      case 'REMOVE_FROM_COLLECTION': {
        const newArr = pullAt([action.index], get(action.path, state.model))
        return {
          ...state,
          model: set(action.path, newArr, state.model),
        }
      }
      case 'RESET':
        return {
          ...state,
          model: action.model,
          errors: buildWrapper(action.model),
        }
      default:
        return state
    }
  }

interface IuseFormResponse<T> {
  model: T
  errors: FErrors<T>
  handleChange: (value: FValue, path: string) => void
  handleCheckboxChange: (
    path: string
  ) => ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => void
  handleFieldChange: (
    path: string,
    value: changeValue,
    type?: FValueType
  ) => void
  pushError: (error: string, path: string) => void
  addAt: (obj: object, path: string) => void
  removeAt: (path: string, index: number) => void
  reset: (model: T) => void
}

export function useForm<T extends object>(source: T): IuseFormResponse<T> {
  const [state, dispatch] = useReducer(createReducer<T, FValue>(), {
    model: source,
    errors: buildWrapper(source),
    isError: false,
  })

  const handleChange = (value: FValue, path: string) => {
    dispatch({ type: 'UPDATE_FIELD', path, value })
    if (state.isError) {
      dispatch({ type: 'CLEAR_ERRORS' })
    }
  }

  return {
    model: state.model,
    errors: state.errors,
    handleChange,
    handleCheckboxChange:
      path =>
      ({ target: { checked } }) => {
        handleChange(checked, path)
      },
    handleFieldChange: (path, value, type) => {
      switch (type) {
        case 'INTEGER':
          handleChange(toInteger(value || '0'), path)
          break
        case 'FLOAT':
          handleChange(toNumber(value || '0'), path)
          break
        default:
          handleChange(value || '', path)
      }
    },
    pushError: (error, path) => {
      dispatch({ type: 'ADD_ERROR', path, error })
    },
    addAt: (obj, path) => {
      dispatch({ type: 'ADD_TO_COLLECTION', path, obj })
    },
    removeAt: (path, index) => {
      dispatch({ type: 'REMOVE_FROM_COLLECTION', path, index })
    },
    reset: model => {
      dispatch({ type: 'RESET', model })
    },
  }
}
