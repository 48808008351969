import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'

export const Provider404: React.FC = () => (
  <Container className="text-center py-5 mt-5 mb-3 fz-20">
    The provider you are looking for was not found.
    <Link className="d-block mt-2" to="/locations">
      go to provider list
    </Link>
  </Container>
)

interface ICommon404 {
  text: string
  link?: string
  hideLink?: boolean
}
export const Common404: React.FC<ICommon404> = ({
  text,
  link = '/',
  hideLink,
}) => (
  <Container className="text-center py-5 mt-5 mb-3 fz-20">
    {text}
    {link && !hideLink && (
      <Link className="d-block mt-2" to={link}>
        go back
      </Link>
    )}
  </Container>
)
