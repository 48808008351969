import React, { useState, useEffect } from 'react'
import findIndex from 'lodash/fp/findIndex'
import { useMutation } from '@apollo/react-hooks'

import Tiers from '../../../../components/Tiers'
import { SmallLoader } from '../../../../components'
import {
  ASSIGN_TIER_TO_EVENT_COMPONENT as ASSIGN,
  REMOVE_TIER_FROM_EVENT_COMPONENT as REMOVE,
} from '../../../../graphql'
import { IAssignTiersToEventComponent } from '../../../../types'

export const AssignTiersToEventComponent: React.FC<
  IAssignTiersToEventComponent
> = ({ componentId, eventTiers = [], assignedTiers, type }) => {
  const [addTier, { loading: addingTier }] = useMutation(ASSIGN)
  const [removeTier, { loading: removingTier }] = useMutation(REMOVE)
  const [tierIds, updateTierIds] = useState<string[]>([])
  const [error, setError] = useState('')

  useEffect(() => {
    updateTierIds(assignedTiers.map(el => el.id))
  }, [assignedTiers])

  const assignRemoveTier = (tierId: string) => {
    if (addingTier || removingTier) return
    setError('')
    const alreadyAssigned = findIndex({ id: tierId }, assignedTiers)
    const variables = {
      id: componentId,
      tierId,
    }

    if (alreadyAssigned < 0) {
      // adding tier
      addTier({ variables }).then(({ data }) => {
        if (data.addTierToEventComponent.errors.length > 0) {
          setError('There was an error adding the Sub-list, try again.')
        }
      })
    } else {
      // removing tier
      removeTier({ variables }).then(({ data }) => {
        if (data.removeTierFromEventComponent.errors.length > 0) {
          setError('There was an error removing the Sub-list, try again.')
        }
      })
    }
  }

  if (eventTiers.length <= 0) return <></>
  return (
    <div className="bg-white px-3 pt-3 pb-2" data-cy="assignTiers">
      <p className="d-block fwsb fz-16 tc-gray w-fit-content">
        Select sub-lists for this {type}:
        {(addingTier || removingTier) && (
          <div className="float-right px-2">
            <SmallLoader />
          </div>
        )}
      </p>
      <Tiers.Selector
        hideClearButton
        tiers={eventTiers}
        selection={tierIds}
        onSelectSingle={assignRemoveTier}
      />
      <span className="mt-2 w-100 d-block text-danger fz-13">{error}</span>
    </div>
  )
}
