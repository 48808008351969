/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getSelectedEvent } from '../common'

const initialState: {
  currentEventId: string // current selected event in the event selector on the feed
  pinnedPostsCount: number
} = {
  currentEventId: getSelectedEvent() ?? '',
  pinnedPostsCount: 0,
}

const siteSlice = createSlice({
  name: 'site_slice',
  initialState,
  reducers: {
    setCurrentEventId: (state, action: PayloadAction<string>) => {
      state.currentEventId = action.payload
    },
    setPinnedPostsCount: (state, action: PayloadAction<number>) => {
      state.pinnedPostsCount = action.payload
    },
  },
})

export const {
  // global utilities for the whole app
  setCurrentEventId,
  setPinnedPostsCount,
} = siteSlice.actions
export default siteSlice.reducer
