import React from 'react'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import { FaClock } from 'react-icons/fa'
import { MdPeople, MdAccessTime } from 'react-icons/md'

import { ILocationMetadata } from '../../types'

import { LocationOverviewContainer } from './style'

export const LocationOverview: React.FC<{
  data: ILocationMetadata
  className?: string
}> = ({ data, className = '' }) => {
  if (isEmpty(data)) {
    return <></>
  }

  const ceremony: number = get('capacity.ceremony', data) || 0
  const cocktail: number = get('capacity.cocktail', data) || 0
  const reception: number = get('capacity.reception', data) || 0

  return (
    <LocationOverviewContainer className={className}>
      <h4 className="pl-1 m-0 mt-2">{get('name', data)}</h4>
      <div className="LocationOverview">
        <div>
          <MdPeople size={22} />
          <span>Capacity</span>
        </div>
        {ceremony > 0 && (
          <div>
            <span className="name">Ceremony</span>
            <span>{data.capacity.ceremony}</span>
          </div>
        )}
        {cocktail > 0 && (
          <div>
            <span className="name">Cocktail</span>
            <span>{data.capacity.cocktail}</span>
          </div>
        )}
        {reception > 0 && (
          <div>
            <span className="name">Reception</span>
            <span>{data.capacity.reception}</span>
          </div>
        )}
        <div className="time">
          <MdAccessTime size={18} />
          <span>Event Hours</span>
        </div>
        {get('hours.standard', data) && (
          <div>
            <span className="name">
              <FaClock size={14} color="white" className="mr-2 mb-1" />
              Cut Off
            </span>
            <span>{data.hours.standard}</span>
          </div>
        )}
      </div>
    </LocationOverviewContainer>
  )
}
