import forEach from 'lodash/fp/forEach'

import { Image, Article, IvideoCardPosition, IBlogVideo } from '../../../types'

export const makeGroups = (list: Image[]): Image[][] => {
  const group1: Image[] = []
  const group2: Image[] = []
  const group3: Image[] = []
  let group = 0

  forEach(el => {
    group += 1
    if (group === 1) {
      group1.push(el)
    } else if (group === 2) {
      group2.push(el)
    } else if (group === 3) {
      group3.push(el)
      group = 0
    }
  }, list)

  return [group1, group2, group3]
}

/**
 * Return array of array
 * 1 array per Row
 * 3 elements, 2 elements, 3 elements, 2 elements...
 */
export const makeArticleChunks = (
  array: Article[],
  videos: IBlogVideo[]
): Article[][] => {
  const chunkedArr: Article[][] = []

  if (!array && !videos) {
    return chunkedArr
  }

  let size = 3
  let index = 0

  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index))
    index += size

    if (chunkedArr.length < videos.length * 2) {
      size = size === 3 ? 2 : 3
    } else {
      size = 3
    }
  }

  return chunkedArr
}

export const makeVideoCardPositions = (
  videoListLength: number
): IvideoCardPosition[] => {
  const accum: IvideoCardPosition[] = []
  let align = 'right'
  let idxSum = 1
  for (let i = 0; i < videoListLength; i += 1) {
    accum.push({
      id: `video${i + idxSum}`, // video1, video3, video5, video7......
      align,
      idx: i,
    })

    align = align === 'right' ? 'left' : 'right' // to toggle between right and left
    idxSum += 1 // to get the correct position row 1, 3, 5, 7......
  }

  return accum
}

/**
 * if case we need to call an API to get the videos we can do it here
 * for now we are just returning a static list of videos
 */
export const getMainVideo = (): IBlogVideo => {
  return {
    id: 'sara-and-jean-paul',
    title: `Sara & Jean Paul's Exotic Beachfront  Wedding in Riviera Maya`,
    description: `Kiss & Tell is a destination wedding planning platform where you can manage your guest lists, book hotel room blocks, and much more.`,
    url: `https://player.vimeo.com/progressive_redirect/playback/1034058339/rendition/1080p/file.mp4?loc=external&signature=18c1104af40dd08e1409fe7be005743d4dda5aa67225967bda3d875ee297366e`,
    poster: '/img/blog/sara_jean.jpg',
    credits: [
      {
        name: 'Videography',
        value: 'Gallardo Films',
        link: 'https://gallardofilms.com',
      },
      {
        name: 'Venue',
        value: 'Secret Jewel Celebrations Venue',
        link: 'https://kissandtell.com/locations/secret-jewel-wedding-venue',
      },
      {
        name: 'Venue',
        value: 'The Fives Oceanfront',
        link: 'https://kissandtell.com/locations/the-fives-oceanfront-hotel',
      },
      {
        name: 'Planning',
        value: 'Diamant Events',
        link: 'https://www.instagram.com/diamantevents/?hl=en',
      },
      {
        name: 'Photography',
        value: 'Alain Martinez Studio',
        link: 'https://alainmartinez.com',
      },
    ],
  }
}
export const getBlogVideos = (): IBlogVideo[] => {
  return [
    {
      id: 'naseem-and-david',
      title: `Naseem & David's Vibrant Wedding in Historic San Miguel de Allende`,
      description: `Joyful wedding celebration`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1036480980/rendition/1080p/file.mp4?loc=external&signature=0c2f97c71bce9c86b30cedfee34cdaf90580c92301b5b595341b35993483fb8b`,
      poster: '/img/blog/naseem_david.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Alex Treviño Films',
          link: 'https://alextrevinofilms.com',
        },
        {
          name: 'Venue',
          value: 'Hotel Casa Cien',
          link: 'https://kissandtell.com/locations/hotel-casa-cien',
        },
        {
          name: 'Accommodations',
          value: 'Rosewood San Miguel de Allende',
          link: 'https://kissandtell.com/locations/rosewood-san-miguel-de-allende',
        },
        {
          name: 'Planning, Decor & Floral',
          value: 'Penzi Weddings & Events',
          link: 'https://www.smapenzi.com',
        },
        {
          name: 'Photography',
          value: 'Brett Butterstein Photography',
          link: 'https://www.brettbutterstein.com',
        },
      ],
    },
    {
      id: 'shay-and-hayden',
      title: `Shay & Hayden's Bespoke Wedding At The St. Regis Resort, Bal Harbour`,
      description: `Intimate love story moments`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1036474245/rendition/1080p/file.mp4?loc=external&signature=c8ccb3ed88b6426e1003bf1a7b78cec49b65ecd065da6066d5c6322b7ae62a3c`,
      poster: '/img/blog/shay_hayden.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Merge Social',
          link: 'https://www.instagram.com/mergesocial/?hl=en',
        },
        {
          name: 'Venue',
          value: 'The St. Regis Bal Harbour Resort',
          link: 'https://kissandtell.com/locations/st-regis-bal-harbour',
        },
        {
          name: 'Planning',
          value: 'Sara Renee Events',
          link: 'https://sarareneeevents.com',
        },
        {
          name: 'Photography',
          value: 'Unique Design Studios',
          link: 'https://udsphoto.com',
        },
      ],
    },
    {
      id: 'kim-and-sam',
      title: `Kim & Sam's Exotic Mayakoba Beach Wedding`,
      description: `Elegant wedding moments`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1036492585/rendition/720p/file.mp4?loc=external&signature=fd4580dde85c3a6b8b302bbeed28a5b6bda1131a17597506c8bce0eea5010b4a`,
      poster: '/img/blog/sam_kim.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Gallardo Films',
          link: 'https://gallardofilms.com',
        },
        {
          name: 'Venue',
          value: 'Rosewood Mayakoba',
          link: 'https://kissandtell.com/locations/rosewood-mayakoba',
        },
      ],
    },
    {
      id: 'jazmine-and-desmond',
      title: `Jazmine & Desmond's Soulful Playa Mujeres Beachfront Wedding`,
      description: `Romantic celebration at Hotel Finest`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1036488283/rendition/1080p/file.mp4?loc=external&signature=b46cf47841f36ac9c49af566b6ed6fb380b18f5455a2a30bb6fe1a82c9804994`,
      poster: '/img/blog/jazmine_desmond.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Gallardo Films',
          link: 'https://gallardofilms.com',
        },
        {
          name: 'Venue',
          value: 'Finest Playa Mujeres',
          link: 'https://kissandtell.com/locations/finest-playa-mujeres',
        },
      ],
    },
    {
      id: 'nuria-and-eduardo',
      title: `Nuria & Eduaro's Historic Merida Hacienda Wedding`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1038250835/rendition/1080p/file.mp4?loc=external&signature=79fe0e5ed4bac5c5a1ef24c7aa15654f1e6104b227a54bb7a33de40c913e5a59`,
      poster: '/img/blog/nuria_eduardo.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Alex Treviño Films',
          link: 'https://alextrevinofilms.com',
        },
        {
          name: 'Venue',
          value: 'Hacienda Tekik de Regil',
          link: 'https://kissandtell.com/locations/hacienda-tekik-de-regil',
        },
      ],
    },
    {
      id: 'ashley-and-ed',
      title: `Ashley & Ed's Historic Miami Wedding at Vizcaya Museum and Gardens`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1038253113/rendition/1080p/file.mp4?loc=external&signature=08de6e669d92889f650bebf28bff636a3d20efe3d8f277ce3421068f093bf9e4`,
      poster: '/img/blog/ashley_ed.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Merge Social',
          link: 'https://www.instagram.com/mergesocial/?hl=en',
        },
        {
          name: 'Venue',
          value: 'Vizcaya Museum & Gardens',
          link: 'https://kissandtell.com/locations/vizcaya-museum-gardens',
        },
        {
          name: 'Planning',
          value: 'Lourdes Milian Productions',
          link: 'https://www.lourdesmilian.com',
        },
        {
          name: 'Photography',
          value: 'KT Merry',
          link: 'https://ktmerry.com',
        },
      ],
    },
    {
      id: 'tali-and-nicolas',
      title: `Tali & Nico's Boho Chic Beachfront Wedding`,
      description: `Magical wedding highlights`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1036484886/rendition/1080p/file.mp4?loc=external&signature=966f253375b49abbaf70a0ac89200b108b07250733459f7a6cdc87bb4f8d4933`,
      poster: '/img/blog/tali_nicolas.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Gallardo Films',
          link: 'https://gallardofilms.com',
        },
        {
          name: 'Venue',
          value: 'Blue Diamond Luxury Boutique Hotel',
          link: 'https://kissandtell.com/locations/blue-diamond-luxury-boutique',
        },
      ],
    },
    {
      id: 'cristi-and-jan',
      title: `Cristi & Jan's Boating Adventure and San Juan Wedding`,
      description: `Love and laughter captured`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1036478196/rendition/1080p/file.mp4?loc=external&signature=68eb12f835c388766038a3635bb12bd72c4f342aab097e8e104d83aa88cde23d`,
      poster: '/img/blog/cristi_jan.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Alex Treviño Films',
          link: 'https://alextrevinofilms.com',
        },
        {
          name: 'Venue',
          value: 'La Concha Renaissance San Juan Resort',
          link: 'https://kissandtell.com/locations/la-concha-renaissance-san-juan-resort',
        },
      ],
    },
    {
      id: 'dani-and-andrew',
      title: `Dani & Andrew's Classic Miami Beach Meets Creative Wynwood Wedding`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1038253786/rendition/1080p/file.mp4?loc=external&signature=877e4eff0fe915d75a06b06d4222da6a1ae4a02213bc6ebb5f100bc67ef91374`,
      poster: '/img/blog/dani_andrew.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Merge Social',
          link: 'https://www.instagram.com/mergesocial/?hl=en',
        },
        {
          name: 'Venues',
          value: `Maps Production House, Casa de Sueños - Ridinger Family Residence`,
        },
        {
          name: 'Photography',
          value: 'Stills by Hernan',
          link: 'https://stillsbyhernan.com/',
        },
      ],
    },
    {
      id: 'pavis-and-el-ruco',
      title: `Pavis & El Ruco's Cracklin Rosie Love Story in San Miguel de Allende`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1038720431/rendition/1080p/file.mp4?loc=external&signature=22f7bd0084f0ed24bba77b19f4e2a1e5638677851f24069327b5cfe992c3d11b`,
      poster: '/img/blog/pavis_elruco.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Alex Treviño Films',
          link: 'https://alextrevinofilms.com',
        },
        {
          name: 'Venue',
          value: 'Hotel Casa Cien',
          link: 'https://kissandtell.com/locations/hotel-casa-cien',
        },
      ],
    },
    {
      id: 'adri-and-sean',
      title: `Adri & Sean's Chic Downtown Miami Wedding`,
      url: `https://player.vimeo.com/progressive_redirect/playback/1038720699/rendition/1080p/file.mp4?loc=external&signature=5eb316de74de21f4d9d5c713245453ae10381c0bb850e05c1e33ee1b96451ec6`,
      poster: '/img/blog/adri_sean.jpg',
      credits: [
        {
          name: 'Videography',
          value: 'Merge Social',
          link: 'https://www.instagram.com/mergesocial/?hl=en',
        },
        {
          name: 'Venue',
          value: 'Mandarin Oriental Miami',
          link: 'https://kissandtell.com/locations/mandarin-oriental-miami',
        },
        {
          name: 'Planning',
          value: 'ET Events',
          link: 'https://etevents.com',
        },
        {
          name: 'Photography',
          value: 'Maloman Studio',
          link: 'https://www.malomanstudios.com',
        },
      ],
    },
  ]
}
