import styled from 'styled-components'

import { cardBorder } from '../../styles/themeColors'

export const HowWeWorkContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h2 {
    font-family: 'DM Serif Display', serif;
    font-style: normal;
    font-weight: 400;
  }
  .text {
    font-size: 16px;
    width: 45%;
  }
  @media (width <= 991px) {
    flex-direction: column;
    .text {
      width: 100%;
      button {
        display: block;
        margin: 0 auto;
      }
    }
  }
`

export const Clipboard = styled.div`
  background-color: #414c52;
  border-radius: 1.2rem;
  font-size: 16px;
  margin: 25px 0 0 0;
  max-width: 310px;
  padding: 20px 18px;
  position: relative;
  width: 50%;
  * {
    font-optical-sizing: auto;
    font-stretch: 75%;
  }
  span {
    font-weight: 300;
  }
  p,
  li {
    font-weight: 700;
  }
  p {
    margin: 0;
  }
  .underline {
    text-decoration: underline;
  }
  .clipboard {
    left: 50%;
    position: absolute;
    top: -28px;
    transform: translate(-50%, 0);
    width: 125px;
  }
  .sheet {
    background-color: white;
    border-radius: 3px;
    padding: 10px 1.5rem;
    > div {
      padding: 10px 0;
      &:not(:last-child) {
        border-bottom: 1px solid ${cardBorder};
      }
    }
  }
  @media (width <= 991px) {
    margin: 3rem auto 1rem auto;
    width: 100%;
  }
`
