import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import { DocumentNode } from 'graphql'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { getMoreData, fromNow } from '../common'
import { NegotiationPreview, IgenericObject } from '../types'

interface INegotiationsPreview {
  QUERY: DocumentNode
  objectPath: string
  url: string
  variables?: IgenericObject
}
export const NegotiationsPreview: React.FC<INegotiationsPreview> = ({
  url,
  objectPath,
  QUERY,
  variables = {},
}) => {
  const { data, loading, fetchMore } = useQuery(QUERY, { variables })

  const cursorToken: string = get(`${objectPath}.cursor`, data)
  const previewEntries: NegotiationPreview[] = getOr(
    [],
    `${objectPath}.entries`,
    data
  )

  const loadMore = () => {
    if (cursorToken && !loading) {
      getMoreData({
        fetchMore,
        query: QUERY,
        cursorToken,
        path: objectPath,
      })
    }
  }

  return (
    <>
      {previewEntries.length === 0 && !loading && (
        <p className="text-center">No active negotiations</p>
      )}

      {previewEntries.map(el => (
        <Link
          key={`${el.event.id}_${el.provider.id}`}
          className="text-reset text-decoration-none"
          to={`${url}/${el.event.id}/${el.provider.id}`}
        >
          <Card
            data-cy="negotiation-preview"
            body
            className="shadow-sm text-center mb-2"
            style={{ width: '98%' }}
          >
            <p className="mb-0 fz-16">{el.provider.name}</p>
            <p className="mb-1 fz-13">{el.event.name}</p>
            <span className="fz-11">{fromNow(el.lastUpdate)}</span>
          </Card>
        </Link>
      ))}

      {cursorToken && (
        <Button
          disabled={loading}
          className="mx-auto d-block bradius-1 py-1"
          variant="outline-primary"
          onClick={() => loadMore()}
        >
          Load more
        </Button>
      )}
    </>
  )
}
