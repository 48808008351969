import React from 'react'
import { VscDeviceMobile } from 'react-icons/vsc'
import { MdDesktopWindows } from 'react-icons/md'
import { Link } from 'react-router-dom'

import { IRSVPpreview } from '../../../../types'

import { PreviewContainer, PreviewControls } from './style'

export const RSVPSettingsPreview: React.FC<IRSVPpreview> = ({
  eventId,
  version,
  setVersion,
}) => {
  return (
    <PreviewContainer>
      <PreviewControls
        style={{ marginBottom: version === 'mobile' ? '25px' : '-115px' }}
      >
        <MdDesktopWindows
          size={30}
          className={`cursor ${version === 'desktop' && 'active'}`}
          title="Desktop version"
          onClick={() => setVersion('desktop')}
        />
        <VscDeviceMobile
          size={30}
          className={`cursor ${version === 'mobile' && 'active'}`}
          title="Mobile version"
          onClick={() => setVersion('mobile')}
        />
        <Link to={`/rsvp-preview/${eventId}`} target="_blank" className="ml-5">
          Your event website
        </Link>
      </PreviewControls>

      <div className={`window shadow ${version}`}>
        <div className={`dots ${version === 'mobile' && 'd-none'}`}>
          <span />
          <span />
          <span />
        </div>
        <iframe
          src={`/rsvp-preview/${eventId}?preview=true`}
          title="rsvp-preview"
          sandbox="allow-scripts allow-same-origin"
          frameBorder={0}
          width={500}
          height={700}
        />
      </div>
    </PreviewContainer>
  )
}
