import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { MdAdd } from 'react-icons/md'

import Zero from '../../../assets/images/zero/browser-listing.jpg'
import CreateEvent from '../CreateEvent'
import { useIsAgent } from '../../../common'

export const GroundZero: React.FC = () => {
  const isAgent = useIsAgent()
  const [createEvent, setCreateEvent] = useState(false)

  return (
    <Container>
      {isAgent && (
        <div className="w-fit-content ml-auto pt-3">
          <CreateEvent
            show={createEvent}
            onClose={() => setCreateEvent(false)}
          />
          <Button
            variant="outline-primary"
            className="ml-auto mb-1 fwsb d-block"
            onClick={() => setCreateEvent(true)}
          >
            <MdAdd className="svg-top1" size={20} />
            Create Event
          </Button>
        </div>
      )}
      <img
        data-cy="guide-image-gz"
        className="w-100 img-fluid mt-3 mt-md-5"
        src={Zero}
        alt="Ground Zero"
      />
    </Container>
  )
}
