import React from 'react'
import moment from 'moment'

import { RSVPCard } from '../../Rsvp/style'
import { EventComponentOffering } from '../../../pages/Rsvp/components'
import { DefaultUserPicture, SimpleTooltip } from '../../../components'
import {
  EventComponent,
  MyEvent,
  Event,
  IcurrentAddons,
  ShortBookingData,
} from '../../../types'

interface IOfferingCard {
  eventComponent: EventComponent
  event?: MyEvent | Event
  currentAddons?: IcurrentAddons
  bookingData?: ShortBookingData
}
export const OfferingCard: React.FC<IOfferingCard> = ({
  eventComponent,
  event,
  currentAddons,
  bookingData,
}) => {
  const { offering, date } = eventComponent
  const { name, address, id: providerId } = offering.provider
  return (
    <RSVPCard className="rsvp-card">
      <DefaultUserPicture />
      <h2 className="fz-15 mb-0 pt-2 fwsb">
        {event?.name}
        <span className="d-block text-primary mt-3 fz-14 position-relative w-fit-content mx-auto px-3">
          {name}
          {address && (
            <SimpleTooltip
              id={`${providerId}-provider-address`}
              className="tooltip-card top-right"
              text={address}
            />
          )}
        </span>
      </h2>
      <span className="fz-13">{moment(date).format('MMM DD, YYYY')}</span>
      <EventComponentOffering
        eventComponent={eventComponent}
        currentAddons={currentAddons}
        hideCTA
        bookingData={bookingData}
      />
    </RSVPCard>
  )
}
