import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'

import Steps from '../../../assets/images/BuildBook.jpg'
import { darkGray } from '../../../styles/themeColors'

import { Banner } from './style'

export const LocationBanner: React.FC<{
  className?: string
  small?: boolean
}> = ({ className = '', small = false }) => {
  const [hideBanner, setHideBanner] = useState(
    sessionStorage.getItem('locationBanner')
  )

  const handleCloseBanner = () => {
    setHideBanner('hide')
    sessionStorage.setItem('locationBanner', 'hide')
  }

  if (hideBanner) {
    return <></>
  }

  return (
    <div className={`d-none d-lg-block ${className}`}>
      <Banner className={small ? 'small' : ''}>
        <MdClose
          color="white"
          size={36}
          onClick={handleCloseBanner}
          className="closeBanner cursor"
        />
        <h1 className="fwb fz-24">Build & Book Your Event</h1>
        <p className="d-block fz-16 pb-5">
          Find your venue and group stay in top global destinations.
        </p>
        <div className="steps-container">
          <img src={Steps} alt="Steps to book" className="steps" />
        </div>
      </Banner>
    </div>
  )
}

export const MobileLocationBanner: React.FC = () => {
  const [hideBanner, setHideBanner] = useState(
    sessionStorage.getItem('locationBanner')
  )

  const handleCloseBanner = () => {
    setHideBanner('hide')
    sessionStorage.setItem('locationBanner', 'hide')
  }

  if (hideBanner) {
    return <></>
  }

  return (
    <div className="bg-white d-inline-block d-md-none p-0">
      <Banner>
        <img src={Steps} alt="Steps to book" className="w-100 px-1 my-3" />
        <MdClose
          color={darkGray}
          size={20}
          onClick={handleCloseBanner}
          className="closeBanner cursor"
        />
        <h2 className="fwb fz-24 mb-3">Build & Book Your Event</h2>
      </Banner>
    </div>
  )
}
