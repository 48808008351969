import gql from 'graphql-tag'

import { IMAGE_FIELDS_FRAG, PACKAGE_ADDONS_FIELDS } from './general'

import { MESSAGES_FIELDS, NEGOTIATION_ITEM_FIELDS } from '.'

export const GET_MY_PROVIDERS = gql`
  query getMyProviders($cursor: String) {
    me {
      id
      ... on Vendor {
        myProviders(cursor: { cursor: $cursor, limit: 15 }) {
          cursor
          entries {
            id
            name
            url
            address
          }
        }
      }
    }
  }
`

export const GET_NEGOTIATION_MESSAGES_VENDOR = gql`
  query getNegotiationMessagesVendor(
    $eventId: ID!
    $providerId: ID!
    $cursor: String
  ) {
    me {
      id
      ... on Vendor {
        id
        negotiation(eventId: $eventId, providerId: $providerId) {
          quotes {
            eventComponent {
              id
              offering {
                __typename
                ... on PackageOffering {
                  name
                }
                ... on RoomOffering {
                  name
                }
              }
            }
          }
          messages(cursor: { cursor: $cursor, limit: 15 }) {
            cursor
            entries {
              ...MessagesFields
            }
          }
        }
      }
    }
  }
  ${MESSAGES_FIELDS}
`

export const GET_NEGOTIATION_QUOTES_VENDOR = gql`
  query getNegotiationQuotesVendor($eventId: ID!, $providerId: ID!) {
    me {
      id
      ... on Vendor {
        id
        negotiation(eventId: $eventId, providerId: $providerId) {
          provider {
            id
            name
            mainImage {
              ...ImageFields
            }
          }
          event {
            id
            name
            host {
              firstName
              lastName
              avatarUrl
            }
          }
          quotes {
            eventComponent {
              id
              status
              date
              duration
              offering {
                __typename
                ... on PackageOffering {
                  addOns {
                    id
                    frequency
                    label
                    price
                    mandatory
                    offeringAddOnOptions {
                      id
                      description
                      label
                      price
                    }
                  }
                }
              }
              packageCustomization {
                ...PackageAddonsFields
              }
            }
            items {
              ...negotiationItemFields
            }
          }
        }
      }
    }
  }
  ${IMAGE_FIELDS_FRAG}
  ${NEGOTIATION_ITEM_FIELDS}
  ${PACKAGE_ADDONS_FIELDS}
`

export const GET_NEGOTIATIONS_PREVIEW_VENDOR = gql`
  query getNegotiationsPreviewVendor($cursor: String) {
    me {
      id
      ... on Vendor {
        id
        negotiations(cursor: { cursor: $cursor, limit: 5 }) {
          cursor
          entries {
            event {
              id
              name
            }
            lastUpdate
            provider {
              id
              name
            }
          }
        }
      }
    }
  }
`
