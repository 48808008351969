import React from 'react'
import kebabCase from 'lodash/fp/kebabCase'

export const BlogTags: React.FC<{ padding?: string; tags: string[] }> = ({
  padding = 'py-4',
  tags = [],
}) => (
  <div className={`w-100 tags text-primary fwsb fz-13 text-center ${padding}`}>
    {tags.map((el, idx) => {
      return (
        <span key={kebabCase(`${idx}-${el}`)} className="mx-2">
          #{el}
        </span>
      )
    })}
  </div>
)
