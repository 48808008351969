import React from 'react'
import Button from 'react-bootstrap/Button'
import { useHistory, useParams } from 'react-router-dom'

import { EventComponent } from '../../../types'
import { EventIcon, RoomIcon } from '../../../components/customIcons'
import { useAppDispatch } from '../../../hooks'
import { setComponent } from '../../../features/publicBookingSlice'
import { offeringTypes } from '../../../common/constants'

export const PublicCTA: React.FC<{
  eventComponent: EventComponent
  className?: string
  childrenBelow?: React.ReactNode
}> = ({ eventComponent, className = '', childrenBelow = null }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { customUrl } = useParams<{ customUrl: string }>()

  const { roomOffering } = offeringTypes
  const { offering, customTexts } = eventComponent
  const { provider } = offering

  const isRoom = offering.__typename === roomOffering
  const icon = isRoom ? (
    <RoomIcon color="white" width="25px" height="25px" />
  ) : (
    <EventIcon color="white" width="25px" height="25px" />
  )
  const text = isRoom ? 'Book' : customTexts?.timeline || 'RSVP'

  const handleClick = () => {
    const link = isRoom
      ? `/invite/${customUrl}/room-selection/${provider.url}`
      : `/invite/${customUrl}/booking`

    dispatch(setComponent(eventComponent))
    history.push(link)
  }
  return (
    <Button className={className} onClick={handleClick}>
      {icon} {text}
      {childrenBelow}
    </Button>
  )
}
