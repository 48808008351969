import React, { useEffect, useState, useContext } from 'react'
import getOr from 'lodash/fp/getOr'
import pick from 'lodash/fp/pick'
import isEmpty from 'lodash/fp/isEmpty'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { MdAccountCircle, MdSave, MdLock } from 'react-icons/md'

import AppContext from '../../context'
import { updateLocalToken } from '../../auth/helpers'
import { UploadImg, SimpleLoader, KnTField } from '../../components'
import {
  useCurrentUser,
  simpleAlert,
  confirmAlert,
  errorAlert,
} from '../../common'
import { gray } from '../../styles/themeColors'
import { useForm } from '../../common/alterForms'
import { Error, PickerResponse } from '../../types'
import {
  GET_MY_MAIN_INFORMATION,
  UPDATE_USER,
  RESET_PASSWORD_LINK,
} from '../../graphql'
import { myHub } from '../../common/constants'

import { Form } from './style'

export const ManageProfile: React.FC = () => {
  const history = useHistory()
  const [UploadImageMsg, setUploadImageMsg] = useState('')
  const [loaded, setloaded] = useState(false)
  const { userId } = useCurrentUser()
  const { state, auth } = useContext(AppContext)

  const [updateUser, { loading: loadingUpdateUser }] = useMutation(UPDATE_USER)
  const [submitResetLink] = useMutation(RESET_PASSWORD_LINK)

  const { data, loading } = useQuery(GET_MY_MAIN_INFORMATION)
  const init = { firstName: '', lastName: '', avatarUrl: '' }
  const { model, errors, handleFieldChange, reset, pushError } = useForm(init)
  const me = getOr({}, 'me', data)

  useEffect(() => {
    if (!loaded && !isEmpty(me)) {
      reset({
        firstName: me.firstName,
        lastName: me.lastName,
        avatarUrl: me.avatarUrl,
      })
      setloaded(true)
    }
  }, [me, reset, loaded])

  if (loading) {
    return <SimpleLoader />
  }

  const onSuccess = (result: PickerResponse) => {
    const url: string = getOr('', 'filesUploaded[0].url', result)
    if (url) {
      reset({ ...model, avatarUrl: url })
      setUploadImageMsg('save to complete this change')
    }
  }

  const updatePersonalData = (ev: React.FormEvent) => {
    ev.preventDefault()
    if (loadingUpdateUser) return
    const currentUser = pick(['userId', 'avatarUrl', 'role'], state.currentUser)
    const variables = {
      input: {
        firstName: model.firstName,
        lastName: model.lastName,
        avatarUrl: model.avatarUrl,
      },
      userId,
    }
    updateUser({ variables }).then(response => {
      if (response.data.updateUser.errors.length > 0) {
        response.data.updateUser.errors.forEach(({ key, message }: Error) => {
          pushError(message, key)
        })
      } else {
        // updating context and token
        const { result } = response.data.updateUser
        const userUpdated = {
          ...currentUser,
          firstName: result.firstName,
          lastName: result.lastName,
          avatarUrl: result.avatarUrl,
          email: result.email,
        }
        updateLocalToken({ user: userUpdated })
        auth.updateUserData(userUpdated)
        // end
        simpleAlert({
          html: 'your data has been successfully updated',
          icon: 'success',
        })
        setUploadImageMsg('')
        history.push(myHub)
      }
    })
  }

  const updatePassword = () => {
    confirmAlert({
      html: 'do you want to start the process to reset your password? We will send you an email with instructions.',
      icon: 'question',
      confirmButtonText: 'Continue',
    }).then(response => {
      if (response.value) {
        submitResetLink().then(({ data: { passwordResetLink } }) => {
          if (passwordResetLink.errors.length > 0) {
            errorAlert(
              passwordResetLink.errors,
              'There was a problem sending your email'
            )
          } else {
            simpleAlert({
              html: 'A password reset email was sent to your email',
              icon: 'success',
            })
          }
        })
      }
    })
  }

  return (
    <Card
      className="shadow-sm mx-auto py-4 my-4 my-lg-5"
      style={{ maxWidth: '585px', width: '96%' }}
    >
      <Helmet>
        <title>Manage Profile - Kiss & Tell</title>
        <meta
          name="description"
          content="Update your personal information and change your password"
        />
      </Helmet>
      <div>
        <h2 className="text-center fz-24 fwb">Manage Profile</h2>
        <Form onSubmit={updatePersonalData}>
          <div className="manage-profile-picture">
            {model.avatarUrl ? (
              <img
                src={model.avatarUrl}
                alt={`${model.firstName} ${model.lastName}`}
              />
            ) : (
              <MdAccountCircle size={138} color={gray} />
            )}
            <UploadImg
              text="Edit photo"
              onSuccess={onSuccess}
              msg={UploadImageMsg}
              storeTo={{
                container: 'kt.user.images',
                location: 's3',
                path: `/${userId}/profile/`,
                region: 'us-east-1',
              }}
            />
          </div>
          <KnTField
            label="First name"
            value={model}
            error={errors}
            setValue={handleFieldChange}
            name="firstName"
            placeholder="Enter your first name"
            icon={<MdAccountCircle size={24} color={gray} />}
          />
          <KnTField
            label="Last name"
            value={model}
            error={errors}
            setValue={handleFieldChange}
            name="lastName"
            placeholder="Enter your last name"
            icon={<MdAccountCircle size={24} color={gray} />}
          />
          <div className="text-right py-4">
            <Button type="button" onClick={updatePassword}>
              <MdLock color="white" size={26} /> Change Password
            </Button>
            <Button type="submit" className="ml-2">
              <MdSave color="white" size={26} className="mr-1" /> Save
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  )
}
