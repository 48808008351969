import React from 'react'
import { Redirect } from 'react-router-dom'

import { useCurrentUser } from '../common'
import { Role } from '../types'

import SocialPanel from './SocialPanel'
import { VendorPanel } from './VendorPanel'

export const Panel: React.FC = () => {
  const { isAuthenticated, role } = useCurrentUser()
  const { Vendor } = Role

  if (!isAuthenticated) return <Redirect to="/" />

  if (role === Vendor) {
    return <VendorPanel />
  }

  return <SocialPanel />
}
