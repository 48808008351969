import { useLocation } from 'react-router-dom'

import { getInvitationData, getSocialMediaSource } from '../../../common'

export const useSource = (): {
  getSource: () => string
} => {
  const location = useLocation()

  const getSource = () => {
    const invitationData = getInvitationData()
    if (invitationData?.id) {
      // if this data exists, it means that the user is coming from an invitation
      // no matter the page, we will return the invitation info as source
      return `${location.pathname} - Invitation for event ${invitationData.id} -> ${invitationData.name}`
    }

    // social media source
    const social = getSocialMediaSource()
    if (social) {
      // we could just add the social media source to the path, but in case we need to track it separately or add more logic, we can do it here
      return `${location.pathname} - From ${social}`
    }

    return location.pathname
  }

  return { getSource }
}
