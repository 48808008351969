import React, { useState, useEffect } from 'react'
import toInteger from 'lodash/fp/toInteger'
import moment from 'moment'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import FormLabel from 'react-bootstrap/FormLabel'
import FormGroup from 'react-bootstrap/FormGroup'
import { MdSync } from 'react-icons/md'

import { EventComponent, PackageOffering } from '../../../../types'
import { SinglePicker, SimpleTooltip } from '../../../../components'
import { salmon } from '../../../../styles/themeColors'

import { CommonEventData } from './CommonEventData'
import { EventBuilderCard } from './style'
import {
  useCancelEventComponent,
  useUpdateEventComponent,
  parseGuestPays,
} from './eventBuilderFunctions'

export const EventBuilderEventCard: React.FC<{
  eventCardData: EventComponent
  eventId: string
}> = ({ eventCardData, eventId }) => {
  const offering = eventCardData.offering as PackageOffering
  const [guestPays, setGuestPays] = useState('0')
  const [guests, setGuests] = useState(0)
  const [eventDate, setEventdate] = useState('')
  const [handleUpdateEventComponent, loadingUpdate] = useUpdateEventComponent()
  const [handleCancelEventComponent, loadingCancel] = useCancelEventComponent(
    eventCardData.id,
    eventId
  )

  useEffect(() => {
    setGuests(eventCardData.capacity)
    setEventdate(eventCardData.date)
    setGuestPays(parseGuestPays(eventCardData.guestPays))
  }, [eventCardData.capacity, eventCardData.date, eventCardData.guestPays])

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = toInteger(ev.currentTarget.value)
    const minimum = offering.package.minimumGuests || 1
    if (value >= minimum && value <= offering.quantity) {
      setGuests(value)
    }
  }

  const handleDateChange = (date: moment.Moment) => {
    setEventdate(date.format('YYYY-MM-DD 00:00:00'))
  }

  const updateEventComponent = () => {
    const variables = {
      id: eventCardData.id,
      input: {
        capacity: guests,
        duration: 1,
        date: eventDate,
        guestPays: toInteger(guestPays) / 100, // because it should be a number between 0 and 1
      },
    }
    handleUpdateEventComponent(variables)
  }

  const cancelBooking = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    handleCancelEventComponent()
  }

  return (
    <EventBuilderCard className="EventBuilderCard">
      <CommonEventData eventCardData={eventCardData} guests={guests}>
        <div className="eventcard__booking">
          <div className="w-100 pt-3">
            <FormGroup controlId="guestPaysRange" className="m-0">
              <FormLabel className="m-0 position-relative pr-3">
                Guests Pay: {guestPays}%
                <SimpleTooltip
                  id="GuestPaysRange-tooltip"
                  className="top-right"
                  text="Need to discount some or all of your guests rooms? Select the percentage here and you pay the rest"
                />
              </FormLabel>
              <FormControl
                type="range"
                value={guestPays}
                onChange={e => setGuestPays(e.target.value)}
              />
            </FormGroup>
          </div>
          <div>
            <span className="d-block">Guests:</span>
            <input
              className="input-capacity"
              onChange={handleChange}
              type="number"
              value={guests}
              max={offering.quantity}
              min={offering.package.minimumGuests || 1}
            />
          </div>
          <div>
            <span className="d-block">Event date:</span>
            <SinglePicker
              defaultDate={moment(eventCardData.date)}
              emitDate={handleDateChange}
            />
          </div>
          <Button
            variant="outline-primary"
            onClick={updateEventComponent}
            disabled={
              loadingUpdate || guests > offering.quantity || loadingCancel
            }
            className="ml-sm-3 ml-1 mt-sm-4 mt-1 update-button"
          >
            <MdSync size={20} className="svg-top1" color={salmon} />
            {loadingUpdate ? 'Updating...' : ' Update'}
          </Button>
        </div>
        <a className="cancel-booking" href="!#" onClick={cancelBooking}>
          cancel booking
        </a>
      </CommonEventData>
    </EventBuilderCard>
  )
}
