import React, { useState, useEffect } from 'react'
import moment, { Moment } from 'moment'
import find from 'lodash/fp/find'
import get from 'lodash/fp/get'
import Button from 'react-bootstrap/Button'

import { DisplayRoomBlockDates, RangePicker } from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  addExtraNight,
  removeExtraNight,
  extraNightWasUpdated,
  setCustomBookingRange,
} from '../../../features/bookingSlice'
import { Booking, OfferingAddOnOptions } from '../../../types'

import { getMaxExtraNight } from './bookingFunctions'

const getDaysDiff = (d1?: Moment, d2?: Moment) => {
  const diff = d1?.startOf('day').diff(d2?.startOf('day'), 'days') ?? 0
  if (diff < 0) return 0
  return diff
}

const prepareBookingDate = (sd?: Moment, option?: OfferingAddOnOptions) => {
  // sd = Start Date
  if (!sd) return null

  const quantity = option?.quantity ?? 0
  return moment(sd)
    .startOf('day')
    .add(quantity, 'days')
    .format('YYYY-MM-DD 00:00:00')
}

const prepareBookingDuration = (
  sd?: Moment,
  ed?: Moment,
  optionBefore?: OfferingAddOnOptions,
  optionAfter?: OfferingAddOnOptions
) => {
  // sd = Start Date ed = End Date
  if (!sd || !ed) return null

  const quantityBefore = optionBefore?.quantity ?? 0
  const quantityAfter = optionAfter?.quantity ?? 0
  return (
    ed.startOf('day').diff(sd.startOf('day'), 'days') +
    1 -
    quantityBefore -
    quantityAfter
  )
}

const getOfferingAddOn = (extraNights: OfferingAddOnOptions[], id?: string) =>
  find(el => el.offeringAddOnId === id, extraNights)

export const ExtraNights: React.FC<{ booking: Booking }> = ({ booking }) => {
  const dispatch = useAppDispatch()
  const {
    availableAddons: { nightsAfterAddon, nightsBeforeAddon },
    extraNights = [],
    extraNightsUpdated = false,
    customBookingDate,
    customBookingDuration,
    renderHelper,
  } = useAppSelector(state => state.booking)

  const selectedBefore = getOfferingAddOn(extraNights, nightsBeforeAddon?.id)
  const selectedAfter = getOfferingAddOn(extraNights, nightsAfterAddon?.id)

  const { eventComponent: ec } = booking
  const date = customBookingDate ?? booking.date ?? ec.date
  const duration = customBookingDuration ?? booking.duration ?? ec.duration
  const [calendarStartDate, setStart] = useState<Moment>()
  const [calendarEndDate, setEnd] = useState<Moment>()

  const initialStartDate = moment(date, 'YYYY-MM-DD')
  if (selectedBefore) {
    initialStartDate.subtract(selectedBefore.quantity ?? 0, 'days')
  }
  const initialEndDate = moment(date, 'YYYY-MM-DD').add(duration - 1, 'days')
  if (selectedAfter) {
    initialEndDate.add(selectedAfter.quantity ?? 0, 'days')
  }

  useEffect(() => {
    if (!calendarStartDate && !calendarEndDate && renderHelper > 0) {
      setStart(initialStartDate)
      setEnd(initialEndDate)
    }
  }, [
    calendarEndDate,
    calendarStartDate,
    initialEndDate,
    initialStartDate,
    renderHelper,
  ])

  const handleStartDate = (startDate: Moment | null) => {
    if (!startDate) return
    setStart(startDate)
    dispatch(extraNightWasUpdated(true))
  }

  const handleEndDate = (endDate: Moment | null) => {
    if (!endDate) return
    setEnd(endDate)
    dispatch(extraNightWasUpdated(true))
  }

  const removeExtraNights = (option: OfferingAddOnOptions) => {
    dispatch(
      removeExtraNight({
        option,
        bookingAddOnId: get('bookingAddOnId', option),
        // Im using "get" because OfferingAddOnOptions has no bookingAddOnId, thats another type I use in the slice "OfferingAddOnOptionsWithType"
      })
    )
  }

  const handleUpdate = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (calendarStartDate?.isSameOrAfter(calendarEndDate)) return

    const daysBefore = getDaysDiff(moment(ec.date), calendarStartDate)
    const daysAfter = getDaysDiff(
      calendarEndDate,
      moment(ec.date).add(ec.duration - 1, 'days')
    )
    const optionBefore = find(
      el => el.quantity === daysBefore,
      nightsBeforeAddon?.offeringAddOnOptions
    )
    const optionAfter = find(
      el => el.quantity === daysAfter,
      nightsAfterAddon?.offeringAddOnOptions
    )
    dispatch(
      addExtraNight({
        option: optionBefore,
        existing: nightsBeforeAddon?.id,
        type: 'before',
      })
    )
    dispatch(
      addExtraNight({
        option: optionAfter,
        existing: nightsAfterAddon?.id,
        type: 'after',
      })
    )

    dispatch(
      setCustomBookingRange({
        date: prepareBookingDate(calendarStartDate, optionBefore),
        duration: prepareBookingDuration(
          calendarStartDate,
          calendarEndDate,
          optionBefore,
          optionAfter
        ),
      })
    )
    dispatch(extraNightWasUpdated(false))
  }

  const { offering } = ec
  const { provider, addOns } = offering
  const extraNightsBefore = getMaxExtraNight({
    type: 'extra_nights_before',
    addOns,
  })
  const extraNightsAfter = getMaxExtraNight({
    type: 'extra_nights_after',
    addOns,
  })
  const minimum = provider?.bookAllRequired
    ? 0
    : extraNightsBefore?.quantity ?? 0
  const maximum = provider?.bookAllRequired
    ? 0
    : extraNightsAfter?.quantity ?? 0
  return (
    <>
      <div
        data-cy="extra-nights-picker"
        className="d-flex align-items-end flex-wrap"
      >
        <DisplayRoomBlockDates
          minimumDaysBefore={minimum}
          maximumDaysAfter={maximum}
          checkin={ec.date}
          checkout={ec?.date}
          duration={ec.duration}
          checkInOut
          small
        />
        <span className="w-100 fz-12 tc-lgray pb-2">
          Dark pink = Group room block. Light pink = Extended room block rates.
        </span>
        <RangePicker
          key={`key-${selectedBefore?.id}-${selectedAfter?.id}`}
          id="check-in-out-booking"
          style={{ width: '100%', maxWidth: '500px' }}
          emitStartDate={handleStartDate}
          emitEndDate={handleEndDate}
          initStartDate={initialStartDate}
          initEndDate={initialEndDate}
          minDate={moment(ec.date, 'YYYY-MM-DD').subtract(minimum, 'days')}
          maxDate={moment(ec.date, 'YYYY-MM-DD').add(
            ec.duration + maximum,
            'days'
          )}
        />
        <Button
          style={{ width: '100px' }}
          onClick={handleUpdate}
          disabled={!extraNightsUpdated}
          className="mt-3 mt-lg-0"
        >
          Update
        </Button>
      </div>
      {extraNightsUpdated && (
        <div className="m-0 pt-1 px-1 text-danger fz-12">* unsaved changes</div>
      )}
      {calendarStartDate?.isSameOrAfter(calendarEndDate) && (
        <p className="m-0 pt-1 px-1 text-danger fz-12">Invalid date range</p>
      )}

      <ul className="pl-4 mb-0 mt-3">
        {selectedBefore && (
          <li>
            {selectedBefore.label} - {selectedBefore.description}
            <span
              onClick={() => removeExtraNights(selectedBefore)}
              className="text-danger fz-12 cursor under mx-3"
            >
              remove
            </span>
          </li>
        )}
        {selectedAfter && (
          <li>
            {selectedAfter.label} - {selectedAfter.description}
            <span
              onClick={() => removeExtraNights(selectedAfter)}
              className="text-danger fz-12 cursor under mx-3"
            >
              remove
            </span>
          </li>
        )}
      </ul>
    </>
  )
}
