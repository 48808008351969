import React, { useState } from 'react'

import { getImages } from '../../../common'
import { offeringTypes } from '../../../common/constants'
import { FullSlider, LocationOverview, RoomDetails } from '../../../components'
import {
  ILocationMetadata,
  Image,
  Offering,
  RoomOffering,
} from '../../../types'

export const BookingImages: React.FC<{ offering: Offering }> = ({
  offering,
}) => {
  const [current, setCurrent] = useState(0)
  const images: Image[] = offering?.images || []
  const imagesArray = getImages(images, '800x450')

  const metadata: ILocationMetadata = images[current]?.metadata
  const { roomOffering } = offeringTypes

  return (
    <div className="position-relative" style={{ maxWidth: '800px' }}>
      {imagesArray.length > 1 && (
        <FullSlider small images={imagesArray} afterChange={setCurrent} />
      )}
      {images.length === 1 && (
        <img src={imagesArray[0]} alt={offering.name} className="img-fluid" />
      )}

      <div className="location-overview-container">
        {offering.__typename === roomOffering ? (
          <RoomDetails
            data={offering as RoomOffering}
            className="panel-style"
            whiteIcon
          />
        ) : (
          <LocationOverview className="panel-style packages" data={metadata} />
        )}
      </div>
    </div>
  )
}
