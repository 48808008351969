import styled from 'styled-components'

import { darkGray, lightGray } from '../../styles/themeColors'

export const SimpleField = styled.div`
  border: 1px solid ${lightGray};
  border-radius: 35px;
  margin: 32px 0 0 0;
  padding: 0.4rem 1rem;
  position: relative;
  & label {
    background-color: white;
    color: ${darkGray};
    left: 20px;
    padding: 0 5px;
    position: absolute;
    top: -12px;
  }
  & input {
    border: none;
    font-size: 1rem;
    padding: 5px 25px 5px 0;
    width: 100%;
    &.is-invalid {
      background-image: unset !important;
    }
    &:focus {
      box-shadow: unset !important;
    }
  }
  & svg {
    position: absolute;
    right: 1rem;
    top: 25%;
  }
  & .form-group {
    margin-bottom: 0px;
  }
  & .invalid-feedback {
    bottom: 0;
    left: 26px;
    position: absolute;
  }
  &.material-style {
    & label {
      font-size: 15px;
      top: 11px;
      transition: all 0.2s ease-in-out;
    }
    &.focus-input {
      & label {
        top: -13px;
        transition: all 0.2s ease-in-out;
      }
    }
  }
`
