import React, { useRef, useEffect } from 'react'
import Slider from 'react-slick'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

import { NavButtonProps, IFullSlider } from '../../types'
import '../../styles/react-slick.css'
import '../../styles/slider-knt.sass'

import { SliderContainer } from './style'

const NextArrow = ({ className, onClick }: NavButtonProps) => (
  <MdKeyboardArrowRight
    size={100}
    color="white"
    className={className}
    onClick={onClick}
  />
)
const PrevArrow = ({ className, onClick }: NavButtonProps) => (
  <MdKeyboardArrowLeft
    size={100}
    color="white"
    className={className}
    onClick={onClick}
  />
)

export const FullSlider: React.FC<IFullSlider> = React.memo(
  ({ images, small = false, afterChange, current, panel }) => {
    const sliderRef = useRef<Slider>(null)
    const settings = {
      className: 'center slider-knt',
      centerMode: true,
      infinite: true,
      centerPadding: small ? '100px' : '240px',
      slidesToShow: 1,
      speed: 500,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 991,
          settings: { centerPadding: '50px' },
        },
        {
          breakpoint: 650,
          settings: { centerPadding: '0px' },
        },
      ],
      afterChange,
    }

    useEffect(() => {
      if (sliderRef.current && current) {
        sliderRef.current.slickGoTo(current, true)
      }
    }, [current])

    return (
      <SliderContainer>
        <Slider {...settings} ref={sliderRef}>
          {images.map(el => (
            <div key={el}>
              <div
                className={`slide ${small ? 'small' : ''}`}
                style={{ backgroundImage: `url(${el})` }}
              />
            </div>
          ))}
        </Slider>
        {panel}
      </SliderContainer>
    )
  }
)
