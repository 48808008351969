import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { MdCancel } from 'react-icons/md'
import { useMutation } from '@apollo/react-hooks'
import TextareaAutosize from 'react-textarea-autosize'

import { replaceLineBreaksWithBR } from '../../../common'
import { UPDATE_COMMENT } from '../../../graphql'
import { Comment } from '../../../types'
import { darkGray } from '../../../styles/themeColors'

import { CommentOptions } from './CommentOptions'
import { FormEditComment } from './style'

interface IDisplayComment {
  singleComment: Comment
  currentUser: string
  postId: string
  preview: boolean
}

export const DisplayComment: React.FC<IDisplayComment> = ({
  singleComment,
  currentUser,
  postId,
  preview,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [editComment, setEditComment] = useState(false)
  const [comment, setComment] = useState(singleComment.body)
  const [updateSingleComment, { loading: loadingEdit }] =
    useMutation(UPDATE_COMMENT)

  useEffect(() => {
    setComment(singleComment.body) // force refresh of body property
  }, [singleComment.body, setComment])

  const reset = () => {
    setComment(singleComment.body) // reset comment
    setEditComment(false)
  }

  const handleOnChange = (ev: React.FormEvent<HTMLTextAreaElement>) => {
    setComment(ev.currentTarget.value)
  }
  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Escape') {
      reset()
    } else if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault()
      if (!loadingEdit) {
        const variables = { id: singleComment.id, input: { body: comment } }
        updateSingleComment({ variables }).then(({ data }) => {
          if (data.updateComment.errors.length > 0) {
            setShowDialog(true)
            reset()
          } else {
            setEditComment(false)
          }
        })
      }
    }
  }

  return (
    <div className="m-0 fz-14 content-comment">
      <Modal centered show={showDialog} onHide={() => setShowDialog(false)}>
        <Modal.Body className="text-center fwsb tc-error w-100">
          It was not possible to update your comment, try again.
        </Modal.Body>
      </Modal>
      <strong className="fwsb fz-13">
        {`${singleComment.user.firstName} ${singleComment.user.lastName} `}{' '}
      </strong>

      {editComment && (
        <FormEditComment>
          <div className="input-edit">
            <TextareaAutosize
              id={`editing-${singleComment.id}`}
              placeholder="Editing comment..."
              disabled={loadingEdit}
              name={`editing-comment-${singleComment.id}`}
              onChange={handleOnChange}
              value={comment}
              onKeyDown={handleKeyDown}
            />
          </div>
          {!loadingEdit && (
            <span className="cancel" onClick={() => reset()}>
              <MdCancel size={10} color={darkGray} />
            </span>
          )}
        </FormEditComment>
      )}

      {!editComment && replaceLineBreaksWithBR(singleComment.body)}

      {currentUser === singleComment.user.id && (
        <CommentOptions
          postId={postId}
          setEditComment={setEditComment}
          comment={singleComment}
          preview={preview}
        />
      )}
    </div>
  )
}
