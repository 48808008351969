import React from 'react'
import get from 'lodash/fp/get'
import find from 'lodash/fp/find'
import trim from 'lodash/fp/trim'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { Article, Image } from '../../types'
import { GET_BLOG_POST } from '../../graphql'
import { useMoveToTop, getImageUrl } from '../../common'

import { UnderlineLink, PostInfo } from './style'

import { BlogTags, BlogShare, BlogPhotoGrid } from '.'

export const BlogPhoto: React.FC = () => {
  useMoveToTop()
  const { postUrl, id } = useParams<{ postUrl: string; id: string }>()
  const { data } = useQuery(GET_BLOG_POST, { variables: { url: postUrl } })

  const post: Article = get('blog.entries[0]', data)
  const title = get('title', post)
  const images = get('images', post)
  const image = find({ id }, images) as Image
  const tags = ['MODERN', 'RUSTIC', 'MANSION+ESTATE']
  const altText = get('metadata.alt', image)
  const caption = get('metadata.caption', image)

  if (!post) {
    return <></>
  }
  return (
    <>
      <Helmet>
        <title>{title} - Kiss & Tell</title>
        <meta name="description" content={`${title} - ${caption}`} />
        <link
          rel="canonical"
          href={`https://kissandtell.com/blog/photo/${postUrl}/${id}`}
        />
      </Helmet>
      <UnderlineLink className="mb-0">
        <h1 className="fwl mt-4">{caption || title}</h1>
        <span className="line" />
      </UnderlineLink>
      <BlogTags tags={tags} padding="pb-4 pt-1" />

      <section
        key={id}
        className="main-photo mx-auto mb-4 mt-1 text-center"
        style={{ maxWidth: '1100px' }}
      >
        <img
          onLoad={ev => {
            const img = ev.currentTarget
            const aspectRatio = img.naturalWidth / img.naturalHeight
            if (aspectRatio < 1) {
              img.classList.add('photo-details-vertical')
            }
          }}
          className="img-fluid"
          src={getImageUrl(image, '900xauto')}
          alt={altText || caption}
        />
      </section>

      <aside className="photo-details">
        <PostInfo className="shadow-sm">
          {post.credits.map(el => (
            <p key={el.articleId}>
              <span>{`${trim(el.service)}:`}</span>
              <span>{trim(el.business)}</span>
            </p>
          ))}
        </PostInfo>
      </aside>
      <BlogTags tags={tags} />

      <BlogShare
        tags={tags}
        shareTitle="Bridal Hair and Makeup"
        shareImg={getImageUrl(image, '1000x1500')}
      />

      <section className="bg-white album-post py-4 text-center mt-4">
        <UnderlineLink>
          <h2 className="fwsb fz-14">MORE FROM THIS ALBUM</h2>
          <span className="line" />
          <span>
            From <Link to={`/blog/${post.url}`}>{post.title}</Link>
          </span>
        </UnderlineLink>

        <Container className="px-5">
          <BlogPhotoGrid list={images} id={postUrl} />
        </Container>
      </section>
    </>
  )
}
