import gql from 'graphql-tag'

export const IMAGE_FIELDS_FRAG = gql`
  fragment ImageFields on Image {
    assocId
    assocType
    ext
    id
    metadata
  }
`

export const PACKAGE_ADDONS_FIELDS = gql`
  fragment PackageAddonsFields on PackageAddons {
    location
    time
    id
    eventComponentId
    offeringAddOn {
      id
      label
      description
      images {
        ...ImageFields
      }
    }
    offeringAddOnOption {
      id
      label
      description
    }
  }
  ${IMAGE_FIELDS_FRAG}
`
