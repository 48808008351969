import React from 'react'
import moment from 'moment'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import set from 'lodash/fp/set'
import sum from 'lodash/fp/sum'
import find from 'lodash/fp/find'
import round from 'lodash/round'
import trim from 'lodash/fp/trim'
import filter from 'lodash/fp/filter'
import concat from 'lodash/fp/concat'
import divide from 'lodash/fp/divide'
import orderBy from 'lodash/fp/orderBy'
import toLower from 'lodash/fp/toLower'
import countBy from 'lodash/fp/countBy'
import replace from 'lodash/fp/replace'
import toNumber from 'lodash/fp/toNumber'
import multiply from 'lodash/fp/multiply'
import remove from 'lodash/fp/remove'
import reduce from 'lodash/fp/reduce'
import Swal from 'sweetalert2'
import { useMutation } from '@apollo/react-hooks'
import { MdCheckCircle, MdOutlinePending, MdCancel } from 'react-icons/md'

import { addonMetadata, offeringTypes } from '../../../common/constants'
import {
  confirmAlert,
  loaderAlert,
  errorAlert,
  getCheckInOut,
  formatDate,
} from '../../../common'
import {
  GuestBookingInput,
  GetBookingInfoQuery,
  Maybe,
  BookingAddOn,
  IcurrentAddons,
  OfferingAddOns,
  ICategorizedAddons,
  Booking,
  OfferingAddOnOptions,
  IFreeBookingGuest,
  IBookingGuest,
  BookingStatus,
  Offering,
  EventComponent,
  BookingGuest,
} from '../../../types'
import {
  ADD_BOOKING_ADDON,
  REMOVE_BOOKING_ADDON,
  GET_BOOKING_DATA,
} from '../../../graphql'
import { useAppSelector } from '../../../hooks'
import { green, red, yellow } from '../../../styles/themeColors'
import { FormatNumber, SimpleTooltip } from '../../../components'

interface IcreateBookingAddon {
  bookingId: string
  guestBookingInput?: GuestBookingInput
  offeringAddOnId: string
  offeringAddOnOptionId?: string
}
export const useCreateBookingAddon = (
  bookingId: string
): [
  (variables: IcreateBookingAddon, callback?: () => void) => void,
  boolean
] => {
  const [addBookingAddOn, { loading }] = useMutation(ADD_BOOKING_ADDON, {
    update(cache, { data: { addAddonToBooking } }) {
      if (addAddonToBooking.errors.length === 0) {
        const cached: Maybe<GetBookingInfoQuery> = cache.readQuery({
          query: GET_BOOKING_DATA,
          variables: { id: bookingId },
        })
        if (cached) {
          const bookingAddOns: BookingAddOn[] =
            get('booking.bookingAddOn', cached) || []
          cache.writeQuery({
            query: GET_BOOKING_DATA,
            data: set(
              'booking.bookingAddOn',
              [...bookingAddOns, addAddonToBooking.result],
              cached
            ),
            variables: { id: bookingId },
          })
        }
      }
    },
  })

  const createBookingAddon = (
    variables: IcreateBookingAddon,
    callback?: () => void
  ): void => {
    loaderAlert({ html: 'saving data...' })
    addBookingAddOn({ variables })
      .then(({ data }) => {
        Swal.close()

        if (data.addAddonToBooking.errors.length > 0) {
          errorAlert(
            data.addAddonToBooking.errors,
            'There was a problem adding this item'
          )
        } else if (callback) {
          callback()
        }
      })
      .catch(() =>
        errorAlert(
          [],
          'There was a problem adding this item. <br />Please try again or contact technical support'
        )
      )
  }

  return [createBookingAddon, loading]
}

interface IdeleteBookingAddon {
  bookingId: string
  bookingAddonId: string
}
export const useDeleteBookingAddon = (
  bookingId: string
): [(variables: IdeleteBookingAddon, text: string) => void, boolean] => {
  const [removeBookingAddOn, { loading }] = useMutation(REMOVE_BOOKING_ADDON, {
    update(cache, { data: { removeAddonFromBooking } }) {
      if (removeAddonFromBooking.errors.length === 0) {
        const cached: Maybe<GetBookingInfoQuery> = cache.readQuery({
          query: GET_BOOKING_DATA,
          variables: { id: bookingId },
        })
        if (cached) {
          const bookingAddOns: BookingAddOn[] =
            get('booking.bookingAddOn', cached) || []
          cache.writeQuery({
            query: GET_BOOKING_DATA,
            data: set(
              'booking.bookingAddOn',
              remove({ id: removeAddonFromBooking.result.id }, bookingAddOns),
              cached
            ),
            variables: { id: bookingId },
          })
        }
      }
    },
  })

  const deleteBookingAddon = (
    variables: IdeleteBookingAddon,
    text: string
  ): void => {
    confirmAlert({
      html: text,
      icon: 'question',
    }).then(resp => {
      if (resp.value) {
        loaderAlert({ html: 'deleting data...' })
        removeBookingAddOn({ variables }).then(({ data }) => {
          Swal.close()

          if (data.removeAddonFromBooking.errors.length > 0) {
            errorAlert(
              data.removeAddonFromBooking.errors,
              'There was a problem deleting this item'
            )
          }
        })
      }
    })
  }

  return [deleteBookingAddon, loading]
}

export const categorizeOfferingAddons = (
  addOns: OfferingAddOns[]
): ICategorizedAddons =>
  reduce(
    (result: ICategorizedAddons, value) => {
      const resp = result // to not reassign
      const subtype = value.metadata?.subtype
      const { guest, kid, freeGuest, nightsAfter, nightsBefore } = addonMetadata

      // TODO: make an object combining both addons keeping in mind the possibility of having more than 2 addons in the future
      if (subtype === kid) resp.extraKidAddon = value
      if (subtype === guest) resp.extraGuestAddon = value

      if (subtype === freeGuest) resp.extraFreeGuestAddon = value

      if (subtype === nightsBefore) resp.nightsBeforeAddon = value
      if (subtype === nightsAfter) resp.nightsAfterAddon = value

      return resp
    },
    {
      extraGuestAddon: undefined,
      extraKidAddon: undefined,
      extraFreeGuestAddon: undefined,
      nightsBeforeAddon: undefined,
      nightsAfterAddon: undefined,
    },
    addOns
  )

export const groupAddons = ({
  addonsList,
  extraGuestAddon,
  extraKidAddon,
  extraFreeGuestAddon,
  nightsBeforeAddon,
  nightsAfterAddon,
}: {
  addonsList: BookingAddOn[]
  extraGuestAddon?: OfferingAddOns
  extraKidAddon?: OfferingAddOns
  extraFreeGuestAddon?: OfferingAddOns
  nightsBeforeAddon?: OfferingAddOns
  nightsAfterAddon?: OfferingAddOns
}): IcurrentAddons =>
  reduce(
    (result, value) => {
      const resp = result // to not reassign
      if (
        value.offeringAddOn.id === extraGuestAddon?.id ||
        value.offeringAddOn.id === extraKidAddon?.id
      ) {
        resp.paidGuests.push(value)
        return resp
      }
      if (value.offeringAddOn.id === extraFreeGuestAddon?.id) {
        resp.freeGuests.push(value)
        return resp
      }
      if (value.offeringAddOn.id === nightsBeforeAddon?.id) {
        resp.extraNightsBefore = value
        return resp
      }
      if (value.offeringAddOn.id === nightsAfterAddon?.id) {
        resp.extraNightsAfter = value
        return resp
      }

      if (value.offeringAddOn.taxable) {
        resp.taxables.push(value)
        return resp
      }
      resp.noTaxables.push(value)
      return resp
    },
    {
      noTaxables: [] as BookingAddOn[],
      taxables: [] as BookingAddOn[],
      paidGuests: [] as BookingAddOn[],
      freeGuests: [] as BookingAddOn[],
      extraNightsBefore: undefined as BookingAddOn | undefined,
      extraNightsAfter: undefined as BookingAddOn | undefined,
    },
    addonsList
  )

interface IBookingTotals {
  basePrice: number
  tax: number
  total: number
  offeringPrice: number
}
export const useCalculateBookingTotals = (
  booking: Booking,
  duration: number,
  currentAddons: IcurrentAddons
): IBookingTotals => {
  const {
    extraNights = [],
    freeGuests = [],
    paidGuests = [],
    customBookingDuration,
    availableAddons: { nightsAfterAddon, nightsBeforeAddon },
  } = useAppSelector(state => state.booking)
  const { noTaxables, taxables } = currentAddons
  const { offering } = booking.eventComponent
  const isRoomBooking = offering.__typename === offeringTypes.roomOffering

  const calculateBase = (acc: IBookingTotals) => {
    if (isRoomBooking) {
      const { eventComponent: ec } = booking
      const bookingDuration = booking.duration || ec.duration
      const nights = customBookingDuration
        ? customBookingDuration - 1
        : bookingDuration

      // (duration * capacity * offering price) -> sum of all items price -> divide by capacity -> multiply by guestPays field
      const initial = flow(
        multiply(ec.capacity),
        multiply(toNumber(ec.offering.price)),
        (val: number) =>
          sum([
            val,
            ec.items
              .filter(el => !el.deleted && !el.master)
              .reduce((prev, el) => prev + toNumber(el.price), 0),
          ]),
        (val: number) => divide(val, ec.capacity),
        multiply(toNumber(ec.guestPays))
      )(nights)

      return {
        ...acc,
        basePrice: initial,
        tax: 0,
        total: initial,
        offeringPrice: initial,
      }
    }

    const initial = toNumber(booking.guestPrice.offeringPrice)
    return {
      ...acc,
      basePrice: initial,
      tax: 0,
      total: initial,
      offeringPrice: initial,
    }
  }

  const calculateExtraNights = (acc: IBookingTotals) => {
    if (isRoomBooking) {
      const totalExtraNight = reduce(
        (result: number, en: OfferingAddOnOptions) => {
          // en = extra night
          const addons = [nightsAfterAddon, nightsBeforeAddon]
          const addon = addons.filter(a => a?.id === en.offeringAddOnId)[0]
          return en.price
            ? toNumber(en.price) + result
            : multiply(
                en.quantity,
                toNumber(addon?.price || booking.eventComponent.offering.price)
              ) + result
        }
      )(0)(extraNights)
      return {
        ...acc,
        basePrice: acc.basePrice + totalExtraNight,
        total: acc.total + totalExtraNight,
      }
    }
    return acc
  }

  const calculateFreeGuests = (acc: IBookingTotals) => {
    const totalFreeGuest = reduce(
      (result: number, value: IFreeBookingGuest) =>
        multiply(duration, toNumber(value.addon.price)) + result
    )(0)(freeGuests)
    return {
      ...acc,
      basePrice: acc.basePrice + totalFreeGuest,
      total: acc.total + totalFreeGuest,
    }
  }

  const calculatePaidGuests = (acc: IBookingTotals) => {
    const totalPaidGuest = reduce(
      (result: number, value: IBookingGuest) =>
        multiply(duration, toNumber(value.option.price)) + result
    )(0)(paidGuests)
    return {
      ...acc,
      basePrice: acc.basePrice + totalPaidGuest,
      total: acc.total + totalPaidGuest,
    }
  }

  const calculateTaxables = (acc: IBookingTotals) => {
    const totalTaxable = reduce((result: number, value: BookingAddOn) => {
      const { format } = value.offeringAddOn
      const price = flow(
        (ba: BookingAddOn) =>
          get('offeringAddOnOption.price', ba) ||
          get('offeringAddOn.price', ba),
        toNumber,
        (p: number) =>
          value.offeringAddOn.frequency === 'DAILY' ? multiply(p, duration) : p
      )(value)
      if (format === 'FLAT') {
        return price + result
      }
      return sum([result, multiply(acc.basePrice, price)])
    })(0)(taxables)
    return {
      ...acc,
      total: acc.total + totalTaxable,
    }
  }

  const calculateTax = (acc: IBookingTotals) => {
    const { venueTaxRate, accomodationTaxRate } = offering.provider
    const taxRate = toNumber(isRoomBooking ? accomodationTaxRate : venueTaxRate)
    const tax = multiply(acc.total, taxRate)
    return {
      ...acc,
      tax,
      total: acc.total + tax,
    }
  }

  const calculateNoTaxables = (acc: IBookingTotals) => {
    const totalNoTaxable = reduce((result: number, value: BookingAddOn) => {
      const { format } = value.offeringAddOn
      const price = toNumber(
        value.offeringAddOnOption?.price || value.offeringAddOn?.price
      )
      if (format === 'FLAT') {
        return price + result
      }
      return sum([result, multiply(acc.basePrice, price)])
    })(0)(noTaxables)
    return {
      ...acc,
      total: acc.total + totalNoTaxable,
    }
  }

  const initialTotals: IBookingTotals = {
    basePrice: 0,
    tax: 0,
    total: 0,
    offeringPrice: 0,
  }
  const calculatedTotals = reduce(
    (
      acc: IBookingTotals,
      fn: (accumulator: IBookingTotals) => IBookingTotals
    ) => fn(acc),
    initialTotals
  )([
    calculateBase,
    calculateExtraNights,
    calculateFreeGuests,
    calculatePaidGuests,
    calculateTaxables,
    calculateTax,
    calculateNoTaxables,
  ])

  return { ...calculatedTotals }
}

export const statusIcon = (status: BookingStatus, size = 26): JSX.Element => {
  if (status === BookingStatus.Booked) {
    return (
      <MdCheckCircle
        size={size}
        color={green}
        className="position-relative"
        style={{ top: '-3px' }}
      />
    )
  }
  if (status === BookingStatus.Created) {
    return (
      <MdOutlinePending
        size={size}
        color={yellow}
        className="position-relative"
        style={{ top: '-3px' }}
      />
    )
  }

  return (
    <MdCancel
      size={size}
      color={red}
      className="position-relative"
      style={{ top: '-3px' }}
    />
  )
}

export const statusText = (
  offering: Offering,
  status: BookingStatus
): string => {
  const { packageOffering } = offeringTypes
  const offeringType = offering.__typename

  if (offeringType === packageOffering && status === BookingStatus.Booked)
    return 'Attending'
  if (status === BookingStatus.Created) return 'Payment Processing'

  return status
}

export const extraGuests = ({
  guest,
  addon,
  hideFrequency = false,
  duration,
}: {
  guest: IBookingGuest
  addon?: OfferingAddOns
  hideFrequency?: boolean
  duration?: number
}): React.ReactElement => {
  const { option, id } = guest
  const { label } = option
  const price = toNumber(option.price)
  const frequency = addon?.frequency
  const total = multiply(duration ?? 1, price)
  return (
    <>
      <tr className="fz-13" key={`breakdown-${id}`}>
        <td>
          <span className="fz-12 pl-2">{label}</span>
        </td>
        <td className="pl-3">
          <FormatNumber n={total} />
        </td>
      </tr>
      {!hideFrequency && frequency === 'DAILY' && price > 0 && duration && (
        <tr className="fz-13" key={`breakdown-${id}`}>
          <td>
            <span className="fz-12 pl-4">Nightly</span>
          </td>
          <td className="pl-4">
            <FormatNumber n={price} />
          </td>
        </tr>
      )}
    </>
  )
}

export const extraFreeGuests = (
  guest: IFreeBookingGuest,
  hideFrequency = false
): JSX.Element => {
  const { addon, id } = guest
  const { frequency, label } = addon
  const price = toNumber(addon.price)
  return (
    <tr className="fz-13" key={`breakdown-${id}`}>
      <td>
        <span className="fz-12 pl-2">{label}</span>
      </td>
      <td className="pl-3">
        <FormatNumber n={price} />
        {!hideFrequency && frequency === 'DAILY' && price > 0 && (
          <span className="tc-lgray fz-11 pl-2">nightly</span>
        )}
      </td>
    </tr>
  )
}

export const extraAddons = (
  addon: BookingAddOn,
  basePrice: number
): JSX.Element => {
  const { offeringAddOn, offeringAddOnOption, id } = addon
  const { frequency } = offeringAddOn

  const label = offeringAddOnOption?.label ?? offeringAddOn.label
  const price = offeringAddOnOption?.price ?? offeringAddOn.price ?? 0
  return (
    <tr className="fz-13" key={`breakdown-${id}`}>
      <td>{label}:</td>
      <td className="pl-3">
        {offeringAddOn.format === 'FLAT' && <FormatNumber n={price} />}
        {offeringAddOn.format === 'PERCENTAGE' && (
          <>
            <FormatNumber n={price * basePrice} />
            <span className="fz-11 tc-lgray pl-2">
              {offeringAddOn?.price * 100}%
            </span>
          </>
        )}
        {frequency === 'DAILY' && (
          <span className="tc-lgray fz-11"> daily</span>
        )}
      </td>
    </tr>
  )
}

export const guestInfoDisplay = (
  basic: number,
  isRoom: boolean
): JSX.Element => {
  return (
    <span className="fz-12 tc-lgray">
      {basic} {basic === 1 ? 'guest is' : 'guests are'} included
      {isRoom ? ' in the basic room price' : ''}
    </span>
  )
}

export const PackageDefaultCard: React.FC<{ component: EventComponent }> = ({
  component,
}) => (
  <div className="w-100 my-4 py-4 px-3 card">
    <p className="text-primary fwsb fz-18 mb-1">
      {component.offering.provider.name}
    </p>
    <span>
      {getCheckInOut({
        date: component.date,
        dateFormat: 'dddd, MMMM DD, YYYY',
        duration: component.duration,
      })}
    </span>
  </div>
)

export const getOfferingBasicField = (offering: Offering): number =>
  toNumber(
    offering.__typename === offeringTypes.roomOffering
      ? get('room.basic', offering)
      : get('package.basic', offering)
  )

export const getPaidGuestsLimit = (component: EventComponent): number =>
  flow((ec: EventComponent) => {
    const { offering } = ec
    if (offering.__typename === offeringTypes.roomOffering) {
      const occupancy = flow(get('room.occupancy'), toNumber)(offering)
      const basic = flow(get('room.basic'), toNumber)(offering)
      return occupancy - basic
    }

    const capacity = flow(get('capacity'), toNumber)(ec)
    const basic = flow(get('package.basic'), toNumber)(offering)
    const bookingCount = flow(get('bookingCount'), toNumber)(ec)
    return capacity - basic - bookingCount // package capacity - guest included wih the initial price - guests already booked
  })(component)

export const paidGuestsGuideText = ({
  extraGuestAddon,
  extraKidAddon,
  component,
  limit,
}: {
  extraGuestAddon?: OfferingAddOns
  extraKidAddon?: OfferingAddOns
  component: EventComponent
  limit: number
}): string => {
  const { offering, customTexts } = component
  const guideText = (addon?: OfferingAddOns) => {
    if (!addon) return ''
    return `${addon.quantity} ${addon.label}`
  }

  const helper = extraGuestAddon && extraKidAddon ? ' and ' : ''
  const details =
    offering.__typename === offeringTypes.roomOffering && helper !== ''
      ? ` (${guideText(extraGuestAddon)}${helper}${guideText(extraKidAddon)})`
      : ''

  const customIndication = customTexts?.paid_guests_indication
  if (customIndication) {
    return `${replace('#', `${limit}`, customIndication)}${details}`
  }

  return `You can add ${limit} extra guest${limit > 1 ? 's' : ''}${details}`
}

export const getAddonFrequency = ({
  id,
  isRoom,
  addons,
}: {
  id?: string
  isRoom: boolean
  addons: { [s: string]: OfferingAddOns }
}): string => {
  if (!isRoom) return '' // only rooms have frequency
  const frequency = flow(get(`[${id}].frequency`), toLower)(addons)

  if (frequency === 'daily') return ' /night'
  return ` /${frequency}`
}

export const getPaidGuestOptions = ({
  extraGuestAddon,
  extraKidAddon,
  paidGuests,
}: {
  extraGuestAddon?: OfferingAddOns
  extraKidAddon?: OfferingAddOns
  paidGuests: IBookingGuest[]
}): OfferingAddOnOptions[] => {
  // options filter to not show more options than allowed in parameter "quantity"
  const filterAddonOptions = (addon?: OfferingAddOns) => {
    if (!addon) return []

    const count = countBy('option.offeringAddOnId', paidGuests) // count of addons already assigned
    return filter(el => {
      // if the array is empty countBy returns {} so we handle that case with "|| 0"
      const countById = count[el.offeringAddOnId] || 0
      return countById < addon.quantity && countById < el.quantity
    }, addon.offeringAddOnOptions)
  }

  return concat(
    filterAddonOptions(extraGuestAddon),
    filterAddonOptions(extraKidAddon)
  )
}

/**
 * Check if a guest appears in a list of BookingAddOn (guests)
 * @param list BookingAddOn[]
 * @param guestId string
 * @returns boolean
 */
export const isInGuestList = (
  list: BookingAddOn[] = [],
  guestId: string
): boolean => {
  const findGuest = find({ bookingGuest: { guest: { id: guestId } } }, list)
  return !!findGuest
}

/**
 * Get all guests from the current addons with default values to avoid errors
 * @param addons IcurrentAddons
 * @returns BookingAddOn[]
 */
export const getAllGuestsFromAddons = (
  addons: IcurrentAddons
): BookingAddOn[] => {
  if (!addons) return []

  const { paidGuests = [], freeGuests = [] } = addons
  return concat(paidGuests, freeGuests)
}

export const orderBookingGuests = ({
  guests = [],
  currentUserEmail = '',
}: {
  guests: BookingAddOn[]
  currentUserEmail: string
}): BookingAddOn[] => {
  if (guests.length <= 0) return []

  return [...guests].sort((a, b) => {
    const { freeGuest } = addonMetadata
    const typeA = a.offeringAddOn.metadata?.subtype
    const typeB = b.offeringAddOn.metadata?.subtype

    // 1. First, the guest whose email matches currentUserEmail
    if (a.bookingGuest?.email === currentUserEmail) return -1
    if (b.bookingGuest?.email === currentUserEmail) return 1

    // 2. Then, guests with plusone = false
    if (a.bookingGuest?.plusone === false && b.bookingGuest?.plusone !== false)
      return -1
    if (b.bookingGuest?.plusone === false && a.bookingGuest?.plusone !== false)
      return 1

    // 3. Next, free guests
    if (typeA === freeGuest && typeB !== freeGuest) return -1
    if (typeB === freeGuest && typeA !== freeGuest) return 1

    // 4. Finally, if both are of the same type (paid), maintain the original order
    return 0
  })
}

export const renderSelectedExtraNights = ({
  nights = [],
  offering,
  addons = [],
}: {
  nights: OfferingAddOnOptions[]
  offering: Offering
  addons: (OfferingAddOns | undefined)[]
}): React.ReactElement[] =>
  nights.map(el => {
    const addon = addons.filter(a => a?.id === el.offeringAddOnId)[0]
    const rate = addon?.price || offering.price // addon price or else offering price
    const price = el.price || round(el.quantity * toNumber(rate), 2)
    return (
      <tr className="fz-13" key={`breakdown-row-${el.id}`}>
        <td>
          <span className="fz-12 pl-2">
            {`${el.label}`}
            {!el?.price && (
              <SimpleTooltip
                id={`${el.id}-extra-night-desc`}
                className="tooltip-card"
                text={`$${round(rate, 2)} /nightly rate`}
              />
            )}
          </span>
        </td>
        <td className="pl-3">
          <FormatNumber n={price} />
        </td>
      </tr>
    )
  })

export const renderSoftDeadline = ({
  softDeadline,
  containerClassName = 'w-100 pt-3 fz-16 fwb',
  dateClassName = 'text-primary pl-3 fwsb',
  type = 'RSVP',
}: {
  softDeadline?: string
  containerClassName?: string
  dateClassName?: string
  type: string
}): React.ReactElement | null => {
  if (!softDeadline) return null

  const today = moment().startOf('day')
  const deadline = moment(softDeadline).startOf('day')
  const isPassed = today.isAfter(deadline)
  if (isPassed) return null

  return (
    <div className={containerClassName}>
      Complete {type} by:
      <span className={dateClassName}>
        {formatDate(softDeadline, 'ddd, MMMM DD, YYYY')}
      </span>
    </div>
  )
}

export const trimGuestFields = (guest: BookingGuest): BookingGuest => ({
  ...guest,
  firstName: trim(guest.firstName),
  lastName: trim(guest.lastName),
  email: trim(guest.email ?? '') || null,
  phone: trim(guest.phone ?? '') || null,
})

export const getMaxExtraNight = ({
  type,
  addOns,
}: {
  type: (typeof addonMetadata)['nightsBefore' | 'nightsAfter']
  addOns?: OfferingAddOns[]
}): OfferingAddOnOptions | undefined => {
  if (!addOns) return undefined

  const filtered = addOns.find(el => el.metadata?.subtype === type)
  const options = orderBy(
    ['quantity'],
    ['desc'],
    filtered?.offeringAddOnOptions
  )
  if (!options) return undefined
  return options[0]
}

export const getPercentageBreakdown = (rate: number | string): string =>
  flow(
    toNumber,
    multiply(100),
    (n: number) => round(n, 2),
    (n: number) => {
      if (n > 0) return `Tax (${n}%):`
      if (n === 0) return 'Tax (included):'
      return 'Tax:'
    }
  )(rate)
