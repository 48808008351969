// this file contains the functions that are used to interact with the storages (sessionStorage and localStorage)

import { IParnershipTrack } from '../types'

/*
 * General data
 */
const selectedEvent = 'selected_event'
export const saveSelectedEvent = (eventId: string): void =>
  localStorage.setItem(selectedEvent, eventId)
export const getSelectedEvent = (): string | null =>
  localStorage.getItem(selectedEvent)

/*
 * Invitation data from landing page
 */
const invitationData = 'invitation-data'
const tokenEmail = 'invitation-token-email'

export const saveInvitationData = ({
  event,
  tokenPayload,
}: {
  event: Record<string, string | number>
  tokenPayload?: Record<string, unknown>
}): void => {
  localStorage.setItem(invitationData, JSON.stringify(event))
  if (tokenPayload) {
    localStorage.setItem(tokenEmail, tokenPayload.email as string)
  }
}

export const getInvitationTokenEmail = (): string | null =>
  localStorage.getItem(tokenEmail)
export const getInvitationData = (): Record<string, string> =>
  JSON.parse(localStorage.getItem(invitationData) || '{}')

export const deleteInvitationData = (): void => {
  localStorage.removeItem(invitationData)
  localStorage.removeItem(tokenEmail)
}

/*
 * Parnership data is used to track the referrer and the destination of the user
 */
const refId = 'refId'
const refIds = 'refIds'
export const saveRefId = (ref: string): void =>
  sessionStorage.setItem(refId, ref)

export const getRefId = (): string | null => sessionStorage.getItem(refId)

export const getRefIds = (): IParnershipTrack[] =>
  JSON.parse(localStorage.getItem(refIds) || '[]')

export const saveRefIds = (refs: IParnershipTrack[]): void => {
  localStorage.setItem(refIds, JSON.stringify(refs))
}

export const addRefId = (ref: IParnershipTrack): void =>
  saveRefIds([ref, ...getRefIds()])

export const deleteRefIds = (): void => localStorage.removeItem(refIds)

export const deletePartnershipData = (): void => {
  sessionStorage.removeItem(refId)
  localStorage.removeItem(refIds)
}

/*
 * Landing pages capture data modal
 */
const landingPageResponseId = 'landing-page-response-id'
export const saveLandingPageResponseId = (id: string): void =>
  sessionStorage.setItem(landingPageResponseId, id)
export const getLandingPageResponseId = (): string =>
  sessionStorage.getItem(landingPageResponseId) || ''

const calendlyData = 'calendly-data'
export const saveCalendlyData = (data: Record<string, string>): void =>
  sessionStorage.setItem(calendlyData, JSON.stringify(data))
export const getCalendlyData = (): Record<string, string> =>
  JSON.parse(sessionStorage.getItem(calendlyData) || '{}')

/*
 * Source data - social media - emails
 */
const social = 'social-source'
export const saveSocialMediaSource = (source: string): void =>
  localStorage.setItem(social, source)
export const getSocialMediaSource = (): string | null =>
  localStorage.getItem(social)
