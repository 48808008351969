import React, { useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Swal from 'sweetalert2'
import { FaDoorOpen } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { MdPerson, MdClear } from 'react-icons/md'

import { KnTField } from '../../components'
import { useQueryParams, cleanParams, loaderAlert } from '../../common'
import { useForm } from '../../common/alterForms'
import { salmon, gray } from '../../styles/themeColors'
import { SiteLogo } from '../../components/customIcons'
import { makeSearchUrl } from '../../pages/Locations/LocationFunctions'
import { useCreateEvent } from '../../pages/Event/eventFunctions'

interface ISearchVenues {
  show: boolean
  onClose: () => void
  title: string
  create?: boolean
  defaultCard?: boolean
  selectedEvent?: string
}

const SearchVenues: React.FC<ISearchVenues> = ({
  show,
  onClose,
  title,
  create = false,
  defaultCard = false,
  selectedEvent = '',
}) => {
  const params = useQueryParams()
  const history = useHistory()
  const defaultWhatText = defaultCard ? '' : "Kiss&Tell Founder's Collection"
  const initModel = {
    where: '',
    what: defaultWhatText,
    maxRoomPrice: '',
    maxPackagePrice: '',
    groupSize: '',
    event: '',
  }
  const { model, handleFieldChange, errors, reset } = useForm(initModel)
  const [createEvent, loadingCreateEvent] = useCreateEvent()

  useEffect(() => {
    const cleanedParams = cleanParams(params)
    reset({
      event: '',
      where: cleanedParams.where || '',
      what: cleanedParams.what || defaultWhatText,
      maxRoomPrice: cleanedParams.maxRoomPrice || '',
      maxPackagePrice: cleanedParams.maxPackagePrice || '',
      groupSize: cleanedParams.groupSize || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.where,
    params.what,
    params.maxRoomPrice,
    params.maxPackagePrice,
    params.groupSize,
  ])

  const redirect = () => {
    const URLsegment = defaultCard ? '/locations' : ''
    const url = makeSearchUrl(URLsegment, model)
    history.push(url)

    if (defaultCard) {
      sessionStorage.setItem('rsvp-default', selectedEvent)
    } else {
      // small delay to allow url to change
      setTimeout(() => window.scrollTo({ top: 0 }), 300)
    }

    onClose()
  }

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault()
    if (loadingCreateEvent) return

    if (create) {
      loaderAlert({ html: 'Creating event...' })
      createEvent({
        name: model.event,
        defaultPlusOne: 1,
        defaultKidsAllowed: true,
        tiers: [],
        guestCanPost: true,
        description: '',
        mainImage: '',
        emailCustomText: null,
        isPrivate: true,
        customUrl: null,
      }).then(() => {
        redirect()
        Swal.close()
      })
    } else {
      redirect()
    }
  }

  return (
    <Modal centered show={show} onHide={onClose} className="modal-w-50">
      <Modal.Body className="text-center py-4">
        <MdClear size={50} className="top-right cursor p-2" onClick={onClose} />
        <SiteLogo width="57px" height="40px" color={salmon} />
        <h4 className="mt-2 text-primary">{title}</h4>
        <form
          className="mx-auto"
          style={{ maxWidth: '500px' }}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col xs={12}>
              {create && (
                <KnTField
                  label="Event name"
                  placeholder="Stephanie and Bradley’s Wedding, Smith Family Reunion"
                  value={model}
                  error={errors}
                  setValue={handleFieldChange}
                  name="event"
                />
              )}
              <KnTField
                required={false}
                label="Where"
                placeholder="Tulum, Italy, Belize"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="where"
              />
              <KnTField
                required={false}
                label="What"
                placeholder="Beach, Winery, Villa"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="what"
              />
              <KnTField
                type="number"
                required={false}
                label="Group Size"
                placeholder="Enter number of guests"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="groupSize"
              />
            </Col>
            <Col xs={12} lg={6}>
              <KnTField
                type="number"
                required={false}
                label="Room Rate"
                placeholder="Max Room Price"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="maxRoomPrice"
                icon={<FaDoorOpen size={20} color={gray} />}
              />
            </Col>
            <Col xs={12} lg={6}>
              <KnTField
                type="number"
                required={false}
                label="Event Rate/Person"
                placeholder="Max package Price"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="maxPackagePrice"
                icon={<MdPerson size={20} color={gray} />}
              />
            </Col>
          </Row>
          <Button type="submit" className="w-50 mt-4 mb-2 py-2">
            Search
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default React.memo(SearchVenues)
