import React from 'react'

import allianzLogo from '../../assets/images/allianz_logo.png'

export const Allianz: React.FC = () => {
  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault()

    window.open(
      'https://www.agentmaxonline.com/agentmaxweb/storefront/index.html#/home/?emaillinkcode=ABIYU4TLWGBGTNHC6ZWLRSKAR7AIBWE33AAW7OYIPBPWYZZAHMNKBTBFQ32S6HIYPM4MHKCU6GICMMRGIAIX4CVB7HFUPW6ASEMQPAY%3d',
      '_blank'
    )
  }

  return (
    <div onClick={handleClick} className="py-4 cursor w-fit-content">
      <div className="w-100 mb-2">
        Do you need travel insurance for your trip? <br />
        Click here
      </div>
      <img
        src={allianzLogo}
        alt="Allianz"
        className="d-block mw-100"
        style={{ width: '200px' }}
      />
    </div>
  )
}
