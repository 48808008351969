import React from 'react'

import { getFirstImage } from '../../common'
import { Article } from '../../types'

import { UnderlineLink } from './style'

import { BlogTags, BlogShare } from '.'

export const BlogArticle: React.FC<{ article: Article }> = ({ article }) => {
  const tags = ['MODERN', 'RUSTIC', 'MANSION+ESTATE']
  const shareImg = getFirstImage(article.images, '1000x1500')

  return (
    <>
      <h1 className="mt-4 text-center fz-35 fwl">{article.title}</h1>
      <h3 className="mb-4 tc-lgray text-center fz-12 fwsb">
        By: {article.author || 'Kiss&Tell'}
      </h3>

      {article.body && (
        <section
          style={{ maxWidth: '1100px' }}
          className="mx-auto head-post"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: article.body }}
        />
      )}

      <BlogTags tags={tags} />
      <BlogShare
        tags={tags}
        shareImg={shareImg}
        shareTitle={article.title || undefined}
      />

      <section className="bg-white related pt-4 pb-1 text-center mt-5">
        <UnderlineLink>
          <span>UP NEXT</span>
          <span className="line" />
        </UnderlineLink>
      </section>
    </>
  )
}
