import React from 'react'
import flow from 'lodash/fp/flow'
import take from 'lodash/fp/take'
import getOr from 'lodash/fp/getOr'
import filter from 'lodash/fp/filter'
import isEmpty from 'lodash/fp/isEmpty'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useQuery } from '@apollo/react-hooks'

import { Provider } from '../../../types'
import { getCoords } from '../../../common'
import { GET_LOCATIONS } from '../../../graphql'
import { SmallLoader } from '../../../components'

import { LocationCard } from './LocationCard'

export const NearbyProviders: React.FC<{ provider: Provider }> = ({
  provider,
}) => {
  const [lat, lng] = getCoords(provider.coord as string)
  const { data, loading } = useQuery(GET_LOCATIONS, {
    variables: { limit: 40, where: `Point(${lng},${lat})` },
    fetchPolicy: 'cache-and-network',
  })

  if (loading) return <SmallLoader />
  if (!data && !loading) return <></>

  const providersWithRooms = flow(
    getOr([], 'marketplace.entries'),
    filter((el: Provider) => !isEmpty(el.room) && el.id !== provider.id),
    take(6)
  )(data)

  return (
    <Row>
      <Col xs={12}>
        <p className="fz-16">
          This venue does not have guest rooms, here are nearby suggestions for
          your group stay
        </p>
      </Col>
      {providersWithRooms.map((el: Provider) => (
        <LocationCard
          groupSize={0}
          key={el.id}
          data={el}
          size={{ xs: 12, md: 6, xl: 4 }}
        />
      ))}
    </Row>
  )
}
