import React from 'react'
import Drawer from 'rc-drawer'
import Container from 'react-bootstrap/Container'

interface IDrawerWrapp {
  children: React.ReactNode
  show: boolean
  closeDrawer: () => void
}

const DrawerWrapp: React.FC<IDrawerWrapp> = ({
  children,
  show,
  closeDrawer,
}): React.ReactElement => {
  return (
    <Drawer
      open={show}
      level={null}
      handler={null}
      width="min(95%, 550px)"
      placement="right"
      maskClosable
      onClose={() => closeDrawer()}
    >
      <Container className="py-3 px-3 px-md-5 pt-md-4">{children}</Container>
    </Drawer>
  )
}

export default React.memo(DrawerWrapp)
