import { Dispatch } from 'react'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import pick from 'lodash/fp/pick'
import { createHttpLink } from 'apollo-link-http'
import { execute, makePromise } from 'apollo-link'

import { AUTHENTICATED, KTUserToken } from '../common/constants'
import { IcurrentUser, Token, Auth, IState, Actions } from '../types'
import { CREATE_TOKEN, REFRESH_TOKEN } from '../graphql'
import { getSelectedEvent, saveSelectedEvent } from '../common'

interface IuseAuthentication {
  state: IState
  dispatch: Dispatch<Actions>
}

export default function useAuthentication({
  state,
  dispatch,
}: IuseAuthentication): Auth {
  const link = createHttpLink({ uri: '/api' })

  return {
    isAuthenticated: state.sessionType === AUTHENTICATED,
    getToken: () => getOr(null, 'userToken.accessToken', state),
    signIn: async ({ email, password }) => {
      const { data, errors } = await makePromise(
        execute(link, {
          query: CREATE_TOKEN,
          variables: { email, password },
        })
      )

      const result = get('createToken.result', data)
      if (errors || !result) {
        return { data: null, error: 'Wrong username or password!' }
      }

      const user: IcurrentUser = pick(
        ['firstName', 'lastName', 'userId', 'avatarUrl', 'role', 'email'],
        result
      )
      const token: Token = pick(
        [
          'accessToken',
          'refreshToken',
          'accessTokenExpiresAt',
          'firstName',
          'lastName',
          'refreshTokenExpiresAt',
          'avatarUrl',
          'role',
          'userId',
          'email',
        ],
        result
      )

      localStorage.setItem(KTUserToken, JSON.stringify({ user, token }))
      dispatch({ type: 'LOGIN', payload: { user, token } })
      return { data: { user, token }, error: null }
    },
    signInWithoutRequest: (token: Token) => {
      const user = pick(
        ['firstName', 'lastName', 'userId', 'avatarUrl', 'role'],
        token
      ) as IcurrentUser

      localStorage.setItem(KTUserToken, JSON.stringify({ user, token }))
      dispatch({ type: 'LOGIN', payload: { user, token } })
      return { data: { user, token }, error: null }
    },
    signOut: () => {
      const keep = getSelectedEvent()

      localStorage.clear()
      sessionStorage.clear()
      dispatch({ type: 'LOGOUT' })

      // Keep only the selected event to next session
      if (keep) {
        saveSelectedEvent(keep)
      }
    },
    refreshToken: () => {
      const query = REFRESH_TOKEN
      const variables = {
        token: get('userToken.refreshToken', state),
      }

      return makePromise(execute(link, { query, variables })).then(
        ({ data, errors }) => {
          if (data) {
            const token: Token = data.refreshToken.result
            const user = get('currentUser', state)
            localStorage.setItem(KTUserToken, JSON.stringify({ user, token }))
            dispatch({ type: 'REFRESH', payload: { token } })
            return token
          } else if (errors) {
            localStorage.removeItem(KTUserToken)
            dispatch({ type: 'LOGOUT' })
          }
          return null
        }
      )
    },
    updateUserData: (user: IcurrentUser) => {
      dispatch({ type: 'UPDATE_USER', payload: { user } })
    },
  }
}
