import React from 'react'
import get from 'lodash/fp/get'
import orderBy from 'lodash/fp/orderBy'
import trim from 'lodash/fp/trim'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'

import { getImageUrl, getFirstImage } from '../../common'
import { Article } from '../../types'

import { UnderlineLink, PostInfo, PostBody, WhiteBox } from './style'

import { BlogTags, BlogShare } from '.'

export const BlogRW: React.FC<{ post: Article }> = ({ post }) => {
  const tags = ['MODERN', 'RUSTIC', 'MANSION+ESTATE']
  const shareImg = getFirstImage(post.images, '1000x1500')
  const orderedImages = orderBy(['position'], ['asc'], post.images) || []
  return (
    <>
      <h1 className="mt-4 text-center fz-35 fwl">{post.title}</h1>
      <UnderlineLink>
        <h2 className="mb-2 text-primary text-center fz-16 fwsb">
          {post.location}
        </h2>
        <span className="line" />
      </UnderlineLink>

      <Row as="section">
        <Col as="section" className="post-content" xs={12} lg={8}>
          {post.body && (
            <PostBody
              className="w-100 mb-4"
              dangerouslySetInnerHTML={{ __html: post.body }}
            />
          )}

          {orderedImages.map(el => {
            const path = getImageUrl(el, '850xauto')
            const caption = get('metadata.caption', el)
            return (
              <div key={el.id} className="image-block">
                <Link
                  className="post-photo d-block mb-3"
                  to={`/blog/photo/${post.url}/${el.id}`}
                >
                  <Image
                    onLoad={ev => {
                      const img = ev.currentTarget
                      const aspectRatio = img.naturalWidth / img.naturalHeight
                      const imageContainer = img.closest('.image-block')
                      if (aspectRatio < 1 && imageContainer) {
                        imageContainer.classList.add('blogImages-side-by-side')
                      }
                    }}
                    src={path}
                    alt={get('metadata.alt', el) || caption}
                    className="w-100"
                    fluid
                  />
                </Link>
                {caption && <p className="fz-20 fwl mb-5">{caption}</p>}
              </div>
            )
          })}

          <BlogTags tags={tags} />
          <BlogShare
            tags={tags}
            shareImg={shareImg}
            shareTitle={post.title || undefined}
          />
        </Col>
        <Col as="aside" className="post-information" xs={12} lg={4}>
          <WhiteBox
            className={`shadow-sm ${
              post.credits.length > 7 ? 'scroll-box' : ''
            }`}
          >
            <UnderlineLink className="mb-2">
              <h2 className="fz-16 fwsb">Vendors</h2>
              <span className="line" />
            </UnderlineLink>
            <PostInfo>
              <Table responsive>
                <tbody>
                  {post.credits.map(el => (
                    <tr key={el.articleId}>
                      <td>{`${trim(el.service)}:`}</td>
                      <td>{trim(el.business)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </PostInfo>
          </WhiteBox>
        </Col>
      </Row>

      <section className="bg-white related py-4 text-center mt-4">
        <UnderlineLink>
          <h2 className="fwsb fz-13">UP NEXT</h2>
          <span className="line" />
        </UnderlineLink>
      </section>
    </>
  )
}
