import {
  EventComponent,
  Guest,
  IBookingGuest,
  IFreeBookingGuest,
  OfferingAddOnOptionsWithType,
} from '../types'

export const cleanupStorage = (): void => {
  localStorage.removeItem('currentBookingId')
  localStorage.removeItem('extraNights')
  localStorage.removeItem('freeGuests')
  localStorage.removeItem('paidGuests')
  localStorage.removeItem('onBehalf')
}

export const setBookingId = (bookingId: string): void => {
  localStorage.setItem('currentBookingId', bookingId)
}

export const getBookingId = (): string | undefined => {
  return localStorage.getItem('currentBookingId') ?? undefined
}

export const setExtraNights = (
  extraNights: OfferingAddOnOptionsWithType[] = []
): void => {
  localStorage.setItem('extraNights', JSON.stringify(extraNights))
}

export const setFreeGuests = (freeGuests: IFreeBookingGuest[] = []): void => {
  localStorage.setItem('freeGuests', JSON.stringify(freeGuests))
}

export const setPaidGuests = (paidGuests: IBookingGuest[] = []): void => {
  localStorage.setItem('paidGuests', JSON.stringify(paidGuests))
}

export const getExtraNights = (): OfferingAddOnOptionsWithType[] => {
  return JSON.parse(localStorage.getItem('extraNights') ?? '[]')
}

export const getFreeGuests = (): IFreeBookingGuest[] => {
  return JSON.parse(localStorage.getItem('freeGuests') ?? '[]')
}

export const getPaidGuests = (): IBookingGuest[] => {
  return JSON.parse(localStorage.getItem('paidGuests') ?? '[]')
}

export const resetAllAddons = (): void => {
  localStorage.removeItem('extraNights')
  localStorage.removeItem('freeGuests')
  localStorage.removeItem('paidGuests')
}

// Public Booking

export const setStoredComponent = (component: EventComponent): void => {
  if (!component) return
  sessionStorage.setItem('component', JSON.stringify(component))
}
export const getStoredComponent = (): EventComponent | undefined => {
  const component = sessionStorage.getItem('component')
  if (!component) return undefined
  return JSON.parse(component)
}
export const resetPublicAddons = (): void => {
  localStorage.removeItem('publicExtraNights')
  localStorage.removeItem('publicFreeGuests')
  localStorage.removeItem('publicPaidGuests')
}

export const getPublicExtraNights = (): OfferingAddOnOptionsWithType[] => {
  return JSON.parse(localStorage.getItem('publicExtraNights') ?? '[]')
}

export const getPublicFreeGuests = (): IFreeBookingGuest[] => {
  return JSON.parse(localStorage.getItem('publicFreeGuests') ?? '[]')
}

export const getPublicPaidGuests = (): IBookingGuest[] => {
  return JSON.parse(localStorage.getItem('publicPaidGuests') ?? '[]')
}

export const setPublicExtraNights = (
  extraNights: OfferingAddOnOptionsWithType[] = []
): void => {
  localStorage.setItem('publicExtraNights', JSON.stringify(extraNights))
}

export const setPublicFreeGuests = (
  freeGuests: IFreeBookingGuest[] = []
): void => {
  localStorage.setItem('publicFreeGuests', JSON.stringify(freeGuests))
}

export const setPublicPaidGuests = (paidGuests: IBookingGuest[] = []): void => {
  localStorage.setItem('publicPaidGuests', JSON.stringify(paidGuests))
}

export const setOnBehalf = (onBehalf: Guest): void => {
  localStorage.setItem('onBehalf', JSON.stringify(onBehalf))
}

export const getOnBehalf = (): Guest | undefined => {
  const onBehalf = localStorage.getItem('onBehalf')
  if (!onBehalf) return undefined
  return JSON.parse(onBehalf)
}
