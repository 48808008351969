import styled, { keyframes } from 'styled-components'

import { salmon, gray, darkGray, lightGray } from '../../styles/themeColors'

export const BlockContainer = styled.div`
  margin: 0 auto;
  padding-bottom: 1rem;
  width: 80%;
  @media (max-width: 600px) {
    width: 95%;
  }
`

export const Block = styled.div`
  border-left: 9px solid ${lightGray};
  padding: 0 0 20px 30px;
  position: relative;
  & ul {
    padding: 0 0 0 20px;
    & li {
      font-size: 16px;
    }
  }
  & p {
    color: ${salmon};
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 5px 0;
    padding: 5px 0 0 0;
  }
  & .number {
    align-items: center;
    background-color: ${salmon};
    border-radius: 50%;
    color: white;
    display: flex;
    font-weight: 700;
    height: 40px;
    justify-content: center;
    left: -25px;
    position: absolute;
    top: 0;
    width: 40px;
  }
  &:last-child {
    border-left: 9px solid transparent;
  }
  @media (max-width: 600px) {
    & ul {
      & li {
        font-size: 14px;
      }
    }
    & p {
      font-size: 16px;
    }
  }
`

const pulse = keyframes`
  0% {
		box-shadow: 0 0 0 0 rgba(255, 110, 120, 0.8);
	}

	70% {
		box-shadow: 0 0 0 15px rgba(255, 110, 120, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 110, 120, 0);
	}
`
export const NotificationCart = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 0 4px;
  position: relative;
  &.newNotification {
    & .aux-shadow {
      animation: ${pulse} 2s infinite;
      // box-shadow: 0 0 0 10px rgba(255, 110, 120, 0.5);
      border-radius: 50%;
      height: 12px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      z-index: 1;
    }
    & svg {
      z-index: 2;
    }
  }
  @media (min-width: 992px) {
    margin: 0 0 0 6px !important;
  }
  @media (max-width: 991px) {
    float: right;
  }
`

export const UserOptionsNav = styled.div`
  & button {
    align-items: center;
    display: flex;
    border-color: rgba(0, 0, 0, 0.14);
    color: ${gray};
    padding: 5px 10px;
  }
  *:focus,
  *:hover {
    background-color: white !important;
    color: ${gray} !important;
    outline: none !important;
  }
  @media (max-width: 991px) {
    display: inline-block;
    margin-bottom: 10px;
    width: fit-content;
  }
`

export const MobileMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0 0.25rem 0;
  width: 100%;
`

export const MobileMenuBody = styled.div`
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: max-height 0.2s linear;
  width: 100%;
  & a {
    color: ${darkGray};
    display: block;
    font-weight: 600;
    padding: 8px 5px 6px 5px;
    text-decoration: none;
    user-select: none;
    width: 100%;
  }
  &.show,
  &.show-user {
    transition: max-height 0.5s ease-in-out;
  }
  &.show {
    max-height: 700px;
  }
  &.show-user {
    max-height: 300px;
  }
  &.expandedSubmenu > a {
    display: none;
  }
`

export const Caret = styled.span`
  display: inline-block;
  position: relative;
  font-size: 50px;
  font-weight: 400;
  padding: 0 5px 15px 5px;
  top: -20px;
  &::after {
    font-family: 'Open Sans', 'Helvetica';
    bottom: 0;
    content: '^';
    font-size: 60px;
    height: 35px;
    left: 0;
    line-height: 1;
    position: absolute;
    text-align: center;
    width: 100%;
  }
`
