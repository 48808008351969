import styled from 'styled-components'

export const ReferralForm = styled.form`
  background: white;
  border-radius: 5px;
  margin: 30px auto 60px auto;
  max-width: 1200px;
  width: 100%;
  & .send {
    border-radius: 30px;
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin: 0 auto;
    max-width: 330px;
    width: 90%;
  }
`
