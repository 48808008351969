import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'

import { IState, Actions } from './types'
import { AUTHENTICATED, UNAUTHENTICATED, KTUserToken } from './common/constants'

export function getInitialState(): IState {
  let sessionType = UNAUTHENTICATED
  let currentUser = {}
  let userToken = {}

  const sessionJson = localStorage.getItem(KTUserToken)
  if (sessionJson) {
    const parsedSessionJson = JSON.parse(sessionJson)
    currentUser = get('user', parsedSessionJson)
    userToken = get('token', parsedSessionJson)
    sessionType = AUTHENTICATED
  }

  return {
    sessionType,
    currentUser,
    myEvents: [],
    currentEvent: {
      guests: [],
      tiers: [],
    },
    userToken,
  }
}

export default function reducer(
  state: IState = getInitialState(),
  action: Actions
): IState {
  let user = {}
  let token = {}
  let sessionType = UNAUTHENTICATED
  switch (action.type) {
    case 'LOGIN':
      user = get('payload.user', action)
      token = get('payload.token', action)
      return {
        ...state,
        sessionType: AUTHENTICATED,
        currentUser: user,
        userToken: token,
      }
    case 'LOGOUT':
      return {
        ...state,
        currentUser: {},
        userToken: {},
        sessionType: UNAUTHENTICATED,
      }
    case 'UPDATE_USER':
      user = get('payload.user', action)

      return {
        ...state,
        currentUser: user,
      }
    case 'REFRESH':
      token = getOr({}, 'payload.token', action)
      sessionType = token ? AUTHENTICATED : UNAUTHENTICATED
      return {
        ...state,
        userToken: token,
        sessionType,
      }
    case 'UPDATE_MY_EVENTS':
      return {
        ...state,
        myEvents: action.payload,
      }
    case 'ADD_EVENT':
      return {
        ...state,
        myEvents: [...state.myEvents, action.payload],
      }
    case 'SET_CURRENT_EVENT':
      return {
        ...state,
        currentEvent: action.payload,
      }
    case 'ADD_GUEST':
      return {
        ...state,
        currentEvent: {
          ...state.currentEvent,
          guests: [...state.currentEvent.guests, action.payload],
        },
      }
    case 'ADD_GUEST_TIER':
      return {
        ...state,
        currentEvent: {
          ...state.currentEvent,
          tiers: [...state.currentEvent.tiers, action.payload],
        },
      }
    default:
      return state
  }
}
