import React from 'react'
import Modal from 'react-bootstrap/Modal'

import { salmon } from '../styles/themeColors'

import { SiteLogo } from './customIcons'

interface IValidationModal {
  title?: string
  text: string
  textClass?: string
  show: boolean
  onHide: () => void
}

export const ValidationModal: React.FC<IValidationModal> = ({
  title,
  text,
  show,
  onHide,
  textClass,
}) => {
  return (
    <Modal
      centered
      show={show}
      onHide={() => onHide()}
      className="modal-rounded-0"
    >
      <Modal.Body className="text-center pt-4">
        <SiteLogo width="57px" height="40px" color={salmon} />
        {title && <h3 className="mt-2 text-primary">{title}</h3>}
        <p
          className={textClass ?? 'fz-14 py-2'}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </Modal.Body>
    </Modal>
  )
}
