import React, { useState, useRef, useEffect, useMemo } from 'react'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import toLower from 'lodash/fp/toLower'
import orderBy from 'lodash/fp/orderBy'
import debounce from 'lodash/fp/debounce'
import includes from 'lodash/fp/includes'
import { MdDone, MdArrowDropDown } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { RiCake2Line } from 'react-icons/ri'

import { gray, salmon } from '../../styles/themeColors'

import {
  DropdownButton,
  DropdownContainer,
  DropdownContent,
  ItemsList,
  SearchContainer,
  SearchIcon,
  SearchInput,
} from './style'

export interface DropdownItem {
  text: string
  link?: string
  externalLink?: boolean
  active?: boolean
  activeIcon?: React.ReactNode
  onClick?: () => void
}

interface DropdownProps {
  title: string
  items: DropdownItem[]
  icon?: React.ReactNode
  activeIcon?: React.ReactNode
  searchPlaceholder?: string
  order?: 'asc' | 'desc'
}

const searchInputId = 'dropdown-search'

export const Dropdown: React.FC<DropdownProps> = ({
  items = [],
  title,
  order = 'asc',
  searchPlaceholder = 'Search...',
  activeIcon = <MdDone className="ml-1 svg-top1" color={salmon} />,
  icon = <RiCake2Line size={22} className="mr-1" color={gray} />,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const showSearch = items.length > 10
  const closeDropdown = () => {
    setIsOpen(false)
    setSearchTerm('')
    const input = document.getElementById(searchInputId) as HTMLInputElement
    if (input) input.value = ''
  }

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current) return
      if (!dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  // Debounce the search term to avoid unnecessary re-renders
  const debouncedSetFilterTerm = useMemo(() => {
    const debounced = debounce(1000, (value: string) =>
      setSearchTerm(value.trim())
    )
    return (value: string) => {
      if (value.trim() === '') {
        setSearchTerm(value.trim())
      } else {
        debounced(value)
      }
    }
  }, [])

  const filteredItems = flow(
    filter((item: DropdownItem) =>
      includes(toLower(searchTerm), toLower(item.text))
    ),
    orderBy([(item: DropdownItem) => toLower(item.text)], [order])
  )(items) as DropdownItem[]

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        {icon}
        <span>{title}</span>
        <MdArrowDropDown size={20} color={gray} />
      </DropdownButton>

      <DropdownContent isOpen={isOpen}>
        {showSearch && (
          <SearchContainer>
            <div style={{ position: 'relative' }}>
              <SearchIcon />
              <SearchInput
                type="text"
                id={searchInputId}
                placeholder={searchPlaceholder}
                onChange={e => debouncedSetFilterTerm(e.target.value)}
              />
            </div>
          </SearchContainer>
        )}

        <ItemsList>
          {filteredItems.map((item, idx) => {
            const key = `item-${idx.toString()}`

            if (item.onClick) {
              return (
                <div
                  className="item"
                  onClick={() => {
                    closeDropdown()
                    item.onClick?.()
                  }}
                  key={key}
                >
                  <span style={{ flexGrow: 1 }}>{item.text}</span>
                  {item.active && activeIcon}
                </div>
              )
            }
            if (item.externalLink) {
              return (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="item item-link"
                  key={key}
                  onClick={closeDropdown}
                >
                  <span style={{ flexGrow: 1 }}>{item.text}</span>
                </a>
              )
            }
            return (
              <Link
                className="item"
                key={key}
                to={item.link as string}
                onClick={closeDropdown}
              >
                <span style={{ flexGrow: 1 }}>{item.text}</span>
                {item.active && activeIcon}
              </Link>
            )
          })}
        </ItemsList>
      </DropdownContent>
    </DropdownContainer>
  )
}
