import React, { useState, useEffect } from 'react'
import get from 'lodash/fp/get'
import toInteger from 'lodash/fp/toInteger'
import moment from 'moment'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import FormLabel from 'react-bootstrap/FormLabel'
import FormGroup from 'react-bootstrap/FormGroup'
import { MdSync } from 'react-icons/md'

import { EventComponent } from '../../../../types'
import { RangePicker, SimpleTooltip } from '../../../../components'
import { salmon } from '../../../../styles/themeColors'

import {
  useCancelEventComponent,
  useUpdateEventComponent,
  parseGuestPays,
} from './eventBuilderFunctions'
import { CommonRoomData } from './CommonRoomData'
import { EventBuilderCard } from './style'

export const EventBuilderRoomCard: React.FC<{
  roomCardData: EventComponent
  eventId: string
}> = ({ roomCardData, eventId }) => {
  const [capacity, setCapacity] = useState(0)
  const [guestPays, setGuestPays] = useState('0')
  const [reservationData, setReservationData] = useState({
    duration: 0,
    date: '',
  })
  const [handleUpdateEventComponent, loadingUpdate] = useUpdateEventComponent()
  const [handleCancelEventComponent, loadingCancel] = useCancelEventComponent(
    roomCardData.id,
    eventId
  )

  useEffect(() => {
    setCapacity(roomCardData.capacity)
    setReservationData({
      duration: roomCardData.duration,
      date: roomCardData.date,
    })

    setGuestPays(parseGuestPays(roomCardData.guestPays))
  }, [
    roomCardData.capacity,
    roomCardData.date,
    roomCardData.duration,
    roomCardData.guestPays,
  ])

  const offering = get('offering', roomCardData)

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = toInteger(ev.currentTarget.value)
    if (value <= offering.quantity && value >= 1) {
      setCapacity(value)
    }
  }

  const handleDateChange = (
    startDate: moment.Moment | null,
    endDate: moment.Moment | null
  ) => {
    if (startDate && endDate) {
      setReservationData({
        duration: endDate.diff(startDate, 'days') + 1,
        date: startDate.format('YYYY-MM-DD 00:00:00'),
      })
    }
  }

  const updateEventComponent = () => {
    const variables = {
      id: roomCardData.id,
      input: {
        capacity,
        guestPays: toInteger(guestPays) / 100, // because it should be a number between 0 and 1
        ...reservationData,
      },
    }
    handleUpdateEventComponent(variables)
  }

  const cancelBooking = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    handleCancelEventComponent()
  }

  return (
    <EventBuilderCard className="EventBuilderCard">
      <CommonRoomData roomCardData={roomCardData}>
        <div className="d-flex align-items-center flex-wrap">
          <RangePicker
            className="w-100"
            emitDate={handleDateChange}
            initStartDate={moment(roomCardData.date)}
            initEndDate={moment(roomCardData.date).add(
              roomCardData.duration - 1,
              'days'
            )}
          />
          <div className="w-100 pt-3">
            <FormGroup controlId="guestPaysRange" className="m-0">
              <FormLabel className="m-0 position-relative pr-3">
                Guest Pays: {guestPays}%
                <SimpleTooltip
                  id="GuestPaysRange-tooltip"
                  className="top-right"
                  text="Need to discount some or all of your guests rooms? Select the percentage here and you pay the rest"
                />
              </FormLabel>
              <FormControl
                type="range"
                value={guestPays}
                onChange={e => setGuestPays(e.target.value)}
              />
            </FormGroup>
          </div>
          <input
            onChange={handleChange}
            className="update-quantity"
            type="number"
            max={offering.quantity}
            min={1}
            value={capacity}
            disabled={offering.quantity <= 0}
          />
          <p className="fwsb mt-3 mb-0 mx-2">
            Select # of rooms
            <span className="d-block fwn">{offering.quantity} available</span>
          </p>
          <Button
            disabled={
              loadingUpdate || capacity > offering.quantity || loadingCancel
            }
            onClick={updateEventComponent}
            className="ml-3 mt-3 btn-outline-salmon-simple"
          >
            <MdSync size={20} className="svg-top1" color={salmon} />
            {loadingUpdate ? 'Updating...' : ' Update'}
          </Button>
        </div>
        <a className="cancel-booking" href="!#" onClick={cancelBooking}>
          cancel booking
        </a>
      </CommonRoomData>
    </EventBuilderCard>
  )
}
