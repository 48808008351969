import styled from 'styled-components'

export const NoBookingBanner = styled.div`
  border-radius: 5px;
  position: relative;
  width: 100%;
  & .no-booking-banner {
    border-radius: 5px 0 0 5px;
    height: auto;
    width: 100%;
  }
  & .close-banner {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  @media (max-width: 600px) {
    overflow: hidden;
    & .no-booking-banner {
      left: -50%;
      position: relative;
      width: 150%;
    }
  }
`
