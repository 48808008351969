import React, { useState } from 'react'
import moment from 'moment'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import Modal from 'react-bootstrap/Modal'
import FormCheck from 'react-bootstrap/FormCheck'
import { MdPeople } from 'react-icons/md'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'

import { DefaultUserPicture, UserPicture } from '../../../components'
import { PollOption, Post, UserProfile } from '../../../types'
import { SEND_VOTE, GET_OPTION_VOTES } from '../../../graphql'

import { PollOptions } from './style'

export const PollResult: React.FC<{
  post: Post
  owned: boolean
}> = ({ post, owned }) => {
  const [sendVote, { loading }] = useMutation(SEND_VOTE)
  const [getVotes, { loading: loadingVotes, data: userList }] =
    useLazyQuery(GET_OPTION_VOTES)
  const [error, setError] = useState(false)
  const [showSelectionsModal, handleCloseSelectionsModal] = useState(false)

  const isExpired = post.deadline
    ? moment(post.deadline).isBefore(moment())
    : false

  const updateVote = (optionId: string) => {
    if (isExpired) return
    const variables = { postId: post.id, optionId }
    sendVote({ variables }).then(({ data }) => {
      if (data.vote.errors.length > 0) {
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 5000)
      }
    })
  }

  const seeVotes = (optionId: string) => {
    if (!loadingVotes) {
      const variables = { postId: post.id, optionId }
      getVotes({ variables })
      handleCloseSelectionsModal(true)
    }
  }

  const votes: UserProfile[] = getOr([], 'votes', userList)

  return (
    <div className="pb-3" data-cy="poll-result">
      <Modal
        className="poll-modal"
        show={showSelectionsModal}
        onHide={() => handleCloseSelectionsModal(false)}
      >
        <Modal.Header closeButton className="poll-modal-title">
          <Modal.Title className="fwsb fz-16">Votes</Modal.Title>
        </Modal.Header>
        <Modal.Body className="poll-modal-content">
          {loadingVotes && <p className="text-center">Loading...</p>}
          {!loadingVotes && isEmpty(votes) && (
            <p className="text-center my-3">No votes yet for this option</p>
          )}

          {!loadingVotes && !isEmpty(votes) && (
            <p className="fwsb px-2">Total votes: {votes.length}</p>
          )}

          {votes.map(el => (
            <div className="user-vote" key={el.id}>
              {el.avatarUrl ? (
                <UserPicture url={el.avatarUrl} name={el.firstName} />
              ) : (
                <DefaultUserPicture />
              )}
              <span className="user-name">
                {el.firstName} {el.lastName}
              </span>
            </div>
          ))}
        </Modal.Body>
      </Modal>

      <PollOptions>
        {post?.poll?.options.map((el: PollOption) => (
          <div className="single-option" key={el.id}>
            <FormCheck
              onChange={() => updateVote(el.id)}
              checked={el.voted}
              custom
              disabled={isExpired || loading}
              type="checkbox"
              label=""
              className="user-selection"
              id={el.id}
            />

            <div className="total-selections">
              <span>{el.name}</span>
              <div
                className="percentage-poll"
                style={{ width: `${el.percent}%` }}
              />
              {owned && (
                <div
                  className="see-votes cursor"
                  onClick={() => seeVotes(el.id)}
                >
                  <MdPeople size={24} />
                </div>
              )}
            </div>
          </div>
        ))}

        {post.deadline && (
          <div className="pt-2 fwsb">
            {isExpired
              ? 'Voting closed'
              : `Vote by: ${moment(post.deadline).format('MM-DD-YYYY')}`}
          </div>
        )}
      </PollOptions>
      {error && (
        <span className="px-4 mb-1 d-block text-danger fz-12">
          It was not possible to send your vote, try again.
        </span>
      )}
    </div>
  )
}
