import React, { useState, useRef, useEffect, memo } from 'react'
import _map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import uniqBy from 'lodash/fp/uniqBy'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

import { checkEnv } from '../../../common'
import {
  MapProps,
  GoogleMap,
  GoogleMarker,
  EventComponent,
} from '../../../types'
import {
  adjustMapZoom,
  createMarkersWithInfoWindow,
} from '../../../components/GoogleMapsUtilities'
import { MAPS_PROD_API_KEY, MAPS_DEV_API_KEY } from '../../../common/constants'

const RenderMap: React.FC<MapProps> = ({ children, providers, ...options }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<GoogleMap>()

  useEffect(() => {
    if (providers.length <= 0 || !ref.current) return undefined

    if (!map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoom: 8,
          center: { lat: 20.3543899, lng: -87.6360736 },
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        })
      )
    }

    const markers: GoogleMarker[] = createMarkersWithInfoWindow({
      providers,
      map,
    })
    adjustMapZoom({ map, providers })

    return () => {
      markers.forEach(marker => marker.setMap(null))
    }
  }, [map, providers])

  if (map) {
    map.setOptions(options)
  }

  const height = `clamp(400px, 60vh, 600px)`
  return <div style={{ height, flexGrow: 1 }} ref={ref} />
}

export const RSVPmap: React.FC<{ components: EventComponent[] }> = memo(
  ({ components }) => {
    const { develop } = checkEnv()
    const apiKey = develop ? MAPS_DEV_API_KEY : MAPS_PROD_API_KEY

    const render = (status: Status) => {
      return <div className="text-center">{status}</div>
    }

    if (components.length <= 0) return null
    const providers = flow(
      _map((el: EventComponent) => el.offering.provider),
      uniqBy('id')
    )(components)
    return (
      <section className="map-section mt-5">
        <h3 className="py-2 py-lg-4 fz-30 mb-3 text-center">Map Your Stay</h3>
        <Wrapper apiKey={apiKey} render={render}>
          <RenderMap providers={providers} />
        </Wrapper>
      </section>
    )
  }
)
