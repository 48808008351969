import gql from 'graphql-tag'

export const GET_MY_MAIN_INFORMATION = gql`
  query getMyMainInformation {
    me {
      id
      firstName
      lastName
      avatarUrl
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser($input: UserBasicData!) {
    updateUser(input: $input) {
      result {
        id
        avatarUrl
        firstName
        lastName
      }
      errors {
        key
        message
      }
    }
  }
`

export const GET_MY_INVITATIONS = gql`
  query getMyInvitations {
    me {
      id
      ... on Customer {
        invitations {
          eventId
          eventName
          status
          guestId
        }
      }
    }
  }
`
