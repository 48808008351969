import React, { useEffect, useState, useMemo } from 'react'
import getOr from 'lodash/fp/getOr'
import Row from 'react-bootstrap/Row'
import get from 'lodash/fp/get'
import toInteger from 'lodash/fp/toInteger'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useQuery } from '@apollo/react-hooks'
import { Helmet } from 'react-helmet'

import { useMoveToTop, getMoreData, useQueryParams } from '../../common'
import { GET_BLOG_POSTS } from '../../graphql'
import { SimpleLoader } from '../../components'
import { Article } from '../../types'

import { MainBlogSection } from './components'
import { makeArticleChunks, getBlogVideos } from './components/BlogFunctions'

import { BlogHomeRow } from '.'

export const HomeBlog: React.FC = () => {
  useMoveToTop()
  const { cards, wedding } = useQueryParams()
  const [limit] = useState(() => (cards ? toInteger(cards) : 16))
  const [hasMore, setHasMore] = useState(true)
  const [articlesChunks, setArticlesChunks] = useState<Article[][]>([])
  const videoList = useMemo(() => getBlogVideos(), [])

  const { data, loading, fetchMore } = useQuery(GET_BLOG_POSTS, {
    variables: { limit },
  })
  const entries: Article[] = getOr([], 'blog.entries', data)
  const cursorToken = getOr(null, 'blog.cursor', data)

  useEffect(() => {
    setHasMore(cursorToken !== null)
    setArticlesChunks(makeArticleChunks(entries, videoList))
  }, [cursorToken, entries, videoList])

  useEffect(() => {
    if (!wedding) return

    const scrollToVideoCard = () => {
      const videoCard = document.getElementById(`video-card-${wedding}`)
      if (!videoCard) return

      const top = videoCard.getBoundingClientRect().top - 70
      window.scrollTo({
        behavior: 'smooth',
        top,
      })
    }

    const timeoutId = setTimeout(scrollToVideoCard, 1000)
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId)
  }, [wedding])

  const fetchMoreData = () => {
    if (cursorToken) {
      getMoreData({
        fetchMore,
        query: GET_BLOG_POSTS,
        cursorToken,
        path: 'blog',
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Kiss & Tell | Destination Wedding Inspiration</title>
        <meta
          name="description"
          content="Get inspired by real weddings and articles on destination wedding planning."
        />
      </Helmet>
      <section className="posts-list mx-auto mw-1200 mt-2 mt-lg-4">
        <MainBlogSection />

        <InfiniteScroll
          className="w-100"
          style={{ overflowX: 'hidden' }}
          dataLength={entries.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<SimpleLoader />}
        >
          <Row noGutters>
            {articlesChunks.length === 0 && !loading && (
              <p className="w-100 text-center tc-lgray pt-5 fz-18 fwsb">
                There are no results to show
              </p>
            )}
            {articlesChunks.map((el, idx) => (
              <BlogHomeRow
                videoList={videoList}
                articles={el}
                index={idx}
                key={get('[0].id', el)}
              />
            ))}
          </Row>
        </InfiniteScroll>
      </section>
    </>
  )
}
