import Swal, { SweetAlertResult } from 'sweetalert2'

import { salmon } from '../styles/themeColors'

const colors: { [x: string]: string } = {
  confirmButtonColor: salmon,
  blackButton: '#333333',
}

// this change is to be prepared to update sweetalert, right now they have some errors with their types
interface SweetAlertProps {
  title?: string
  html?: string
  icon?: 'success' | 'error' | 'warning' | 'info' | 'question'
  confirmButtonText?: string
}

export const confirmAlert = ({
  title = '',
  html = '',
  icon = 'question',
  confirmButtonText = 'Yes',
}: SweetAlertProps): Promise<SweetAlertResult> => {
  return Swal.fire({
    title,
    html,
    type: icon,
    showCancelButton: true,
    confirmButtonColor: colors.confirmButtonColor,
    cancelButtonColor: colors.blackButton,
    confirmButtonText,
  })
}

export const simpleAlertWithPromise = ({
  title = '',
  html = '',
  icon = 'question',
  confirmButtonText = 'Ok',
}: SweetAlertProps): Promise<SweetAlertResult> => {
  return Swal.fire({
    title,
    html,
    type: icon,
    confirmButtonColor: colors.confirmButtonColor,
    cancelButtonColor: colors.blackButton,
    confirmButtonText,
  })
}

export const simpleAlert = ({
  title = '',
  html = '',
  icon = 'info',
}: SweetAlertProps): void => {
  Swal.fire({
    title,
    html,
    type: icon,
    confirmButtonColor: colors.confirmButtonColor,
    confirmButtonText: 'Close',
  })
}

export const loaderAlert = ({
  title = '',
  html = '',
}: SweetAlertProps): void => {
  Swal.fire({
    title,
    html,
    onBeforeOpen: () => {
      Swal.showLoading()
    },
  })
}
