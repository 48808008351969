import React from 'react'
import get from 'lodash/fp/get'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'

import { IframeYoutube, getFirstImage } from '../../common'
import { Article, IyoutubeItem } from '../../types'

import { UnderlineLink } from './style'

export const PostCard: React.FC<{
  className?: string
  width?: string
  data: Article
}> = ({ className = '', width, data }) => {
  const img = getFirstImage(get('images', data), '400x247')

  return (
    <Link className="text-decoration-none" to={`/blog/${data.url}`}>
      <Card
        className={`post-card mb-3 shadow-sm mx-auto ${className} ${
          !data?.body ? 'rw-card' : 'article-card'
        }`}
        style={{ maxWidth: width || '380px' }}
        as="article"
      >
        <Card.Img
          variant="top"
          src={img}
          alt="Wedding or post name"
          style={{ minHeight: '233px' }}
        />
        <Card.Body className="py-3">
          <Card.Title
            className="text-primary justify-content-between d-flex fwsb fz-13 mb-1"
            as="h2"
          >
            {!data?.body ? 'Real Wedding' : 'Article'}
            {!data?.body && <span>{data.location}</span>}
          </Card.Title>
          <Card.Text
            style={{ minHeight: '48px' }}
            className="d-flex align-items-center fz-16 tc-gray"
          >
            {data.title}
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  )
}

export const YoutubeCard: React.FC<{ width?: string; video: IyoutubeItem }> = ({
  width,
  video,
}) => {
  const id = get('snippet.resourceId.videoId', video)
  return (
    <Card
      className="youtube-card mb-3 shadow-sm mx-auto"
      style={{ maxWidth: width || '380px' }}
      as="article"
    >
      <IframeYoutube height="450px" videoId={id} />
      <Card.Body className="py-3 text-center py-2 py-md-5">
        <UnderlineLink className="mb-2 mt-5">
          <Card.Title className="text-primary fwsb fz-13 mb-1" as="h2">
            MAKEOUT SESSIONS
          </Card.Title>
          <span className="line" />
        </UnderlineLink>
        <Card.Text className="fz-16 pb-5">
          {get('snippet.title', video)}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}
