import styled from 'styled-components'

export const TooltipContainer = styled.span`
  position: relative;
  &.top-right {
    position: absolute;
    right: -5px;
    top: -3px;
  }
  &.top-right-form {
    position: absolute;
    right: -30px;
    top: -3px;
  }
`
