import { createContext, Dispatch } from 'react'

import { IState, Actions, Auth } from './types'

interface IContextProps {
  state: IState
  dispatch: Dispatch<Actions>
  auth: Auth
}

const AppContext = createContext({} as IContextProps)

export default AppContext
