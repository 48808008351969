import React from 'react'
import find from 'lodash/fp/find'
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button'
import { useHistory, Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import { CREATE_BOOKING } from '../../../graphql'
import { EventIcon, RoomIcon } from '../../../components/customIcons'
import { offeringTypes } from '../../../common/constants'
import { errorAlert, loaderAlert } from '../../../common'
import { Booking, BookingStatus, EventComponent, MyEvent } from '../../../types'

interface IRSVPcta {
  eventComponent: EventComponent
  className?: string
  myEvents?: MyEvent[]
  RsvpCustomText?: string
  RoomCustomText?: string
  customCallback?: () => void
  childrenBelow?: React.ReactNode
}

export const RSVPcta: React.FC<IRSVPcta> = ({
  eventComponent,
  className,
  children,
  myEvents,
  RsvpCustomText = 'RSVP',
  RoomCustomText = 'Book',
  customCallback,
  childrenBelow = null,
}) => {
  const history = useHistory()
  const [createBooking, { loading }] = useMutation(CREATE_BOOKING)
  const { roomOffering } = offeringTypes
  const { id: eventComponentId, offering } = eventComponent

  const handleCreateBooking = (ev: React.MouseEvent) => {
    ev.preventDefault()
    loaderAlert({ html: 'processing your booking' })

    const variables = { eventComponentId }
    createBooking({ variables })
      .then(({ data }) => {
        Swal.close()
        const errors = data?.createBooking?.errors || []
        const result = data?.createBooking?.result

        if (errors.length > 0) {
          errorAlert(errors, 'There was an error booking this property')
        } else {
          customCallback?.()
          history.push(`/booking/${result?.id}`)
        }
      })
      .catch(error => errorAlert([], error.message))
  }

  const isMyEvent = !!find({ id: eventComponent.eventId }, myEvents)
  const ctaClass = isMyEvent ? `${className} my-event-cta` : className

  const isRoom = offering.__typename === roomOffering
  const ctaContent = isRoom ? (
    <>
      <RoomIcon color="white" width="25px" height="25px" />
      {RoomCustomText}
    </>
  ) : (
    <>
      <EventIcon color="white" width="25px" height="25px" />
      {RsvpCustomText}
    </>
  )

  return (
    <Button
      className={ctaClass}
      onClick={handleCreateBooking}
      disabled={loading}
    >
      {children ?? ctaContent}
      {childrenBelow}
    </Button>
  )
}

export const RSVPctaLink: React.FC<{
  booking: Booking
  RsvpCustomText?: string
  RoomCustomText?: string
  childrenBelow?: React.ReactNode
  className?: string
}> = ({
  booking,
  children,
  RsvpCustomText = 'RSVP',
  RoomCustomText = 'Book',
  childrenBelow = null,
  className,
}) => {
  const isBooked = booking?.status === BookingStatus.Booked
  const suffix = isBooked ? '/details' : ''

  const { eventComponent } = booking
  const { offering } = eventComponent
  const { roomOffering } = offeringTypes
  const isRoom = offering.__typename === roomOffering
  const ctaContent = isRoom ? (
    <>
      <RoomIcon color="white" width="25px" height="25px" />
      {RoomCustomText}
    </>
  ) : (
    <>
      <EventIcon color="white" width="25px" height="25px" />
      {RsvpCustomText}
    </>
  )

  return (
    <Link to={`/booking/${booking.id}${suffix}`}>
      <Button className={className}>
        {children ?? isBooked ? 'Details' : ctaContent}
        {childrenBelow}
      </Button>
    </Link>
  )
}
