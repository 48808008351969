import React, { useState, useMemo } from 'react'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import toLower from 'lodash/fp/toLower'
import orderBy from 'lodash/fp/orderBy'
import debounce from 'lodash/fp/debounce'
import includes from 'lodash/fp/includes'
import { Link, useLocation } from 'react-router-dom'
import { MdChevronLeft, MdDone } from 'react-icons/md'

import { MyEvent } from '../../../types'
import { SearchContainer, SearchIcon, SearchInput } from '../../Dropdown/style'

import { MobileSubMenu } from './style'

interface ISubmenu {
  title: string
  expandedSubmenu: React.Dispatch<React.SetStateAction<boolean>>
  events: MyEvent[]
  toggleMenu: () => void
}

const searchInputId = 'menu-mobile-search'
export const Submenu: React.FC<ISubmenu> = ({
  title,
  expandedSubmenu,
  events = [],
  toggleMenu,
}) => {
  const location = useLocation()
  const [subMenuList, openSubMenuList] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const showSearch = events.length > 10

  const activeEvent =
    events.length > 0
      ? events.find(ev => location.pathname.indexOf(`/event/${ev.id}`) === 0)
      : null

  const manageEvents = (ev: React.MouseEvent) => {
    ev.preventDefault()
    expandedSubmenu(!subMenuList)
    openSubMenuList(!subMenuList)
  }

  // Debounce the search term to avoid unnecessary re-renders
  const debouncedSetFilterTerm = useMemo(() => {
    const debounced = debounce(1000, (value: string) =>
      setSearchTerm(value.trim())
    )
    return (value: string) => {
      if (value.trim() === '') {
        setSearchTerm(value.trim())
      } else {
        debounced(value)
      }
    }
  }, [])

  const filtered = flow(
    filter((item: MyEvent) =>
      includes(toLower(searchTerm), toLower(item.name))
    ),
    orderBy([(item: MyEvent) => toLower(item.name)], ['asc'])
  )(events) as MyEvent[]

  return (
    <MobileSubMenu className={subMenuList ? 'open-submenu' : 'close-submenu'}>
      <a className="title-submenu" onClick={manageEvents}>
        <MdChevronLeft size={22} /> {title}
      </a>

      {showSearch && (
        <SearchContainer className="search-container px-0">
          <div style={{ position: 'relative' }}>
            <SearchIcon />
            <SearchInput
              type="text"
              id={searchInputId}
              placeholder="Search event..."
              onChange={e => debouncedSetFilterTerm(e.target.value)}
            />
          </div>
        </SearchContainer>
      )}
      <div className="link-list">
        {filtered?.map(event => (
          <Link
            key={event.id}
            to={`/event/${event.id}/settings`}
            onClick={toggleMenu}
          >
            {event.name}
            {activeEvent?.id === event.id && <MdDone />}
          </Link>
        ))}
      </div>
    </MobileSubMenu>
  )
}
