import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import getOr from 'lodash/fp/getOr'
import toLower from 'lodash/fp/toLower'
import Swal from 'sweetalert2'
import { MdMailOutline, MdLockOutline } from 'react-icons/md'

import AppContext from '../../context'
import { useForm } from '../../common/alterForms'
import { loaderAlert } from '../../common'
import { IgenericObject, ISignInUpPreloadedData } from '../../types'
import { KnTField } from '../../components'
import { gray } from '../../styles/themeColors'

interface ISignin {
  onClose: () => void
  callback?: () => void
  preloadedData?: ISignInUpPreloadedData
}

export const Signin: React.FC<ISignin> = ({
  onClose,
  preloadedData,
  callback,
}) => {
  const { auth } = useContext(AppContext)
  const [errors, setErrors] = useState({ loginEmail: '' })

  const initModel: IgenericObject = {
    loginEmail: getOr('', 'email', preloadedData),
    loginPassword: '',
  }
  const { model, handleFieldChange } = useForm(initModel)

  const login = async (ev: React.FormEvent) => {
    ev.preventDefault()
    const email = toLower(model.loginEmail)
    loaderAlert({ html: 'verifying credentials...' })

    try {
      const { data, error } = await auth.signIn({
        email,
        password: model.loginPassword,
      })
      Swal.close()

      if (error) {
        setErrors({ loginEmail: 'either password or email is incorrect.' })
      } else if (data) {
        onClose()
        callback?.()
      }
    } catch {
      setErrors({ loginEmail: 'Error connecting to server' })
      Swal.close()
    }
  }

  return (
    <Form data-cy="signin-form" onSubmit={login}>
      <KnTField
        label="Email"
        value={model}
        error={errors}
        setValue={handleFieldChange}
        name="loginEmail"
        placeholder="your-email@example.com"
        type="email"
        icon={<MdMailOutline size={24} color={gray} />}
      />
      <KnTField
        label="Password"
        value={model}
        error={errors}
        setValue={handleFieldChange}
        name="loginPassword"
        placeholder="Enter your password"
        type="password"
        icon={<MdLockOutline size={24} color={gray} />}
      />
      <Button className="w-100 mt-4" type="submit">
        Login
      </Button>
    </Form>
  )
}
