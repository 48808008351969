import React, { useState, useRef } from 'react'
import { MdVolumeOff, MdVolumeUp, MdPlayArrow } from 'react-icons/md'

import { VimeoMobileContainer, VimeoContainer } from '../styles/commonStyles'

export const VimeoMobile: React.FC<{
  url: string
  loop?: boolean
  muted?: boolean
  poster?: string
}> = ({ url, muted = false, poster, loop = true }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [mute, setMute] = useState(muted)
  const [isPlaying, setIsPlaying] = useState(false) // by default the video is not playing

  const handleMute = (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    ev.preventDefault()
    if (!videoRef.current) return
    setMute(!mute)
    if (videoRef.current.volume !== 1 && !mute) {
      videoRef.current.volume = 1
    }
  }

  const playVideo = (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    ev.preventDefault()
    if (!videoRef.current) return
    videoRef.current.play()
    setIsPlaying(true)
  }

  const stopVideo = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.preventDefault()
    if (!videoRef.current) return
    videoRef.current.pause()
    setIsPlaying(false)
  }

  return (
    <VimeoMobileContainer>
      <div className="frame-top" />
      <div className="frame-bottom" />
      <div className="frames-lr" onClick={stopVideo} />

      <video
        src={url}
        loop={loop}
        playsInline
        muted={mute}
        preload="auto"
        ref={videoRef}
        autoPlay={false}
        poster={poster}
      >
        <track kind="captions" />
      </video>
      {!isPlaying && (
        <MdPlayArrow
          size={40}
          color="white"
          aria-label="Play video"
          className="cursor shadow-sm play-vimeo"
          onClick={playVideo}
        />
      )}

      {mute ? (
        <MdVolumeOff
          size={20}
          color="white"
          arai-label="Unmute video"
          className="custom-volume-control cursor shadow-sm"
          onClick={handleMute}
        />
      ) : (
        <MdVolumeUp
          size={20}
          color="white"
          arai-label="Mute video"
          className="custom-volume-control cursor shadow-sm"
          onClick={handleMute}
        />
      )}
    </VimeoMobileContainer>
  )
}

export const Vimeo: React.FC<{
  url: string
  poster?: string
  style?: React.CSSProperties
  className?: string
  preload?: 'auto' | 'metadata' | 'none'
}> = ({ url, poster, style, className = '', preload = 'auto' }) => {
  // TODO: maybe add a param to pass captions, it's not necessary for now but its essential for deaf users to follow along
  // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/7566e13531f09a040daee4c16d0cba0c28d321c4/docs/rules/media-has-caption.md
  return (
    <VimeoContainer style={style} className={className}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        loop
        controls
        src={url}
        playsInline
        preload={preload}
        autoPlay={false}
        poster={poster}
      />
    </VimeoContainer>
  )
}
