import styled from 'styled-components'

import { gray, salmon } from '../../../../styles/themeColors'

export const EventBuilderCard = styled.article`
  align-items: stretch;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  position: relative;
  width: 100%;
  & .component-card-header {
    align-items: center;
    display: flex;
    & h2 {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      padding: 0 10px;
      width: calc(100% - 50px);
    }
    &.created {
      justify-content: space-between;
      & h2 {
        width: calc(100% - 150px);
      }
    }
  }
  & .cancel-booking {
    color: ${gray};
    bottom: 10px;
    position: absolute;
    right: 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: ${salmon};
      transition: all 0.2s ease-in-out;
    }
  }
  & .EventBuilderCard__actions {
    display: none !important;
  }
  & .EventBuilderCard__data {
    padding: 20px;
    width: 63%;
    & table thead {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    & .update-quantity {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      margin-top: 1rem;
      padding: 6px 5px;
    }
  }
  & .EventBuilderCard__package_customization {
    padding-top: 15px;
    & h6 {
      font-weight: 600;
    }
    & p {
      margin: 0 5px;
    }
  }
  & .EventBuilderCard__event__intro .eventcard__data {
    margin: 10px 0 0 0;
    padding: 0;
    & h3 {
      margin-bottom: 5px;
    }
    & ul {
      margin-bottom: 0;
      padding-left: 10px;
      & li {
        display: inline-block;
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  & .EventBuilderCard__open_modal {
    align-items: center;
    background-color: ${salmon};
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    left: 10px;
    position: absolute;
    top: 20px;
    width: 40px;
  }
  & .EventBuilderCard__image {
    min-height: 200px;
    position: relative;
    width: 37%;
  }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    justify-content: center;
    & .EventBuilderCard__image {
      width: fit-content;
    }
    & .EventBuilderCard__data {
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    padding-bottom: 40px;
  }
  @media (max-width: 400px) {
    & .EventBuilderCard__data {
      padding: 15px 10px 0 10px;
    }
  }
`

export const CollapsedCardContainer = styled.div`
  margin: 0 0 10px 0;
  & .card {
    background-color: transparent;
    border: none;
    overflow: visible !important;
    & .swiper-container {
      // temp fix for swiper bug
      overflow: hidden visible;
    }
    & .card-header {
      align-items: center;
      background-color: white;
      border-radius: 5px 5px 0 0;
      display: flex;
      padding: 0.6rem;
      & .custom-event-icon,
      & .custom-room-icon {
        width: 30px;
      }
      & .cta-booking {
        min-width: 105px;
        &.NEGOTIATING {
          min-width: unset;
        }
      }
      & .header-status {
        display: block;
        font-size: 15px;
        font-weight: 600;
        padding: 0 0 0 30px;
      }
      & h2 {
        font-size: 16px;
        font-weight: 700;
        margin: 0 0 5px 0;
        padding: 0 0 0 0.6rem;
        & a {
          display: block;
          font-size: 14px;
          font-weight: 600;
          text-decoration: underline;
          width: fit-content;
        }
      }
    }
  }
  @media (max-width: 600px) {
    & .card {
      & .card-header {
        flex-wrap: wrap;
        & .custom-event-icon,
        & .custom-room-icon {
          order: 1;
        }
        & .cta-booking {
          order: 4;
        }
        & h2 {
          order: 2;
          padding: 5px 0.3rem 0 0.3rem;
          width: 100%;
        }
        & .header-status {
          margin: 0 !important;
          order: 3;
          padding: 0 0 5px 0 !important;
          text-align: left;
          width: 100%;
        }
      }
    }
  }
`
