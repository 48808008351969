import React, { useState } from 'react'
import getOr from 'lodash/fp/getOr'
import TextareaAutosize from 'react-textarea-autosize'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'

import { fromNow, useCurrentUser, getMoreData } from '../../../common'
import { characterLimit } from '../../../common/constants'
import { IComments, Comment } from '../../../types'
import { CREATE_COMMENT_POST, GET_COMMENTS } from '../../../graphql'
import { DefaultUserPicture, UserPicture } from '../../../components'
import { NewComment, ViewReplies } from '../style'
import { useAppSelector } from '../../../hooks'

import { CommentsContainer } from './style'
import { addCommentCache } from './commentsCacheFunctions'

import { DisplayComment } from '.'

export const Comments: React.FC<IComments> = ({
  commentsPreview,
  postId,
  reply = false,
  count,
}) => {
  const { currentEventId } = useAppSelector(state => state.site)
  const { userId, avatarUrl, firstName } = useCurrentUser()
  const [showCount, setShowCount] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [newComment, setNewComment] = useState('')
  const [characterCount, setCharacterCount] = useState(0)
  const [
    getAllComments,
    { data: allComments, loading: loadingComments, fetchMore },
  ] = useLazyQuery(GET_COMMENTS, {
    variables: {
      postId,
    },
  })

  const entries: Comment[] = getOr([], 'comments.entries', allComments)
  const commentsList = entries.length > 0 ? entries : commentsPreview
  const currentCommentsCount = allComments
    ? entries.length
    : commentsPreview.length
  const cursorToken = getOr(null, 'comments.cursor', allComments)

  const [addComment, { loading }] = useMutation(CREATE_COMMENT_POST, {
    update(cache, { data: { createComment } }) {
      if (createComment.errors.length > 0) {
        setErrorMsg(getOr('', 'errors[0].message', createComment))
      } else {
        addCommentCache(
          cache,
          postId,
          entries.length <= 0,
          createComment.result,
          currentEventId
        )
      }
    },
  })

  const loadMore = () => {
    if (cursorToken) {
      // I'm not sure why but I get an error with the fetchMore of useLazyQuery but not with the fetchMore of useQuery
      getMoreData({
        // eslint-disable-next-line @typescript-eslint/ban-types
        fetchMore: fetchMore as Function,
        query: GET_COMMENTS,
        variables: { postId },
        cursorToken,
        path: 'comments',
      })
    }
  }

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Escape') {
      setNewComment('')
    } else if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault()
      if (!loading && newComment && characterCount <= characterLimit) {
        const variables = { input: { body: newComment }, postId }
        addComment({ variables }).then(({ data }) => {
          if (data.createComment.errors.length <= 0) {
            setNewComment('')
            setCharacterCount(0)
          } else {
            setErrorMsg(
              getOr(
                'your comment could not be sent, try again',
                'createComment.errors[0].message',
                data
              )
            )
          }
        })
      }
    }
  }

  const handleOnChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = ev.currentTarget.value || ''
    setCharacterCount(value.length)
    setNewComment(value)
  }

  return (
    <>
      {count > 2 && entries.length < count && (
        <ViewReplies
          onClick={() => {
            if (!loadingComments && entries.length === 0) {
              getAllComments()
            } else if (!loadingComments) {
              loadMore()
            }
          }}
        >
          View more comments{' '}
          <span className="px-2">
            {currentCommentsCount} of {count}
          </span>
        </ViewReplies>
      )}

      <CommentsContainer
        data-cy="comments-container"
        className={count > 0 ? 'p-2' : 'p-0'}
      >
        {commentsList.map(el => (
          <div className="py-2 w-100" key={el.id}>
            {el.user.avatarUrl ? (
              <UserPicture url={el.user.avatarUrl} name={el.user.firstName} />
            ) : (
              <DefaultUserPicture />
            )}
            <div className="comment pl-2">
              <DisplayComment
                preview={entries.length <= 0}
                singleComment={el}
                currentUser={userId}
                postId={postId}
              />
              <span className="actions pl-3">
                <small className="cursor mr-1 d-none">Like • </small>
                <small>{fromNow(el.insertedAt)}</small>
              </span>
            </div>
          </div>
        ))}
      </CommentsContainer>

      <NewComment className={` ${reply ? '' : 'd-none'}`}>
        {avatarUrl ? (
          <UserPicture url={avatarUrl} name={firstName} />
        ) : (
          <DefaultUserPicture />
        )}
        <TextareaAutosize
          className="ml-2"
          id={`${postId}_newComment`}
          value={newComment}
          placeholder="Write a comment…"
          name="body"
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          onFocus={() => !showCount && setShowCount(true)} // to not do unnecessary re-renders
          onBlur={() => characterCount === 0 && setShowCount(false)} // to not do unnecessary re-renders
        />
      </NewComment>
      {errorMsg && <small className="ml-5 text-danger">{errorMsg}</small>}
      {((characterCount > 0 && characterCount <= characterLimit) ||
        showCount) && (
        <small className="float-right mr-3 mt-1">
          remaining characters {characterLimit - characterCount}
        </small>
      )}
      {characterCount > characterLimit && (
        <small className="float-right mr-3 mt-1 text-danger">
          character limit exceeded
        </small>
      )}
    </>
  )
}
