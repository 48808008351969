import styled from 'styled-components'

import { salmon, gray } from '../../styles/themeColors'

export const Header = styled.header`
  align-items: center;
  display: flex;
  height: 400px;
  justify-content: center;
  text-align: center;
  & h1 {
    color: white;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    font-size: 45px;
    max-width: 1000px;
    width: 90%;
    @media (max-width: 600px) {
      font-size: 35px;
    }
  }
`

export const TeamMemberInfo = styled.article`
  margin: 0 30px;
  text-align: center;
  @media (max-width: 991px) {
    margin: 0 0 10px 0;
    width: 50%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`

export const AboutUsInfoSection = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  & h3 {
    color: ${salmon};
    font-family: LuckyFellas;
    font-size: 50px;
    margin: 0 auto;
    max-width: 520px;
  }
  & .info-section-content {
    max-width: 520px;
    width: 50%;
    & .sub-title {
      display: block;
      font-size: 13px;
      font-weight: 600;
      padding-bottom: 15px;
    }
    & p {
      color: ${gray};
      font-size: 18px;
      font-weight: 600;
      margin-top: 25px;
      &::after {
        content: '';
        display: block;
        width: 60px;
        height: 15px;
        margin: 0px auto;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }
      &:last-child::after {
        content: none;
      }
    }
  }
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    & .info-section-content {
      order: 2;
      width: 100% !important;
    }
    & img {
      order: 1;
      margin-bottom: 25px;
      max-width: 400px;
    }
  }
`

export const FeaturedOn = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 30px;
  max-width: 1100px;
  & img {
    align-self: center;
    filter: grayscale(95%);
    justify-self: center;
    max-height: 60px;
    max-width: 150px;
  }
`
