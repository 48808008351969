import React from 'react'
import flow from 'lodash/fp/flow'
import find from 'lodash/fp/find'
import orderBy from 'lodash/fp/orderBy'
import groupBy from 'lodash/fp/groupBy'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper/core'

import { getImageUrl, sortPackagesBySubEvent } from '../../../common'
import { Booking, EventComponent } from '../../../types'
import { offeringTypes } from '../../../common/constants'
import { RSVPctaLink, RSVPcta } from '../../SocialPanel/components'

import { MainProviderImage } from './style'

import { EventComponentOffering } from '.'

import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

SwiperCore.use([Navigation])

export const EventComponentsSlider: React.FC<{
  eventComponents: EventComponent[]
  isMyEvent: boolean
  myBookings?: Booking[]
}> = React.memo(({ eventComponents, isMyEvent, myBookings = [] }) => {
  const { roomOffering, packageOffering } = offeringTypes
  const filtered = groupBy(el => el.offering.__typename, eventComponents) // 1 groupBy instead of 2 filters

  const { provider } = eventComponents[0].offering
  const providerImage = getImageUrl(provider.mainImage, '400x225')

  const orderedPackages = flow(
    pkgs => sortPackagesBySubEvent(pkgs),
    orderBy(['date'], ['asc']) // TODO: refactor to not do too many sorts
  )(filtered[packageOffering]) as EventComponent[]
  return (
    <>
      {orderedPackages?.map(el => {
        const { id } = el
        const booking = find({ eventComponent: { id } }, myBookings) as Booking
        const customCTAtext =
          el.customTexts?.status?.[booking?.status as string] ||
          el.customTexts?.timeline
        return (
          <EventComponentOffering
            key={`package-${el.id}`}
            eventComponent={el}
            isMyEvent={isMyEvent}
            showMainIcon
          >
            {booking ? (
              <RSVPctaLink booking={booking} RsvpCustomText={customCTAtext} />
            ) : (
              <RSVPcta
                className="px-3 py-0"
                eventComponent={el}
                RsvpCustomText={customCTAtext}
              />
            )}
          </EventComponentOffering>
        )
      })}

      <Swiper style={{ width: '100%' }} navigation pagination spaceBetween={10}>
        {filtered[roomOffering]?.length > 0 && (
          <SwiperSlide>
            <MainProviderImage
              style={{
                backgroundImage: `url(${providerImage})`,
                marginTop: '5px',
              }}
            >
              <h4 className="text-center">Guest Rooms</h4>
            </MainProviderImage>
          </SwiperSlide>
        )}

        {filtered[roomOffering]?.map(el => (
          <SwiperSlide key={`slide-rooms-${el.id}`}>
            <EventComponentOffering eventComponent={el} isMyEvent={isMyEvent} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
})
