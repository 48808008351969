import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { v4 as uuidv4 } from 'uuid'
import { MdModeEdit } from 'react-icons/md'
import { PickerInline } from 'filestack-react'

import { FILESTACK_KEY } from '../common/constants'
import { PickerResponse, PickerFile, IUploadImg } from '../types'

export const UploadImg: React.FC<IUploadImg> = ({
  text,
  className = 'ml-3',
  variant = 'outline-secondary',
  icon = <MdModeEdit size={24} className="mr-1" />,
  onSuccess,
  msg,
  storeTo,
  aspectRatio = 1,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div>
        <Button
          className={className}
          variant={variant}
          onClick={() => setOpen(true)}
        >
          {icon} {text}
        </Button>

        {msg && <span className="d-block fz-11 ml-3 text-primary">{msg}</span>}
      </div>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        className="modal-w-60 modal-rounded-0"
      >
        <PickerInline
          apikey={FILESTACK_KEY}
          pickerOptions={{
            accept: ['image/jpeg', 'image/png'],
            fromSources: ['local_file_system'],
            transformations: {
              crop: {
                aspectRatio,
                force: true,
              },
              rotate: true,
              circle: false,
            },
            customText: {
              'Select Files to Upload': 'Select your photo',
            },
            onFileSelected(file: PickerFile) {
              // We only allow jpg and png right now -> accept: ['image/jpeg', 'image/png']
              let ext = 'png'
              if (file.mimetype === 'image/jpeg') {
                ext = 'jpg'
              }
              return { ...file, name: `${uuidv4()}.${ext}` }
            },
            storeTo,
          }}
          onSuccess={(result: PickerResponse) => {
            onSuccess(result)
            // just to prevent an error on console
            setTimeout(() => setOpen(false), 500)
          }}
        />
      </Modal>
    </>
  )
}
