import set from 'lodash/fp/set'
import find from 'lodash/fp/find'
import remove from 'lodash/fp/remove'
import findIndex from 'lodash/fp/findIndex'
import { ApolloCache } from '@apollo/client'

import { GET_COMMENTS, GET_EVENT_FEEDS } from '../../../graphql'
import { CommentsQuery, FeedQuery, Comment, Post } from '../../../types'

const updateCommentCount = (
  cache: ApolloCache<CommentsQuery | FeedQuery>,
  postId: string,
  n: number,
  eventId: string
) => {
  // update comment count
  const cachedFeed = cache.readQuery({
    query: GET_EVENT_FEEDS,
    variables: { eventIds: [eventId] },
  }) as FeedQuery

  const postIndex = findIndex({ id: postId }, cachedFeed.feed?.entries)
  let post = find({ id: postId }, cachedFeed.feed?.entries) as Post
  post = set('commentCount', post.commentCount + n, post)

  cache.writeQuery({
    query: GET_EVENT_FEEDS,
    variables: { eventIds: [eventId] },
    data: set(`feed.entries[${postIndex}]`, post, cachedFeed),
  })
}

export const deleteCommentCache = (
  cache: ApolloCache<CommentsQuery | FeedQuery>,
  postId: string,
  preview: boolean,
  commentId: string,
  eventId: string
): void => {
  // since commentPreview is used at startup there are two ways to update the cache
  if (preview) {
    // updating cache - post (commentsPreview)
    const cached = cache.readQuery({
      query: GET_EVENT_FEEDS,
      variables: { eventIds: [eventId] },
    }) as FeedQuery

    const postIndex = findIndex({ id: postId }, cached.feed?.entries)
    let post = find({ id: postId }, cached.feed?.entries) as Post
    post = set('commentCount', post.commentCount - 1, post)
    post = set(
      'commentsPreview',
      remove((c: Comment) => c.id === commentId, post.commentsPreview),
      post
    )

    cache.writeQuery({
      query: GET_EVENT_FEEDS,
      variables: { eventIds: [eventId] },
      data: set(`feed.entries[${postIndex}]`, post, cached),
    })
  } else {
    // updating cache - comments query
    const cached = cache.readQuery({
      query: GET_COMMENTS,
      variables: { postId },
    }) as CommentsQuery

    const comments = cached.comments?.entries as Comment[]
    cache.writeQuery({
      query: GET_COMMENTS,
      variables: { postId },
      data: set(
        `comments.entries`,
        remove((c: Comment) => c.id === commentId, comments),
        cached
      ),
    })

    // update comment count
    updateCommentCount(cache, postId, -1, eventId)
  }
}

export const addCommentCache = (
  cache: ApolloCache<CommentsQuery | FeedQuery>,
  postId: string,
  preview: boolean,
  result: Comment,
  eventId: string
): void => {
  // since commentPreview is used at startup there are two ways to add comments to the cache
  if (preview) {
    // updating cache - post (commentsPreview)
    const cached = cache.readQuery({
      query: GET_EVENT_FEEDS,
      variables: { eventIds: [eventId] },
    }) as FeedQuery

    const postIndex = findIndex({ id: postId }, cached.feed?.entries)
    let post = find({ id: postId }, cached.feed?.entries) as Post
    post = set('commentCount', post.commentCount + 1, post)
    post = set('commentsPreview', [result, ...post.commentsPreview], post)

    cache.writeQuery({
      query: GET_EVENT_FEEDS,
      variables: { eventIds: [eventId] },
      data: set(`feed.entries[${postIndex}]`, post, cached),
    })
  } else {
    // updating cache - comments query
    const cached = cache.readQuery({
      query: GET_COMMENTS,
      variables: { postId },
    }) as CommentsQuery

    const comments = cached.comments?.entries as Comment[]
    cache.writeQuery({
      query: GET_COMMENTS,
      variables: { postId },
      data: set(`comments.entries`, [result, ...comments], cached),
    })

    // update comment count
    updateCommentCount(cache, postId, 1, eventId)
  }
}
