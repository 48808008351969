import React, { useState, lazy, Suspense } from 'react'
import { MdPeople } from 'react-icons/md'

import { salmon } from '../../styles/themeColors'
import { Tier, Event, MyEvent } from '../../types'

import { TagsContainer, Tag } from './style'

const SublistDetails = lazy(() => import('./components/SublistDetails'))

export const RenderTiers: React.FC<{
  eventId: string
  events: (MyEvent | Event)[]
  tags: Tier[]
  color?: string
  type?: 'small' | 'normal'
}> = React.memo(
  ({ tags = [], color = salmon, type = 'small', eventId, events }) => {
    const [showGuestsModal, openGuestsModal] = useState(false)
    const [tier, setTier] = useState<Tier>()

    return (
      <TagsContainer>
        {tags.map(el => {
          return (
            <Tag
              className={`cursor ${type}`}
              style={{ backgroundColor: color }}
              key={el.id}
              title="Click to see the guests"
              onClick={() => {
                setTier(el)
                openGuestsModal(true)
              }}
            >
              {el.name}
            </Tag>
          )
        })}

        {tags.length <= 0 && (
          <Tag
            className={`cursor ${type}`}
            style={{ backgroundColor: color }}
            title="Click to see the guests"
            onClick={() => {
              openGuestsModal(true)
            }}
          >
            Guests <MdPeople size={16} />
          </Tag>
        )}

        {showGuestsModal && (
          <Suspense fallback={<span className="px-1">loading...</span>}>
            <SublistDetails
              open={showGuestsModal}
              onClose={() => openGuestsModal(false)}
              tier={tier}
              eventId={eventId}
              events={events}
            />
          </Suspense>
        )}
      </TagsContainer>
    )
  }
)
