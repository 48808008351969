import React, { useState, useRef } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import orderBy from 'lodash/fp/orderBy'
import toNumber from 'lodash/fp/toNumber'
import { Moment } from 'moment'

import Room from '../../../assets/images/icons/room.svg'
import { RoomOffering, IRoomsTab } from '../../../types'
import { orderMyEvents, useIsAgent } from '../../../common'
import { RoomCard, RangePicker, ValidationModal } from '../../../components'

import { TabContainer } from './style'

export const Rooms: React.FC<IRoomsTab> = ({
  roomsList,
  totalRooms,
  myEvents,
  cancellationPolicy,
}) => {
  const isAgent = useIsAgent()
  const initData = { duration: 0, date: '' }
  const [reservationData, setReservationData] = useState(initData)
  const [showModal, setShowModal] = useState(false)
  const [eventName, setEventName] = useState('')
  const inputEventRef = useRef<HTMLInputElement>(null)
  const myEventsRef = useRef<HTMLSelectElement>(null)

  const handleDateChange = (
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    if (startDate && endDate) {
      setReservationData({
        duration: endDate.diff(startDate, 'days') + 1,
        date: startDate.format('YYYY-MM-DD 00:00:00'),
      })
    }
  }

  const scrollToCheckInOut = () => {
    if ('activeElement' in document) {
      // to remove focus from active input and prevent infinite loop with the modal
      const activeElement = document.activeElement as HTMLElement
      activeElement?.blur()
    }
    myEventsRef.current?.focus() // helper
    inputEventRef.current?.focus() // helper
    setShowModal(true)
  }

  if (roomsList.length <= 0) return <></>

  const ordered = orderBy(
    (el: RoomOffering) => toNumber(el.price),
    'asc',
    roomsList
  )

  return (
    <>
      <ValidationModal
        title="When’s your gathering?"
        text="Enter Check in / Check out dates to proceed with room selection.<br /> Dates can be edited later in negotiation."
        show={showModal}
        onHide={() => setShowModal(false)}
      />

      {isAgent && (
        <>
          <div className="d-flex align-content-center mb-4">
            <img src={Room} alt="# of rooms" id="room-icon-provider" />
            <p className="fwsb fz-16 mb-0 ml-1">
              Total # of Rooms
              <span
                className="fz-14 d-block tc-lgray fwn"
                style={{ lineHeight: '1' }}
              >
                {totalRooms}
              </span>
            </p>
          </div>

          <Row className="pb-4">
            <Col sm={12} md={5} id="check-in-ou-container">
              <RangePicker
                id="check-in-ou-providerPage"
                emitDate={handleDateChange}
              />
            </Col>
            {myEvents && (
              <Col sm={12} md={3}>
                <p className="mb-1 pl-1 fz-15">
                  {myEvents.length > 0 ? 'Select your event' : 'Event Name'}
                </p>
                {myEvents.length <= 0 && (
                  <input
                    ref={inputEventRef}
                    type="text"
                    className="form-control no-focus mb-2 event-name-room"
                    placeholder="Enter you event name"
                    value={eventName}
                    onChange={ev => setEventName(ev.currentTarget.value)}
                  />
                )}
                {myEvents.length > 0 && (
                  <select
                    ref={myEventsRef}
                    className="custom-select w-75"
                    id="select-myEvent-provider"
                  >
                    {orderMyEvents(myEvents).map(myEvent => (
                      <option key={myEvent.id} value={myEvent.id}>
                        {myEvent.name}
                      </option>
                    ))}
                  </select>
                )}
              </Col>
            )}
            {cancellationPolicy && (
              <Col xs={12}>
                <p className="tc-lgray fz-13 mw-1200 pr-0 pr-md-5 pt-2 pt-md-3 mb-0">
                  {cancellationPolicy}
                </p>
              </Col>
            )}
          </Row>
        </>
      )}

      <TabContainer>
        {ordered.map((el: RoomOffering) => (
          <RoomCard
            validate={scrollToCheckInOut}
            eventName={eventName}
            myEvents={myEvents}
            myEventsRef={myEventsRef}
            className="margin"
            reservationData={reservationData}
            key={el.id}
            roomData={el}
          />
        ))}
      </TabContainer>
    </>
  )
}
