import React from 'react'

import { formatTime } from '../common'
import { PackageAddons } from '../types'

export const PackageCustomizations: React.FC<{
  customizations: PackageAddons[]
  className?: string
  compact?: boolean
}> = ({ compact, customizations, className = 'w-100 py-2 px-3 px-lg-0' }) => {
  if (customizations.length <= 0) return <></>

  if (compact) {
    return (
      <div className={className}>
        <ul className="p-0 m-0">
          {customizations.map(el => {
            const event = el.offeringAddOn.label
            const time = formatTime(el.time) || '--'
            return (
              <li key={el.id}>
                {event} | {el.location} | {time}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
  return (
    <div className={className}>
      <p className="mb-1 customizations-title">Schedule</p>
      <ul>
        {customizations.map(el => {
          const event = el.offeringAddOn.label
          const time = formatTime(el.time) || '--'
          const duration = el.offeringAddOnOption.label || '--'
          return (
            <li key={el.id}>
              {event} at {el.location} - {time} ({duration})
            </li>
          )
        })}
      </ul>
    </div>
  )
}
