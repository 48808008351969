import React, { useContext, lazy, Suspense } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from 'react-router-dom'
import { MdCreate, MdAccountCircle } from 'react-icons/md'

import AppContext from '../../context'
import { MyEvent } from '../../types'
import { SmallLoader } from '../../components'
import { gray } from '../../styles/themeColors'

import { VanityBoxContainer } from './style'

const MyInvitationsDropdown = lazy(
  () => import('./components/MyInvitationsDropdown')
)

interface IVanityBox {
  myEvents: MyEvent[]
}

export const VanityBox: React.FC<IVanityBox> = ({ myEvents }) => {
  const { state } = useContext(AppContext)
  const firstName = state.currentUser?.firstName
  const lastName = state.currentUser?.lastName
  const avatarUrl = state.currentUser?.avatarUrl

  return (
    <VanityBoxContainer data-cy="Vanity-Box" className="mb-3">
      <div className="manage-profile-picture">
        <Link to="/manage-profile">
          {avatarUrl ? (
            <img src={avatarUrl} alt={`${firstName} ${lastName}`} />
          ) : (
            <MdAccountCircle size={100} color={gray} />
          )}
          <MdCreate
            size={23}
            className="bg-primary rounded-circle p-1 cursor upload-icon"
            color="white"
            title="Edit profile"
          />
        </Link>
      </div>
      <p className="text-center fz-16 fwsb">
        {firstName} {lastName}
      </p>
      <ul className="info-list">
        <li>
          <Dropdown>
            <Dropdown.Toggle
              id="my-events"
              variant="outline-primary"
              className="p-0"
            >
              My Events
            </Dropdown.Toggle>

            <Dropdown.Menu id="my-events-body">
              {myEvents.map(ev => (
                <Dropdown.Item
                  as={Link}
                  to={`/event/${ev.id}/settings`}
                  key={`event-${ev.id}`}
                >
                  {ev.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <span>{myEvents.length}</span>
        </li>
        <li>
          <Suspense fallback={<SmallLoader />}>
            <MyInvitationsDropdown />
          </Suspense>
        </li>
        <li>
          My Room Bookings <span>0</span>
        </li>
      </ul>
    </VanityBoxContainer>
  )
}
