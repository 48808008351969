import React, { lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { Route } from 'react-router-dom'

import { SimpleLoader } from '../../components'

import { BookingPage } from './BookingPage'

const EditBookingAsHost = lazy(() => import('./EditBookingAsHost'))
const CustomPayment = lazy(() => import('./components/CustomPayment'))
const BookingDetails = lazy(() => import('./BookingDetails'))
const EditMyBooking = lazy(() => import('./EditMyBooking'))

export const Booking: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Kiss & Tell - Booking</title>
        <meta
          name="description"
          content="Book your room or package for the event and secure your spot now! Get all the details about your booking and save your flight details."
        />
      </Helmet>
      <Route exact path="/booking/:bookingId" component={BookingPage} />

      <Suspense fallback={<SimpleLoader />}>
        <Route
          exact
          path="/booking/:bookingId/payment"
          component={CustomPayment}
        />
      </Suspense>

      <Suspense fallback={<SimpleLoader />}>
        <Route
          exact
          path="/booking/:bookingId/details"
          component={BookingDetails}
        />
      </Suspense>

      <Suspense fallback={<SimpleLoader />}>
        <Route
          exact
          path="/booking/:bookingId/edit"
          component={EditBookingAsHost}
        />
      </Suspense>

      <Suspense fallback={<SimpleLoader />}>
        <Route
          exact
          path="/booking/:bookingId/edit-my-booking"
          component={EditMyBooking}
        />
      </Suspense>
    </>
  )
}
