import React from 'react'
import set from 'lodash/fp/set'
import getOr from 'lodash/fp/getOr'
import findIndex from 'lodash/fp/findIndex'
import { ApolloCache } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'
import { MdOutlinePushPin, MdPushPin } from 'react-icons/md'

import { simpleAlert } from '../../../common'
import { useAppSelector } from '../../../hooks'
import { SmallLoader } from '../../../components'
import { Post, Maybe, FeedQuery } from '../../../types'
import { PIN_POST, GET_EVENT_FEEDS, UNPIN_POST } from '../../../graphql'

import { PinPostButton } from './style'

export const PinPost: React.FC<{ post: Post }> = ({ post }) => {
  const { currentEventId, pinnedPostsCount } = useAppSelector(
    state => state.site
  )

  const updateCache = (cache: ApolloCache<FeedQuery>, postResult: Post) => {
    const cached: Maybe<FeedQuery> = cache.readQuery({
      query: GET_EVENT_FEEDS,
      variables: { eventIds: [currentEventId] },
    })

    if (cached) {
      const cachedPosts = getOr([], 'feed.entries', cached)
      const index = findIndex({ id: postResult.id }, cachedPosts)
      cache.writeQuery({
        query: GET_EVENT_FEEDS,
        variables: { eventIds: [currentEventId] },
        data:
          index > -1 ? set(`[${index}]`, postResult, cachedPosts) : cachedPosts,
      })
    }
  }

  const [pinPostMutation, { loading: pinning }] = useMutation(PIN_POST, {
    update(cache, { data: { pinPost } }) {
      if (pinPost.errors.length === 0) {
        updateCache(cache, pinPost.result)
      }
    },
  })

  const [unpinPostMutation, { loading: unpinning }] = useMutation(UNPIN_POST, {
    update(cache, { data: { unpinPost } }) {
      if (unpinPost.errors.length === 0) {
        updateCache(cache, unpinPost.result)
      }
    },
  })

  const pinPost = () => {
    if (pinnedPostsCount >= 2) {
      simpleAlert({
        html: 'You can only pin 2 posts at a time.<br />Please unpin one of the pinned posts first.',
        icon: 'info',
      })
      return
    }

    if (pinning) return
    pinPostMutation({ variables: { id: post.id } })
      .then(({ data }) => {
        if (data.pinPost.errors.length > 0) {
          simpleAlert({
            html: 'Was not possible to pin the post. Please try again later',
            icon: 'error',
          })
        } else {
          // scroll to firts pinned post
          const pinnedPosts = document.querySelector('.pinned-posts')
          if (!pinnedPosts) return

          pinnedPosts.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      })
      .catch(() => {
        simpleAlert({
          html: 'An error occurred while trying to pin the post.',
          icon: 'error',
        })
      })
  }

  const unpinPost = () => {
    if (unpinning) return
    unpinPostMutation({ variables: { id: post.id } })
      .then(({ data }) => {
        if (data.unpinPost.errors.length > 0) {
          simpleAlert({
            html: 'Was not possible to unpin the post. Please try again later',
            icon: 'error',
          })
        }
      })
      .catch(() => {
        simpleAlert({
          html: 'An error occurred while trying to unpin the post.',
          icon: 'error',
        })
      })
  }

  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (post.pinned) {
      unpinPost()
    } else {
      pinPost()
    }
  }

  if (pinning || unpinning) {
    return (
      <PinPostButton className="cursor">
        <SmallLoader />
      </PinPostButton>
    )
  }
  return (
    <PinPostButton className="cursor">
      {post.pinned ? (
        <MdPushPin onClick={handleClick} size={20} title="Unpin post" />
      ) : (
        <MdOutlinePushPin onClick={handleClick} size={20} title="Pin post" />
      )}
    </PinPostButton>
  )
}
