import styled from 'styled-components'

export const MobileSubMenu = styled.div`
  background-color: white;
  width: 100%;
  .search-container {
    display: none;
  }
  & .title-submenu svg {
    display: none;
  }
  & .link-list {
    display: none;
    max-height: 50vh;
    overflow-y: auto;
    width: 100%;
    & a svg {
      margin-left: 5px;
      position: relative;
      top: -1.5px;
    }
  }
  &.open-submenu {
    & .search-container {
      display: block;
    }
    & .link-list {
      display: block;
    }
    & .title-submenu {
      border-bottom: 2px solid rgba(0, 0, 0, 0.14);
      border-top: 2px solid rgba(0, 0, 0, 0.14);
      margin: 5px 0 6px 0;
      padding: 6px 5px 8px 5px;
      position: relative;
      text-align: center;
      width: 100%;
      & svg {
        display: block;
        position: absolute;
        top: 6px;
        left: 0px;
      }
    }
  }
`
