import React, { useEffect } from 'react'
import get from 'lodash/fp/get'
import sum from 'lodash/fp/sum'
import sumBy from 'lodash/fp/sumBy'

import { FormatNumber } from '../../../components'
import { setTotalPrice } from '../../../features/bookingSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { Booking, IcurrentAddons } from '../../../types'

import {
  extraGuests,
  extraAddons,
  useCalculateBookingTotals,
  renderSelectedExtraNights,
  getPercentageBreakdown,
} from './bookingFunctions'

export const RoomBookingBreakdown: React.FC<{
  currentAddons: IcurrentAddons
  booking: Booking
  editing?: boolean
}> = ({ currentAddons, booking, editing }) => {
  const dispatch = useAppDispatch()
  const {
    paidGuests = [],
    extraNights = [],
    customBookingDuration,
    availableAddons: { extraGuestAddon, nightsAfterAddon, nightsBeforeAddon },
  } = useAppSelector(state => state.booking)

  const { offering } = booking.eventComponent
  const duration = sum([
    customBookingDuration,
    -1,
    extraNights[0]?.quantity || 0, // we can only have 2 possible extra nights, after or before
    extraNights[1]?.quantity || 0,
  ])
  const { basePrice, total, tax, offeringPrice } = useCalculateBookingTotals(
    booking,
    duration,
    currentAddons
  )

  const { noTaxables, taxables } = currentAddons

  const addedAdults = sumBy(el => {
    if (el.type === 'adult') return 1
    return 0
  }, paidGuests)

  useEffect(() => {
    dispatch(setTotalPrice(total))
  }, [dispatch, total])

  const roomBasic = get('room.basic', offering)
  const taxPercentage = getPercentageBreakdown(
    get('provider.accomodationTaxRate', offering)
  )

  return (
    <div className="mb-3">
      <table data-cy="breakdown">
        <tbody>
          <tr className="fz-15">
            <td data-cy="book-duration">
              <FormatNumber n={total / duration} /> x {duration} nights:
            </td>
            <td className="pl-3" data-cy="total-room">
              <FormatNumber n={total} />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="fz-13 py-1">
              Rate includes {sum([roomBasic, addedAdults])} adult(s)
            </td>
          </tr>
          {editing && (
            <tr>
              <td colSpan={2} className="fz-16 pt-3 fwsb">
                Paid in full ✓
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={2}>
              <hr className="my-2" />
            </td>
          </tr>
          <tr className="fz-13">
            <td>Room Total:</td>
            <td className="pl-3 fwsb">
              <FormatNumber n={basePrice} />
            </td>
          </tr>
          <tr className="fz-13">
            <td>
              <span className="fz-12 pl-2">
                Room price ({roomBasic} guest{roomBasic > 1 ? 's' : ''}):
              </span>
            </td>
            <td className="pl-3">
              <FormatNumber n={offeringPrice} />
            </td>
          </tr>
          <tr className="fz-13">
            <td>
              <span className="fz-12 pl-4">Nightly</span>
            </td>
            <td className="pl-4">
              <FormatNumber n={offering.price} />
            </td>
          </tr>

          {paidGuests.map(el =>
            extraGuests({
              guest: el,
              addon: extraGuestAddon,
              duration,
            })
          )}

          {renderSelectedExtraNights({
            nights: extraNights,
            offering,
            addons: [nightsAfterAddon, nightsBeforeAddon],
          })}
          <tr>
            <td className="aux py-1" colSpan={2} />
          </tr>

          {taxables.map(el => extraAddons(el, basePrice))}
          <tr className="fz-13">
            <td>{taxPercentage}</td>
            <td className="pl-3">
              <FormatNumber n={tax} />
            </td>
          </tr>
          <tr>
            <td className="aux py-1" colSpan={2} />
          </tr>
          {noTaxables.map(el => extraAddons(el, basePrice))}
        </tbody>
      </table>
    </div>
  )
}
