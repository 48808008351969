import styled from 'styled-components'

export const VimeoVideo = styled.video`
  background-color: #000;
  border-radius: 5px;
  height: auto;
  width: 100%;
`

export const MainVideoSection = styled.section`
  background-color: white;
  border-radius: 5px;
  margin: 0 0 2rem 0;
  padding: 2rem;
  .info {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    h2 {
      font-size: 5rem;
      width: fit-content;
      img {
        display: block;
        margin: -10px 40px 0 auto;
        width: 160px;
      }
    }
  }
  @media (width <= 900px) {
    .info {
      h2 {
        font-size: 4rem;
      }
    }
  }
  @media (width < 768px) {
    padding: 1rem;
    .info {
      margin-bottom: 1rem;
      text-align: center;
      h2 {
        font-size: 5rem;
      }
    }
  }
  @media (width <= 500px) {
    padding: 1rem 5px 0 5px;
    .info {
      margin-bottom: 1rem;
      text-align: center;
      h2 {
        font-size: 4rem;
      }
    }
  }
`
