import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Link, useHistory } from 'react-router-dom'

import { EventIcon } from '../../../../components/customIcons'
import { useCurrentUser } from '../../../../common'
import { ICollapsedEvent, EventComponentStatus } from '../../../../types'
import { darkGray } from '../../../../styles/themeColors'
import {
  getComponentStatus,
  getCTAtext,
  getRemaining,
} from '../../eventFunctions'

import { CollapsedCardContainer } from './style'
import { useStartNegotiationEventComponent } from './eventBuilderFunctions'
import { AssignTiersToEventComponent } from './AssignTiersToEventComponent'

export const CollapsedCard: React.FC<ICollapsedEvent> = ({
  eventId,
  eventComponent,
  children,
  eventTiers = [],
  balances,
}) => {
  const { userId } = useCurrentUser()
  const history = useHistory()
  const [handleStartNegotiation, loadingNegotiate] =
    useStartNegotiationEventComponent(eventComponent.id)
  const { offering, status, tiers = [] } = eventComponent
  const { Created } = EventComponentStatus
  const { provider } = offering

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (loadingNegotiate) return

    if (status === Created) {
      handleStartNegotiation()
    } else {
      history.push(`/planning/${eventId}/${provider.id}`)
    }
  }

  const ecStatus = getComponentStatus({ eventComponent, balances })
  return (
    <CollapsedCardContainer className="shadow-sm" data-cy="builder-package">
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            variant="link"
            eventKey="0"
            className="flex-wrap"
          >
            <EventIcon color={darkGray} />
            <h2>
              {offering.name}
              <Link to={`/locations/${provider.url}`}>{provider.name}</Link>
            </h2>
            <span className="header-status">{ecStatus?.split('-')[1]}</span>
            <span className="header-status ml-auto mr-3">
              {balances &&
                getRemaining(
                  ecStatus,
                  balances[provider.id],
                  eventComponent.id
                )}
            </span>

            <Button className={`cta-booking ${status}`} onClick={handleClick}>
              {getCTAtext({
                ecStatus,
                balances,
                currentUser: userId,
                providerId: provider.id,
              })}
            </Button>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              {children as JSX.Element}

              <AssignTiersToEventComponent
                componentId={eventComponent.id}
                eventTiers={eventTiers}
                assignedTiers={tiers}
                type="Package"
              />
            </>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </CollapsedCardContainer>
  )
}
