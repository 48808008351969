import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import '../styles/policy.css'

const CustomLink: React.FC<{ to: string }> = ({ children, to }) => {
  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault()
    const element = document.querySelector(to)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    }
  }
  return (
    <a href={to} onClick={handleClick}>
      {children}
    </a>
  )
}
export const Privacy: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>Kiss & Tell | Privacy Policy</title>
        <meta
          name="description"
          content="Kiss & Tell | Privacy Policy: Your privacy matters. Learn how we protect your information."
        />
      </Helmet>
      <div className="WordSection1">
        <p className="MsoNormal">
          <b>
            <span lang="EN" style={{ fontSize: '19.5pt', lineHeight: '115%' }}>
              PRIVACY POLICY
            </span>
          </b>
        </p>
        <p className="MsoNormal mb-4">
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '115%',
                color: '#595959',
              }}
            >
              Last updated May 03, 2023
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            This privacy notice for Life Unfiltered Media, Inc (doing business
            as Kiss &amp; Tell) ("<b>Kiss &amp; Tell</b>," "<b>we</b>," "
            <b>us</b>," or "<b>our</b>"), describes how and why we might
            collect, store, use, and/or share ("<b>process</b>") your
            information when you use our services ("
            <b>Services</b>"), such as when you:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            ■ Visit our website at{' '}
          </span>
          <span lang="EN">
            <Link to="/">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                http://www.kissandtell.com
              </span>
            </Link>
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            , or any website of ours that links to this privacy notice
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            ■ Engage with us in other related ways, including any sales,
            marketing, or events
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Questions or concerns?{' '}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you still have any
            questions or concerns, please contact us at ben@kissandtell.com.
          </span>
        </p>
        <p className="MsoNormal mt-5" style={{ lineHeight: '150%' }}>
          <b>
            <span lang="EN" style={{ fontSize: '14.5pt', lineHeight: '150%' }}>
              SUMMARY OF KEY POINTS
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our{' '}
              </span>
            </i>
          </b>
          <span lang="EN">
            <CustomLink to="#toc">
              <b>
                <i>
                  <span
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#3030F1',
                    }}
                  >
                    table of contents
                  </span>
                </i>
              </b>
            </CustomLink>
          </span>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                {' '}
                below to find the section you are looking for.
              </span>
            </i>
          </b>
        </p>
        <p className="MsoNormal mt-4" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              What personal information do we process?
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            When you visit, use, or navigate our Services, we may process
            personal information depending on how you interact with Kiss &amp;
            Tell and the Services, the choices you make, and the products and
            features you use. Learn more about{' '}
          </span>
          <span lang="EN">
            <CustomLink to="#personalinfo">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                personal information you disclose to us.
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal mt-3" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Do we process any sensitive personal information?
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We do not process sensitive personal information.
          </span>
        </p>
        <p className="MsoNormal mt-3" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Do we receive any information from third parties?
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We do not receive any information from third parties.
          </span>
        </p>
        <p className="MsoNormal mt-3" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              How do we process your information?
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention,
            and to comply with law. We may also process your information for
            other purposes with your consent. We process your information only
            when we have a valid legal reason to do so. Learn more about{' '}
          </span>
          <span lang="EN">
            <CustomLink to="#infouse">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                how we process your information.
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal mt-3" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              In what situations and with which parties do we share personal
              information?
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may share information in specific situations and with specific
            third parties. Learn more about{' '}
          </span>
          <span lang="EN">
            <CustomLink to="#whoshare">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                when and with whom we share your personal information.
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal mt-3" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              How do we keep your information safe?
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We have organizational and technical processes and procedures in
            place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information. Learn more about{' '}
          </span>
          <span lang="EN">
            <CustomLink to="#infosafe">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                how we keep your information safe.
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal mt-3" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              What are your rights?
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            Depending on where you are located geographically, the applicable
            privacy law may mean you have certain rights regarding your personal
            information. Learn more about{' '}
          </span>
          <span lang="EN">
            <CustomLink to="#privacyrights">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                your privacy rights.
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal mt-3" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              How do you exercise your rights?
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            The easiest way to exercise your rights is by visiting{' '}
            <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>, or
            by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </span>
        </p>
        <p className="MsoNormal mt-5" style={{ lineHeight: '150%' }}>
          <b>
            <span lang="EN" style={{ fontSize: '14.5pt', lineHeight: '150%' }}>
              TABLE OF CONTENTS
            </span>
          </b>
        </p>
        <p className="MsoNormal mt-2" style={{ lineHeight: '150%' }} id="toc">
          <span lang="EN">
            <CustomLink to="#infocollect">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                1. WHAT INFORMATION DO WE COLLECT?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#infouse">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#legalbases">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                INFORMATION?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#whoshare">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#cookies">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#inforetain">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                6. HOW LONG DO WE KEEP YOUR INFORMATION?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#infosafe">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                7. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#infominors">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                8. DO WE COLLECT INFORMATION FROM MINORS?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#privacyrights">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                9. WHAT ARE YOUR PRIVACY RIGHTS?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#DNT">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                10. CONTROLS FOR DO-NOT-TRACK FEATURES
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#caresidents">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#virginia">
              <span
                style={{
                  fontSize: '11.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#policyupdates">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                13. DO WE MAKE UPDATES TO THIS NOTICE?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#contact">
              <span
                style={{
                  fontSize: '11.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span lang="EN">
            <CustomLink to="#request">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal mt-5" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="infocollect"
            >
              1. WHAT INFORMATION DO WE COLLECT?
            </span>
          </b>
        </p>
        <p
          className="MsoNormal mt-3"
          style={{ lineHeight: '150%' }}
          id="personalinfo"
        >
          <b>
            <span lang="EN" style={{ fontSize: '13.0pt', lineHeight: '150%' }}>
              Personal information you disclose to us
            </span>
          </b>
        </p>
        <p className="MsoNormal mt-3" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:{' '}
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              We collect personal information that you provide to us.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Personal Information Provided by You.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            The personal information that we collect depends on the context of
            your interactions with us and the Services, the choices you make,
            and the products and features you use. The personal information we
            collect may include the following:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN">■ </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            names
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN">■ </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            phone numbers
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN">■ </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            email addresses
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN">■ </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            usernames
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN">■ </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            passwords
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN">■ </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            contact preferences
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN">■ </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            contact or authentication data
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN">■ </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            billing addresses
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN">■ </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            debit/credit card numbers
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Sensitive Information.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We do not process sensitive information.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Payment Data.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may collect data necessary to process your payment if you make
            purchases, such as your payment instrument number, and the security
            code associated with your payment instrument. All payment data is
            stored by Stripe. You may find their privacy notice link(s) here:{' '}
          </span>
          <span lang="EN">
            <a href="https://stripe.com/privacy">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                https://stripe.com/privacy
              </span>
            </a>
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span lang="EN" style={{ fontSize: '13.0pt', lineHeight: '150%' }}>
              Information automatically collected
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:{' '}
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Some information — such as your Internet Protocol (IP) address
              and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            The information we collect includes:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '14px' }}>
            ■
          </span>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Log and Usage Data.
            </span>
          </i>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            Log and usage data is service-related, diagnostic, usage, and
            performance information our servers automatically collect when you
            access or use our Services and which we record in log files.
            Depending on how you interact with us, this log data may include
            your IP address, device information, browser type, and settings and
            information about your activity in the Services (such as the
            date/time stamps associated with your usage, pages and files viewed,
            searches, and other actions you take such as which features you
            use), device event information (such as system activity, error
            reports (sometimes called "crash dumps"), and hardware settings).
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '14px' }}>
            ■
          </span>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Device Data.
            </span>
          </i>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We collect device data such as information about your computer,
            phone, tablet, or other device you use to access the Services.
            Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '14px' }}>
            ■
          </span>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Location Data.
            </span>
          </i>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We collect location data such as information about your device's
            location, which can be either precise or imprecise. How much
            information we collect depends on the type and settings of the
            device you use to access the Services. For example, we may use GPS
            and other technologies to collect geolocation data that tells us
            your current location (based on your IP address). You can opt out of
            allowing us to collect this information either by refusing access to
            the information or by disabling your Location setting on your
            device. However, if you choose to opt out, you may not be able to
            use certain aspects of the Services.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="infouse"
            >
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:{' '}
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To facilitate account creation and authentication and otherwise
              manage user accounts.{' '}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To deliver and facilitate delivery of services to the user.{' '}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may process your information to provide you with the requested
            service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To respond to user inquiries/offer support to users.{' '}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may process your information to respond to your inquiries and
            solve any potential issues you might have with the requested
            service.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To send administrative information to you.{' '}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may process your information to send you details about our
            products and services, changes to our terms and policies, and other
            similar information.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To fulfill and manage your orders.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may process your information to fulfill and manage your orders,
            payments, returns, and exchanges made through the Services.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To enable user-to-user communications.{' '}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may process your information if you choose to use any of our
            offerings that allow for communication with another user.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To request feedback.{' '}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may process your information when necessary to request feedback
            and to contact you about your use of our Services.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To send you marketing and promotional communications.{' '}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may process the personal information you send to us for our
            marketing purposes, if this is in accordance with your marketing
            preferences. You can opt out of our marketing emails at any time.
            For more information, see "
          </span>
          <span lang="EN">
            <CustomLink to="#privacyrights">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                WHAT ARE YOUR PRIVACY RIGHTS?
              </span>
            </CustomLink>
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            " below).
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To protect our Services.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may process your information as part of our efforts to keep our
            Services safe and secure, including fraud monitoring and prevention.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To identify usage trends.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may process information about how you use our Services to better
            understand how they are being used so we can improve them.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To determine the effectiveness of our marketing and promotional
              campaigns.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may process your information to better understand how to provide
            marketing and promotional campaigns that are most relevant to you.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              To save or protect an individual's vital interest.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may process your information when necessary to save or protect an
            individual's vital interest, such as to prevent harm.
          </span>
        </p>
        <p className=" mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="legalbases"
            >
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:{' '}
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              We only process your personal information when we believe it is
              necessary and we have a valid legal reason (i.e., legal basis) to
              do so under applicable law, like with your consent, to comply with
              laws, to provide you with services to enter into or fulfill our
              contractual obligations, to protect your rights, or to fulfill our
              legitimate business interests.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <u>
                <span
                  lang="EN"
                  style={{
                    fontSize: '10.5pt',
                    lineHeight: '150%',
                    color: '#595959',
                  }}
                >
                  If you are located in the EU or UK, this section applies to
                  you.
                </span>
              </u>
            </i>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Consent.{' '}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may process your information if you have given us permission
            (i.e., consent) to use your personal information for a specific
            purpose. You can withdraw your consent at any time. Learn more about{' '}
          </span>
          <span lang="EN">
            <CustomLink to="#withdrawconsent">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                withdrawing your consent.
              </span>
            </CustomLink>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Performance of a Contract.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may process your personal information when we believe it is
            necessary to fulfill our contractual obligations to you, including
            providing our Services or at your request prior to entering into a
            contract with you.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Legitimate Interests.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may process your information when we believe it is reasonably
            necessary to achieve our legitimate business interests and those
            interests do not outweigh your interests and fundamental rights and
            freedoms. For example, we may process your personal information for
            some of the purposes described in order to:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '66.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Send users information about special offers and discounts on our
            products and services
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '66.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Analyze how our Services are used so we can improve them to engage
            and retain users
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '66.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Support our marketing activities
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '66.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Diagnose problems and/or prevent fraudulent activities
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '66.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Understand how our users use our products and services so we can
            improve user experience
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Legal Obligations.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may process your information where we believe it is necessary for
            compliance with our legal obligations, such as to cooperate with a
            law enforcement body or regulatory agency, exercise or defend our
            legal rights, or disclose your information as evidence in litigation
            in which we are involved.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Vital Interests.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may process your information where we believe it is necessary to
            protect your vital interests or the vital interests of a third
            party, such as situations involving potential threats to the safety
            of any person.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <u>
                <span
                  lang="EN"
                  style={{
                    fontSize: '10.5pt',
                    lineHeight: '150%',
                    color: '#595959',
                  }}
                >
                  If you are located in Canada, this section applies to you.
                </span>
              </u>
            </i>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may process your information if you have given us specific
            permission (i.e., express consent) to use your personal information
            for a specific purpose, or in situations where your permission can
            be inferred (i.e., implied consent). You can{' '}
          </span>
          <span lang="EN">
            <CustomLink to="#withdrawconsent">
              <span
                style={{
                  fontSize: '11.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                withdraw your consent
              </span>
            </CustomLink>
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            at any time.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            In some exceptional cases, we may be legally permitted under
            applicable law to process your information without your consent,
            including, for example:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            For investigations and fraud detection and prevention
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            For business transactions provided certain conditions are met
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            For identifying injured, ill, or deceased persons and communicating
            with next of kin
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If we have reasonable grounds to believe an individual has been, is,
            or may be victim of financial abuse
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If the collection is solely for journalistic, artistic, or literary
            purposes
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If the information is publicly available and is specified by the
            regulations
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="whoshare"
            >
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              {' '}
              We may share information in specific situations described in this
              section and/or with the following third parties.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may need to share your personal information in the following
            situations:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Business Transfers.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to
            another company.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              When we use Google Maps Platform APIs.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may share your information with certain Google Maps Platform APIs
            (e.g., Google Maps API, Places API). We obtain and store on your
            device ("cache") your location. You may revoke your consent anytime
            by contacting us at the contact details provided at the end of this
            document.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Affiliates.{' '}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may share your information with our affiliates, in which case we
            will require those affiliates to honor this privacy notice.
            Affiliates include our parent company and any subsidiaries, joint
            venture partners, or other companies that we control or that are
            under common control with us.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Business Partners.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            We may share your information with our business partners to offer
            you certain products, services, or promotions.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '10px' }}>
            ■
          </span>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Other Users.
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            When you share personal information (for example, by posting
            comments, contributions, or other content to the Services) or
            otherwise interact with public areas of the Services, such personal
            information may be viewed by all users and may be publicly made
            available outside the Services in perpetuity. Similarly, other users
            will be able to view descriptions of your activity, communicate with
            you within our Services, and view your profile.
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="cookies"
            >
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              {' '}
              We may use cookies and other tracking technologies to collect and
              store your information.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="inforetain"
            >
              6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:{' '}
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              We keep your information for as long as necessary to fulfill the
              purposes outlined in this privacy notice unless otherwise required
              by law.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than three (3) months past the termination of the user's account.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="infosafe"
            >
              7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:{' '}
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              We aim to protect your personal information through a system of
              organizational and technical security measures.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="infominors"
            >
              8. DO WE COLLECT INFORMATION FROM MINORS?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              {' '}
              We do not knowingly collect data from or market to children under
              18 years of age.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent's use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at{' '}
            <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>.
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="privacyrights"
            >
              9. WHAT ARE YOUR PRIVACY RIGHTS?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              {' '}
              In some regions, such as the European Economic Area (EEA), United
              Kingdom (UK), and Canada, you have rights that allow you greater
              access to and control over your personal information. You may
              review, change, or terminate your account at any time.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            In some regions (like the EEA, UK, and Canada), you have certain
            rights under applicable data protection laws. These may include the
            right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section "
          </span>
          <span lang="EN">
            <CustomLink to="#contact">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </CustomLink>
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            " below.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your{' '}
          </span>
          <span lang="EN">
            <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
              <span
                style={{
                  fontSize: '11.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                Member State data protection authority
              </span>
            </a>
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            or{' '}
          </span>
          <span lang="EN">
            <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                UK data protection authority.
              </span>
            </a>
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If you are located in Switzerland, you may contact the{' '}
          </span>
          <span lang="EN">
            <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                Federal Data Protection and Information Commissioner.
              </span>
            </a>
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <u>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
                id="withdrawconsent"
              >
                Withdrawing your consent:
              </span>
            </u>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending
            on the applicable law, you have the right to withdraw your consent
            at any time. You can withdraw your consent at any time by contacting
            us by using the contact details provided in the section "
          </span>
          <span lang="EN">
            <CustomLink to="#contact">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </CustomLink>
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            " below or updating your preferences.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <u>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                Opting out of marketing and promotional communications:{' '}
              </span>
            </u>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            You can unsubscribe from our marketing and promotional
            communications at any time by clicking on the unsubscribe link in
            the emails that we send, replying "STOP" or "UNSUBSCRIBE" to the SMS
            messages that we send, or by contacting us using the details
            provided in the section "
          </span>
          <span lang="EN">
            <CustomLink to="#contact">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </CustomLink>
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            " below. You will then be removed from the marketing lists. However,
            we may still communicate with you — for example, to send you
            service-related messages that are necessary for the administration
            and use of your account, to respond to service requests, or for
            other non-marketing purposes.
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span lang="EN" style={{ fontSize: '13.0pt', lineHeight: '150%' }}>
              Account Information
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Contact us using the contact information provided.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Log in to your account settings and update your user account.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <u>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                Cookies and similar technologies:
              </span>
            </u>
          </b>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Services. You may also{' '}
          </span>
          <span lang="EN">
            <a href="http://www.aboutads.info/choices/">
              <span
                style={{
                  fontSize: '11.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                opt out of interest-based advertising by advertisers
              </span>
            </a>
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            on our Services.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If you have questions or comments about your privacy rights, you may
            email us at{' '}
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="DNT"
            >
              10. CONTROLS FOR DO-NOT-TRACK FEATURES
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="caresidents"
            >
              11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:{' '}
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Yes, if you are a resident of California, you are granted specific
              rights regarding access to your personal information.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If you are under 18 years of age, reside in California, and have a
            registered account with Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g., backups, etc.).
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span lang="EN" style={{ fontSize: '13.0pt', lineHeight: '150%' }}>
              CCPA Privacy Notice
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            The California Code of Regulations defines a "resident" as:
          </span>
        </p>
        <p
          className="mt-3 MsoNormal"
          style={{ marginLeft: '15.0pt', lineHeight: '150%' }}
        >
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            (1) every individual who is in the State of California for other
            than a temporary or transitory purpose and
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginLeft: '15.0pt', lineHeight: '150%' }}
        >
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            (2) every individual who is domiciled in the State of California who
            is outside the State of California for a temporary or transitory
            purpose
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            All other individuals are defined as "non-residents."
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If this definition of "resident" applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              What categories of personal information do we collect?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </span>
        </p>
        <table
          className="a mt-3 "
          cellSpacing={0}
          cellPadding={0}
          width={624}
          style={{ borderCollapse: 'collapse', border: 'none' }}
        >
          <tbody>
            <tr style={{ height: '24.25pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '24.25pt',
                }}
              >
                <p className="MsoNormal">
                  <b>
                    <span
                      lang="EN"
                      style={{
                        fontSize: '10.5pt',
                        lineHeight: '115%',
                        color: '#595959',
                      }}
                    >
                      Category
                    </span>
                  </b>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '24.25pt',
                }}
              >
                <p className="MsoNormal">
                  <b>
                    <span
                      lang="EN"
                      style={{
                        fontSize: '10.5pt',
                        lineHeight: '115%',
                        color: '#595959',
                      }}
                    >
                      Examples
                    </span>
                  </b>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '24.25pt',
                }}
              >
                <p className="MsoNormal" style={{ textAlign: 'center' }}>
                  <b>
                    <span
                      lang="EN"
                      style={{
                        fontSize: '10.5pt',
                        lineHeight: '115%',
                        color: '#595959',
                      }}
                    >
                      Collected
                    </span>
                  </b>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.0pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    A. Identifiers
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Contact details, such as real name, alias, postal address,
                    telephone or mobile contact number, unique personal
                    identifier, online identifier, Internet Protocol address,
                    email address, and account name
                  </span>
                </p>
              </td>
              <td
                width={95}
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    YES
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.0pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    B. Personal information categories listed in the California
                    Customer Records statute
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Name, contact information, education, employment, employment
                    history, and financial information
                  </span>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    YES
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.0pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    C. Protected classification characteristics under California
                    or federal law
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Gender and date of birth
                  </span>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    NO
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.0pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    D. Commercial information
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Transaction information, purchase history, financial
                    details, and payment information
                  </span>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    YES
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.0pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    E. Biometric information
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Fingerprints and voiceprints
                  </span>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    NO
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.0pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    F. Internet or other similar network activity
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Browsing history, search history, online behavior, interest
                    data, and interactions with our and other websites,
                    applications, systems, and advertisements
                  </span>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    NO
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.0pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    G. Geolocation data
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Device location
                  </span>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    NO
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.0pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    H. Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Images and audio, video or call recordings created in
                    connection with our business activities
                  </span>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    NO
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.0pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    I. Professional or employment-related information
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Business contact details in order to provide you our
                    Services at a business level or job title, work history, and
                    professional qualifications if you apply for a job with us
                  </span>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    NO
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.0pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderBottom: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    J. Education Information
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Student records and directory information
                  </span>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'solid black 1.0pt',
                  borderLeft: 'none',
                  borderBottom: 'none',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.0pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    NO
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '64.75pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.75pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    K. Inferences drawn from other personal information
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  border: 'solid black 1.0pt',
                  borderLeft: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.75pt',
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: '150%' }}>
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    Inferences drawn from any of the collected personal
                    information listed above to create a profile or summary
                    about, for example, an individual's preferences and
                    characteristics
                  </span>
                </p>
              </td>
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  border: 'solid black 1.0pt',
                  borderLeft: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '64.75pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    NO
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: '59.5pt' }}>
              <td
                width={211}
                valign="middle"
                style={{
                  width: '158.2pt',
                  border: 'solid black 1.0pt',
                  borderTop: 'none',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '59.5pt',
                }}
              >
                <p className="MsoNormal">
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '115%',
                      color: '#595959',
                    }}
                  >
                    L. Sensitive Personal Information
                  </span>
                </p>
              </td>
              <td
                width={318}
                valign="middle"
                style={{
                  width: '238.75pt',
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderBottom: 'solid black 1.0pt',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '59.5pt',
                }}
              />
              <td
                width={95}
                valign="middle"
                style={{
                  width: '71.0pt',
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderBottom: 'solid black 1.0pt',
                  borderRight: 'solid black 1.0pt',
                  padding: '5.0pt 5.0pt 5.0pt 5.0pt',
                  height: '59.5pt',
                }}
              >
                <p
                  className="MsoNormal"
                  style={{ textAlign: 'center', lineHeight: '150%' }}
                >
                  <span
                    lang="EN"
                    style={{
                      fontSize: '10.5pt',
                      lineHeight: '150%',
                      color: '#595959',
                    }}
                  >
                    NO
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We will use and retain the collected personal information as needed
            to provide the Services or for:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Category A - As long as the user has an account with us
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Category B - As long as the user has an account with us
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Category D - As long as the user has an account with us
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Receiving help through our customer support channels;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Participation in customer surveys or contests; and
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              How do we use and share your personal information?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            You may contact us by email at{' '}
            <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>, or
            by referring to the contact details at the bottom of this document.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If you are using an authorized agent to exercise your right to opt
            out we may deny a request if the authorized agent does not submit
            proof that they have been validly authorized to act on your behalf.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Will your information be shared with anyone else?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf, following the same strict privacy
            protection obligations mandated by the CCPA.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal information.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Life Unfiltered Media, Inc has not disclosed, sold, or shared any
            personal information to third parties for a business or commercial
            purpose in the preceding twelve (12) months. Life Unfiltered Media,
            Inc will not sell or share personal information in the future
            belonging to website visitors, users, and other consumers.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Your rights with respect to your personal data
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <u>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Right to request deletion of the data — Request to delete
            </span>
          </u>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <u>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Right to be informed — Request to know
            </span>
          </u>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Depending on the circumstances, you have a right to know:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            whether we collect and use your personal information;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            the categories of personal information that we collect;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            the purposes for which the collected personal information is used;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            whether we sell or share personal information to third parties;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            the categories of personal information that we sold, shared, or
            disclosed for a business purpose;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            the categories of third parties to whom the personal information was
            sold, shared, or disclosed for a business purpose;
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            the business or commercial purpose for collecting, selling, or
            sharing personal information; and
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            the specific pieces of personal information we collected about you.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <u>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Right to Non-Discrimination for the Exercise of a Consumer's
              Privacy Rights
            </span>
          </u>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We will not discriminate against you if you exercise your privacy
            rights.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <u>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Right to Limit Use and Disclosure of Sensitive Personal
              Information
            </span>
          </u>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We do not process consumer's sensitive personal information.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <u>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Verification process
            </span>
          </u>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g., phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <u>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Other privacy rights
            </span>
          </u>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '14px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            You may object to the processing of your personal information.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '14px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            You may request correction of your personal data if it is incorrect
            or no longer relevant, or ask to restrict the processing of the
            information.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '14px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            You can designate an authorized agent to make a request under the
            CCPA on your behalf. We may deny a request from an authorized agent
            that does not submit proof that they have been validly authorized to
            act on your behalf in accordance with the CCPA.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" style={{ paddingRight: '14px' }}>
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            You may request to opt out from future selling or sharing of your
            personal information to third parties. Upon receiving an opt-out
            request, we will act upon the request as soon as feasibly possible,
            but no later than fifteen (15) days from the date of the request
            submission.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            To exercise these rights, you can contact us by email at{' '}
            <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>, or
            by referring to the contact details at the bottom of this document.
            If you have a complaint about how we handle your data, we would like
            to hear from you.
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="virginia"
            >
              12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              {' '}
              Yes, if you are a resident of Virginia, you may be granted
              specific rights regarding access to and use of your personal
              information.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span lang="EN" style={{ fontSize: '13.0pt', lineHeight: '150%' }}>
              Virginia CDPA Privacy Notice
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Under the Virginia Consumer Data Protection Act (CDPA):
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            "Consumer" means a natural person who is a resident of the
            Commonwealth acting only in an individual or household context. It
            does not include a natural person acting in a commercial or
            employment context.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            "Personal data" means any information that is linked or reasonably
            linkable to an identified or identifiable natural person. "Personal
            data" does not include de-identified data or publicly available
            information.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            "Sale of personal data" means the exchange of personal data for
            monetary consideration.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If this definition "consumer" applies to you, we must adhere to
            certain rights and obligations regarding your personal data.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            The information we collect, use, and disclose about you will vary
            depending on how you interact with Life Unfiltered Media, Inc and
            our Services. To find out more, please visit the following links:
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span lang="EN">
            <CustomLink to="#infocollect">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                Personal data we collect
              </span>
            </CustomLink>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span lang="EN">
            <CustomLink to="#infouse">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                How we use your personal data
              </span>
            </CustomLink>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span lang="EN">
            <CustomLink to="#whoshare">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                When and with whom we share your personal data
              </span>
            </CustomLink>
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <u>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Your rights with respect to your personal data
            </span>
          </u>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '12.0pt',
            marginRight: '0cm',
            marginBottom: '.0001pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Right to be informed whether or not we are processing your personal
            data
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Right to access your personal data
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Right to correct inaccuracies in your personal data
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Right to request deletion of your personal data
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Right to obtain a copy of the personal data you previously shared
            with us
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginTop: '0cm',
            marginRight: '0cm',
            marginBottom: '12.0pt',
            marginLeft: '36.0pt',
            textIndent: '-18.0pt',
            lineHeight: '150%',
          }}
        >
          <span lang="EN" className="pr-2">
            ■
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ("profiling")
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Life Unfiltered Media, Inc
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            has not sold any personal data to third parties for business or
            commercial purposes.{' '}
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Life Unfiltered Media, Inc
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
            will not sell personal data in the future belonging to website
            visitors, users, and other consumers.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <u>
            <span
              lang="EN"
              style={{
                fontSize: '11.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Exercise your rights provided under the Virginia CDPA
            </span>
          </u>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            You may contact us by email at{' '}
            <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>, by
            visiting{' '}
            <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>, or
            by referring to the contact details at the bottom of this document.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If you are using an authorized agent to exercise your rights, we may
            deny a request if the authorized agent does not submit proof that
            they have been validly authorized to act on your behalf.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <u>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Verification process
            </span>
          </u>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may request that you provide additional information reasonably
            necessary to verify you and your consumer's request. If you submit
            the request through an authorized agent, we may need to collect
            additional information to verify your identity before processing
            your request.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Upon receiving your request, we will respond without undue delay,
            but in all cases, within forty-five (45) days of receipt. The
            response period may be extended once by forty-five (45) additional
            days when reasonably necessary. We will inform you of any such
            extension within the initial 45-day response period, together with
            the reason for the extension.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <u>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Right to appeal
            </span>
          </u>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If we decline to take action regarding your request, we will inform
            you of our decision and reasoning behind it. If you wish to appeal
            our decision, please email us at{' '}
            <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>.
            Within sixty (60) days of receipt of an appeal, we will inform you
            in writing of any action taken or not taken in response to the
            appeal, including a written explanation of the reasons for the
            decisions. If your appeal if denied, you may contact the{' '}
          </span>
          <span lang="EN">
            <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">
              <span
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#3030F1',
                }}
              >
                Attorney General to submit a complaint.
              </span>
            </a>
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="policyupdates"
            >
              13. DO WE MAKE UPDATES TO THIS NOTICE?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <i>
              <span
                lang="EN"
                style={{
                  fontSize: '10.5pt',
                  lineHeight: '150%',
                  color: '#595959',
                }}
              >
                In Short:{' '}
              </span>
            </i>
          </b>
          <i>
            <span
              lang="EN"
              style={{
                fontSize: '10.5pt',
                lineHeight: '150%',
                color: '#595959',
              }}
            >
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </span>
          </i>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="contact"
            >
              14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            If you have questions or comments about this notice, you may email
            us at <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>{' '}
            or by post to:
          </span>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Life Unfiltered Media, Inc
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            701 Brazos St 16th Fl.
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Austin
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            ,{' '}
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            TX
          </span>
          <span
            lang="EN"
            style={{ fontSize: '11.5pt', lineHeight: '150%', color: '#595959' }}
          >
            {' '}
          </span>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            78701
          </span>
        </p>
        <p className="MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            United States
          </span>
        </p>
        <p className="mt-4 MsoNormal" style={{ lineHeight: '150%' }}>
          <b>
            <span
              lang="EN"
              style={{ fontSize: '14.5pt', lineHeight: '150%' }}
              id="request"
            >
              15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </span>
          </b>
        </p>
        <p className="mt-3 MsoNormal" style={{ lineHeight: '150%' }}>
          <span
            lang="EN"
            style={{ fontSize: '10.5pt', lineHeight: '150%', color: '#595959' }}
          >
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please visit:{' '}
            <a href="mailto:team@kissandtell.com">team@kissandtell.com</a>.
          </span>
        </p>
      </div>
    </Container>
  )
}
