import get from 'lodash/fp/get'
import set from 'lodash/fp/set'
import find from 'lodash/fp/find'
import findIndex from 'lodash/fp/findIndex'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import {
  Maybe,
  GuestEvent,
  Invitation,
  GetInviatationDataQuery,
} from '../types'
import {
  GET_INVITATION_DATA,
  GET_MY_INVITATIONS,
  UPDATE_MY_INVITATION,
} from '../graphql'

import { guestStatuses, myHub } from './constants'
import { errorAlert } from './general'
import { simpleAlert, simpleAlertWithPromise } from './alerts'

interface IupdateInvitation {
  event: GuestEvent
  guestId?: string
  eventId: string
  customMessage?: string
}
export const useUpdateInvitation = ({
  alerts = true,
}: {
  alerts?: boolean
}): {
  updateInvitation: (params: IupdateInvitation) => void
  updating: boolean
} => {
  let invitationEventId: string
  const history = useHistory()
  const [update, { loading: updating }] = useMutation(UPDATE_MY_INVITATION, {
    update(cache, { data: { updateMyInvitation } }) {
      if (updateMyInvitation.errors.length <= 0) {
        const cached: Maybe<GetInviatationDataQuery> = cache.readQuery({
          query: GET_INVITATION_DATA,
          variables: { eventId: invitationEventId },
        })
        if (cached) {
          let list: Invitation[] = get('me.invitations', cached) || []
          const index = findIndex(el => el.eventId === invitationEventId, list)
          list = set(
            `[${index}].status`,
            updateMyInvitation?.result?.status,
            list
          )

          cache.writeQuery({
            query: GET_INVITATION_DATA,
            data: set('me.invitations', list, cached),
            variables: { eventId: invitationEventId },
          })
        }
      }
    },
  })

  const updateInvitation = ({
    event,
    eventId,
    guestId,
    customMessage,
  }: IupdateInvitation) => {
    invitationEventId = eventId
    if (updating) return

    const variables = { guestId, event }

    update({ variables })
      .then(response => {
        const errors = response?.data?.updateMyInvitation?.errors || []

        if (!alerts) return

        if (errors.length > 0) {
          errorAlert(errors, 'There was an error updating your RSVP')
        } else if (event === GuestEvent.Accept) {
          simpleAlertWithPromise({
            html:
              customMessage ||
              'Thanks for being part of our event!<br />More details under My Hub',
            icon: 'success',
          }).then(() => history.push(myHub))
        }
      })
      .catch(() => {
        if (!alerts) return
        simpleAlert({
          html: 'There was an error updating your RSVP',
          icon: 'error',
        })
      })
  }

  return { updateInvitation, updating }
}

export const useGuestAcceptInvitacion = (): {
  updateGuestState: (eventId: string) => void
} => {
  const { updateInvitation, updating } = useUpdateInvitation({ alerts: false })
  const { data, refetch } = useQuery(GET_MY_INVITATIONS, {
    fetchPolicy: 'cache-and-network',
  })

  const updateGuestState = async (eventId: string) => {
    const invitationsData = data ?? (await refetch())
    const invitations: Invitation[] =
      get('me.invitations', invitationsData) ??
      get('data.me.invitations', invitationsData)
    const invitation = find({ eventId }, invitations)

    if (updating) return
    if (!invitation) return

    const { created, sent } = guestStatuses
    if (invitation.status !== created && invitation.status !== sent) return

    updateInvitation({
      event: GuestEvent.Accept,
      guestId: invitation.guestId,
      eventId,
    })
  }

  return { updateGuestState }
}
