import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { MdDone } from 'react-icons/md'

import { Tier } from '../../types'
import { orderTiers } from '../../common'

import { SublistsContainer } from './style'

export const Sublists: React.FC<{
  tiers: Tier[]
  selectedTiers?: string[]
  className?: string
  badgeClassName?: string
}> = React.memo(
  ({
    tiers = [],
    selectedTiers = [],
    className = '',
    badgeClassName = 'fz-13',
  }) => {
    if (tiers.length === 0) return null

    const orderedTiers = orderTiers(tiers)
    return (
      <SublistsContainer className={className}>
        {orderedTiers.map(tier => {
          const styleClass = selectedTiers.includes(tier.id)
          const activeClass = styleClass ? 'badge-salmon' : 'badge-gray'
          return (
            <Badge
              pill
              key={tier.id}
              className={`mr-1 py-1 ${activeClass} ${badgeClassName}`}
            >
              {styleClass && <MdDone className="mr-1" />}
              {tier.name}
            </Badge>
          )
        })}
      </SublistsContainer>
    )
  }
)
