import styled from 'styled-components'

import { gray, lightGray } from '../../../styles/themeColors'

export const AddOnsSelectContainer = styled.div`
  & button {
    align-items: center;
    display: flex;
    border-color: ${lightGray};
    border-radius: 35px;
    color: ${gray};
    padding: 10px 18px;
  }
  *:focus,
  *:hover {
    background-color: white !important;
    color: ${gray} !important;
    outline: none !important;
  }
`
