import React from 'react'
import { useSwipeable } from 'react-swipeable'

import { SwipeControlContainer } from './style'

export const SwipeControl: React.FC<{
  action: React.Dispatch<React.SetStateAction<boolean>>
  state: boolean
  className?: string
}> = ({ action, className, state, children }) => {
  const handlers = useSwipeable({
    onSwipedUp: () => action(true),
    onSwipedDown: () => action(false),
  })

  // if the swipe-up gesture is not detected for some reason
  const handleClick = () => action(!state)

  return (
    <SwipeControlContainer
      {...handlers}
      className={className}
      onClick={handleClick}
    >
      {children}
    </SwipeControlContainer>
  )
}
