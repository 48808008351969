import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'

import textLogo from '../../../assets/images/text_logo.jpg'
import { UnderlineLink } from '../style'
import { IBlogVideo } from '../../../types'

import { MainVideoSection, VimeoVideo } from './style'
import { getMainVideo } from './BlogFunctions'

const RenderVideo: React.FC<{
  url: string
  className?: string
  style?: React.CSSProperties
  poster?: string
}> = ({ url, className, style, poster }) => {
  return (
    <VimeoVideo
      loop
      controls
      src={url}
      playsInline
      style={style}
      preload="metadata"
      className={className}
      poster={poster}
    />
  )
}

export const MainBlogSection: React.FC = () => {
  const mainVideo = getMainVideo()
  return (
    <MainVideoSection className="shadow">
      <Row>
        <Col xs={12} md={6}>
          <div className="info">
            <h2 className="font-lf mb-2 mb-lg-4 ml-3 ml-lg-0">
              Featured Wedding
              <img src={textLogo} alt="Kiss&Tell" />
            </h2>
            <p className="text-primary mb-2 fz-18">{mainVideo.title}</p>
            <table className="w-fit-content fwsb text-left ml-3 ml-md-0">
              <tbody>
                {mainVideo.credits?.map(credit => (
                  <tr key={`row-${credit.value}-${mainVideo.id}`}>
                    <td>{credit.name}: </td>
                    <td className="pl-1 pl-lg-3">
                      {credit.link ? (
                        <a
                          href={credit.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {credit.value}
                        </a>
                      ) : (
                        <span className="text-primary">{credit.value}</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <RenderVideo url={mainVideo.url} poster={mainVideo.poster} />
        </Col>
      </Row>
    </MainVideoSection>
  )
}

export const VimeoCard: React.FC<{ width?: string; video: IBlogVideo }> = ({
  width,
  video,
}) => {
  return (
    <Card
      id={`video-card-${video.id}`}
      className="vimeo-card-blog mb-3 shadow-sm"
      style={{ maxWidth: width || '380px' }}
      as="article"
    >
      <RenderVideo
        poster={video.poster}
        url={video.url}
        className="rounded-0"
        style={{ height: '450px' }}
      />
      <div className="fwsb p-3 pt-2 pt-md-4">
        <UnderlineLink>
          <Card.Title className="text-primary fz-18 mb-2" as="h2">
            {video.title}
          </Card.Title>
          <span className="line" />
        </UnderlineLink>

        <table className="mx-auto w-fit-content text-left">
          <tbody>
            {video.credits?.map(credit => (
              <tr key={`row-${credit.value}-${video.id}`}>
                <td>{credit.name}: </td>
                <td className="pl-1 pl-lg-5">
                  {credit.link ? (
                    <a
                      href={credit.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {credit.value}
                    </a>
                  ) : (
                    <span className="text-primary">{credit.value}</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  )
}
