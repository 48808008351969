import React, { useEffect, useState } from 'react'
import axios from 'axios'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'

import { IyoutubeList, IyoutubeItem } from '../../types'
import { YoutubeKey, televisionSegmentsPlaylist } from '../../common/constants'
import { IframeYoutube } from '../../common'

import { Segments, Player } from './style'

export const YoutubeList: React.FC = () => {
  const [list, setList] = useState<IyoutubeItem[]>([])
  const loadVideos = () => {
    const playlistId = televisionSegmentsPlaylist
    const key = YoutubeKey
    const params = {
      part: 'snippet',
      playlistId,
      maxResults: 50,
      key,
    }
    axios
      .get('https://www.googleapis.com/youtube/v3/playlistItems', { params })
      .then(({ data }: { data: IyoutubeList }) => {
        setList(get('items', data))
      })
      .catch(() => false)
  }

  useEffect(() => {
    if (isEmpty(list)) {
      loadVideos()
    }
  }, [list])

  return (
    <Segments>
      {list.map(el => {
        const videoTitle: string = get('snippet.title', el)
        const videoID: string = get('snippet.resourceId.videoId', el)
        if (videoTitle.toLowerCase() === 'deleted video') {
          return <></>
        }

        let thumbnail: string = get('snippet.thumbnails.standard.url', el)
        if (!thumbnail) {
          thumbnail = get('snippet.thumbnails.high.url', el)
        }

        return (
          <Player key={el.id}>
            <h1>{videoTitle}</h1>
            <IframeYoutube videoId={videoID} height="300px" light={thumbnail} />
          </Player>
        )
      })}
    </Segments>
  )
}
