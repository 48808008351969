import styled from 'styled-components'

import { gray, grayBackground, lightGray } from '../../styles/themeColors'

export const FooterHome = styled.footer`
  background-color: ${grayBackground};
  & .container {
    border-top: 1px solid ${lightGray};
  }
  & a.map,
  & a.terms {
    color: ${gray} !important;
    text-decoration: underline;
    &:hover {
      color: ${gray} !important;
    }
  }
`
