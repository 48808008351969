import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import Tiers from '../../../../components/Tiers'
import { MyEvent } from '../../../../types'

import { ModalTiersContainer } from './style'

interface ITiersModal {
  event: MyEvent
  selectedTiers: string[]
  updateSelectedTiers: React.Dispatch<React.SetStateAction<string[]>>
  closeTiersModal: React.Dispatch<React.SetStateAction<boolean>>
  show: boolean
}

export const TiersModal: React.FC<ITiersModal> = ({
  event,
  selectedTiers,
  updateSelectedTiers,
  show,
  closeTiersModal,
}) => {
  if (!event) {
    return <></>
  }
  const handleClose = () => closeTiersModal(false)
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body className="text-center">
        <h3 className="fwb text-primary fz-16 mb-0">
          Please assign sublists to all relevant guests
        </h3>
        <p className="mb-2">Easily filter your master list by sublists</p>
        <ModalTiersContainer>
          <Tiers.Container
            event={event}
            selection={selectedTiers}
            onSelect={updateSelectedTiers}
          />
        </ModalTiersContainer>
      </Modal.Body>
      <Modal.Footer className="py-2">
        <Button
          type="button"
          onClick={handleClose}
          className="fwsb text-center m-0"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
