import gql from 'graphql-tag'

import { IMAGE_FIELDS_FRAG } from './general'

export const ROOM_FIELDS_FRAG = gql`
  fragment RoomFields on RoomOffering {
    description
    id
    images {
      ...ImageFields
    }
    intro
    mainImage {
      ...ImageFields
    }
    name
    price
    marketPrice
    provider {
      name
      id
      url
      images {
        ...ImageFields
      }
      mainImage {
        ...ImageFields
      }
    }
    quantity
    room {
      beds
      occupancy
      size
    }
  }
  ${IMAGE_FIELDS_FRAG}
`

export const PACKAGE_FIELDS_FRAG = gql`
  fragment PackageFields on PackageOffering {
    description
    id
    images {
      ...ImageFields
    }
    intro
    mainImage {
      ...ImageFields
    }
    name
    package {
      baseGuestNumber
      basePrice
      minimumGuests
    }
    price
    provider {
      name
      id
      url
      images {
        ...ImageFields
      }
      mainImage {
        ...ImageFields
      }
    }
    quantity
  }
  ${IMAGE_FIELDS_FRAG}
`

export const GET_LOCATIONS = gql`
  query getLocations(
    $limit: Int = 9
    $cursor: String
    $where: Location
    $what: String
    $maxPackagePrice: Decimal
    $maxRoomPrice: Decimal
    $groupSize: Int = 1
  ) {
    marketplace(
      where: $where
      what: $what
      maxPackagePrice: $maxPackagePrice
      maxRoomPrice: $maxRoomPrice
      groupSize: $groupSize
      cursor: { cursor: $cursor, limit: $limit }
    ) {
      entries {
        id
        url
        name
        location
        address
        room {
          price
          sleeps
        }
        package {
          capacity
          price
          baseNumber
          minimumGuests
        }
        mainImage {
          ...ImageFields
        }
        coord
      }
      cursor
    }
  }
  ${IMAGE_FIELDS_FRAG}
`

export const GET_LOCATION = gql`
  query getLocation($url: String!) {
    provider(url: $url) {
      address
      airportCode
      bookAllRequired
      description
      id
      url
      name
      location
      coord
      metadata
      cancellationPolicy
      package {
        capacity
        price
        minimumGuests
      }
      room {
        price
        sleeps
      }
      packages {
        ...PackageFields
      }
      rooms {
        ...RoomFields
      }
      timeToAirport
      images {
        ...ImageFields
      }
    }
  }
  ${ROOM_FIELDS_FRAG}
  ${PACKAGE_FIELDS_FRAG}
  ${IMAGE_FIELDS_FRAG}
`

export const GET_LOCATIONS_BY_IDS = gql`
  query getLocationsByIds($ids: [ID!]!) {
    providers(ids: $ids) {
      address
      airportCode
      bookAllRequired
      description
      id
      url
      name
      location
      coord
      metadata
      cancellationPolicy
      package {
        capacity
        price
        minimumGuests
      }
      room {
        price
        sleeps
      }
      packages {
        ...PackageFields
      }
      rooms {
        ...RoomFields
      }
      timeToAirport
      images {
        ...ImageFields
      }
      mainImage {
        ...ImageFields
      }
    }
  }
  ${ROOM_FIELDS_FRAG}
  ${PACKAGE_FIELDS_FRAG}
  ${IMAGE_FIELDS_FRAG}
`

export const GET_LOCATIONS_FEED = gql`
  query getLocationsFeed(
    $limit: Int = 10
    $cursor: String
    $where: Location
    $what: String = "Kiss&Tell Founder's Collection"
    $maxPackagePrice: Decimal
    $maxRoomPrice: Decimal
    $groupSize: Int = 0
  ) {
    marketplace(
      where: $where
      what: $what
      maxPackagePrice: $maxPackagePrice
      maxRoomPrice: $maxRoomPrice
      groupSize: $groupSize
      cursor: { cursor: $cursor, limit: $limit }
    ) {
      entries {
        id
        name
        description
        location
        address
        airportCode
        timeToAirport
        url
        room {
          sleeps
        }
        package {
          capacity
        }
        packages {
          mainImage {
            ...ImageFields
          }
        }
        mainImage {
          ...ImageFields
        }
        images {
          ...ImageFields
        }
      }
      cursor
    }
  }
  ${IMAGE_FIELDS_FRAG}
`

export const GET_PROVIDER_MAIN_IMAGES = gql`
  query getProviderMainImages($ids: [ID!]!) {
    providers(ids: $ids) {
      id
      name
      mainImage {
        ...ImageFields
      }
    }
  }
  ${IMAGE_FIELDS_FRAG}
`
