import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import { MdLockOutline, MdWarning } from 'react-icons/md'
import { useMutation } from '@apollo/react-hooks'
import { useParams, Redirect, useHistory } from 'react-router-dom'

import AppContext from '../../context'
import { Error } from '../../types'
import { KnTField } from '../../components'
import { useForm } from '../../common/alterForms'
import { SET_PASSWORD_LANDING_USER } from '../../graphql'
import {
  errorAlert,
  saveSelectedEvent,
  simpleAlert,
  simpleAlertWithPromise,
  useValidatePassword,
} from '../../common'
import { myHub } from '../../common/constants'

export const SetPassword: React.FC = () => {
  const history = useHistory()
  const { auth } = useContext(AppContext)
  const { token, event } = useParams<{ token: string; event?: string }>()
  const initModel = { password: '', password2: '' }
  const { model, handleFieldChange } = useForm(initModel)
  const validatePass = useValidatePassword(model.password)
  const [submitReset, { loading }] = useMutation(SET_PASSWORD_LANDING_USER)

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault()
    if (validatePass.length > 0) return
    if (model.password !== model.password2) return

    const variables = { token, password: model.password, event }
    submitReset({ variables })
      .then(({ data }) => {
        const errors: Error[] = data?.setPasswordLandingUser?.errors || []

        if (errors.length > 0) {
          errorAlert(errors, 'Could not set password. Please try again.')
        } else {
          auth.signInWithoutRequest(data.setPasswordLandingUser.result.token)
          simpleAlertWithPromise({
            html: 'Password set successfully!',
            icon: 'success',
          }).then(() => {
            if (event) {
              saveSelectedEvent(event)
            }
            history.push(myHub)
          })
        }
      })
      .catch(() => {
        simpleAlert({
          html: 'Something went wrong. Please try again later.',
          icon: 'error',
        })
      })
  }

  if (!token) return <Redirect to="/" />
  return (
    <Container className="py-5">
      <Helmet>
        <title>Kiss & Tell | Set Password</title>
        <meta
          name="description"
          content="Set a password for your Kiss & Tell account to verify your account, access your dashboard and manage your events."
        />
      </Helmet>
      <h2 className="px-3 text-center pb-3 mw-600 mx-auto">
        Almost there! Please set a password for your account.
      </h2>
      <form
        onSubmit={handleSubmit}
        className="card card-body pt-2 pb-4 mw-600 mx-auto"
      >
        <KnTField
          label="Password"
          value={model}
          setValue={handleFieldChange}
          name="password"
          placeholder="your password"
          type="password"
          icon={<MdLockOutline size={24} />}
        />
        {model.password && validatePass?.length > 0 && (
          <span className="text-danger fz-12 pl-3 pt-1 d-block">
            {validatePass[0].message}
          </span>
        )}
        <KnTField
          label="Confirm password"
          value={model}
          setValue={handleFieldChange}
          name="password2"
          placeholder="repeat password"
          type="password"
          icon={
            model.password === model.password2 ? (
              <></>
            ) : (
              <MdWarning title="Password doesn't match" size={24} />
            )
          }
        />
        <Button
          type="submit"
          variant="primary"
          className="mt-3 mx-auto px-4 fz-18 w-fit-content"
          disabled={loading}
        >
          Set Password
        </Button>
      </form>
    </Container>
  )
}
