import styled from 'styled-components'

export const Form = styled.form`
  margin: 0 auto;
  max-width: 360px;
  width: 96%;
  & .manage-profile-picture {
    align-items: center;
    display: flex;
    margin: 30px 0 0 0;
    & img {
      border-radius: 50%;
      height: 115px;
      margin: 10px 13px 13px 0;
      width: 115px;
    }
    & > svg {
      margin-left: -10px;
    }
  }
`
