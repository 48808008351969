import { getImageUrl } from '../../common'
import { Maybe, Image, Guest } from '../../types'

const exampleBgImage = {
  assocId: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
  assocType: 'providers',
  ext: 'jpg',
  id: 'd16e1bb4-6a0a-49f7-ba86-1e1feb564bff',
} as Image
export const getBgImage = (mainImage?: Maybe<Image>): string =>
  getImageUrl(mainImage || exampleBgImage, '1300x600')

export const exampleData = {
  bookedComponents: 18,
  email: 'guest@kissandtell.com',
  event: {
    __typename: 'Event',
    description: '',
    id: 'caf390f3-af39-41aa-8a0d-e6f87089d331',
    mainImage: '',
    name: 'Elizabeth & Philip’s Wedding',
  },
  eventComponents: [
    {
      alias: null,
      date: '2022-11-06T00:00:00',
      duration: 2,
      id: 'abd7e673-9173-4504-8913-108d8efee2dc',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>250-sq-foot room</li>\n            <li>Internet - Free WiFi and wired Internet access</li>\n            <li>Entertainment - Cable / satellite channels and flat screen / HD TV</li>\n            <li>Food &amp; Drink - Food &amp; Drink - American Breakfast $8 USD per day</li>\n            <li>Sleep - Luxury bedding and Premium Mattress </li>\n            <li>Bathroom - Bathrobes, Private bathroom and Rainfall Showerhead, Toiletries</li>\n            <li>Practical - Hair Dryer, Phone, Safe, Private Pool</li>\n            <li>Comfort - Air conditioning and daily housekeeping</li>\n            <li>Outdoor - Terrace</li>\n            <li>Rules - Non-Smoking, No Pets or Service Animals Allowed</li>\n            <li>The Paloma Master Suite offers a unique and relaxing luxury space to rejuvenate after a day exploring Merida.  Open and airy and including a private plunge pool.</li>\n    </ul>\n',
        images: [
          {
            assocId: '074b79aa-d186-4d72-9107-323de39ac7c1',
            assocType: 'offerings',
            ext: 'jpg',
            id: '1c9290c9-b3da-464a-9a2b-3de3dd8fe58b',
            metadata: {},
          },
          {
            assocId: '074b79aa-d186-4d72-9107-323de39ac7c1',
            assocType: 'offerings',
            ext: 'jpg',
            id: '958b16b0-8d54-40e2-a824-e3af97f26273',
            metadata: {},
          },
          {
            assocId: '074b79aa-d186-4d72-9107-323de39ac7c1',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'c928bb18-2016-4aa2-bd73-bdbaffccef4e',
            metadata: {},
          },
          {
            assocId: '074b79aa-d186-4d72-9107-323de39ac7c1',
            assocType: 'offerings',
            ext: 'jpg',
            id: '83bd111d-1586-410e-b0cf-c4a0250ca712',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: '074b79aa-d186-4d72-9107-323de39ac7c1',
          assocType: 'offerings',
          ext: 'jpg',
          id: '1c9290c9-b3da-464a-9a2b-3de3dd8fe58b',
          metadata: {},
        },
        name: 'PALOMA MASTER SUITE',
        price: '164',
        provider: {
          address:
            'Calle 64ᴬ 417, Parque Santa Lucia, Centro, 97000 Mérida, Yuc., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6245605 20.9744886)',
          id: 'e3a259f4-83cb-4df2-916e-84a4d3a65180',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: 'e3a259f4-83cb-4df2-916e-84a4d3a65180',
            assocType: 'providers',
            ext: 'jpg',
            id: 'cd1d4dd3-7148-49f8-a27e-a72f9d7102bb',
            metadata: {},
          },
          name: 'Casa de las Palomas Boutique Hotel',
          url: 'casa-de-las-palomas-boutique-hotel',
          timeToAirport: 21,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 1,
          occupancy: 2,
          size: 250,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-05T00:00:00',
      duration: 2,
      id: 'beb4c121-a2b4-4c2d-aabd-fd1ed4536950',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>592-sq-foot room</li>\n            <li>Internet - Free WiFi and wired Internet access</li>\n            <li>Entertainment - Cable / satellite channels and flat screen / HD TV and iPod / MP3 Docking Station </li>\n            <li>Food &amp; Drink - Breakfast included, Coffee/tea maker, Minibar, Room Service, and Free bottled water</li>\n            <li>Sleep - Luxury bedding</li>\n            <li>Bathroom - Bathrobes, Premium bath amenities, Slippers, and shower</li>\n            <li>Practical - Safe (laptop compatible),Iron / Ironing Board, Hair Dryer, Desk</li>\n            <li>Comfort - Air conditioning, Daily housekeeping, Separate living room</li>\n            <li>Outdoor - Terrace, Plunge pool on patio</li>\n            <li>Rules - Non-Smoking, Cribs not permitted</li>\n            <li>All of our suites feature 18-foot ceilings with exposed beams and rafters, 10-foot wooden double doors, massive iron beds, dark, tropical hardwood cabinets, cool tile floors, and a private plunge pool on an ample terrace. Additionally, large, bulbous pegs in the stonewalls hold woven cotton hammocks, for which Yucatan is famous, allowing you to sleep in the Mayan style. The largest room at the Hacienda Temozon featuring a private pool. </li>\n    </ul>\n',
        images: [
          {
            assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
            assocType: 'offerings',
            ext: 'jpg',
            id: '7800670b-c647-44f1-849c-c32d7c4882dd',
            metadata: {},
          },
          {
            assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
            assocType: 'offerings',
            ext: 'jpg',
            id: '58d3fdfa-9e32-41fc-9387-02536d7cbc17',
            metadata: {},
          },
          {
            assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
            assocType: 'offerings',
            ext: 'jpg',
            id: '1c22b2be-9eb9-42eb-a835-9b6c70ef328b',
            metadata: {},
          },
          {
            assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
            assocType: 'offerings',
            ext: 'jpg',
            id: '298fa56f-dfb6-4829-a893-75d486a978ed',
            metadata: {},
          },
          {
            assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'e57d80ec-d419-4e10-ae3a-e45e51e3d05d',
            metadata: {},
          },
          {
            assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'ce83b98b-55cd-4598-ab4d-4d1762ac6bdb',
            metadata: {},
          },
          {
            assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'a8423afb-d177-40c1-baee-c8d4ee2e90a7',
            metadata: {},
          },
          {
            assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'ed56e841-e85a-4a88-9b31-d2cde00fd41c',
            metadata: {},
          },
          {
            assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
            assocType: 'offerings',
            ext: 'jpg',
            id: '50f88550-f2c8-42d6-aecc-55fc603b1789',
            metadata: {},
          },
          {
            assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
            assocType: 'offerings',
            ext: 'jpg',
            id: '74f3c2c5-545b-4f1c-9bc2-041e385bec64',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: '12e1d7a0-2eeb-4f28-921f-35d45fb40129',
          assocType: 'offerings',
          ext: 'jpg',
          id: '7800670b-c647-44f1-849c-c32d7c4882dd',
          metadata: {},
        },
        name: 'Suite 2 Queen',
        price: '354',
        provider: {
          address:
            'Km 182 Carretera Merida-Uxmal, 97825 Temozón Sur, Yuc., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.653218 20.68508)',
          id: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
            assocType: 'providers',
            ext: 'jpg',
            id: 'd16e1bb4-6a0a-49f7-ba86-1e1feb564bff',
            metadata: {},
          },
          name: 'Hacienda Temozon',
          url: 'hacienda-temozon',
          timeToAirport: 45,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 2,
          occupancy: 4,
          size: 592,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-05T00:00:00',
      duration: 2,
      id: 'ceba5ece-67b9-468e-afcd-672cb08c6dbb',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>592-sq-foot room</li>\n            <li>Internet - Free WiFi and wired Internet access</li>\n            <li>Entertainment - Cable / satellite channels and flat screen / HD TV and iPod / MP3 Docking Station </li>\n            <li>Food &amp; Drink - Breakfast included, Coffee/tea maker, Minibar, Room Service, and Free bottled water</li>\n            <li>Sleep - Luxury bedding</li>\n            <li>Bathroom - Bathrobes, Premium bath amenities, Slippers, and shower</li>\n            <li>Practical - Safe (laptop compatible),Iron / Ironing Board, Hair Dryer, Desk</li>\n            <li>Comfort - Air conditioning, Daily housekeeping, Separate living room</li>\n            <li>Outdoor - Terrace, Plunge pool on patio</li>\n            <li>Rules - Non-Smoking</li>\n            <li>All of our suites feature 18-foot ceilings with exposed beams and rafters, 10-foot wooden double doors, massive iron beds, dark, tropical hardwood cabinets, cool tile floors, and a private plunge pool on an ample terrace. Additionally, large, bulbous pegs in the stonewalls hold woven cotton hammocks, for which Yucatan is famous, allowing you to sleep in the Mayan style. </li>\n    </ul>\n',
        images: [
          {
            assocId: '16e3bd82-b46b-4439-88a1-360ee25a273f',
            assocType: 'offerings',
            ext: 'jpg',
            id: '9ca609f4-5966-42ca-ba5f-f0fc023af2dd',
            metadata: {},
          },
          {
            assocId: '16e3bd82-b46b-4439-88a1-360ee25a273f',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'bed904e1-c47b-4997-aae7-a46ede173a0f',
            metadata: {},
          },
          {
            assocId: '16e3bd82-b46b-4439-88a1-360ee25a273f',
            assocType: 'offerings',
            ext: 'jpg',
            id: '83a549e7-1f26-4dd8-9c7c-33d3903f65be',
            metadata: {},
          },
          {
            assocId: '16e3bd82-b46b-4439-88a1-360ee25a273f',
            assocType: 'offerings',
            ext: 'jpg',
            id: '366d18fb-984e-4619-85a7-439b3fc9993e',
            metadata: {},
          },
          {
            assocId: '16e3bd82-b46b-4439-88a1-360ee25a273f',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'e8e74065-f453-4ce5-ab1a-4349a611c5a3',
            metadata: {},
          },
          {
            assocId: '16e3bd82-b46b-4439-88a1-360ee25a273f',
            assocType: 'offerings',
            ext: 'jpg',
            id: '817900c3-6f40-4695-802a-59ece83f5c8c',
            metadata: {},
          },
          {
            assocId: '16e3bd82-b46b-4439-88a1-360ee25a273f',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'a5ff2023-25ef-434b-9394-c06d9aa230a5',
            metadata: {},
          },
          {
            assocId: '16e3bd82-b46b-4439-88a1-360ee25a273f',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'f425d4a3-8121-4e02-9fec-376bb2cc58c4',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: '16e3bd82-b46b-4439-88a1-360ee25a273f',
          assocType: 'offerings',
          ext: 'jpg',
          id: '9ca609f4-5966-42ca-ba5f-f0fc023af2dd',
          metadata: {},
        },
        name: 'Suite, 1 King',
        price: '354',
        provider: {
          address:
            'Km 182 Carretera Merida-Uxmal, 97825 Temozón Sur, Yuc., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.653218 20.68508)',
          id: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
            assocType: 'providers',
            ext: 'jpg',
            id: 'd16e1bb4-6a0a-49f7-ba86-1e1feb564bff',
            metadata: {},
          },
          name: 'Hacienda Temozon',
          url: 'hacienda-temozon',
          timeToAirport: 45,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 1,
          occupancy: 2,
          size: 592,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-03T00:00:00',
      duration: 2,
      id: '8e147486-40fe-4be1-b39c-d71bc3f8cf20',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>430-sq-foot room</li>\n            <li>Internet - Free WiFi and wired Internet access</li>\n            <li>Entertainment - Bose sound system, cable / satellite channels and flat screen / HD TV and iPod dock </li>\n            <li>Food &amp; Drink - Breakfast included, room service, minibar, and free bottled water</li>\n            <li>Sleep - Luxury bedding and pillow menu </li>\n            <li>Bathroom - Private bathroom, luxury linens, luxury toiletries, and shower</li>\n            <li>Practical - Laptop-compatible safe, free newspaper, and VoIP phone</li>\n            <li>Comfort - Air conditioning and daily housekeeping</li>\n            <li>Outdoor - Jacuzzi / soaking tub</li>\n            <li>Rules - Non-Smoking</li>\n            <li>Spacious room featuring designer furnishings and unique details, overlooking the pool, water gardens or private corridor. </li>\n    </ul>\n',
        images: [
          {
            assocId: '1e14f275-7d84-4b4f-ae55-0606c15e7e4f',
            assocType: 'offerings',
            ext: 'jpg',
            id: '290eccde-c45d-45a8-80da-f662036dc3a5',
            metadata: {},
          },
          {
            assocId: '1e14f275-7d84-4b4f-ae55-0606c15e7e4f',
            assocType: 'offerings',
            ext: 'png',
            id: '5e537ce2-280d-482e-b021-8559685296b0',
            metadata: {},
          },
          {
            assocId: '1e14f275-7d84-4b4f-ae55-0606c15e7e4f',
            assocType: 'offerings',
            ext: 'png',
            id: '2385168e-0706-489b-88b9-ef2f2e653f14',
            metadata: {},
          },
          {
            assocId: '1e14f275-7d84-4b4f-ae55-0606c15e7e4f',
            assocType: 'offerings',
            ext: 'png',
            id: '669fd2f0-246e-4fdc-9875-0e53c62ed611',
            metadata: {},
          },
          {
            assocId: '1e14f275-7d84-4b4f-ae55-0606c15e7e4f',
            assocType: 'offerings',
            ext: 'png',
            id: '33558d2b-c3a0-4439-944f-7427bb30e49f',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: '1e14f275-7d84-4b4f-ae55-0606c15e7e4f',
          assocType: 'offerings',
          ext: 'jpg',
          id: '290eccde-c45d-45a8-80da-f662036dc3a5',
          metadata: {},
        },
        name: 'Deluxe Room, 1 King Bed',
        price: '255',
        provider: {
          address: '\\N',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6206127 20.979003)',
          id: '95b9374c-4ae5-4e2c-af60-fc5a6738f49d',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: '95b9374c-4ae5-4e2c-af60-fc5a6738f49d',
            assocType: 'providers',
            ext: 'jpg',
            id: 'fae18c01-71b7-4a15-aed3-31d737956abe',
            metadata: {},
          },
          name: 'Rosas & Xocolate Boutique Hotel & Spa',
          url: 'rosas-xocolate-boutique-hotel-spa',
          timeToAirport: 20,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 1,
          occupancy: 2,
          size: 430,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-04T00:00:00',
      duration: 2,
      id: 'a0d339c9-7b67-4472-bfe0-76a7cdede9e8',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>350-sq-foot room</li>\n            <li>Internet - WiFi</li>\n            <li>Entertainment - Cable / satellite tv, and flat screen / HDTV</li>\n            <li>Sleep - Luxury bedding</li>\n            <li>Bathroom - Private bathroom, shower, soaking tub, and luxury linens</li>\n            <li>Comfort - Air conditioning and ceiling fan</li>\n            <li>Rules - Adults only, non-smoking, no parties or events unless a venue booking is made</li>\n            <li>Luxury master style room with ensuite bathroom and direct courtyard access</li>\n    </ul>\n',
        images: [
          {
            assocId: '344a1a47-badf-4a06-a7e1-045ccbfc62e0',
            assocType: 'offerings',
            ext: 'jpg',
            id: '7bd8e8de-fb82-42f9-813f-09d8a0ed7148',
            metadata: {},
          },
          {
            assocId: '344a1a47-badf-4a06-a7e1-045ccbfc62e0',
            assocType: 'offerings',
            ext: 'jpg',
            id: '75b0f9f2-73e0-40ce-9d69-b2a60a2fbbdd',
            metadata: {},
          },
          {
            assocId: '344a1a47-badf-4a06-a7e1-045ccbfc62e0',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'b8b936e7-0d3a-4fd8-87d9-33eb34eceff8',
            metadata: {},
          },
          {
            assocId: '344a1a47-badf-4a06-a7e1-045ccbfc62e0',
            assocType: 'offerings',
            ext: 'png',
            id: '3e254184-ba99-4cd6-8461-5a0b5d202f1a',
            metadata: {},
          },
          {
            assocId: '344a1a47-badf-4a06-a7e1-045ccbfc62e0',
            assocType: 'offerings',
            ext: 'jpg',
            id: '544fcb4b-7882-4b24-8d6b-67271d06a077',
            metadata: {},
          },
          {
            assocId: '344a1a47-badf-4a06-a7e1-045ccbfc62e0',
            assocType: 'offerings',
            ext: 'png',
            id: 'f7eed0ce-8dff-4705-b240-6f0753a24614',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: '344a1a47-badf-4a06-a7e1-045ccbfc62e0',
          assocType: 'offerings',
          ext: 'jpg',
          id: '7bd8e8de-fb82-42f9-813f-09d8a0ed7148',
          metadata: {},
        },
        name: 'Guest Room 1',
        price: '167',
        provider: {
          address: 'Privada 942, Zona Centro, 22000 Tijuana, B.C., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6292353 20.9682858)',
          id: '88f3e66c-56af-4803-8117-da9d2a9686e5',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: '88f3e66c-56af-4803-8117-da9d2a9686e5',
            assocType: 'providers',
            ext: 'jpg',
            id: '3d435109-9687-4888-9795-2033dae41ee3',
            metadata: {},
          },
          name: 'La Casa del Centro',
          url: 'la-casa-del-centro',
          timeToAirport: 20,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 1,
          occupancy: 2,
          size: 350,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-03T00:00:00',
      duration: 2,
      id: 'a4ac53e4-97fe-4dea-a812-cfd52599068b',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>517-sq-foot room</li>\n            <li>Internet - Free WiFi and wired Internet access</li>\n            <li>Entertainment - Bose sound system, cable / satellite channels and flat screen / HD TV and iPod dock </li>\n            <li>Food &amp; Drink - Breakfast included, Coffee/tea maker, minibar, room service, and free bottled water</li>\n            <li>Sleep - Luxury bedding and pillow menu</li>\n            <li>Bathroom - Private bathroom, luxury linens, luxury toiletries, and shower</li>\n            <li>Practical - Laptop-compatible safe, free newspaper, and VoIP phone</li>\n            <li>Comfort - Air conditioning and daily housekeeping</li>\n            <li>Outdoor - Jacuzzi / soaking tub, terrace</li>\n            <li>Rules - Non-Smoking</li>\n            <li>This beautiful and spacious suite is located in the main house. It features a terrace overlooking Paseo de Montejo, the most famous avenue in Merida. A living room decorated with designer furnishings and unique details fused with cutting edge technology</li>\n    </ul>\n',
        images: [
          {
            assocId: '59504116-3489-437f-ab48-41f0b70d4f56',
            assocType: 'offerings',
            ext: 'png',
            id: 'b852cdbc-6ccf-43e8-a49f-321e5ad88961',
            metadata: {},
          },
          {
            assocId: '59504116-3489-437f-ab48-41f0b70d4f56',
            assocType: 'offerings',
            ext: 'jpg',
            id: '5f0f7f66-d28f-41de-9b36-10ae314106b9',
            metadata: {},
          },
          {
            assocId: '59504116-3489-437f-ab48-41f0b70d4f56',
            assocType: 'offerings',
            ext: 'jpg',
            id: '77edf7f0-8044-442e-91b0-6d4848c70b73',
            metadata: {},
          },
          {
            assocId: '59504116-3489-437f-ab48-41f0b70d4f56',
            assocType: 'offerings',
            ext: 'jpg',
            id: '12d1b6d2-5d69-4b1d-94b0-cb6d640eb15b',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: '59504116-3489-437f-ab48-41f0b70d4f56',
          assocType: 'offerings',
          ext: 'png',
          id: 'b852cdbc-6ccf-43e8-a49f-321e5ad88961',
          metadata: {},
        },
        name: 'Rosas & Xocolate Master Suite',
        price: '695',
        provider: {
          address: '\\N',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6206127 20.979003)',
          id: '95b9374c-4ae5-4e2c-af60-fc5a6738f49d',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: '95b9374c-4ae5-4e2c-af60-fc5a6738f49d',
            assocType: 'providers',
            ext: 'jpg',
            id: 'fae18c01-71b7-4a15-aed3-31d737956abe',
            metadata: {},
          },
          name: 'Rosas & Xocolate Boutique Hotel & Spa',
          url: 'rosas-xocolate-boutique-hotel-spa',
          timeToAirport: 20,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 1,
          occupancy: 2,
          size: 517,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-04T00:00:00',
      duration: 2,
      id: 'b39d018d-6304-4f99-9cad-e7d042b1c2f3',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>350-sq-foot room</li>\n            <li>Internet - WiFi</li>\n            <li>Entertainment - Cable / satellite tv, and flat screen / HDTV</li>\n            <li>Sleep - Luxury bedding</li>\n            <li>Bathroom - Private bathroom, shower, soaking tub, and luxury linens</li>\n            <li>Comfort - Air conditioning and ceiling fan</li>\n            <li>Rules - Adults only, non-smoking, no parties or events unless a venue booking is made</li>\n            <li>Luxury master style room with ensuite bathroom and direct courtyard access</li>\n    </ul>\n',
        images: [
          {
            assocId: '6505c2d0-ed76-459f-a044-1aee5d30f8e0',
            assocType: 'offerings',
            ext: 'jpg',
            id: '1222bd57-9bfb-40fe-bd3a-1e0bc8765038',
            metadata: {},
          },
          {
            assocId: '6505c2d0-ed76-459f-a044-1aee5d30f8e0',
            assocType: 'offerings',
            ext: 'jpg',
            id: '0158c669-b1e8-4d35-9839-765dfbc771ba',
            metadata: {},
          },
          {
            assocId: '6505c2d0-ed76-459f-a044-1aee5d30f8e0',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'd7c6806e-e08e-4b20-9c8f-8c9d6c57f693',
            metadata: {},
          },
          {
            assocId: '6505c2d0-ed76-459f-a044-1aee5d30f8e0',
            assocType: 'offerings',
            ext: 'png',
            id: 'b1a88879-716d-4fae-9c79-5af97af24492',
            metadata: {},
          },
          {
            assocId: '6505c2d0-ed76-459f-a044-1aee5d30f8e0',
            assocType: 'offerings',
            ext: 'png',
            id: 'acc1c959-60e2-42b4-8986-450977761614',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: '6505c2d0-ed76-459f-a044-1aee5d30f8e0',
          assocType: 'offerings',
          ext: 'jpg',
          id: '1222bd57-9bfb-40fe-bd3a-1e0bc8765038',
          metadata: {},
        },
        name: 'Guest Room 2',
        price: '167',
        provider: {
          address: 'Privada 942, Zona Centro, 22000 Tijuana, B.C., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6292353 20.9682858)',
          id: '88f3e66c-56af-4803-8117-da9d2a9686e5',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: '88f3e66c-56af-4803-8117-da9d2a9686e5',
            assocType: 'providers',
            ext: 'jpg',
            id: '3d435109-9687-4888-9795-2033dae41ee3',
            metadata: {},
          },
          name: 'La Casa del Centro',
          url: 'la-casa-del-centro',
          timeToAirport: 20,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 1,
          occupancy: 2,
          size: 350,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-06T00:00:00',
      duration: 2,
      id: 'c62c9edc-6354-44de-ba90-1f37b061a86e',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>215-sq-foot room</li>\n            <li>Internet - Free WiFi and wired Internet access</li>\n            <li>Entertainment - Cable / satellite channels and flat screen / HD TV,</li>\n            <li>Food &amp; Drink - American Breakfast $8 USD per day</li>\n            <li>Sleep - Luxury bedding and Premium Mattress </li>\n            <li>Bathroom - Bathrobes, Private bathroom and Rainfall Showerhead, Toiletries</li>\n            <li>Practical - Hair Dryer, Phone, Safe, Private Pool</li>\n            <li>Comfort - Air conditioning and daily housekeeping</li>\n            <li>Outdoor - Terrace</li>\n            <li>Rules - Non-Smoking, No Pets or Service Animals Allowed</li>\n            <li>The garden view rooms offer you a relaxing oasis in the vibrant cultural center of Merida.</li>\n    </ul>\n',
        images: [
          {
            assocId: '72c9808b-2f5c-46d9-87c7-3cbc3ed915bc',
            assocType: 'offerings',
            ext: 'jpg',
            id: '92499a5c-9036-4005-8b07-ed0e97c2fcc7',
            metadata: {},
          },
          {
            assocId: '72c9808b-2f5c-46d9-87c7-3cbc3ed915bc',
            assocType: 'offerings',
            ext: 'jpg',
            id: '389852d6-dd8a-444d-94f4-9ca476fb8e29',
            metadata: {},
          },
          {
            assocId: '72c9808b-2f5c-46d9-87c7-3cbc3ed915bc',
            assocType: 'offerings',
            ext: 'jpg',
            id: '8c3a6031-15ac-43b1-9a32-0d20bf0691df',
            metadata: {},
          },
          {
            assocId: '72c9808b-2f5c-46d9-87c7-3cbc3ed915bc',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'ea386724-7d86-4440-811a-313804429acc',
            metadata: {},
          },
          {
            assocId: '72c9808b-2f5c-46d9-87c7-3cbc3ed915bc',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'db6feae8-0455-4e91-bfbd-cdf5d1dbd3df',
            metadata: {},
          },
          {
            assocId: '72c9808b-2f5c-46d9-87c7-3cbc3ed915bc',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'cf16bec8-ec0d-464c-ac47-c3181519e6ce',
            metadata: {},
          },
          {
            assocId: '72c9808b-2f5c-46d9-87c7-3cbc3ed915bc',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'bc0c23e6-a98a-4028-adf4-51a81507fa10',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: '72c9808b-2f5c-46d9-87c7-3cbc3ed915bc',
          assocType: 'offerings',
          ext: 'jpg',
          id: '92499a5c-9036-4005-8b07-ed0e97c2fcc7',
          metadata: {},
        },
        name: 'GARDEN VIEW KING',
        price: '125',
        provider: {
          address:
            'Calle 64ᴬ 417, Parque Santa Lucia, Centro, 97000 Mérida, Yuc., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6245605 20.9744886)',
          id: 'e3a259f4-83cb-4df2-916e-84a4d3a65180',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: 'e3a259f4-83cb-4df2-916e-84a4d3a65180',
            assocType: 'providers',
            ext: 'jpg',
            id: 'cd1d4dd3-7148-49f8-a27e-a72f9d7102bb',
            metadata: {},
          },
          name: 'Casa de las Palomas Boutique Hotel',
          url: 'casa-de-las-palomas-boutique-hotel',
          timeToAirport: 21,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 1,
          occupancy: 2,
          size: 215,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-05T00:00:00',
      duration: 2,
      id: 'ec4c2560-e51b-4f1c-be4a-ce9bb5dbcaba',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>484-sq-foot room</li>\n            <li>Internet - Free WiFi and wired Internet access</li>\n            <li>Entertainment - Cable / satellite channels and flat screen / HD TV and iPod / MP3 Docking Station </li>\n            <li>Food &amp; Drink - Breakfast included, Coffee/tea maker, Minibar, Room Service, and Free bottled water</li>\n            <li>Sleep - Luxury bedding</li>\n            <li>Bathroom - Bathrobes, Premium bath amenities, Slippers, and shower</li>\n            <li>Practical - Safe (laptop compatible),Iron / Ironing Board, Hair Dryer, Desk</li>\n            <li>Comfort - Air conditioning, Daily housekeeping, Separate living room</li>\n            <li>Outdoor - Terrace, Plunge pool on patio</li>\n            <li>Rules - Non-Smoking, Cribs not permitted</li>\n            <li>All of our suites feature 18-foot ceilings with exposed beams and rafters, 10-foot wooden double doors, massive iron beds, dark, tropical hardwood cabinets, cool tile floors, and a private plunge pool on an ample terrace. Additionally, large, bulbous pegs in the stonewalls hold woven cotton hammocks, for which Yucatan is famous, allowing you to sleep in the Mayan style. The largest room at the Hacienda Temozon featuring a private pool. </li>\n    </ul>\n',
        images: [
          {
            assocId: 'b4e59c07-3b0a-4b28-8e89-b7380d6276f3',
            assocType: 'offerings',
            ext: 'jpg',
            id: '2a466aef-7d73-424a-984c-3d14850be5c9',
            metadata: {},
          },
          {
            assocId: 'b4e59c07-3b0a-4b28-8e89-b7380d6276f3',
            assocType: 'offerings',
            ext: 'jpg',
            id: '57fe4de3-c752-4c51-85e7-f6c0407d4d6a',
            metadata: {},
          },
          {
            assocId: 'b4e59c07-3b0a-4b28-8e89-b7380d6276f3',
            assocType: 'offerings',
            ext: 'jpg',
            id: '66727d76-0df4-4e6a-a620-52ff189dfd1f',
            metadata: {},
          },
          {
            assocId: 'b4e59c07-3b0a-4b28-8e89-b7380d6276f3',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'c23f1b35-d17b-41ed-9445-9076fee6afa6',
            metadata: {},
          },
          {
            assocId: 'b4e59c07-3b0a-4b28-8e89-b7380d6276f3',
            assocType: 'offerings',
            ext: 'jpg',
            id: '571c9af3-9e22-4d09-b35c-e880234146cc',
            metadata: {},
          },
          {
            assocId: 'b4e59c07-3b0a-4b28-8e89-b7380d6276f3',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'e1c7cb8d-6b3f-407d-af68-8d47cddbf765',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: 'b4e59c07-3b0a-4b28-8e89-b7380d6276f3',
          assocType: 'offerings',
          ext: 'jpg',
          id: '2a466aef-7d73-424a-984c-3d14850be5c9',
          metadata: {},
        },
        name: 'Deluxe Guest room, 1 King',
        price: '176',
        provider: {
          address:
            'Km 182 Carretera Merida-Uxmal, 97825 Temozón Sur, Yuc., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.653218 20.68508)',
          id: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
            assocType: 'providers',
            ext: 'jpg',
            id: 'd16e1bb4-6a0a-49f7-ba86-1e1feb564bff',
            metadata: {},
          },
          name: 'Hacienda Temozon',
          url: 'hacienda-temozon',
          timeToAirport: 45,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 1,
          occupancy: 2,
          size: 484,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-06T00:00:00',
      duration: 2,
      id: '71670736-cfb8-4b54-9c79-1945d47fbb60',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>215-sq-foot room</li>\n            <li>Internet - Free WiFi and wired Internet access</li>\n            <li>Entertainment - Cable / satellite channels and flat screen / HD TV</li>\n            <li>Food &amp; Drink - American Breakfast $8 USD per day</li>\n            <li>Sleep - Luxury bedding and Premium Mattress </li>\n            <li>Bathroom - Bathrobes, Private bathroom and Rainfall Showerhead, Toiletries</li>\n            <li>Practical - Hair Dryer, Phone, Safe, Private Pool</li>\n            <li>Comfort - Air conditioning and daily housekeeping</li>\n            <li>Outdoor - Terrace</li>\n            <li>Rules - Non-Smoking, No Pets or Service Animals Allowed</li>\n            <li>The garden view rooms offer you a relaxing oasis in the vibrant cultural center of Merida.</li>\n    </ul>\n',
        images: [
          {
            assocId: 'bce2b1e2-c6eb-4c44-ad5d-e2a3609a2fb3',
            assocType: 'offerings',
            ext: 'jpg',
            id: '06bd38c4-5748-4e9e-9695-0a429afc0371',
            metadata: {},
          },
          {
            assocId: 'bce2b1e2-c6eb-4c44-ad5d-e2a3609a2fb3',
            assocType: 'offerings',
            ext: 'jpg',
            id: '3fe8535f-bf2a-491d-ad80-dbda3e8b0a9d',
            metadata: {},
          },
          {
            assocId: 'bce2b1e2-c6eb-4c44-ad5d-e2a3609a2fb3',
            assocType: 'offerings',
            ext: 'jpg',
            id: '0b3fb38f-64f0-4b5f-ab7f-c7fe75ee3f05',
            metadata: {},
          },
          {
            assocId: 'bce2b1e2-c6eb-4c44-ad5d-e2a3609a2fb3',
            assocType: 'offerings',
            ext: 'jpg',
            id: '43194188-f0e7-4530-bb15-799915baf7e7',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: 'bce2b1e2-c6eb-4c44-ad5d-e2a3609a2fb3',
          assocType: 'offerings',
          ext: 'jpg',
          id: '06bd38c4-5748-4e9e-9695-0a429afc0371',
          metadata: {},
        },
        name: 'Garden View 2 Queens',
        price: '125',
        provider: {
          address:
            'Calle 64ᴬ 417, Parque Santa Lucia, Centro, 97000 Mérida, Yuc., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6245605 20.9744886)',
          id: 'e3a259f4-83cb-4df2-916e-84a4d3a65180',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: 'e3a259f4-83cb-4df2-916e-84a4d3a65180',
            assocType: 'providers',
            ext: 'jpg',
            id: 'cd1d4dd3-7148-49f8-a27e-a72f9d7102bb',
            metadata: {},
          },
          name: 'Casa de las Palomas Boutique Hotel',
          url: 'casa-de-las-palomas-boutique-hotel',
          timeToAirport: 21,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 2,
          occupancy: 2,
          size: 215,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-05T00:00:00',
      duration: 2,
      id: '01afdf78-2378-4456-9dff-3eef8bd525ca',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>1248-sq-foot room</li>\n            <li>Internet - Free WiFi and wired Internet access</li>\n            <li>Entertainment - Cable / satellite channels and flat screen / HD TV and iPod / MP3 Docking Station </li>\n            <li>Food &amp; Drink - Breakfast included, Coffee/tea maker, Minibar, Room Service, and Free bottled water</li>\n            <li>Sleep - Luxury bedding</li>\n            <li>Bathroom - Bathrobes, Premium bath amenities, Slippers, and shower</li>\n            <li>Practical - Safe (laptop compatible),Iron / Ironing Board, Hair Dryer, Desk</li>\n            <li>Comfort - Air conditioning, Daily housekeeping, Separate living room</li>\n            <li>Outdoor - Terrace, Plunge pool on patio</li>\n            <li>Rules - Non-Smoking, Cribs not permitted</li>\n            <li>All of our suites feature 18-foot ceilings with exposed beams and rafters, 10-foot wooden double doors, massive iron beds, dark, tropical hardwood cabinets, cool tile floors, and a private plunge pool on an ample terrace. Additionally, large, bulbous pegs in the stonewalls hold woven cotton hammocks, for which Yucatan is famous, allowing you to sleep in the Mayan style. The largest room at the Hacienda Temozon featuring a private pool. </li>\n    </ul>\n',
        images: [
          {
            assocId: 'df3b43b2-7dd3-4653-9484-74f450f1d508',
            assocType: 'offerings',
            ext: 'png',
            id: '7b2f6269-af15-4f42-8b15-5308c45d4bd7',
            metadata: {},
          },
          {
            assocId: 'df3b43b2-7dd3-4653-9484-74f450f1d508',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'b8dfc172-2cf9-442e-8eeb-10e94c940370',
            metadata: {},
          },
          {
            assocId: 'df3b43b2-7dd3-4653-9484-74f450f1d508',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'cf014947-02d4-4114-89d6-e366349d4a4e',
            metadata: {},
          },
          {
            assocId: 'df3b43b2-7dd3-4653-9484-74f450f1d508',
            assocType: 'offerings',
            ext: 'jpg',
            id: '2338e2fc-df6a-42ca-8224-d99b321fbef3',
            metadata: {},
          },
          {
            assocId: 'df3b43b2-7dd3-4653-9484-74f450f1d508',
            assocType: 'offerings',
            ext: 'jpg',
            id: '9aded8da-f05f-4383-a836-c103a3c02af2',
            metadata: {},
          },
          {
            assocId: 'df3b43b2-7dd3-4653-9484-74f450f1d508',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'd3e604cb-9537-409b-82e8-a4a01a04d21a',
            metadata: {},
          },
          {
            assocId: 'df3b43b2-7dd3-4653-9484-74f450f1d508',
            assocType: 'offerings',
            ext: 'jpg',
            id: '3809b7fb-1403-4d24-a59d-6ec1f6339916',
            metadata: {},
          },
          {
            assocId: 'df3b43b2-7dd3-4653-9484-74f450f1d508',
            assocType: 'offerings',
            ext: 'jpg',
            id: '140deaee-d9bc-4057-960b-62ee93dc558c',
            metadata: {},
          },
          {
            assocId: 'df3b43b2-7dd3-4653-9484-74f450f1d508',
            assocType: 'offerings',
            ext: 'png',
            id: '30b94f55-4f50-4a32-afb7-08ad55f2a537',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: 'df3b43b2-7dd3-4653-9484-74f450f1d508',
          assocType: 'offerings',
          ext: 'png',
          id: '7b2f6269-af15-4f42-8b15-5308c45d4bd7',
          metadata: {},
        },
        name: 'Casa Del Patron Presidential Suite',
        price: '425',
        provider: {
          address:
            'Km 182 Carretera Merida-Uxmal, 97825 Temozón Sur, Yuc., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.653218 20.68508)',
          id: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
            assocType: 'providers',
            ext: 'jpg',
            id: 'd16e1bb4-6a0a-49f7-ba86-1e1feb564bff',
            metadata: {},
          },
          name: 'Hacienda Temozon',
          url: 'hacienda-temozon',
          timeToAirport: 45,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 1,
          occupancy: 2,
          size: 1248,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-03T00:00:00',
      duration: 2,
      id: '1d24cad6-d8fd-49d5-bb38-7e98d20a9385',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>430-sq-foot room</li>\n            <li>Internet - Free WiFi</li>\n            <li>Entertainment - Bose sound system, cable / satellite channels and flat screen / HD TV and iPod dock </li>\n            <li>Food &amp; Drink - Breakfast included, room service, minibar, and free bottled water</li>\n            <li>Sleep - Luxury bedding and pillow menu</li>\n            <li>Bathroom - Private bathroom, luxury linens, luxury toiletries, and shower</li>\n            <li>Practical - Laptop-compatible safe, free newspaper, and VoIP phone</li>\n            <li>Comfort - Air conditioning and daily housekeeping</li>\n            <li>Outdoor - Jacuzzi / soaking tub</li>\n            <li>Rules - Non-Smoking</li>\n            <li>Spacious room featuring designer furnishings and unique details, overlooking the pool, water gardens or private corridor</li>\n    </ul>\n',
        images: [
          {
            assocId: 'ebaee83d-f15d-4906-93f9-86b6e7ae2780',
            assocType: 'offerings',
            ext: 'png',
            id: 'eeef3551-8d93-4aee-b36f-db54086e742a',
            metadata: {},
          },
          {
            assocId: 'ebaee83d-f15d-4906-93f9-86b6e7ae2780',
            assocType: 'offerings',
            ext: 'png',
            id: 'e2468075-3f5f-4913-baff-6038ffa33458',
            metadata: {},
          },
          {
            assocId: 'ebaee83d-f15d-4906-93f9-86b6e7ae2780',
            assocType: 'offerings',
            ext: 'jpg',
            id: '130502be-c1f2-46d2-b2d9-e7a72f57e6c8',
            metadata: {},
          },
          {
            assocId: 'ebaee83d-f15d-4906-93f9-86b6e7ae2780',
            assocType: 'offerings',
            ext: 'png',
            id: '4c3c7fde-8c2b-4598-9e29-119c6e524c28',
            metadata: {},
          },
          {
            assocId: 'ebaee83d-f15d-4906-93f9-86b6e7ae2780',
            assocType: 'offerings',
            ext: 'png',
            id: 'f8f8f43e-c89e-4337-83f4-43c6611af5bd',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: 'ebaee83d-f15d-4906-93f9-86b6e7ae2780',
          assocType: 'offerings',
          ext: 'png',
          id: 'eeef3551-8d93-4aee-b36f-db54086e742a',
          metadata: {},
        },
        name: 'Deluxe Room, 2 Queen Beds',
        price: '245',
        provider: {
          address: '\\N',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6206127 20.979003)',
          id: '95b9374c-4ae5-4e2c-af60-fc5a6738f49d',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: '95b9374c-4ae5-4e2c-af60-fc5a6738f49d',
            assocType: 'providers',
            ext: 'jpg',
            id: 'fae18c01-71b7-4a15-aed3-31d737956abe',
            metadata: {},
          },
          name: 'Rosas & Xocolate Boutique Hotel & Spa',
          url: 'rosas-xocolate-boutique-hotel-spa',
          timeToAirport: 20,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 2,
          occupancy: 2,
          size: 430,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-03T00:00:00',
      duration: 2,
      id: 'ff97e539-af13-4bee-bea9-6a55ee83d192',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description: '',
        images: [
          {
            assocId: 'ededa2b4-415b-4e30-b975-27a05fccc813',
            assocType: 'offerings',
            ext: 'png',
            id: 'e000f8d3-b87b-49cb-b0eb-8260b2f55e4b',
            metadata: {},
          },
          {
            assocId: 'ededa2b4-415b-4e30-b975-27a05fccc813',
            assocType: 'offerings',
            ext: 'png',
            id: '55086e5e-4f4c-4bc9-abef-2f84697423ee',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: 'ededa2b4-415b-4e30-b975-27a05fccc813',
          assocType: 'offerings',
          ext: 'png',
          id: 'e000f8d3-b87b-49cb-b0eb-8260b2f55e4b',
          metadata: {},
        },
        name: 'Superior Double Room, 2 Double Beds',
        price: '0',
        provider: {
          address: '\\N',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6206127 20.979003)',
          id: '95b9374c-4ae5-4e2c-af60-fc5a6738f49d',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: '95b9374c-4ae5-4e2c-af60-fc5a6738f49d',
            assocType: 'providers',
            ext: 'jpg',
            id: 'fae18c01-71b7-4a15-aed3-31d737956abe',
            metadata: {},
          },
          name: 'Rosas & Xocolate Boutique Hotel & Spa',
          url: 'rosas-xocolate-boutique-hotel-spa',
          timeToAirport: 20,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 0,
          occupancy: 0,
          size: 431,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-05T00:00:00',
      duration: 2,
      id: '41633474-6300-4a6f-bcaf-42761e145418',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>484-sq-foot room</li>\n            <li>Internet - Free WiFi and wired Internet access</li>\n            <li>Entertainment - Cable / satellite channels and flat screen / HD TV and iPod / MP3 Docking Station </li>\n            <li>Food &amp; Drink - Breakfast included, Coffee/tea maker, Minibar, Room Service, and Free bottled water</li>\n            <li>Sleep - Luxury bedding</li>\n            <li>Bathroom - Bathrobes, Premium bath amenities, Slippers, and shower</li>\n            <li>Practical - Safe (laptop compatible),Iron / Ironing Board, Hair Dryer, Desk</li>\n            <li>Comfort - Air conditioning, Daily housekeeping, Separate living room</li>\n            <li>Outdoor - Terrace, Plunge pool on patio</li>\n            <li>Rules - Non-Smoking</li>\n            <li>All of our suites feature 18-foot ceilings with exposed beams and rafters, 10-foot wooden double doors, massive iron beds, dark, tropical hardwood cabinets, cool tile floors, and a private plunge pool on an ample terrace. Additionally, large, bulbous pegs in the stonewalls hold woven cotton hammocks, for which Yucatan is famous, allowing you to sleep in the Mayan style. The largest room at the Hacienda Temozon featuring a private pool. </li>\n    </ul>\n',
        images: [
          {
            assocId: 'f60cb429-9d9f-46f2-b024-c39feba4ede4',
            assocType: 'offerings',
            ext: 'jpg',
            id: '68387f6c-8318-4264-a3b8-8420a4f13edd',
            metadata: {},
          },
          {
            assocId: 'f60cb429-9d9f-46f2-b024-c39feba4ede4',
            assocType: 'offerings',
            ext: 'jpg',
            id: '76edf031-4a40-4bc3-bad5-9b3fd9e73f1b',
            metadata: {},
          },
          {
            assocId: 'f60cb429-9d9f-46f2-b024-c39feba4ede4',
            assocType: 'offerings',
            ext: 'jpg',
            id: '1e668f29-0b31-4034-a8d4-77bbe88466ce',
            metadata: {},
          },
          {
            assocId: 'f60cb429-9d9f-46f2-b024-c39feba4ede4',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'b0f996c4-066c-4047-83eb-35bc46937884',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: 'f60cb429-9d9f-46f2-b024-c39feba4ede4',
          assocType: 'offerings',
          ext: 'jpg',
          id: '68387f6c-8318-4264-a3b8-8420a4f13edd',
          metadata: {},
        },
        name: 'Deluxe Guest room 2 Queen',
        price: '176',
        provider: {
          address:
            'Km 182 Carretera Merida-Uxmal, 97825 Temozón Sur, Yuc., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.653218 20.68508)',
          id: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
            assocType: 'providers',
            ext: 'jpg',
            id: 'd16e1bb4-6a0a-49f7-ba86-1e1feb564bff',
            metadata: {},
          },
          name: 'Hacienda Temozon',
          url: 'hacienda-temozon',
          timeToAirport: 45,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 2,
          occupancy: 4,
          size: 484,
        },
      },
    },
    {
      alias: null,
      date: '2022-11-04T00:00:00',
      duration: 2,
      id: '715cee5e-87e9-422f-b42d-f02e22fcbbe6',
      main: false,
      offering: {
        __typename: 'RoomOffering',
        description:
          '<p class="fwsb fz-15 pl-3">\n    ROOM DETAILS\n</p>\n<ul>\n            <li>350-sq-foot room</li>\n            <li>Internet - WiFi</li>\n            <li>Entertainment - Cable / satellite tv, and flat screen / HDTV</li>\n            <li>Sleep - Luxury bedding</li>\n            <li>Bathroom - Private bathroom, shower, soaking tub, and luxury linens</li>\n            <li>Comfort - Air conditioning and ceiling fan</li>\n            <li>Rules - Adults only, non-smoking, no parties or events unless a venue booking is made</li>\n            <li>Luxury double room with ensuite bathroom and private balcony with courtyard view</li>\n    </ul>\n',
        images: [
          {
            assocId: 'f72e8ba5-5161-41e7-b615-2179a5fbac4f',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'd7317b29-8d88-407d-b38e-afa8ed02c30a',
            metadata: {},
          },
          {
            assocId: 'f72e8ba5-5161-41e7-b615-2179a5fbac4f',
            assocType: 'offerings',
            ext: 'jpg',
            id: '00dff506-db42-4fa0-b4ce-fbf3453a8e2e',
            metadata: {},
          },
          {
            assocId: 'f72e8ba5-5161-41e7-b615-2179a5fbac4f',
            assocType: 'offerings',
            ext: 'png',
            id: '2331d340-5664-43d2-a95e-6dbcee4c4529',
            metadata: {},
          },
        ],
        mainImage: {
          assocId: 'f72e8ba5-5161-41e7-b615-2179a5fbac4f',
          assocType: 'offerings',
          ext: 'jpg',
          id: 'd7317b29-8d88-407d-b38e-afa8ed02c30a',
          metadata: {},
        },
        name: 'Guest Room 3',
        price: '166',
        provider: {
          address: 'Privada 942, Zona Centro, 22000 Tijuana, B.C., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6292353 20.9682858)',
          id: '88f3e66c-56af-4803-8117-da9d2a9686e5',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: '88f3e66c-56af-4803-8117-da9d2a9686e5',
            assocType: 'providers',
            ext: 'jpg',
            id: '3d435109-9687-4888-9795-2033dae41ee3',
            metadata: {},
          },
          name: 'La Casa del Centro',
          url: 'la-casa-del-centro',
          timeToAirport: 20,
        },
        room: {
          __typename: 'RoomDetails',
          beds: 2,
          occupancy: 2,
          size: 350,
        },
      },
    },
    {
      alias: 'Welcome Party',
      date: '2022-11-03T00:00:00',
      duration: 1,
      id: '8d065ae9-8518-4a05-9cd7-c4e3affa550d',
      main: false,
      offering: {
        __typename: 'PackageOffering',
        description: '<p class="summary"></p>\n',
        images: [
          {
            assocId: '3cfe63bd-f3a8-4524-a276-d89666131161',
            assocType: 'offerings',
            ext: 'jpg',
            id: '2064c323-80dd-4afb-bd8c-0cc70a7e1d37',
            metadata: {
              capacity: {
                ceremony: 40,
                cocktail: 55,
                reception: 40,
              },
              hours: {
                standard: '5PM-12AM',
              },
              name: '"Outside Terrace "',
              version: 'package_1',
            },
          },
          {
            assocId: '3cfe63bd-f3a8-4524-a276-d89666131161',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'a863afb8-7e58-4aac-8004-7f023f164fe7',
            metadata: {
              capacity: {
                ceremony: 80,
                cocktail: 100,
                reception: 80,
              },
              hours: {
                standard: '5PM-12AM',
              },
              name: '"Salon"',
              version: 'package_1',
            },
          },
          {
            assocId: '3cfe63bd-f3a8-4524-a276-d89666131161',
            assocType: 'offerings',
            ext: 'jpg',
            id: '18c80464-428e-45b5-8f65-f0d0e2100473',
            metadata: {
              capacity: {
                ceremony: 40,
                cocktail: 50,
                reception: 35,
              },
              hours: {
                standard: '5PM-12AM',
              },
              name: '"Rooftop (Moon Lounge)"',
              version: 'package_1',
            },
          },
          {
            assocId: '3cfe63bd-f3a8-4524-a276-d89666131161',
            assocType: 'offerings',
            ext: 'jpg',
            id: '8fc76b2f-d733-44c2-ba0d-6473c7e8dd85',
            metadata: {
              capacity: {
                ceremony: 25,
                cocktail: 40,
                reception: 25,
              },
              hours: {
                standard: '5PM-12AM',
              },
              name: '" Tequila Bar"',
              version: 'package_1',
            },
          },
        ],
        intro:
          '<span class="eventcard__rules fz-13 tc-lgray pt-3">Includes food & beverage.</span><div class="eventcard__data"><h3>Includes</h3><ul><li>Cocktail - 60 minutes, High top tables, Linens</li></ul></div>',
        mainImage: {
          assocId: '3cfe63bd-f3a8-4524-a276-d89666131161',
          assocType: 'offerings',
          ext: 'jpg',
          id: '2064c323-80dd-4afb-bd8c-0cc70a7e1d37',
          metadata: {
            capacity: {
              ceremony: 40,
              cocktail: 55,
              reception: 40,
            },
            hours: {
              standard: '5PM-12AM',
            },
            name: '"Outside Terrace "',
            version: 'package_1',
          },
        },
        name: 'Cocktail Party',
        provider: {
          address: '\\N',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6206127 20.979003)',
          id: '95b9374c-4ae5-4e2c-af60-fc5a6738f49d',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: '95b9374c-4ae5-4e2c-af60-fc5a6738f49d',
            assocType: 'providers',
            ext: 'jpg',
            id: 'fae18c01-71b7-4a15-aed3-31d737956abe',
            metadata: {},
          },
          name: 'Rosas & Xocolate Boutique Hotel & Spa',
          url: 'rosas-xocolate-boutique-hotel-spa',
          timeToAirport: 20,
        },
      },
    },
    {
      alias: 'Rehearsal Dinner',
      date: '2022-11-04T00:00:00',
      duration: 1,
      id: 'c36e0a0c-cb8e-4eed-b7a3-351a9a2d1854',
      main: false,
      offering: {
        __typename: 'PackageOffering',
        description:
          '<p class="summary">Customize your event. Inquire with us for pricing and details.</p>\n',
        images: [
          {
            assocId: '9408740a-4eb9-4689-984c-babc1296968d',
            assocType: 'offerings',
            ext: 'jpg',
            id: '4df30f61-b242-4c95-8451-99dbefe4b727',
            metadata: {
              capacity: {
                ceremony: 0,
                cocktail: 30,
                reception: 20,
              },
              hours: {
                standard: '2:00 AM',
              },
              name: 'Elegant Indoor Room',
              version: 'package_1',
            },
          },
          {
            assocId: '9408740a-4eb9-4689-984c-babc1296968d',
            assocType: 'offerings',
            ext: 'jpg',
            id: 'af59c31f-f972-4405-8bbf-85fe3a7c85ea',
            metadata: {
              capacity: {
                ceremony: 0,
                cocktail: 40,
                reception: 0,
              },
              hours: {
                standard: '2:00 AM',
              },
              name: 'Outdoor Poolside Space',
              version: 'package_1',
            },
          },
        ],
        intro:
          '<div class="eventcard__data"><h3>Includes</h3><ul><li>Ceremony - 60 minutes</li><li>Cocktail - 60 minutes</li><li>Reception - 180 minutes</li></ul></div>',
        mainImage: {
          assocId: '9408740a-4eb9-4689-984c-babc1296968d',
          assocType: 'offerings',
          ext: 'jpg',
          id: '4df30f61-b242-4c95-8451-99dbefe4b727',
          metadata: {
            capacity: {
              ceremony: 0,
              cocktail: 30,
              reception: 20,
            },
            hours: {
              standard: '2:00 AM',
            },
            name: 'Elegant Indoor Room',
            version: 'package_1',
          },
        },
        name: 'La Casa del Centro Wedding',
        provider: {
          address: 'Privada 942, Zona Centro, 22000 Tijuana, B.C., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.6292353 20.9682858)',
          id: '88f3e66c-56af-4803-8117-da9d2a9686e5',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: '88f3e66c-56af-4803-8117-da9d2a9686e5',
            assocType: 'providers',
            ext: 'jpg',
            id: '3d435109-9687-4888-9795-2033dae41ee3',
            metadata: {},
          },
          name: 'La Casa del Centro',
          url: 'la-casa-del-centro',
          timeToAirport: 20,
        },
      },
    },
    {
      alias: 'Wedding Ceremony',
      date: '2022-11-05T00:00:00',
      duration: 1,
      id: 'b4af7f28-e6ed-434c-ad5b-16d7e639a317',
      main: true,
      offering: {
        __typename: 'PackageOffering',
        description: '<p class="summary"></p>\n',
        images: [
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'jpeg',
            id: 'adad91c8-3cc3-4e02-8a71-b346dfacdce6',
            metadata: {
              capacity: {
                ceremony: 120,
                cocktail: 150,
                reception: 120,
              },
              hours: {
                standard: '12:00 AM',
              },
              name: 'Garden View Terrace',
              version: 'package_1',
            },
          },
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'jpeg',
            id: '211f0bec-60c4-4c59-8361-513a5bb9f2f7',
            metadata: {
              capacity: {
                ceremony: 0,
                cocktail: 200,
                reception: 160,
              },
              hours: {
                standard: '11:00 PM',
              },
              name: 'Casa Temozon Restaurant',
              version: 'package_1',
            },
          },
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'jpeg',
            id: '5902a016-e7a5-4b87-af75-9616e6be5c56',
            metadata: {
              capacity: {
                ceremony: 120,
                cocktail: 150,
                reception: 120,
              },
              hours: {
                standard: '12:00 AM',
              },
              name: 'Poolside Terrace',
              version: 'package_1',
            },
          },
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'jpeg',
            id: 'c726a770-abe6-421b-8424-3dc6efaca7bf',
            metadata: {
              capacity: {
                ceremony: 60,
                cocktail: 80,
                reception: 60,
              },
              hours: {
                standard: '2:00 AM',
              },
              name: 'Salon Sky',
              version: 'package_1',
            },
          },
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'png',
            id: '074019b1-aa24-4692-a71d-824ab11ec005',
            metadata: {
              capacity: {
                ceremony: 150,
                cocktail: 250,
                reception: 150,
              },
              hours: {
                standard: '12PM-5AM',
              },
              name: '"Main House Terrace"',
              version: 'package_1',
            },
          },
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'png',
            id: '7871d09e-3911-44f4-9909-23425eb868db',
            metadata: {
              capacity: {
                ceremony: 600,
                cocktail: 1000,
                reception: 600,
              },
              hours: {
                standard: '12PM-5AM',
              },
              name: '"Ramon Main House Garden"',
              version: 'package_1',
            },
          },
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'jpeg',
            id: '09f68ef4-7bed-4bc8-8595-1ea4cf4f7bbf',
            metadata: {
              capacity: {
                ceremony: 200,
                cocktail: 250,
                reception: 200,
              },
              hours: {
                standard: '2:00 AM',
              },
              name: 'Salon de Pacas',
              version: 'package_1',
            },
          },
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'png',
            id: '7c71836c-b2a3-44b8-bb04-d83e57f0dc99',
            metadata: {
              capacity: {
                ceremony: 1000,
                cocktail: 1000,
                reception: 1000,
              },
              hours: {
                standard: '12PM-5AM',
              },
              name: '"Pitch Garden & Pool"',
              version: 'package_1',
            },
          },
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'jpg',
            id: '3f8335b3-a860-48dd-9bf7-67fe92d9c19c',
            metadata: {
              capacity: {
                ceremony: 150,
                cocktail: 200,
                reception: 150,
              },
              hours: {
                standard: '12PM-5AM',
              },
              name: '"Salon de Pacas"',
              version: 'package_1',
            },
          },
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'png',
            id: 'fbcb0c56-85bc-42bd-9733-e3e22e1fc72c',
            metadata: {
              capacity: {
                ceremony: 120,
                cocktail: 150,
                reception: 120,
              },
              hours: {
                standard: '11AM-11PM',
              },
              name: '"Temozon Restaurant"',
              version: 'package_1',
            },
          },
          {
            assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
            assocType: 'offerings',
            ext: 'jpeg',
            id: '544228b9-2209-4aac-b7a3-93a5c56ccd0a',
            metadata: {
              capacity: {
                ceremony: 220,
                cocktail: 300,
                reception: 220,
              },
              hours: {
                standard: '12:00 AM',
              },
              name: 'Garden',
              version: 'package_1',
            },
          },
        ],
        intro: '',
        mainImage: {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'jpeg',
          id: 'adad91c8-3cc3-4e02-8a71-b346dfacdce6',
          metadata: {
            capacity: {
              ceremony: 120,
              cocktail: 150,
              reception: 120,
            },
            hours: {
              standard: '12:00 AM',
            },
            name: 'Garden View Terrace',
            version: 'package_1',
          },
        },
        name: 'International Flavors Premium Wedding',
        provider: {
          address:
            'Km 182 Carretera Merida-Uxmal, 97825 Temozón Sur, Yuc., Mexico',
          airportCode: 'MID',
          coord: 'SRID=4326;POINT(-89.653218 20.68508)',
          id: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
          location: 'Merida, Mexico',
          mainImage: {
            assocId: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
            assocType: 'providers',
            ext: 'jpg',
            id: 'd16e1bb4-6a0a-49f7-ba86-1e1feb564bff',
            metadata: {},
          },
          name: 'Hacienda Temozon',
          url: 'hacienda-temozon',
          timeToAirport: 45,
        },
      },
    },
  ],
  firstName: 'GUEST',
  id: 'b7d3b55e-b223-439e-b730-28dc0ded4f25',
  lastName: 'GUEST',
  mainComponent: {
    alias: 'Wedding Ceremony',
    date: '2022-11-05T00:00:00',
    duration: 1,
    id: 'b4af7f28-e6ed-434c-ad5b-16d7e639a317',
    main: true,
    offering: {
      __typename: 'PackageOffering',
      description: '<p class="summary"></p>\n',
      images: [
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'jpeg',
          id: 'adad91c8-3cc3-4e02-8a71-b346dfacdce6',
          metadata: {
            capacity: {
              ceremony: 120,
              cocktail: 150,
              reception: 120,
            },
            hours: {
              standard: '12:00 AM',
            },
            name: 'Garden View Terrace',
            version: 'package_1',
          },
        },
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'jpeg',
          id: '211f0bec-60c4-4c59-8361-513a5bb9f2f7',
          metadata: {
            capacity: {
              ceremony: 0,
              cocktail: 200,
              reception: 160,
            },
            hours: {
              standard: '11:00 PM',
            },
            name: 'Casa Temozon Restaurant',
            version: 'package_1',
          },
        },
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'jpeg',
          id: '5902a016-e7a5-4b87-af75-9616e6be5c56',
          metadata: {
            capacity: {
              ceremony: 120,
              cocktail: 150,
              reception: 120,
            },
            hours: {
              standard: '12:00 AM',
            },
            name: 'Poolside Terrace',
            version: 'package_1',
          },
        },
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'jpeg',
          id: 'c726a770-abe6-421b-8424-3dc6efaca7bf',
          metadata: {
            capacity: {
              ceremony: 60,
              cocktail: 80,
              reception: 60,
            },
            hours: {
              standard: '2:00 AM',
            },
            name: 'Salon Sky',
            version: 'package_1',
          },
        },
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'png',
          id: '074019b1-aa24-4692-a71d-824ab11ec005',
          metadata: {
            capacity: {
              ceremony: 150,
              cocktail: 250,
              reception: 150,
            },
            hours: {
              standard: '12PM-5AM',
            },
            name: '"Main House Terrace"',
            version: 'package_1',
          },
        },
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'png',
          id: '7871d09e-3911-44f4-9909-23425eb868db',
          metadata: {
            capacity: {
              ceremony: 600,
              cocktail: 1000,
              reception: 600,
            },
            hours: {
              standard: '12PM-5AM',
            },
            name: '"Ramon Main House Garden"',
            version: 'package_1',
          },
        },
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'jpeg',
          id: '09f68ef4-7bed-4bc8-8595-1ea4cf4f7bbf',
          metadata: {
            capacity: {
              ceremony: 200,
              cocktail: 250,
              reception: 200,
            },
            hours: {
              standard: '2:00 AM',
            },
            name: 'Salon de Pacas',
            version: 'package_1',
          },
        },
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'png',
          id: '7c71836c-b2a3-44b8-bb04-d83e57f0dc99',
          metadata: {
            capacity: {
              ceremony: 1000,
              cocktail: 1000,
              reception: 1000,
            },
            hours: {
              standard: '12PM-5AM',
            },
            name: '"Pitch Garden & Pool"',
            version: 'package_1',
          },
        },
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'jpg',
          id: '3f8335b3-a860-48dd-9bf7-67fe92d9c19c',
          metadata: {
            capacity: {
              ceremony: 150,
              cocktail: 200,
              reception: 150,
            },
            hours: {
              standard: '12PM-5AM',
            },
            name: '"Salon de Pacas"',
            version: 'package_1',
          },
        },
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'png',
          id: 'fbcb0c56-85bc-42bd-9733-e3e22e1fc72c',
          metadata: {
            capacity: {
              ceremony: 120,
              cocktail: 150,
              reception: 120,
            },
            hours: {
              standard: '11AM-11PM',
            },
            name: '"Temozon Restaurant"',
            version: 'package_1',
          },
        },
        {
          assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
          assocType: 'offerings',
          ext: 'jpeg',
          id: '544228b9-2209-4aac-b7a3-93a5c56ccd0a',
          metadata: {
            capacity: {
              ceremony: 220,
              cocktail: 300,
              reception: 220,
            },
            hours: {
              standard: '12:00 AM',
            },
            name: 'Garden',
            version: 'package_1',
          },
        },
      ],
      intro: '',
      mainImage: {
        assocId: 'b6f91b1c-503c-4e91-ba52-a63e3387003c',
        assocType: 'offerings',
        ext: 'jpeg',
        id: 'adad91c8-3cc3-4e02-8a71-b346dfacdce6',
        metadata: {
          capacity: {
            ceremony: 120,
            cocktail: 150,
            reception: 120,
          },
          hours: {
            standard: '12:00 AM',
          },
          name: 'Garden View Terrace',
          version: 'package_1',
        },
      },
      name: 'International Flavors Premium Wedding',
      provider: {
        address:
          'Km 182 Carretera Merida-Uxmal, 97825 Temozón Sur, Yuc., Mexico',
        airportCode: 'MID',
        coord: 'SRID=4326;POINT(-89.653218 20.68508)',
        id: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
        location: 'Merida, Mexico',
        mainImage: {
          assocId: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
          assocType: 'providers',
          ext: 'jpg',
          id: 'd16e1bb4-6a0a-49f7-ba86-1e1feb564bff',
          metadata: {},
        },
        name: 'Hacienda Temozon',
        url: 'hacienda-temozon',
        timeToAirport: 45,
      },
    },
  },
  status: 'sent',
} as Guest
