import React from 'react'
import forIn from 'lodash/forIn'
import join from 'lodash/fp/join'
import values from 'lodash/fp/values'
import { MdPerson } from 'react-icons/md'

import { IFilterInputs } from '../../types'
import { darkGray } from '../../styles/themeColors'

export const createOccupancyIcons = (occupancy: number): JSX.Element[] => {
  const icons: JSX.Element[] = []
  for (let i = 0; i < occupancy; i += 1) {
    icons.push(<MdPerson key={i} size={16} color={darkGray} />)
  }
  return icons
}

export const makeSearchUrl = (
  base: string,
  filterInputs: IFilterInputs
): string => {
  let url = base
  if (join('', values(filterInputs)) !== '') {
    // at least 1 value is needed
    url = `${base}?`
    forIn(filterInputs, (value, key) => {
      if (value && typeof value === 'string') {
        const parsedValue = value.replace('&', '[38]') // ASCII Code for Ampersand
        url = `${url}${key}=${parsedValue}&`
      }
    })
    url = url.slice(0, -1) // remove the last &
  }

  return url
}

export const getEventRoomRate = (price: string): string => {
  // new function in case we have more conditions or exceptions
  if (price === '0' || price === '1000000') return 'request'

  return `$${price}`
}
