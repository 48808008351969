import React from 'react'
import { FaLinkedin } from 'react-icons/fa'

import { AboutUsInfoSection, TeamMemberInfo } from '../style'

interface ISection extends React.HtmlHTMLAttributes<HTMLDivElement> {
  photo: string
  reverse?: boolean
  title: string
  titleClassname?: string
}
export const Section: React.FC<ISection> = ({
  photo,
  title,
  children,
  reverse = false,
  className,
  titleClassname,
}) => (
  <AboutUsInfoSection className={`${className} ${reverse ? 'reverse' : ''}`}>
    <img src={`/img/about/${photo}`} alt={title} className="photo" />
    <div className="content">
      <h3 className={titleClassname}>{title}</h3>
      {children}
    </div>
  </AboutUsInfoSection>
)

interface ITeamMember {
  photo: string
  title: string
  subtitle: string
  link: string
}
export const TeamMember: React.FC<ITeamMember> = ({
  photo,
  title,
  subtitle,
  link,
}) => (
  <TeamMemberInfo>
    <img src={`/img/about/leaders/${photo}`} alt={title} className="mb-3" />
    <h2 className="fwb fz-18 text-primary">
      {title}
      <a href={link} target="_blank" rel="noopener noreferrer">
        <FaLinkedin size={24} color="#0e76a8" className="ml-1 svg-top1" />
      </a>
    </h2>
    <p className="fwsb fz-16">{subtitle}</p>
  </TeamMemberInfo>
)
