import React from 'react'
import moment from 'moment'
import map from 'lodash/fp/map'
import last from 'lodash/fp/last'
import head from 'lodash/fp/head'
import flow from 'lodash/fp/flow'
import sortBy from 'lodash/fp/sortBy'
import filter from 'lodash/fp/filter'
import orderBy from 'lodash/fp/orderBy'
import groupBy from 'lodash/fp/groupBy'
import toArray from 'lodash/fp/toArray'
import { useMutation } from '@apollo/react-hooks'
import Swal from 'sweetalert2'
import find from 'lodash/fp/find'
import { useLocation } from 'react-router-dom'

import { SET_MAIN_COMPONENT } from '../../../graphql'
import { simpleAlert } from '../../../common'
import { useAppSelector } from '../../../hooks'
import {
  IcurrentAddons,
  EventComponent,
  IBookingGuest,
  IFreeBookingGuest,
  ECorList,
} from '../../../types'

export const useSetMainComponent = (
  background = false
): [(eventComponentId: string) => void, boolean] => {
  const [setMain, { loading }] = useMutation(SET_MAIN_COMPONENT)

  const setMainComponent = (eventComponentId: string): void => {
    const variables = {
      id: eventComponentId,
    }
    setMain({ variables }).then(({ data }) => {
      Swal.close() // in case of a loading alert
      if (data.setMainComponent.errors.length > 0 && !background) {
        simpleAlert({
          html: 'it was not possible to update the status of this package',
          icon: 'warning',
        })
      }
    })
  }

  return [setMainComponent, loading]
}

export const defaultIntro = (): React.ReactElement => (
  <>
    We’re thrilled to have you join us at our big event!
    <br />
    Let us know if you can make it or feel free to come back and RSVP at a later
    date.
    <br />
    For more details on our event, updates and to chat with the group.
  </>
)

export const useGetExtraDaysFromAddons = (
  currentAddons?: IcurrentAddons
): { before: number; after: number } => {
  const location = useLocation()
  const extraNights = useAppSelector(state => state.booking.extraNights)
  const type = location.pathname.includes('details') ? 'details' : 'booking'

  if (type === 'booking') {
    // get the extra nights from redux when the booking is being created
    const before = find({ type: 'before' }, extraNights)
    const after = find({ type: 'after' }, extraNights)
    return {
      before: before?.quantity ?? 0,
      after: after?.quantity ?? 0,
    }
  }

  // type = 'details'
  // get the extra nights from the booking (database)
  const before = currentAddons?.extraNightsBefore?.offeringAddOnOption
  const after = currentAddons?.extraNightsAfter?.offeringAddOnOption
  return {
    before: before?.quantity ?? 0,
    after: after?.quantity ?? 0,
  }
}

export const getSooner = (
  rooms: EventComponent[] = []
): EventComponent | undefined => {
  if (rooms.length <= 0) return undefined

  const currentDate = moment()
  const ordered = flow(
    filter((ec: EventComponent) => moment(ec.date).isSameOrAfter(currentDate)),
    sortBy((ec: EventComponent) => moment(ec.date).diff(currentDate))
  )(rooms)

  return head(ordered)
}
export const getFurthest = (
  rooms: EventComponent[] = []
): EventComponent | undefined => {
  if (rooms.length <= 0) return undefined

  const currentDate = moment()
  const ordered = flow(
    filter((ec: EventComponent) => moment(ec.date).isSameOrAfter(currentDate)),
    map((ec: EventComponent) => ({
      ...ec,
      date: moment(ec.date).add(ec.duration, 'days'),
    })),
    sortBy((ec: EventComponent) => moment(ec.date).diff(currentDate))
  )(rooms)

  return last(ordered)
}

export const checkDuplicatedGuests = (
  list: (IFreeBookingGuest | IBookingGuest)[]
): boolean => {
  const seen: { [key: string]: boolean } = {}

  return list.some(item => {
    const guestEmail = item.guest.email as string
    if (guestEmail && seen[guestEmail]) {
      return true
    }
    seen[guestEmail] = true
    return false
  })
}

// Functions to use in the invitation landing page
export const groupComponentsByDate = (list: EventComponent[]): ECorList[][] =>
  flow(
    orderBy(['date', 'order'], ['asc', 'asc']),
    groupBy('offering.__typename'),
    (res: {
      RoomOffering: EventComponent[]
      PackageOffering: EventComponent[]
    }) => {
      const roomsByProvider = flow(
        groupBy((el: EventComponent) => el.offering.provider.id),
        toArray,
        map((el: EventComponent[]) => ({ date: el[0].date, rooms: el }))
      )(res.RoomOffering)

      return [...roomsByProvider, ...res.PackageOffering]
    },
    sortBy((ec: ECorList) => moment(ec.date)),
    groupBy('date'),
    toArray
  )(list)

export const sortPackagesFirst = (group: ECorList[]): ECorList[] =>
  [...group].sort((a, b) => {
    const aHasRooms = 'rooms' in a
    const bHasRooms = 'rooms' in b
    if (aHasRooms && !bHasRooms) {
      return 1
    } else if (!aHasRooms && bHasRooms) {
      return -1
    }
    return 0
  })
