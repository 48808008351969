import React, { useState, useEffect, useRef } from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

import { Provider } from '../../../types'
import { getCoords, checkEnv } from '../../../common'
import { MAPS_DEV_API_KEY, MAPS_PROD_API_KEY } from '../../../common/constants'

const Map: React.FC<{ provider: Provider; style?: React.CSSProperties }> = ({
  provider,
  style,
}) => {
  // eslint-disable-next-line no-undef
  const [map, setMap] = useState<google.maps.Map>()
  // eslint-disable-next-line no-undef
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const [lat, lng] = getCoords(provider.coord as string)
    const center = { lat, lng }

    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom: 13,
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        })
      )
    }

    if (map) {
      // eslint-disable-next-line no-new
      new window.google.maps.Marker({
        position: center,
        map,
        title: provider.name,
      })
    }
  }, [provider, map])

  return (
    <div
      style={style || { height: '100%', minHeight: '400px' }}
      id="location-map"
      ref={ref}
    />
  )
}
export const LocationMap: React.FC<{
  provider: Provider
  style?: React.CSSProperties
}> = ({ provider, style }) => {
  const { develop } = checkEnv()
  const apiKey = develop ? MAPS_DEV_API_KEY : MAPS_PROD_API_KEY
  const render = (status: Status) => <div className="text-center">{status}</div>

  return (
    <Wrapper apiKey={apiKey} render={render}>
      <Map provider={provider} style={style} />
    </Wrapper>
  )
}
