import React from 'react'
import moment from 'moment'
import get from 'lodash/fp/get'
import set from 'lodash/fp/set'
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import find from 'lodash/fp/find'
import sumBy from 'lodash/fp/sumBy'
import filter from 'lodash/fp/filter'
import remove from 'lodash/fp/remove'
import reduce from 'lodash/fp/reduce'
import sortBy from 'lodash/fp/sortBy'
import compact from 'lodash/fp/compact'
import toNumber from 'lodash/fp/toNumber'
import orderBy from 'lodash/fp/orderBy'
import findIndex from 'lodash/fp/findIndex'
import Swal from 'sweetalert2'
import { useMutation } from '@apollo/react-hooks'
import { MdChat, MdMonetizationOn } from 'react-icons/md'

import { FormatNumber } from '../../components'
import { offeringTypes } from '../../common/constants'
import {
  errorAlert,
  loaderAlert,
  confirmAlert,
  simpleAlert,
} from '../../common'
import {
  DELETE_GUEST,
  CREATE_EVENT,
  GET_ALL_EVENTS,
  GET_MY_EVENT,
  GET_GUESTLIST_WITH_BOOKINGS,
} from '../../graphql'
import {
  GetAllEventsQuery,
  Maybe,
  Error,
  EventInput,
  Event,
  GetMyEventQuery,
  Guest,
  Quote,
  EventComponent,
  Ibalances,
  NegotiationItem,
  Ibalance,
  EventComponentStatus,
  IgenericObject,
} from '../../types'

export const useCreateEvent = (
  pushError?: (error: string, path: string) => void,
  callback?: (event?: Event) => void
): [(input: EventInput) => Promise<Event | undefined>, boolean] => {
  const [handleCreateEvent, { loading: loadingCreateEvent }] = useMutation(
    CREATE_EVENT,
    {
      update(cache, { data }) {
        if (data.createEvent.errors.length === 0) {
          const cached: Maybe<GetAllEventsQuery> = cache.readQuery({
            query: GET_ALL_EVENTS,
          })
          if (cached) {
            let myEvents = get('me.myEventList', cached) || []
            myEvents = [...myEvents, data.createEvent.result]
            cache.writeQuery({
              query: GET_ALL_EVENTS,
              data: set('me.myEventList', myEvents, cached),
            })
          }
        }
      },
      onError: () => {
        // in case a user different from :admin or :concierge tries to create an event
        simpleAlert({
          html: 'Unauthorized access',
          icon: 'error',
        })
      },
    }
  )

  const createEvent = async (input: EventInput) => {
    let event: Event | undefined
    const variables = { input }

    const response = await handleCreateEvent({ variables })
    const errors: Error[] = get('data.createEvent.errors', response) || []
    const result: Event = get('data.createEvent.result', response)

    if (errors.length > 0) {
      errors.forEach(({ key, message }) => {
        if (pushError) {
          // was called from the side panel
          pushError(message, key)
        } else {
          // anywhere else
          errorAlert(errors, 'There was an error creating event')
        }
      })
    } else if (callback) {
      callback(result)
    } else {
      event = result
    }

    return event
  }

  return [createEvent, loadingCreateEvent]
}

export const useDeleteGuest = (): [(id: string) => Promise<void>, boolean] => {
  const [handleDeleteGuest, { loading: loadingDeleteGuest }] = useMutation(
    DELETE_GUEST,
    {
      update(cache, { data }) {
        if (data.deleteGuest.errors.length === 0) {
          const cached: Maybe<GetMyEventQuery> = cache.readQuery({
            query: GET_GUESTLIST_WITH_BOOKINGS,
            variables: {
              id: get('deleteGuest.result.event.id', data),
              tierIds: [],
            },
          })
          if (cached && data?.deleteGuest?.result?.invitedBy) {
            // plus one
            const mainGuestId = data?.deleteGuest?.result?.invitedBy.id

            const guests: Guest[] = get('me.myEventInfo.guests', cached) || []
            let mainGuest = find({ id: mainGuestId }, guests) as Guest
            const mainGuestIdx = findIndex({ id: mainGuestId }, guests)
            const plusOnes = remove(
              { id: data?.deleteGuest?.result?.id },
              mainGuest.guests
            )
            mainGuest = set('guests', plusOnes, mainGuest)

            cache.writeQuery({
              query: GET_GUESTLIST_WITH_BOOKINGS,
              data: set(
                `me.myEventInfo.guests[${mainGuestIdx}]`,
                mainGuest,
                cached
              ),
              variables: { tierIds: [] },
            })
          } else if (cached) {
            // normal guest
            let guests: Guest[] = get('me.myEventInfo.guests', cached) || []
            guests = remove({ id: get('deleteGuest.result.id', data) }, guests)

            cache.writeQuery({
              query: GET_MY_EVENT,
              data: set('me.myEventInfo.guests', guests, cached),
              variables: { tierIds: [] },
            })
          }
        }
      },
    }
  )

  const deleteGuest = async (id: string) => {
    confirmAlert({
      html: 'Are you sure you want to delete this guest?',
      icon: 'warning',
    })
      .then(async ({ value }) => {
        if (value === true) {
          loaderAlert({ html: 'deleting guest...' })
          const variables = { id }
          const response = await handleDeleteGuest({ variables })
          const errors: Error[] = get('data.deleteGuest.errors', response) || []

          Swal.close()
          if (errors.length > 0) {
            errorAlert(errors, 'There was an error deleting this guest')
          }
        }
      })
      .catch(() => {
        simpleAlert({
          html: 'There was an error deleting this guest',
          icon: 'error',
        })
      })
  }

  return [deleteGuest, loadingDeleteGuest]
}

export const sumItems = (items: NegotiationItem[]): number =>
  sumBy(item => {
    if (item.deleted) {
      return 0
    }
    return toNumber(item.price)
  }, items)

export const getEventTotal = (quotes: Quote[] = []): number => {
  const { Ready, Available } = EventComponentStatus

  return (
    flow(
      compact, // remove all falsy values ex: undefined, null, 0, ''
      filter(
        (el: Quote) =>
          el.eventComponent.status === Ready ||
          el.eventComponent.status === Available
      ),
      reduce((accum: number, element: Quote) => {
        const itemPrices = sumItems(element.items)
        return accum + itemPrices
      }, 0)
    )(quotes) || 0
  )
}

export const getComponentStatus = ({
  eventComponent,
  balances,
}: {
  eventComponent: EventComponent
  balances?: Ibalances
}): string => {
  const { status, offering } = eventComponent
  const { Ready, Created, Negotiating } = EventComponentStatus
  const statuses: IgenericObject = {
    [Created]: '1-Selected',
    [Negotiating]: '2-Negotiation',
    [Ready]: '3-Quote',
  }

  if (statuses[status]) {
    return statuses[status]
  }

  if (!balances) {
    return 'loading...'
  }
  const { provider } = offering
  const paid = toNumber(get(`[${provider.id}].balance`, balances)) || 0
  const total = toNumber(get(`[${provider.id}].total`, balances)) || 0

  if (paid < total) {
    return '4-Booked'
  }

  return '5-Paid In Full'
}

export const getHighestStatus = ({
  eventComponents,
  balances,
}: {
  eventComponents: EventComponent[]
  balances: Ibalances
}): string =>
  flow(
    map((ec: EventComponent) =>
      getComponentStatus({ eventComponent: ec, balances })
    ),
    orderBy(['[0]'], ['desc']),
    get('[0]')
  )(eventComponents)

export const getCTAtext = ({
  ecStatus,
  balances,
  currentUser,
  providerId,
}: {
  ecStatus: string
  balances?: Ibalances
  currentUser: string
  providerId: string
}): string | React.ReactElement => {
  if (ecStatus === '1-Selected') {
    return 'Add to Negotiation'
  }

  const balance = get(`[${providerId}]`, balances)
  const msg = balance?.lastMessage
  if (msg?.seen === false && msg?.user.id !== currentUser) {
    return (
      <>
        <MdChat size={24} /> Reply
      </>
    )
  }

  const statuses: { [s: string]: string | React.ReactElement } = {
    '2-Negotiation': (
      <>
        <MdChat size={24} /> Negotiate
      </>
    ),
    '3-Quote': 'Book',
    '4-Booked': (
      <>
        <MdMonetizationOn size={24} /> Pay
      </>
    ),
    '5-Paid In Full': (
      <>
        <MdChat size={24} /> Message
      </>
    ),
  }

  return statuses[ecStatus]
}

export const getDeadline = (quotes: Quote[]): string =>
  flow(
    sortBy((quote: Quote) => moment(quote.eventComponent.date)),
    get('[0].eventComponent.date'),
    (date: string) => moment(date).subtract(1, 'month').format('MMM DD, YYYY')
  )(quotes)

export const getRemaining = (
  status: string,
  balance: Ibalance,
  packageId?: string
): React.ReactElement => {
  const statusId = toNumber(status?.split('-')[0]) || 0
  if (statusId === 4) {
    // booked only
    const paid = toNumber(balance.balance) || 0

    const { roomOffering } = offeringTypes
    const quotes = packageId
      ? [balance.quotes.find(el => el.eventComponent.id === packageId)]
      : balance.quotes.filter(
          el => el.eventComponent.offering.__typename === roomOffering
        )
    const total = getEventTotal(quotes as Quote[])

    /**
     * tricky solution for Amy's request
     * since I don't have the payments separately it is not possible to specify how much is paid in the packages and how much in the rooms
     * so I just take what is paid, divide it by the amount of Quotes and multiply it by the actual Quotes
     *
     * ex:
     * quotes:        5 (1 package and 4 rooms)
     * paid:          4000
     *
     * package card
     * total: 4,330
     * paid: 4,000 / 5 * 1 = 800
     * remaining: 3,530
     *
     * slider card (rooms)
     * total: 18,000
     * paid: 4,000 / 5 * 4 = 3,200
     * remaining: 14,800
     *
     * it's a bit complicated to understand at first but it is the only solution at the moment
     */
    const remaining = total - (paid / balance.quotes.length) * quotes.length
    return (
      <>
        <FormatNumber n={remaining} /> due by {getDeadline(balance.quotes)}
      </>
    )
  }

  return <></>
}
