import React, { useState, Suspense, lazy } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

import clipboard from '../../assets/images/icons/clip.svg'
import { Vimeo } from '../../common'

import { HowWeWorkContainer, Clipboard } from './style'

const howWeWorkVideoUrl = `https://player.vimeo.com/progressive_redirect/playback/1036819562/rendition/1080p/file.mp4?loc=external&signature=bf4c3bb1b321cfa58d1ac43fbbf50683dddfeee0c045bdcc2610b241e3fe8fb3`
const howWeWorkVideoPoster = `/img/posterHWW.jpg`
const ConversionlyModal = lazy(
  () => import('../../components/SigninSignup/ConversionlyModal')
)

const HowWeWorkInfo: React.FC<{
  handleConversionlyModal: () => void
  className?: string
  style?: React.CSSProperties
}> = ({ handleConversionlyModal, className = '', style }) => {
  return (
    <HowWeWorkContainer className={className} style={style}>
      <div className="text">
        <h2 className="w-100 mb-4 mt-4">
          You Dream it <span className="text-primary">We plan it</span>
        </h2>
        <p className="fwsb">
          Regardless of how hands-on or hands-off you want to be, we will coach
          you every step of the way!
        </p>
        <p>
          Planning the perfect wedding can be a monstrous undertaking. We know
          how much thought you've put into this. See what planning headaches can
          be cured via a 15-20 minute call with our specialists.
        </p>
        <p>
          <span className="fwb">Book a Free Call</span> - We will coach you
          through this process together for a seamless destination wedding.
        </p>
        <Button
          onClick={handleConversionlyModal}
          className="px-5 py-2 mt-3 shadow-sm rounded-pill"
        >
          Plan With Us
        </Button>
      </div>

      <Clipboard>
        <img src={clipboard} alt="clipboard" className="clipboard" />
        <div className="sheet">
          <div>
            <p className="fz-24">What To Expect</p>
            <p className="fz-11 mt-1">
              IN YOUR FIRST WEDDING COACHING MEETING.
            </p>
          </div>
          <div>
            <p className="underline">What we’re doing</p>
            <span>
              Celebrate your engagement 🎉 <br /> Tell us about your dream
              wedding
            </span>
          </div>
          <div>
            <p className="underline">Steps</p>
            <ol>
              <li>
                Defining Your Goals:
                <span className="d-block pl-2">
                  a. Vibe, setting, style, budget, and group size.
                </span>
              </li>
              <li>
                Who & How We Help:
                <span className="d-block pl-2">
                  a. Overview of Kiss & Tell services: goals, technology, and
                  coaching.
                </span>
              </li>
            </ol>
          </div>
          <div>
            <p className="underline">What’s next?</p>
            <span>Receive your curated list of wedding destinations.</span>
          </div>
        </div>
      </Clipboard>
    </HowWeWorkContainer>
  )
}

const HowWeWorkPage: React.FC = () => {
  const [registerModal, openRegisterModal] = useState(false)
  const [shouldLoadModal, setShouldLoadModal] = useState(false)

  const handleConversionlyModal = () => {
    setShouldLoadModal(true)
    openRegisterModal(true)
  }
  return (
    <Container className="py-0 py-lg-4">
      <HowWeWorkInfo
        className="py-0 py-lg-3 mx-auto"
        style={{ maxWidth: '1000px' }}
        handleConversionlyModal={handleConversionlyModal}
      />
      <Vimeo
        url={howWeWorkVideoUrl}
        className="mx-auto my-3"
        style={{ maxWidth: '1000px' }}
        poster={howWeWorkVideoPoster}
      />
      {shouldLoadModal && (
        <Suspense fallback={<div className="d-none" />}>
          <ConversionlyModal
            open={registerModal}
            onClose={() => openRegisterModal(false)}
          />
        </Suspense>
      )}
    </Container>
  )
}

const HowWeWorkModal: React.FC<{
  open: boolean
  close: () => void
}> = ({ open, close }) => {
  const [registerModal, openRegisterModal] = useState(false)
  const [shouldLoadModal, setShouldLoadModal] = useState(false)

  const handleConversionlyModal = () => {
    setShouldLoadModal(true)
    openRegisterModal(true)
    close()
  }

  return (
    <>
      <Modal show={open} onHide={close} size="lg">
        <Modal.Header closeButton className="py-0 px-1 border-0" />
        <Modal.Body className="pt-0 pb-0 pb-md-3">
          <HowWeWorkInfo
            className="pb-3 px-2 px-lg-5"
            handleConversionlyModal={handleConversionlyModal}
          />
          <Vimeo
            url={howWeWorkVideoUrl}
            className="mx-auto mt-4 mb-2 px-0 px-md-2"
            style={{ maxWidth: '550px' }}
            poster={howWeWorkVideoPoster}
            preload="metadata"
          />
        </Modal.Body>
      </Modal>

      {shouldLoadModal && (
        <Suspense fallback={<div className="d-none" />}>
          <ConversionlyModal
            open={registerModal}
            onClose={() => openRegisterModal(false)}
          />
        </Suspense>
      )}
    </>
  )
}

export const HowWeWork: React.FC<{
  open: boolean
  close: () => void
  modal?: boolean
}> = ({ open, close, modal = false }) => {
  if (modal) {
    return <HowWeWorkModal open={open} close={close} />
  }

  return <HowWeWorkPage />
}
