import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

export const TandCmodal: React.FC<{
  className?: string
  style?: React.CSSProperties
}> = ({
  className = 'position-relative tc-lgray fz-12 cursor',
  style = { top: '40px', left: '65px' },
}) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="modal-w-50 modal-rounded-0"
      >
        <Modal.Header closeButton style={{ border: 'none' }} className="pb-0">
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 px-lg-5 pb-5">
          <p>
            Plan and book your destination wedding and group stay with Kiss &
            Tell and receive a heavily discounted engagement trip stay, fully
            reimbursed up to 5 nights depending your group booking size.
          </p>
          <ol>
            <li>
              Engagement trip nightly rates are based on hotel site visit rates,
              typically 20% discounts on published rates *pending approved
              availability with each hotel.
            </li>
            <li>
              Receive a refunded 1 night site visit stay when you book a wedding
              and 3+ night group stay of 20 adult guests (10+ rooms) with any
              property in the Kiss & Tell marketplace. Accrue additional site
              visit night refunds on the following scale when more guests book
              their stay: 2 nights = 40 guests (20+ rooms), 3 nights = 60 guests
              (30+ rooms), 4 nights = 90 nights (45+ rooms), 5 nights = 120
              guests (60+ rooms).
            </li>
            <li>
              This offer is applicable to destination weddings or any group trip
              booking on Kiss & Tell (anniversaries, reunions, mitzvahs,
              bachelor / bachelorette trips, girls trips, guys trips, retreats,
              etc...) that have a minimum of 20 adult guests staying for 3
              nights.
            </li>
            <li>
              If you choose not to take an engagement trip, Kiss & Tell will
              offer reimbursement of 1-5 guest room nights at your location’s
              site visit rate per the group size-based scale in item 2.
            </li>
            <li>
              If you book a venue that does not offer individual guest room
              bookings, Kiss & Tell can help you choose a nearby hotel for your
              engagement trip or you can choose to not take the engagement trip
              and receive the same reimbursement as item 4, based on the site
              visit rates of the nearby hotel.
            </li>
            <li>
              All reimbursements are paid by Kiss & Tell directly to the hosts
              after the completion of their group stay and upon Kiss & Tell’s
              receipt of their revenue from the group booking.
            </li>
          </ol>
          <p className="fz-14 pt-3">
            *rates are typically 20% but can vary depending on provider.
            <br />
            **pending approved availability with each hotel.
            <br />
            ***offer is applicable to destination weddings or any group trip
            booking on Kiss & Tell (anniversaries, reunions, mitzvahs, bachelor
            / bachelorette trips, girls trips, guys trips, retreats, etc...)
            with a minimum of 20 adult guests staying for 3 nights.
            <br />
          </p>
        </Modal.Body>
      </Modal>
      <span className={className} style={style} onClick={() => setShow(true)}>
        Terms & Conditions
      </span>
    </>
  )
}
