import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import LazyLoad from 'react-lazyload'

export const Testimony: React.FC<{ data: number }> = ({ data }) => {
  const testimonials = [
    {
      name: 'Summer & Pedro',
      provider: 'Penha Longa Resort',
      location: 'Sintra, Portugal',
      txt: `As a highly organized person, I expected destination wedding planning to be a breeze, yet our venue and resort search across Portugal was anything but that!  Not only was it challenging to find great options but the ability to compare packages and receive transparency on pricing was head splitting to put it lightly.  Finding Kiss & Tell was a huge lifesaver as we could see each property's event packages, customize them and select the actual hotel rooms that we needed for our group, with peace of mind that we received fair pricing!`,
      pic: '/img/people/person1.jpg',
    },
    {
      name: 'Dylan',
      provider: `Summer & Pedro's Bridesmaid`,
      location: 'Sintra, Portugal',
      txt: `Having traveled to a ton of weddings recently, I felt a bit in the dark making my plans and connecting with other guests due to jumping around between conversations via text, itineraries on wedding websites and emailed updates, which was A Lot to keep up with.  When Summer invited us to her wedding on Kiss & Tell, it made total sense!   I received updates, viewed the itinerary and my travel bookings while chatting with the whole group, other bridesmaids and even those staying at my hotel.  Looking forward to my next friends' wedding on Kiss & Tell so I can continue to manage everything in one place!`,
      pic: '/img/people/person2.jpg',
    },
    {
      name: 'Adriana & Cyril',
      provider: 'Hacienda Temozon',
      location: 'Merida, Mexico',
      txt: `With 170 guests traveling to our wedding, we knew there had to be a better way to manage our 4 room blocks and logistics connecting our group through the wedding day.  Kiss & Tell's tiered guest list management and group travel booking solution really made it possible for us to enjoy all of our guests for our celebration.`,
      pic: '/img/people/person3.jpg',
    },
  ]
  return (
    <div className="mx-auto my-1 my-md-5 | py-1 py-md-4 | w-90 mw-1200 | Testimonials">
      <Row>
        <Col xs={12} md={2} className="text-center text-md-right pt-1">
          <LazyLoad height={120} once>
            <Image
              className="mb-3"
              src={testimonials[data].pic}
              roundedCircle
              alt={testimonials[data].name}
              style={{ maxWidth: '120px' }}
            />
          </LazyLoad>
        </Col>
        <Col xs={12} md={10} className="text-center text-md-left tc-gray">
          <p className="font-italic" style={{ fontSize: '1.2rem' }}>
            “{testimonials[data].txt}”
          </p>
          <p className="m-0 fwb">{testimonials[data].name}</p>
          <p className="m-0 fwsb text-primary">{testimonials[data].provider}</p>
          <p className="m-0 fwsb text-primary">{testimonials[data].location}</p>
        </Col>
      </Row>
    </div>
  )
}
