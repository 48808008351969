import React, { useContext } from 'react'
import { RouteProps, Route, Redirect, useLocation } from 'react-router'

import AppContext from '../context'

function ConditionalRoute(
  { component, render, children, ...routeProps }: RouteProps,
  inv: boolean,
  redirect: string
): JSX.Element {
  const location = useLocation()
  const { auth } = useContext(AppContext)

  if (inv !== auth.isAuthenticated) {
    return <Route component={component} render={render} {...routeProps} />
  }

  // we want to redirect to the page the user was trying to access
  sessionStorage.setItem('auth-return-path', location.pathname)

  return <Redirect to={redirect} />
}

export function PrivateRoute(props: RouteProps): JSX.Element {
  return ConditionalRoute(props, false, '/login')
}

export function OnlyPublicRoute(props: RouteProps): JSX.Element {
  return ConditionalRoute(props, true, '/')
}
