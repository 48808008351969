import gql from 'graphql-tag'

import { IMAGE_FIELDS_FRAG } from './general'

export const GET_BLOG_POSTS = gql`
  query getBlogPosts($limit: Int = 16, $cursor: String) {
    blog(cursor: { cursor: $cursor, limit: $limit }) {
      entries {
        id
        body
        title
        url
        location
        images {
          ...ImageFields
        }
      }
      cursor
    }
  }
  ${IMAGE_FIELDS_FRAG}
`

export const GET_BLOG_POST = gql`
  query getBlogPost($url: String) {
    blog(url: $url) {
      entries {
        author
        body
        credits {
          business
          service
        }
        id
        insertedAt
        location
        title
        url
        images {
          ...ImageFields
        }
      }
    }
  }
  ${IMAGE_FIELDS_FRAG}
`
