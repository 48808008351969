import React from 'react'
import Swal from 'sweetalert2'
import uniq from 'lodash/fp/uniq'
import Button from 'react-bootstrap/Button'
import { useMutation } from '@apollo/react-hooks'
import { Link, useHistory } from 'react-router-dom'

import { EDIT_BOOKING } from '../../../graphql'
import { Booking, BookingStatus } from '../../../types'
import { errorAlert, loaderAlert, simpleAlert } from '../../../common'
import { checkDuplicatedGuests } from '../../Rsvp/components'
import { useAppSelector } from '../../../hooks'

export const EditBooking: React.FC<{
  booking: Booking
  editClassName?: string
  editText?: string
  cancelClassName?: string
  cancelText?: string
  disabled?: boolean
  className?: string
}> = ({
  booking,
  editClassName = 'py-1 px-4',
  cancelClassName = 'py-1 px-4',
  editText = 'Edit',
  cancelText = 'Cancel',
  disabled = false,
  className = 'py-3 d-flex justify-content-between align-items-center',
}) => {
  const history = useHistory()
  const { freeGuests = [], addonsIdsToDelete = [] } = useAppSelector(
    state => state.booking
  )
  const [editBooking, { loading }] = useMutation(EDIT_BOOKING)

  // If the booking is not booked or its not free it can't be edited
  const isBooked = booking.status === BookingStatus.Booked
  if (!isBooked) return null

  const { id: bookingId } = booking
  const handleEdit = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (loading || disabled) return

    if (checkDuplicatedGuests(freeGuests)) {
      simpleAlert({
        html: 'There cannot be duplicated guests',
        icon: 'warning',
      })
      return
    }
    loaderAlert({ html: 'editing...' })
    const variables = {
      bookingId,
      freeGuests: freeGuests.map(fg => ({
        bookingId,
        guestBookingInput: { ...fg.guest },
        offeringAddOnId: fg.addon.id,
        bookingAddOnId: fg.bookingAddOnId,
      })),
      addonsToDelete: uniq(addonsIdsToDelete),
    }
    editBooking({ variables })
      .then(({ data }) => {
        Swal.close()
        const errors = data?.editBooking?.errors || []

        if (errors.length > 0) {
          errorAlert(
            errors,
            'there has been an error confirming your reservation'
          )
        } else {
          history.push(`/booking/${bookingId}/details`)
        }
      })
      .catch(() => {
        simpleAlert({
          html: 'There has been an error confirming your booking',
          icon: 'error',
        })
      })
  }

  return (
    <div className={className}>
      <Link to={`/booking/${booking.id}/details`}>
        <Button className={cancelClassName} variant="outline-primary">
          {cancelText}
        </Button>
      </Link>
      <Button
        className={editClassName}
        disabled={disabled}
        onClick={handleEdit}
      >
        {editText}
      </Button>
    </div>
  )
}
