import React, { useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'

import Logo from '../../assets/images/logo.png'
import { myHub } from '../../common/constants'

import { UserOptions } from '.'

export const VendorNarbar: React.FC = () => {
  const [navExpanded, setNavExpanded] = useState(false)

  return (
    <Navbar
      data-cy="private-nav"
      className="py-1 shadow-none border-b"
      bg="white"
      expand="lg"
      fixed="top"
      onToggle={expanded => setNavExpanded(expanded)}
      expanded={navExpanded}
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={Logo} alt="Kiss and Tell" style={{ maxWidth: '149px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Nav.Link className="tc-gray fwsb" to={myHub} as={Link}>
              Dashboard
            </Nav.Link>
            <Nav.Link
              to="/blog"
              as={Link}
              className="tc-gray fwsb"
              onClick={() => setNavExpanded(false)}
            >
              Inspiration
            </Nav.Link>
            <Nav.Link
              to="/locations"
              as={Link}
              className="tc-gray fwsb"
              onClick={() => setNavExpanded(false)}
            >
              Locations
            </Nav.Link>
          </Nav>
          <Nav className="ml-auto mr-2">
            <UserOptions />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
