import React from 'react'
import get from 'lodash/fp/get'
import isNil from 'lodash/fp/isNil'
import Col, { ColProps } from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'

import { Provider } from '../../../types'
import { getImageUrl } from '../../../common'

import { Card } from './style'

import { LocationRates } from '.'

export const LocationCard: React.FC<{
  data: Provider
  groupSize: number
  size?: ColProps
}> = React.memo(({ data, groupSize, size = { xs: 12, md: 6, xl: 4 } }) => {
  // I realized that there were too much rerenders, so I decided to use React.memo
  const publicImage = getImageUrl(get('mainImage', data), '400x280')

  const roomSleeps: number = get('room.sleeps', data)

  const groupSizeQueryParam = groupSize > 0 ? `?group-size=${groupSize}` : ''

  const { location } = data
  const hasSleeps = !isNil(roomSleeps)
  return (
    <Col {...size} id={`card-${data.id}`}>
      <Card>
        <Link
          className="text-decoration-none"
          to={`/locations/${data.url}${groupSizeQueryParam}`}
        >
          <div
            style={{ backgroundImage: `url(${publicImage})` }}
            className="mainPhoto"
          />
          <div className="cursor title-locationCard">
            <h2 className="text-body px-2 pt-2 m-0 fwsb fz-16">
              <span className="d-block pb-1 text-primary fz-14">
                {location}
              </span>
              {data.name}
            </h2>
          </div>
        </Link>
        <LocationRates
          id={data.id}
          bestPackage={data.package}
          bestRoom={data.room}
          className={`w-100 pl-2 ${!hasSleeps && 'full'} listing-card`}
          groupSize={groupSize}
        />
      </Card>
    </Col>
  )
})
