import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { MdSearch, MdClose } from 'react-icons/md'
import { Route, useHistory } from 'react-router-dom'

import { useQueryParams, cleanParams } from '../../common'
import { IFilterInputs } from '../../types'

import { makeSearchUrl } from './LocationFunctions'
import { Filter, FiltersContainer } from './style'
import ProviderList from './ProviderList'

import { ProviderDetails, MarketplaceHome } from '.'

export const Locations: React.FC = () => {
  const history = useHistory()
  const params = useQueryParams()
  const [loading, setLoading] = useState(false)
  const [filterOptions, openFilterOptions] = useState(false)
  const [filterInputs, setFilterInputs] = useState<IFilterInputs>({
    where: '',
    what: '',
    maxRoomPrice: '',
    maxPackagePrice: '',
    groupSize: '',
  })

  useEffect(() => {
    const cleanedParams = cleanParams(params)
    setFilterInputs({
      where: cleanedParams.where || '',
      what: cleanedParams.what || '',
      maxRoomPrice: cleanedParams.maxRoomPrice || '',
      maxPackagePrice: cleanedParams.maxPackagePrice || '',
      groupSize: cleanedParams.groupSize || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.where,
    params.what,
    params.maxRoomPrice,
    params.maxPackagePrice,
    params.groupSize,
  ])

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = ev.currentTarget
    const { value } = ev.currentTarget
    setFilterInputs({
      ...filterInputs,
      [name]: value,
    })
  }

  const scroll = () => {
    if (window.innerWidth <= 575) {
      if (document.querySelector('img.BuildBook')) {
        window.scrollTo({ top: 810, behavior: 'smooth' })
      } else {
        window.scrollTo({ top: 420, behavior: 'smooth' })
      }
    }
  }

  const handleSearch = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    setLoading(true)
    const url = makeSearchUrl('/locations', filterInputs)
    history.push(url)
    setLoading(false)

    openFilterOptions(false)
    // some time to complete the request
    setTimeout(scroll, 400)
  }

  return (
    <>
      <FiltersContainer
        className={`${
          filterOptions ? 'open-search-filters' : 'close-search-filters'
        } bg-white shadow-sm pb-2 pb-lg-3`}
      >
        <section className="location-filter-container">
          <form onSubmit={handleSearch} className="container p-0">
            <Filter>
              <div className="filter-control">
                <label htmlFor="where">WHERE</label>
                <input
                  onChange={handleChange}
                  className="text"
                  name="where"
                  id="where"
                  type="text"
                  value={filterInputs.where}
                  placeholder="Enter location: ex. Tulum, Italy, Villa Sola Cabiati"
                />
              </div>
              <div className="filter-control">
                <label htmlFor="what">WHAT</label>
                <input
                  onChange={handleChange}
                  className="text"
                  name="what"
                  id="what"
                  type="text"
                  value={filterInputs.what}
                  placeholder="Enter style or setting: ex. Beach, Winery, Villa"
                />
              </div>
              <div className="filter-control">
                <label htmlFor="maxRoomPrice">MAX ROOM PRICE</label>
                <input
                  onChange={handleChange}
                  className="number"
                  name="maxRoomPrice"
                  id="maxRoomPrice"
                  min="0"
                  value={filterInputs.maxRoomPrice}
                  type="number"
                  placeholder="Room Rate"
                />
              </div>
              <div className="filter-control">
                <label htmlFor="maxPackagePrice">MAX PACKAGE PRICE</label>
                <input
                  onChange={handleChange}
                  className="number"
                  name="maxPackagePrice"
                  id="maxPackagePrice"
                  min="0"
                  value={filterInputs.maxPackagePrice}
                  type="number"
                  placeholder="Event Rate/Person"
                />
              </div>
              <div className="filter-control">
                <label htmlFor="groupSize">GROUP SIZE</label>
                <input
                  onChange={handleChange}
                  className="number"
                  name="groupSize"
                  id="groupSize"
                  min="0"
                  value={filterInputs.groupSize}
                  type="number"
                  placeholder="Group size"
                />
              </div>
              <Button
                disabled={loading}
                type="submit"
                className="px-5 search-action"
                variant="primary"
              >
                Search
              </Button>
            </Filter>
          </form>
        </section>
        <Button
          type="button"
          onClick={() => {
            if (filterOptions) {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }
            openFilterOptions(!filterOptions)
          }}
          className="mx-auto mt-3 d-block d-sm-none btn-outline-salmon-simple"
        >
          {!filterOptions && <MdSearch size={20} />}
          {filterOptions && <MdClose className="svg-top1" size={20} />}
          {filterOptions ? 'Close' : 'Search filters'}
        </Button>
      </FiltersContainer>
      <Route exact path="/marketplace" component={MarketplaceHome} />
      <Route exact path="/locations" component={ProviderList} />
      <Route exact path="/locations/:locationURL" component={ProviderDetails} />
    </>
  )
}
