import { Image } from '../../types'

interface IdataTMP {
  image: Image
  name: string
  rate: string
  url: string
  location: string
}

const data: IdataTMP[] = [
  {
    image: {
      __typename: 'Image',
      assocId: 'a37e2149-ab5a-4211-a2e9-0fc1d22953c1',
      assocType: 'providers',
      ext: 'jpg',
      id: '48f0df55-52fb-44fe-8987-839e0c01cdd0',
      metadata: {},
      path: '',
    },
    name: 'Tintswalo Atlantic',
    rate: '$91.00',
    url: 'tintswalo-atlantic',
    location: 'Chapmans Peak Dr, Hout Bay, Sudáfrica',
  },
]

export default data
