import React, { useState, useEffect, useRef } from 'react'
import flow from 'lodash/fp/flow'
import unset from 'lodash/fp/unset'
import { useHistory } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

import { checkEnv } from '../../../common'
import { MAPS_DEV_API_KEY, MAPS_PROD_API_KEY } from '../../../common/constants'
import {
  GoogleMap,
  GoogleMarker,
  GoogleLatLng,
  IFilterInputs,
  IProviderListMap,
} from '../../../types'
import {
  adjustMapZoom,
  createMarkersWithInfoWindow,
} from '../../../components/GoogleMapsUtilities'
import { makeSearchUrl } from '../LocationFunctions'

import { HideMap, UpdateMap } from './style'

const Map: React.FC<
  IProviderListMap & {
    setLatLng: React.Dispatch<React.SetStateAction<GoogleLatLng | undefined>>
    latLng?: GoogleLatLng
  }
> = ({ providers, urlParams, latLng, setLatLng }) => {
  const [map, setMap] = useState<GoogleMap>()
  const [autoZoom, setAutoZoom] = useState(true)
  const [prevUrlParams, setPrevUrlParams] = useState('init')
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (providers.length <= 0 || !ref.current) return undefined

    if (!map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center: { lat: 20.3543899, lng: -87.6360736 },
          zoom: 8,
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        })
      )
    }

    const markers: GoogleMarker[] = createMarkersWithInfoWindow({
      providers,
      map,
    })
    if (autoZoom && map) {
      adjustMapZoom({ map, providers })
      setAutoZoom(false)
    }

    return () => {
      // this is called when the component is unmounted to remove the old markers
      // if I don't do this, the markers will stay on the map even after the provider list changes
      markers.forEach(marker => marker.setMap(null))
    }
  }, [map, providers, autoZoom])

  if (map && prevUrlParams !== JSON.stringify(urlParams)) {
    setPrevUrlParams(JSON.stringify(urlParams))
    setAutoZoom(true)
  }

  if (map) {
    map.addListener('dragend', () => !latLng && setLatLng(map.getCenter()))
  }

  return <div className="w-100 h-100" ref={ref} />
}

const ProviderListMap: React.FC<IProviderListMap> = ({
  providers,
  urlParams,
  showGoogleMap,
}) => {
  const history = useHistory()
  const { develop } = checkEnv()
  const [latLng, setLatLng] = useState<GoogleLatLng>()
  const apiKey = develop ? MAPS_DEV_API_KEY : MAPS_PROD_API_KEY
  const render = (status: Status) => <div className="text-center">{status}</div>

  const handleUpdateMap = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    const url = makeSearchUrl(history.location.pathname, {
      ...flow(unset('where'), unset('what'))(urlParams), // remove the where and what params to use the new latlng for the search
      lat: `${latLng?.lat()}`,
      lng: `${latLng?.lng()}`,
    } as IFilterInputs)

    history.push(url)
    setLatLng(undefined)
  }
  return (
    <section className="providers-map">
      <div className="position-relative h-100">
        {showGoogleMap && (
          <HideMap
            onClick={() => showGoogleMap(false)}
            type="button"
            className="d-none d-lg-inline-block"
          >
            Hide Map
          </HideMap>
        )}

        {latLng && (
          <UpdateMap type="button" onClick={handleUpdateMap}>
            <MdSearch /> search in this area
          </UpdateMap>
        )}

        <Wrapper apiKey={apiKey} render={render}>
          <Map
            latLng={latLng}
            setLatLng={setLatLng}
            providers={providers}
            urlParams={flow(unset('lat'), unset('lng'))(urlParams)}
          />
        </Wrapper>
      </div>
    </section>
  )
}

export default React.memo(ProviderListMap)
