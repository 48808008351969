import React from 'react'
import Container from 'react-bootstrap/Container'
import { Route } from 'react-router-dom'

import { HomeBlog, BlogPost, BlogPhoto } from '.'

export const Blog: React.FC = () => {
  return (
    <Container>
      <Route exact path="/blog/photo/:postUrl/:id" component={BlogPhoto} />
      <Route exact path="/blog" component={HomeBlog} />
      <Route exact path="/blog/:url" component={BlogPost} />
    </Container>
  )
}
