import React from 'react'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { getMoreData } from '../../common'
import { SimpleLoader, NegotiationsPreview } from '../../components'
import {
  GET_MY_PROVIDERS,
  GET_NEGOTIATIONS_PREVIEW_VENDOR,
} from '../../graphql'
import { MyProvider } from '../../types'

export const VendorPanel: React.FC = () => {
  const { data, loading, fetchMore } = useQuery(GET_MY_PROVIDERS, {
    variables: { cursor: null },
  })
  const myProviders: MyProvider[] = getOr([], 'me.myProviders.entries', data)
  const cursorToken: string = getOr(null, 'me.myProviders.cursor', data)

  if (isEmpty(myProviders) && !loading) {
    return <Container className="py-5 text-center">no data to show</Container>
  }

  const fetchMoreData = () => {
    if (cursorToken) {
      getMoreData({
        fetchMore,
        query: GET_MY_PROVIDERS,
        cursorToken,
        path: 'me.myProviders',
      })
    }
  }

  return (
    <Container className="py-4">
      <Helmet>
        <title>Kiss & Tell | Vendor Panel</title>
        <meta name="description" content="Kiss & Tell Vendor Panel page" />
      </Helmet>
      <Row>
        <Col xs={3}>
          <h4 className="mb-4">Negotiations</h4>
          <NegotiationsPreview
            QUERY={GET_NEGOTIATIONS_PREVIEW_VENDOR}
            objectPath="me.negotiations"
            url="/my-offerings-chats/chat"
          />
        </Col>
        <Col xs={9}>
          <h4 className="mb-4">My Providers</h4>
          <InfiniteScroll
            className="w-100 d-flex flex-wrap"
            dataLength={myProviders.length}
            next={fetchMoreData}
            hasMore={cursorToken !== null}
            loader={<SimpleLoader />}
          >
            {myProviders.map(el => (
              <Card
                key={el.id}
                body
                className="mr-2 mb-2 shadow-sm"
                style={{ width: '18rem' }}
              >
                <Card.Title>{el.name}</Card.Title>
                <Card.Text>{el.address}</Card.Text>
                <Link to={`/locations/${el.url}`}>
                  <Button className="float-right">View provider</Button>
                </Link>
              </Card>
            ))}
          </InfiniteScroll>
        </Col>
      </Row>
    </Container>
  )
}
