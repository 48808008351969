import React, { useState, useEffect } from 'react'
import some from 'lodash/fp/some'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import { Link, useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useCurrentUser } from '../../../../common'
import { ICollapsedSlider, EventComponentStatus } from '../../../../types'
import { darkGray } from '../../../../styles/themeColors'
import { RoomIcon } from '../../../../components/customIcons'
import {
  getHighestStatus,
  getCTAtext,
  getRemaining,
} from '../../eventFunctions'

import { useAddRoomsToNegotiation } from './eventBuilderFunctions'
import { CollapsedCardContainer } from './style'

import {
  EventBuilderRoomCard,
  NegotiationRoomCard,
  AssignTiersToEventComponent,
} from '.'

export const CollapsedSlider: React.FC<ICollapsedSlider> = ({
  eventId,
  eventComponents,
  eventTiers = [],
  balances,
}) => {
  const { userId } = useCurrentUser()
  const history = useHistory()
  const [generalStatus, setGeneralStatus] = useState<string>('')
  const [handleStartNegotiation, loading] = useAddRoomsToNegotiation()

  const { Created, Available } = EventComponentStatus
  const { provider } = eventComponents[0].offering

  const pendingRooms = some({ status: Created }, eventComponents)

  useEffect(() => {
    if (balances) {
      setGeneralStatus(getHighestStatus({ eventComponents, balances }))
    }
  }, [balances, eventComponents])

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (loading) return

    if (pendingRooms) {
      handleStartNegotiation(eventId, provider.url)
    } else {
      history.push(`/planning/${eventId}/${provider.id}`)
    }
  }

  return (
    <CollapsedCardContainer className="shadow-sm" data-cy="builder-rooms">
      <Accordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            variant="link"
            eventKey="0"
            className="flex-wrap"
          >
            <RoomIcon color={darkGray} />
            <h2>
              Guest Rooms
              <Link to={`/locations/${provider.url}`}>{provider.name}</Link>
            </h2>
            {generalStatus && (
              <span className="header-status">
                {generalStatus.split('-')[1]}
              </span>
            )}
            <span className="header-status ml-auto mr-3">
              {balances && getRemaining(generalStatus, balances[provider.id])}
            </span>
            <Button className="cta-booking" onClick={handleClick}>
              {pendingRooms
                ? 'Add to Negotiation'
                : getCTAtext({
                    ecStatus: generalStatus,
                    balances,
                    currentUser: userId,
                    providerId: provider.id,
                  })}
            </Button>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Swiper
              className="swiper-rsvp-slider"
              style={{ width: '100%' }}
              navigation
              spaceBetween={10}
              touchRatio={0}
            >
              {eventComponents.map(el => {
                const { tiers = [], status } = el
                return (
                  <SwiperSlide key={`slide-rooms-${el.id}`}>
                    {status !== Available && (
                      <EventBuilderRoomCard
                        roomCardData={el}
                        eventId={eventId}
                      />
                    )}
                    {status === Available && (
                      <NegotiationRoomCard
                        roomCardData={el}
                        eventId={eventId}
                      />
                    )}

                    <AssignTiersToEventComponent
                      componentId={el.id}
                      eventTiers={eventTiers}
                      assignedTiers={tiers}
                      type="Accommodation"
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </CollapsedCardContainer>
  )
}
