import styled from 'styled-components'
import { FiSearch } from 'react-icons/fi'

import { salmon, lightSalmon } from '../../styles/themeColors'

export const DropdownButton = styled.button`
  align-items: center;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  padding: 5px 1rem;
  transition: all 0.3s linear;
  &:hover {
    background: #f7fafc;
    border-color: ${salmon};
  }
  &:focus {
    background: #f7fafc;
    border-color: ${salmon};
    box-shadow: 0 0 0 0.2rem rgba(255, 110, 120, 0.4);
  }
`

export const DropdownContainer = styled.div`
  display: inline-block;
  position: relative;
`

export const DropdownContent = styled.div<{ isOpen: boolean }>`
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: ${props => (props.isOpen ? 'block' : 'none')};
  margin-top: 0.2rem;
  position: absolute;
  right: 0;
  top: 100%;
  width: 14rem;
  z-index: 50;
`

export const ItemsList = styled.div`
  max-height: 16rem;
  overflow-y: auto;
  .item {
    align-items: center;
    color: #4a5568;
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    &:hover {
      background: #f7fafc;
      text-decoration: none;
    }
  }
`

export const SearchContainer = styled.div`
  border-bottom: 1px solid #e2e8f0;
  padding: 0.5rem;
`

export const SearchIcon = styled(FiSearch)`
  color: #999;
  left: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const SearchInput = styled.input`
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  width: 100%;
  &:focus {
    border-color: ${lightSalmon};
    outline: none;
  }
`
