import React from 'react'
import LazyLoad from 'react-lazyload'

import List from '../../../assets/images/icons/list.svg'
import Group from '../../../assets/images/icons/group.svg'
import Celebrate from '../../../assets/images/icons/celebrate.svg'

import { ImgCont } from './style'

export const Benefit: React.FC<{ data: number }> = ({ data }) => {
  const benefits = [
    {
      icon: List,
      title: 'Expert Destination Wedding Consultant',
      text: `Planning the perfect wedding can be a monstrous undertaking.  We know how much thought you've put into this.  See what planning headaches can be cured via a 15-20 minute call with our specialists.`,
    },
    {
      icon: Group,
      title: 'Save Time, Stress and Energy',
      text: `Let us save you hundreds of hours in the planning and group management process, freeing up both time and money.`,
    },
    {
      icon: Celebrate,
      title: 'Focus on the dream, not the headache',
      text: `After attending so many weddings at home and abroad, it's your turn to plan your big day, but do those daydreams of unique venues in breathtaking settings include finding rooms for your guests and shuttling them around via ground transportation? If not, let us help with the behind-the-scenes wedding necessities.`,
    },
  ]
  return (
    <article className="m-auto text-center">
      <ImgCont>
        <LazyLoad height={80} once>
          <img
            src={benefits[data].icon}
            alt={benefits[data].title}
            className="benefit-img"
          />
        </LazyLoad>
      </ImgCont>
      <h3 className="text-primary | fz-18 fwb | mt-3 mb-2 benefit-title">
        {benefits[data].title}
      </h3>
      <p
        className="d-inline-block | tc-lgray | benefit-text"
        style={{ maxWidth: '300px' }}
      >
        {benefits[data].text}
      </p>
    </article>
  )
}
