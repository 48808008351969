import styled from 'styled-components'

import mobileframeTop from '../assets/images/mobileframeTop.png'
import mobileframeBottom from '../assets/images/mobileframeBottom.png'
import mobileframeBorders from '../assets/images/mobileframeBorders.png'

import { salmon } from './themeColors'

export const VideoContainer = styled.div`
  background-image: url(${mobileframeBorders});
  background-position: center 40px;
  background-repeat: no-repeat;
  background-size: 96% auto;
  padding: 36px 0 16px 0;
  position: relative;
  margin: 10px auto;
  width: 320px;
  &::after,
  &::before {
    background-repeat: no-repeat;
    background-size: 96% auto;
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
  }
  &::before {
    background-image: url(${mobileframeTop});
    background-position: center 0;
    height: 42px;
    top: 0;
  }
  &::after {
    background-image: url(${mobileframeBottom});
    background-position: center bottom;
    bottom: 0;
    height: 50px;
  }
  & .custom-volume-control {
    bottom: 26px;
    left: 45px;
    position: absolute;
    z-index: 1;
  }
`

export const CustomTabs = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  & button {
    align-items: center;
    background-color: white;
    border-bottom: 3px solid white;
    border-left: none;
    border-right: none;
    border-top: none;
    display: flex;
    color: ${salmon};
    cursor: pointer;
    flex: 1;
    justify-content: center;
    height: 40px;
    transition: border-bottom 0.3s ease-in-out;
    &:hover {
      border-bottom: 3px solid ${salmon};
    }
    &.active {
      border-bottom: 3px solid ${salmon};
    }
    img {
      height: 25px;
      width: 25px;
    }
  }
`

export const VimeoContainer = styled.div`
  height: auto;
  video {
    width: 100%;
    height: auto;
  }
`
export const VimeoMobileContainer = styled.div`
  height: 476px;
  margin: 0 auto;
  position: relative;
  width: 290px;
  .frame-top,
  .frame-bottom,
  .frames-lr {
    background-repeat: no-repeat;
    background-size: 97% auto;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  .frame-top {
    background-image: url(${mobileframeTop});
    background-position: center 0;
    height: 38px;
    top: 5px;
  }
  .frame-bottom {
    background-image: url(${mobileframeBottom});
    background-position: center bottom;
    bottom: -6px;
    height: 50px;
  }
  .frames-lr {
    background-image: url(${mobileframeBorders});
    background-position: center 0;
    height: 100%;
    top: 42px;
  }
  .play-vimeo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  .custom-volume-control {
    bottom: 20px;
    position: absolute;
    right: 30px;
    z-index: 3;
  }
  video {
    height: calc(100% - 45px);
    left: 22px;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 35px;
    width: calc(100% - 44px);
    z-index: 1;
  }
`
