import React, { useState } from 'react'
import { MdEdit, MdSave } from 'react-icons/md'

import { Maybe } from '../../types'

export const EditableText: React.FC<{
  value?: Maybe<string>
  changeValue?: (value: string) => void
}> = ({ value, changeValue }) => {
  const [edit, setEdit] = useState(false)

  if (edit) {
    return (
      <>
        <input
          type="text"
          value={value as string}
          onChange={e => changeValue?.(e.target.value)}
          onBlur={() => setEdit(false)}
          className="form-control form-control-sm d-inline-block"
          style={{ width: '100px' }}
          onKeyDown={e => e.key === 'Enter' && setEdit(false)}
        />
        <MdSave
          size={16}
          className="svg-top1 cursor ml-2"
          onClick={() => setEdit(true)}
        />
      </>
    )
  }
  return (
    <>
      {value}
      <MdEdit
        size={16}
        className="svg-top1 cursor ml-2"
        onClick={() => setEdit(true)}
      />
    </>
  )
}
