import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { MdAccountCircle, MdMailOutline, MdLockOutline } from 'react-icons/md'

import Money from '../../assets/images/icons/money.svg'
import WithLogo from '../../assets/images/icons/With_Clarity_Logo.svg'
import { KnTField } from '../../components'
import { salmon } from '../../styles/themeColors'
import { useForm } from '../../common/alterForms'
import { ReferralForm } from '../Referrals/style'
import { IframeYoutube, getImageUrl } from '../../common'

import { Header, GridSection, GridCard, TitleWithLine } from './style'
import tmp from './temp'

/**
 * import { useMoveToTop } from '../../common'
 * useMoveToTop()
 */
export const Landing: React.FC = () => {
  const initModel = {
    LandingfirstName: '',
    LandinglastName: '',
    Landingemail: '',
    Landingpassword: '',
  }
  const tmpImage = getImageUrl(tmp[0].image, '400x225')
  const tmpImage2 = getImageUrl(tmp[0].image, '800x450')
  const { model, errors, handleFieldChange } = useForm(initModel)

  return (
    <section className="public-container public-page shadow-sm">
      <Helmet>
        <title>Kiss & Tell | Engagement trip deals</title>
        <meta
          name="description"
          content="Kiss & Tell Engagement trip gift with purchase"
        />
      </Helmet>
      <Header className="partnership-landing-page">
        <h1 className="title">Ready to pop the question?</h1>
        <h3 className="subtitle">
          Let us help you discover the perfect location!
        </h3>
      </Header>
      <section className="pt-4 container pb-3">
        <TitleWithLine className="text-center mb-3">
          Exclusive{' '}
          <a
            href="https://www.withclarity.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={WithLogo}
              style={{ width: '150px', margin: '0 10px 5px 5px' }}
              alt="With Clarity logo"
            />
          </a>
          Engagement Trip Offers
        </TitleWithLine>
        <GridSection>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage2} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
              <div className="extra-data">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Reiciendis quia sed soluta, amet asperiores animi tempore esse
                officiis repellendus, ipsa, in ea quo! Alias illum, eveniet a
                aut ratione eaque. officiis repellendus, ipsa, in ea quo! Alias
                illum, eveniet a aut ratione eaque. officiis repellendus, ipsa,
                in ea quo! Alias illum.
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage2} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
              <div className="extra-data">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Reiciendis quia sed soluta, amet asperiores animi tempore esse
                officiis repellendus, ipsa, in ea quo! Alias illum, eveniet a
                aut ratione eaque. officiis repellendus, ipsa, in ea quo! Alias
                illum, eveniet a aut ratione eaque. officiis repellendus, ipsa,
                in ea quo! Alias illum.
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
        </GridSection>
      </section>
      <section className="video-landing">
        <h2 className="text-primary w-100 text-center py-4 fz-60 font-lf">
          Destination Weddings are easy & affordable!
        </h2>
        <div className="mx-auto w-100" style={{ maxWidth: '1000px' }}>
          <IframeYoutube
            videoId="GO50kxg6qpI"
            height="500px"
            light="https://img.youtube.com/vi/GO50kxg6qpI/hqdefault.jpg"
          />
        </div>
      </section>
      <ReferralForm>
        <h2 className="text-center text-primary fwb fz-30 mt-4 mb-3">
          Need a customized proposal?
          <span className="fz-16 d-block fwn pt-2">
            We’ll get you and your guests where you really want to be!
          </span>
        </h2>
        <div className="form w-90 mx-auto d-block">
          <Row>
            <Col xs={12} sm={6}>
              <KnTField
                label="First name"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="LandingfirstName"
                placeholder="Enter your first name"
                icon={<MdAccountCircle size={24} color={salmon} />}
              />
            </Col>
            <Col xs={12} sm={6}>
              <KnTField
                label="Last name"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="LandinglastName"
                placeholder="Enter your last name"
                icon={<MdAccountCircle size={24} color={salmon} />}
              />
            </Col>
            <Col xs={12} sm={6}>
              <KnTField
                label="Email"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="Landingemail"
                placeholder="your-email@example.com"
                type="email"
                icon={<MdMailOutline size={24} color={salmon} />}
              />
            </Col>
            <Col xs={12} sm={6}>
              <KnTField
                label="Password"
                value={model}
                error={errors}
                setValue={handleFieldChange}
                name="Landingpassword"
                placeholder="Enter your password"
                type="password"
                icon={<MdLockOutline size={24} color={salmon} />}
              />
            </Col>
          </Row>
        </div>
        <Button type="submit" disabled className="send py-1 my-5">
          Send
        </Button>
      </ReferralForm>
      <section className="pt-3 container pb-5">
        <h2 className="text-center text-primary fz-30 fz-60 font-lf">
          Get married in your dream destination
        </h2>
        <GridSection>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage2} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
              <div className="extra-data">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Reiciendis quia sed soluta, amet asperiores animi tempore esse
                officiis repellendus, ipsa, in ea quo! Alias illum, eveniet a
                aut ratione eaque. officiis repellendus, ipsa, in ea quo! Alias
                illum, eveniet a aut ratione eaque. officiis repellendus, ipsa,
                in ea quo! Alias illum.
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage2} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
              <div className="extra-data">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Reiciendis quia sed soluta, amet asperiores animi tempore esse
                officiis repellendus, ipsa, in ea quo! Alias illum, eveniet a
                aut ratione eaque. officiis repellendus, ipsa, in ea quo! Alias
                illum, eveniet a aut ratione eaque. officiis repellendus, ipsa,
                in ea quo! Alias illum.
              </div>
            </Link>
          </GridCard>
          <GridCard>
            <Link to={`/partnerships-landing/${tmp[0].url}`}>
              <img src={tmpImage} alt={tmp[0].name} />
              <div className="offer">
                OFFER
                <span>Text</span>
              </div>
              <div className="info">
                <h3>
                  {tmp[0].name}
                  <span>{tmp[0].location}</span>
                </h3>
                <div className="price">
                  <img className="mb-1" src={Money} alt="$" /> Starting Nightly
                  Rate: <span>{tmp[0].rate}</span>
                </div>
              </div>
            </Link>
          </GridCard>
        </GridSection>
      </section>
    </section>
  )
}
