import getOr from 'lodash/fp/getOr'
import flow from 'lodash/fp/flow'
import head from 'lodash/fp/head'
import split from 'lodash/fp/split'
import reduce from 'lodash/fp/reduce'

import { Image, Maybe, PackageAddons } from '../types'

import { IMAGES } from './constants'

export const getImageUrl = (
  img?: Image | Maybe<Image>,
  size = '600x400'
): string => {
  if (!img) {
    return ''
  }

  const [width, height] = split('x', size)

  const encodedUrl = btoa(
    JSON.stringify({
      bucket: IMAGES.bucket,
      key: `${img.assocType}/${img.assocId}/${img.id}.${img.ext}`,
      edits: {
        resize: {
          width: width === 'auto' ? null : width,
          height: height === 'auto' ? null : height,
          fit: 'cover',
        },
      },
    })
  )

  return `${IMAGES.url}/${encodedUrl}`
}

export const getImages = (
  originalImages: Image[] = [],
  size = '600x400'
): string[] =>
  reduce(
    (accum: string[], el: Image) => {
      accum.push(getImageUrl(el, size))
      return accum
    },
    [],
    originalImages
  )

export const getFirstImage = (
  originalImages: Image[] = [],
  size = '600x400'
): string => flow(head, (el: Image) => getImageUrl(el, size))(originalImages)

export const packageAddonsHaveImages = (
  addons: Maybe<PackageAddons[]>
): boolean => {
  if (!addons) return false
  // this function is only to check that there is at least 1 image
  return getOr([], '[0].offeringAddOn.images', addons).length > 0
}
