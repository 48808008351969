import get from 'lodash/fp/get'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable, split } from 'apollo-link'
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  PossibleTypesMap,
} from '@apollo/client'
import { ApolloLink as ApolloLinkApolloClient } from '@apollo/client/core'
// socket config
import * as AbsintheSocket from '@absinthe/socket'
import { createHttpLink } from 'apollo-link-http'
import { hasSubscription } from '@jumpn/utils-graphql'
import { Socket as PhoenixSocket } from 'phoenix'
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link'

import { checkEnv } from './common'
import introspectionResult, {
  IntrospectionResultData,
} from './types/graphql/introspection-result'
import { Auth } from './types'

export const generatePossibleTypes = (
  GraphQLintrospectionQuery: IntrospectionResultData
): PossibleTypesMap => {
  const possibleTypes: PossibleTypesMap = {}
  GraphQLintrospectionQuery.__schema.types.forEach(supertype => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map(
        subtype => subtype.name
      )
    }
  })

  return possibleTypes
}

export function createApolloClient(
  auth: Auth
): ApolloClient<NormalizedCacheObject> {
  const httpLink = createHttpLink({ uri: '/api' })

  // SOCKETS
  let socketParams: string | null = null
  const { develop } = checkEnv()
  const socketUrl = develop
    ? 'ws://localhost:4000/socket'
    : 'wss://kissandtell.com/socket'

  const phoenixSocket = new PhoenixSocket(socketUrl, {
    params: () => {
      const token = socketParams || auth.getToken()
      if (token) {
        return { token }
      }
      return {}
    },
  })
  phoenixSocket.onError(() => {
    phoenixSocket.disconnect() // cancel auto connection recovery
  })

  const absintheSocket = AbsintheSocket.create(phoenixSocket)
  const websocketLink = createAbsintheSocketLink(absintheSocket) as ApolloLink
  // END SOCKETS

  const authLink = setContext((_req, { headers }) => {
    if (get('authorization', headers)) {
      return {
        headers: { ...headers },
      }
    }

    if (auth.isAuthenticated) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${auth.getToken()}`,
        },
      }
    }
    return null
  })

  const tokenRefreshLink = onError(({ graphQLErrors, forward, operation }) => {
    if (
      auth.isAuthenticated &&
      graphQLErrors &&
      graphQLErrors.some(e => e.message === 'unauthenticated')
    ) {
      return new Observable(observer => {
        auth
          .refreshToken()
          .then(token => {
            if (token) {
              operation.setContext({
                headers: {
                  authorization: `Bearer ${auth.getToken()}`,
                },
              })
              forward(operation).subscribe(observer)

              // reset socket connection
              socketParams = token.accessToken
              if (phoenixSocket.isConnected()) {
                phoenixSocket.disconnect()
              }
              setTimeout(() => {
                phoenixSocket.connect()
              }, 1000)
            }
          })
          .catch(observer.error)
      })
    }

    /**
     * if we return -> return new Observable(() => {})
     * apollo doesn't know what to do when it gets an error and the loading property never changes
     */
    return forward(operation)
  })

  const link = split(
    operation => hasSubscription(operation.query),
    websocketLink,
    authLink.concat(tokenRefreshLink).concat(httpLink)
  ) as unknown

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: generatePossibleTypes(introspectionResult),
      typePolicies: {
        Negotiation: { merge: true },
        RoomOffering: { merge: true },
        PackageOffering: { merge: true },
        Quote: { merge: true },
      },
    }),
    assumeImmutableResults: true,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
    link: link as ApolloLinkApolloClient,
  })
}
