import { CSSProperties } from 'react'

import { Provider } from '../../../types'
import { colorPalette } from '../../../styles/themeColors'

export interface IMarketplaceCategory {
  name: string
  cardText?: string
  mainImageIdx: number
  providers: Provider[]
  style: CSSProperties
}

export const categoriesData: IMarketplaceCategory[] = [
  {
    mainImageIdx: 3,
    name: "Kiss & Tell Founder's Collection",
    style: { backgroundColor: colorPalette.color1, color: '#333' },
    providers: [
      {
        id: '478c8870-7fa6-48ab-a411-79250c483b52',
        mainImage: {
          assocId: '478c8870-7fa6-48ab-a411-79250c483b52',
          assocType: 'providers',
          ext: 'jpg',
          id: '5c362f09-15be-4366-a45e-8efb93176f4e',
        },
        name: 'Villa Sola Cabiati',
        package: {
          capacity: 450,
          price: '0',
        },
        room: {
          price: '1000',
          sleeps: 12,
        },
        url: 'villa-sola-cabiati',
        location: 'Lake Como, Italy',
      },
      {
        id: '455332d6-82a9-4a5e-90e4-3dfeb0fc7562',
        mainImage: {
          assocId: '455332d6-82a9-4a5e-90e4-3dfeb0fc7562',
          assocType: 'providers',
          ext: 'jpg',
          id: '47776fb8-35f1-498c-985b-d067e0206dc7',
        },
        name: 'Hotel Esencia',
        package: {
          capacity: 300,
          price: '2500',
        },
        room: {
          price: '100',
          sleeps: 216,
        },
        url: 'hotel-esencia',
        location: 'Riviera Maya, Mexico',
      },
      {
        id: '40051a69-3770-4924-bc68-e3bf5863ba75',
        mainImage: {
          assocId: '40051a69-3770-4924-bc68-e3bf5863ba75',
          assocType: 'providers',
          ext: 'jpg',
          id: '980aa57a-9568-4643-a0f0-56c5ab56a1b8',
        },
        name: 'Le Château de Tourreau',
        package: {
          capacity: 120,
          price: '0',
        },
        room: {
          price: '332',
          sleeps: 28,
        },
        url: 'le-chateau-de-tourreau',
        location: 'Provence, France',
      },
      {
        id: 'e4f49f40-a7ff-411f-a166-e60fc53837a4',
        mainImage: {
          assocId: 'e4f49f40-a7ff-411f-a166-e60fc53837a4',
          assocType: 'providers',
          ext: 'jpg',
          id: 'f7eb8a39-9ef8-4b33-a8e4-c106600a60ec',
        },
        name: 'Golden Eye / Fleming Villa',
        package: {
          capacity: 250,
          price: '0',
        },
        room: {
          price: '315',
          sleeps: 124,
        },
        url: 'golden-eye-fleming-villa',
        location: 'Orcabessa, Jamaica',
      },
      {
        id: '2aff293b-2d3a-4832-a73b-644f28b48655',
        mainImage: {
          assocId: '2aff293b-2d3a-4832-a73b-644f28b48655',
          assocType: 'providers',
          ext: 'jpg',
          id: '8cdbc998-8303-4276-8056-6907c3a5f443',
        },
        name: 'Hotel Villa Cimbrone',
        package: {
          capacity: 300,
          price: '4626',
        },
        room: {
          price: '682',
          sleeps: 38,
        },
        url: 'hotel-villa-cimbrone',
        location: 'Amalfi Coast, Italy',
      },
      {
        id: '6e2b5900-8259-461f-95aa-c95cd48bbc14',
        mainImage: {
          assocId: '6e2b5900-8259-461f-95aa-c95cd48bbc14',
          assocType: 'providers',
          ext: 'jpg',
          id: '6e7e475d-3b05-4334-a309-e7cf568b384f',
        },
        name: 'Teitiare Estate / The Cove Sayulita',
        package: {
          capacity: 100,
          price: '0',
        },
        room: {
          price: '400',
          sleeps: 32,
        },
        url: 'teitiare-estate-the-cove-sayulita',
        location: 'Sayulita, Mexico',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 1,
    name: 'Tulum',
    cardText: 'Tulum Gatherings',
    style: { backgroundColor: colorPalette.color2, color: '#333' },
    providers: [
      {
        id: '15e3dc1f-d862-4200-b86b-81df04e38d56',
        mainImage: {
          assocId: '15e3dc1f-d862-4200-b86b-81df04e38d56',
          assocType: 'providers',
          ext: 'jpg',
          id: '03d42b7e-ba7d-4673-b3f1-10591354d187',
        },
        name: 'Ahau Tulum',
        package: {
          capacity: 150,
          price: '3510',
        },
        room: {
          price: '152',
          sleeps: 50,
        },
        url: 'ahau-tulum',
        location: 'Tulum, Mexico',
      },
      {
        id: '36542f2c-338b-4fe0-813f-e08825b46559',
        mainImage: {
          assocId: '36542f2c-338b-4fe0-813f-e08825b46559',
          assocType: 'providers',
          ext: 'jpg',
          id: '97362bc0-4170-4f43-9fef-dcd75f9e23a1',
        },
        name: 'Papaya Playa Project',
        package: {
          capacity: 200,
          price: '15020',
        },
        room: {
          price: '299',
          sleeps: 66,
        },
        url: 'papaya-playa-project',
        location: 'Tulum, Mexico',
      },
      {
        id: '1953a3d1-ca22-4e5e-9b5e-737d3a933ab7',
        mainImage: {
          assocId: '1953a3d1-ca22-4e5e-9b5e-737d3a933ab7',
          assocType: 'providers',
          ext: 'png',
          id: '393b6218-39df-4c17-afbd-c40c8c323af0',
        },
        name: 'Villa Las Estrellas',
        package: {
          capacity: 45,
          price: '0',
        },
        room: {
          price: '245',
          sleeps: 42,
        },
        url: 'villa-las-estrellas',
        location: 'Tulum, Mexico',
      },
      {
        id: 'd9f52636-85f0-4046-9160-329793a627c1',
        mainImage: {
          assocId: 'd9f52636-85f0-4046-9160-329793a627c1',
          assocType: 'providers',
          ext: 'jpg',
          id: 'b76df0ec-33c3-4539-8071-3ebb095e97f3',
        },
        name: 'Azulik',
        package: {
          capacity: 350,
          price: '0',
        },
        room: {
          price: '100',
          sleeps: 14,
        },
        url: 'azulik',
        location: 'Tulum, Mexico',
      },
      {
        id: '2a54cb72-5e34-4783-ab90-b5d378e7a74b',
        mainImage: {
          assocId: '2a54cb72-5e34-4783-ab90-b5d378e7a74b',
          assocType: 'providers',
          ext: 'jpg',
          id: '5e898dcd-a3bb-470f-b405-ab91a18091a3',
        },
        name: 'Casa Malca',
        package: {
          capacity: 200,
          price: '0',
        },
        room: {
          price: '560',
          sleeps: 142,
        },
        url: 'casa-malca',
        location: 'Tulum, Mexico',
      },
      {
        id: '5fb65188-5fdc-45f3-9d10-37fadae41f85',
        mainImage: {
          assocId: '5fb65188-5fdc-45f3-9d10-37fadae41f85',
          assocType: 'providers',
          ext: 'jpg',
          id: 'd0b0ed42-463b-418f-ad80-a006c010987c',
        },
        name: 'Coco Tulum',
        package: {
          capacity: 200,
          price: '0',
        },
        room: {
          price: '132',
          sleeps: 90,
        },
        url: 'coco-tulum',
        location: 'Tulum, Mexico',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 2,
    name: 'Historic Estates',
    cardText: 'Historic Estate Weddings',
    style: { backgroundColor: colorPalette.color3, color: '#333' },
    providers: [
      {
        id: '1e80f25d-20a1-4438-8d53-72537c45ff79',
        mainImage: {
          assocId: '1e80f25d-20a1-4438-8d53-72537c45ff79',
          assocType: 'providers',
          ext: 'jpg',
          id: 'eedb4330-827f-49c9-afd6-d5f521be0d90',
        },
        name: 'Villa Pliniana',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '1510',
          sleeps: 36,
        },
        url: 'villa-pliniana',
        location: 'Lake Como, Italy',
      },
      {
        id: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
        mainImage: {
          assocId: 'bed6e6d1-30af-4cd3-b383-57a475ab1e37',
          assocType: 'providers',
          ext: 'jpg',
          id: 'd16e1bb4-6a0a-49f7-ba86-1e1feb564bff',
        },
        name: 'Hacienda Temozon',
        package: {
          capacity: 350,
          price: '1620',
        },
        room: {
          price: '176',
          sleeps: 82,
        },
        url: 'hacienda-temozon',
        location: 'Merida, Mexico',
      },
      {
        id: 'eb644d4e-56f0-4616-b8c1-71945221eec3',
        mainImage: {
          assocId: 'eb644d4e-56f0-4616-b8c1-71945221eec3',
          assocType: 'providers',
          ext: 'png',
          id: 'c25c26e3-f187-491f-8d72-4d157b091353',
        },
        name: 'Villa Mangiacane',
        package: {
          capacity: 120,
          price: '1500',
        },
        room: {
          price: '192',
          sleeps: 60,
        },
        url: 'villa-mangiacane',
        location: 'Tuscany, Italy',
      },
      {
        id: 'd3c43ca3-077b-47de-b1d4-859973ce93d0',
        mainImage: {
          assocId: 'd3c43ca3-077b-47de-b1d4-859973ce93d0',
          assocType: 'providers',
          ext: 'png',
          id: '3083125d-187f-422a-aebe-005d40fbe29c',
        },
        name: 'Estancia La Bandada',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '600',
          sleeps: 18,
        },
        url: 'estancia-la-bandada',
        location: 'Buenos Aires, Argentina',
      },
      {
        id: '7efff3f0-83a4-4ae9-b696-c973c9d5122a',
        mainImage: {
          assocId: '7efff3f0-83a4-4ae9-b696-c973c9d5122a',
          assocType: 'providers',
          ext: 'jpg',
          id: '37b89715-533c-4bf8-b7b8-b4f8589083f4',
        },
        name: 'Villa San Giacomo',
        package: {
          capacity: 100,
          price: '6836',
        },
        room: {
          price: '330',
          sleeps: 32,
        },
        url: 'villa-san-giacomo',
        location: 'Amalfi Coast, Italy',
      },
      {
        id: 'b34b285c-a7ca-4c6a-bf01-c8794e6354b0',
        mainImage: {
          assocId: 'b34b285c-a7ca-4c6a-bf01-c8794e6354b0',
          assocType: 'providers',
          ext: 'jpg',
          id: '5b494d34-a394-46d9-a4f8-8d8d10443fff',
          metadata: {},
        },
        name: 'Montpelier Plantation & Beach',
        package: {
          capacity: 175,
          price: '800',
        },
        room: {
          price: '172',
          sleeps: 35,
        },
        url: 'montpelier-plantation-beach',
        location: 'Nevis',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 0,
    name: 'Caribbean Island',
    cardText: 'Caribbean Island Celebrations',
    style: { backgroundColor: colorPalette.color4, color: '#333' },
    providers: [
      {
        id: '46ca3ed6-e25a-47c2-ba18-e88c64d5bd74',
        mainImage: {
          assocId: '46ca3ed6-e25a-47c2-ba18-e88c64d5bd74',
          assocType: 'providers',
          ext: 'jpg',
          id: 'c257c95d-2fe8-46bd-80d4-b19efd9252d6',
        },
        name: 'Zemi Beach House Hotel & Spa',
        package: {
          capacity: 75,
          price: '0',
        },
        room: {
          price: '615',
          sleeps: 340,
        },
        url: 'zemi-beach-house-hotel-spa',
        location: 'Anguilla',
      },
      {
        id: '49823232-dfbb-4870-bc9f-193dff0cc723',
        mainImage: {
          assocId: '49823232-dfbb-4870-bc9f-193dff0cc723',
          assocType: 'providers',
          ext: 'jpg',
          id: 'cd355791-02a5-4f65-a059-004b55589391',
        },
        name: 'Casa Sandra',
        package: {
          capacity: 100,
          price: '2350',
        },
        room: {
          price: '313',
          sleeps: 46,
        },
        url: 'casa-sandra',
        location: 'Holbox, Mexico',
      },
      {
        id: '9dff6acd-ce51-48dc-a6ac-8aac204007c5',
        mainImage: {
          assocId: '9dff6acd-ce51-48dc-a6ac-8aac204007c5',
          assocType: 'providers',
          ext: 'jpg',
          id: 'b8b0355b-6465-4ebd-8737-ebf55dba36e5',
        },
        name: 'Cayo Espanto',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '1200',
          sleeps: 18,
        },
        url: 'cayo-espanto',
        location: 'Ambergris Caye, Belize',
      },
      {
        id: '48a3b752-f8f7-4a3d-bf99-4e2d02a25673',
        mainImage: {
          assocId: '48a3b752-f8f7-4a3d-bf99-4e2d02a25673',
          assocType: 'providers',
          ext: 'jpg',
          id: '5efdf1e8-8073-4715-ab89-e75e970e0818',
        },
        name: 'The Ocean Club, A Four Seasons Hotel',
        package: {
          capacity: 300,
          price: '6500',
        },
        room: {
          price: '2158',
          sleeps: 234,
        },
        url: 'the-ocean-club-a-four-seasons-hotel',
        location: 'Paradise Island, Bahamas',
      },
      {
        id: '289e30a6-7409-459e-8156-0379352793a7',
        mainImage: {
          assocId: '289e30a6-7409-459e-8156-0379352793a7',
          assocType: 'providers',
          ext: 'jpg',
          id: '34a183ab-9e1f-4109-89c6-e25b7c2e7168',
        },
        name: 'Musha Cay',
        package: {
          capacity: 125,
          price: '0',
        },
        room: {
          price: '3334',
          sleeps: 24,
        },
        url: 'musha-cay',
        location: 'Out Islands, Bahamas',
      },
      {
        id: '9654778d-f8dd-44b0-b85b-3126d03d22c3',
        mainImage: {
          assocId: '9654778d-f8dd-44b0-b85b-3126d03d22c3',
          assocType: 'providers',
          ext: 'jpg',
          id: '35a3c0c4-1606-48d2-bc0b-78f0b37cf271',
        },
        name: 'Secret Bay',
        package: {
          capacity: 100,
          price: '0',
        },
        room: {
          price: '838',
          sleeps: 10,
        },
        url: 'secret-bay',
        location: 'Dominica',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 4,
    name: 'Lake Como',
    cardText: 'Lake Como Venues',
    style: { backgroundColor: colorPalette.color5, color: '#333' },
    providers: [
      {
        id: 'e4bc64a8-05dd-4136-8f66-3c31dcc7ff86',
        mainImage: {
          assocId: 'e4bc64a8-05dd-4136-8f66-3c31dcc7ff86',
          assocType: 'providers',
          ext: 'jpg',
          id: '1dfb4912-3471-4927-b71c-8a4082bcfca0',
        },
        name: 'Villa La Cassinella ',
        package: {
          capacity: 25,
          price: '0',
        },
        room: null,
        url: 'villa-la-cassinella',
        location: 'Lake Como, Italy',
      },
      {
        id: 'c0b4bcc1-80b2-4d4c-9245-ff125d73249b',
        mainImage: {
          assocId: 'c0b4bcc1-80b2-4d4c-9245-ff125d73249b',
          assocType: 'providers',
          ext: 'jpg',
          id: '4983c172-e4c2-44a1-b83a-33b816c1b67a',
        },
        name: 'Villa Balbiano',
        package: {
          capacity: 220,
          price: '0',
        },
        room: null,
        url: 'villa-balbiano',
        location: 'Lake Como, Italy',
      },
      {
        id: 'e0c5feaa-cbf4-4696-bc6a-d5d9d3626aa6',
        mainImage: {
          assocId: 'e0c5feaa-cbf4-4696-bc6a-d5d9d3626aa6',
          assocType: 'providers',
          ext: 'jpg',
          id: 'fdaca9cf-2205-419b-ae2b-5b8599a74889',
        },
        name: 'Villa Marchese',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '600',
          sleeps: 20,
        },
        url: 'villa-marchese',
        location: 'Lake Como, Italy',
      },
      {
        id: '6f557c52-3552-4bfd-b7f8-559f3f786dd0',
        mainImage: {
          assocId: '6f557c52-3552-4bfd-b7f8-559f3f786dd0',
          assocType: 'providers',
          ext: 'jpg',
          id: '5475769e-4d91-456c-885c-dba9e64f8e3e',
        },
        name: 'Grand Hotel Villa Serbelloni',
        package: {
          capacity: 240,
          price: '0',
        },
        room: {
          price: '673',
          sleeps: 230,
        },
        url: 'grand-hotel-villa-serbelloni',
        location: 'Lake Como, Italy',
      },
      {
        id: '03570b87-40d4-466d-a9eb-e584922b7bde',
        mainImage: {
          assocId: '03570b87-40d4-466d-a9eb-e584922b7bde',
          assocType: 'providers',
          ext: 'jpg',
          id: '52743438-ad35-4319-8e4b-b3802f4eacf8',
        },
        name: 'Villa Balbianello ',
        package: {
          capacity: 150,
          price: '0',
        },
        room: null,
        url: 'villa-balbianello',
        location: 'Lake Como, Italy',
      },
      {
        id: 'c846941a-20ec-40f6-a338-5cd317b5c4d2',
        mainImage: {
          assocId: 'c846941a-20ec-40f6-a338-5cd317b5c4d2',
          assocType: 'providers',
          ext: 'jpg',
          id: 'dafd7791-a077-4da1-a052-d4d395d465c4',
        },
        name: 'Grand Hotel Tremezzo',
        package: {
          capacity: 200,
          price: '2000',
        },
        room: {
          price: '100',
          sleeps: 276,
        },
        url: 'grand-hotel-tremezzo',
        location: 'Lake Como, Italy',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 3,
    name: 'Wine Country',
    cardText: 'Wine Country Festivities',
    style: { backgroundColor: colorPalette.color6, color: '#333' },
    providers: [
      {
        id: '002c648a-75f2-4bd2-b3c6-03216e966de1',
        mainImage: {
          assocId: '002c648a-75f2-4bd2-b3c6-03216e966de1',
          assocType: 'providers',
          ext: 'jpg',
          id: '0df61591-1d20-42f7-8ffc-bda0cb60c344',
        },
        name: 'Hotel Mas Tinell',
        package: {
          capacity: 200,
          price: '0',
        },
        room: {
          price: '210',
          sleeps: 22,
        },
        url: 'hotel-mas-tinell',
        location: 'Penedes, Spain',
      },
      {
        id: 'a13251ef-03ad-49fd-a4b4-2661a6786d5e',
        mainImage: {
          assocId: 'a13251ef-03ad-49fd-a4b4-2661a6786d5e',
          assocType: 'providers',
          ext: 'jpg',
          id: 'dc34665f-303f-476b-a969-9ed94b1c1ea8',
        },
        name: 'St. Helena French Farmhouse',
        package: {
          capacity: 60,
          price: '0',
        },
        room: {
          price: '454',
          sleeps: 22,
        },
        url: 'st-helena-french-farmhouse',
        location: 'Napa, California',
      },
      {
        id: '56d62bdf-8816-4682-950f-8513b837d4fb',
        mainImage: {
          assocId: '56d62bdf-8816-4682-950f-8513b837d4fb',
          assocType: 'providers',
          ext: 'jpg',
          id: 'c4849604-f104-4e7e-8fe5-f24ecf2b2f03',
        },
        name: 'Cavas Wine Lodge',
        package: {
          capacity: 250,
          price: '4100',
        },
        room: {
          price: '750',
          sleeps: 38,
        },
        url: 'cavas-wine-lodge',
        location: 'Mendoza, Argentina',
      },
      {
        id: 'a1052b4b-a45b-47a9-8ba1-2eced92d6d06',
        mainImage: {
          assocId: 'a1052b4b-a45b-47a9-8ba1-2eced92d6d06',
          assocType: 'providers',
          ext: 'png',
          id: '3e5dac1c-e9ea-4a10-997d-92b557eda5ba',
        },
        name: 'Mont Rochelle, Virgin Limited Edition',
        package: {
          capacity: 80,
          price: '4170',
        },
        room: {
          price: '673',
          sleeps: 56,
        },
        url: 'mont-rochelle-virgin-limited-edition',
        location: 'Franschhoek, South Africa',
      },
      {
        id: 'b8e4df04-bb0b-4f80-b885-6cfdf12a1dde',
        mainImage: {
          assocId: 'b8e4df04-bb0b-4f80-b885-6cfdf12a1dde',
          assocType: 'providers',
          ext: 'jpg',
          id: 'dd85d62b-876d-4ee4-9d86-345c4dbc927a',
        },
        name: 'Il Borro Estate - A Relais & Chateaux Property',
        package: {
          capacity: 300,
          price: '12000',
        },
        room: {
          price: '340',
          sleeps: 336,
        },
        url: 'il-borro-estate-a-relais-chateaux-property',
        location: 'Tuscany, Italy',
      },
      {
        id: '061b16c0-bb7c-46e4-8517-f25d3471884f',
        mainImage: {
          assocId: '061b16c0-bb7c-46e4-8517-f25d3471884f',
          assocType: 'providers',
          ext: 'jpg',
          id: 'cde4619a-33f8-47d6-8e65-c53cd0de56a0',
        },
        name: 'Venetsanos Winery',
        package: {
          capacity: 160,
          price: '1800',
        },
        room: null,
        url: 'venetsanos-winery',
        location: 'Santorini, Greece',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 3,
    name: 'Greece',
    cardText: 'Greece Gatherings',
    style: { backgroundColor: colorPalette.color1, color: '#333' },
    providers: [
      {
        id: '7c29bfee-d038-4ede-b77a-fd90c1ef996e',
        mainImage: {
          assocId: '7c29bfee-d038-4ede-b77a-fd90c1ef996e',
          assocType: 'providers',
          ext: 'jpg',
          id: '2496d8b9-56fd-4022-a666-3a48868ac38a',
        },
        name: 'Cape Sounio',
        package: {
          capacity: 300,
          price: '1356',
        },
        room: {
          price: '269',
          sleeps: 296,
        },
        url: 'cape-sounio',
        location: 'Athens Riviera, Greece',
      },
      {
        id: 'cc771c9c-8c44-44ca-a307-bc23e480e25b',
        mainImage: {
          assocId: 'cc771c9c-8c44-44ca-a307-bc23e480e25b',
          assocType: 'providers',
          ext: 'jpg',
          id: '02be4680-2ca8-48d0-b7ec-7726be191000',
        },
        name: 'Cycladic Gem Luxury Villa',
        package: {
          capacity: 125,
          price: '0',
        },
        room: {
          price: '280',
          sleeps: 10,
        },
        url: 'cycladic-gem-luxury-villa',
        location: 'Ios, Greece',
      },
      {
        id: 'f044511a-4fe0-4ddc-b11a-c6232859e2c5',
        mainImage: {
          assocId: 'f044511a-4fe0-4ddc-b11a-c6232859e2c5',
          assocType: 'providers',
          ext: 'jpg',
          id: '550076f6-d928-4cff-bd1d-0461adc47489',
        },
        name: 'Four Seasons Astir Palace',
        package: {
          capacity: 500,
          price: '0',
        },
        room: {
          price: '349',
          sleeps: 706,
        },
        url: 'four-seasons-astir-palace',
        location: 'Vouliagmenis, Athens Riviera',
      },
      {
        id: 'ca6a5536-3f56-4dcf-b4e7-f0d1238484fb',
        mainImage: {
          assocId: 'ca6a5536-3f56-4dcf-b4e7-f0d1238484fb',
          assocType: 'providers',
          ext: 'jpg',
          id: '3ab6b50a-3de6-4e8f-9641-78d26957a70c',
        },
        name: 'Grace Hotel, an Auberge Resort',
        package: {
          capacity: 60,
          price: '0',
        },
        room: null,
        url: 'grace-hotel-an-auberge-resort',
        location: 'Santorini, Greece',
      },
      {
        id: 'b48ac527-838c-449c-892f-5675a848ac03',
        mainImage: {
          assocId: 'b48ac527-838c-449c-892f-5675a848ac03',
          assocType: 'providers',
          ext: 'jpg',
          id: '7dd449cf-7422-4be9-a728-ca406921b099',
        },
        name: 'Elounda Mare, a Relais & Chateaux property',
        package: {
          capacity: 150,
          price: '950',
        },
        room: null,
        url: 'elounda-mare-a-relais-chateaux-property',
        location: 'Crete, Greece',
      },
      {
        id: '71435946-ec97-4a2d-9ec8-5ab8afec684c',
        mainImage: {
          assocId: '71435946-ec97-4a2d-9ec8-5ab8afec684c',
          assocType: 'providers',
          ext: 'png',
          id: '8f0c5ece-5d03-4143-bcfa-729dff2e4081',
        },
        name: 'Cavo Ventus Luxury Villa',
        package: {
          capacity: 150,
          price: '2346',
        },
        room: {
          price: '646',
          sleeps: 4,
        },
        url: 'cavo-ventus-luxury-villa',
        location: 'Santorini, Greece',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 4,
    name: 'Beach',
    cardText: 'Beach Venues',
    style: { backgroundColor: colorPalette.color2, color: '#333' },
    providers: [
      {
        id: '6b8bb4ec-7e28-4a2a-9364-5724edaba03a',
        mainImage: {
          assocId: '6b8bb4ec-7e28-4a2a-9364-5724edaba03a',
          assocType: 'providers',
          ext: 'jpg',
          id: 'fdae4996-2566-4f76-a2a9-53b47c764f78',
        },
        name: 'El Dorado Maroma',
        package: {
          capacity: 200,
          price: '2030',
        },
        room: {
          price: '472',
          sleeps: 322,
        },
        url: 'el-dorado-maroma',
        location: 'Riviera Maya, Mexico',
      },
      {
        id: '4af0ffbe-13d6-408c-9ce5-9029b8aaee34',
        mainImage: {
          assocId: '4af0ffbe-13d6-408c-9ce5-9029b8aaee34',
          assocType: 'providers',
          ext: 'jpg',
          id: '747bd841-bf5a-48c2-a348-59db6ae6f119',
        },
        name: 'Coco Mat Eco Residences',
        package: {
          capacity: 90,
          price: '0',
        },
        room: null,
        url: 'coco-mat-eco-residences',
        location: 'Serifos, Greece',
      },
      {
        id: '5568500d-b49f-4742-8a50-300dc255a240',
        mainImage: {
          assocId: '5568500d-b49f-4742-8a50-300dc255a240',
          assocType: 'providers',
          ext: 'png',
          id: '059cc911-1b80-4ead-8552-60add0805c37',
        },
        name: 'Jamaica Inn ',
        package: {
          capacity: 125,
          price: '950',
        },
        room: {
          price: '303',
          sleeps: 160,
        },
        url: 'jamaica-inn',
        location: 'Ocho Rios, Jamaica',
      },
      {
        id: 'fd71bd7f-3e75-43ec-91c8-71bc0ade700d',
        mainImage: {
          assocId: 'fd71bd7f-3e75-43ec-91c8-71bc0ade700d',
          assocType: 'providers',
          ext: 'jpg',
          id: '73e4bec4-3a09-4cd1-b8f9-4efb551d8c98',
        },
        name: 'Le Sereno Hotel',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '414',
          sleeps: 85,
        },
        url: 'le-sereno-hotel',
        location: 'St. Barths',
      },
      {
        id: '783bc72c-58ca-4319-8de1-f28c4e4752b2',
        mainImage: {
          assocId: '783bc72c-58ca-4319-8de1-f28c4e4752b2',
          assocType: 'providers',
          ext: 'jpg',
          id: '128a3503-1d01-411f-bb05-7c77d2ada11b',
        },
        name: 'Beach Kandi',
        package: {
          capacity: 30,
          price: '0',
        },
        room: {
          price: '850',
          sleeps: 4,
        },
        url: 'beach-kandi',
        location: 'Turks & Caicos',
      },
      {
        id: 'acb74cf8-0f6f-423f-8268-23395dda2d7b',
        mainImage: {
          assocId: 'acb74cf8-0f6f-423f-8268-23395dda2d7b',
          assocType: 'providers',
          ext: 'jpg',
          id: '26fb51b8-7ab8-4033-a7a8-66e3243e899a',
        },
        name: 'La Zebra Hotel Tulum',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '496',
          sleeps: 48,
        },
        url: 'la-zebra-hotel-tulum',
        location: 'Tulum, Mexico',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 0,
    name: 'Tuscany',
    cardText: 'Tuscany Weddings',
    style: { backgroundColor: colorPalette.color3, color: '#333' },
    providers: [
      {
        id: '5b0ed92e-78cf-4249-ac01-8a5c2a72f73a',
        mainImage: {
          assocId: '5b0ed92e-78cf-4249-ac01-8a5c2a72f73a',
          assocType: 'providers',
          ext: 'jpg',
          id: '62931747-eacf-41fc-b28f-b4154edacfe6',
        },
        name: 'Villa Cetinale',
        package: {
          capacity: 150,
          price: '0',
        },
        room: null,
        url: 'www.villacetinale.com',
        location: 'Tuscany, Italy',
      },
      {
        id: 'f65817ca-7faa-4978-a2ab-835fa2d78ffe',
        mainImage: {
          assocId: 'f65817ca-7faa-4978-a2ab-835fa2d78ffe',
          assocType: 'providers',
          ext: 'jpg',
          id: '59a252fc-f098-487c-bf7b-3f7aa37af4de',
        },
        name: 'Villa Laura',
        package: {
          capacity: 90,
          price: '0',
        },
        room: {
          price: '343',
          sleeps: 20,
        },
        url: 'villa-laura',
        location: 'Tuscany, Italy',
      },
      {
        id: '2641e0f0-c18d-4497-96c8-514a0ca38cbd',
        mainImage: {
          assocId: '2641e0f0-c18d-4497-96c8-514a0ca38cbd',
          assocType: 'providers',
          ext: 'jpg',
          id: '6b8d4aec-ab32-4f3d-8209-6e7663f3437d',
        },
        name: 'Villa La Vedetta',
        package: {
          capacity: 270,
          price: '0',
        },
        room: {
          price: '387',
          sleeps: 36,
        },
        url: 'villa-la-vedetta',
        location: 'Florence, Italy',
      },
      {
        id: 'b0003fc5-96c1-424a-a553-c033ffad679c',
        mainImage: {
          assocId: 'b0003fc5-96c1-424a-a553-c033ffad679c',
          assocType: 'providers',
          ext: 'png',
          id: '5a512a9b-5f18-4804-9863-ff28bd20054f',
        },
        name: 'La Foce',
        package: {
          capacity: 150,
          price: '5080',
        },
        room: {
          price: '99',
          sleeps: 44,
        },
        url: 'la-foce',
        location: 'Tuscany, Italy',
      },
      {
        id: '5d010a16-ef33-47a7-8bb5-7f4e72d98aa4',
        mainImage: {
          assocId: '5d010a16-ef33-47a7-8bb5-7f4e72d98aa4',
          assocType: 'providers',
          ext: 'png',
          id: 'c282eda8-001e-4a7e-8508-ca5ed77e6638',
        },
        name: 'Rosewood Castiglion del Bosco',
        package: {
          capacity: 225,
          price: '0',
        },
        room: {
          price: '794',
          sleeps: 180,
        },
        url: 'rosewood-castiglion-del-bosco',
        location: 'Tuscany, Italy',
      },
      {
        id: '73671602-5914-4779-bcf4-bbcd9fb9d417',
        mainImage: {
          assocId: '73671602-5914-4779-bcf4-bbcd9fb9d417',
          assocType: 'providers',
          ext: 'jpg',
          id: '59c6a01d-63c0-486b-a235-6d5b13873433',
        },
        name: 'Castello di Vicarello',
        package: {
          capacity: 150,
          price: '2120',
        },
        room: null,
        url: 'castello-di-vicarello',
        location: 'Tuscany, Italy',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 5,
    name: 'Design Forward',
    cardText: 'Design Forward Venues',
    style: { backgroundColor: colorPalette.color4, color: '#333' },
    providers: [
      {
        id: '6560c3a4-1539-4949-b998-adef7b1997d3',
        mainImage: {
          assocId: '6560c3a4-1539-4949-b998-adef7b1997d3',
          assocType: 'providers',
          ext: 'jpg',
          id: '4445dc8d-f686-4517-bcae-e64b15a9746a',
        },
        name: 'The Cape, A Thompson Hotel',
        package: {
          capacity: 250,
          price: '0',
        },
        room: {
          price: '959',
          sleeps: 402,
        },
        url: 'the-cape-a-thompson-hotel',
        location: 'Los Cabos, Mexico',
      },
      {
        id: 'ffcc8ef0-90f5-4208-bab5-2c5c0f74f620',
        mainImage: {
          assocId: 'ffcc8ef0-90f5-4208-bab5-2c5c0f74f620',
          assocType: 'providers',
          ext: 'png',
          id: '777adc00-46dd-484b-9875-0a2cdfdcec8b',
        },
        name: 'Ryad Dyor',
        package: {
          capacity: 40,
          price: '0',
        },
        room: {
          price: '130',
          sleeps: 35,
        },
        url: 'ryad-dyor',
        location: 'Marrakech, Morocco',
      },
      {
        id: '250c00ca-af1c-4f5a-aea1-f2fa9c8a019d',
        mainImage: {
          assocId: '250c00ca-af1c-4f5a-aea1-f2fa9c8a019d',
          assocType: 'providers',
          ext: 'png',
          id: '318809dc-41b8-420b-8292-d7dc4b548799',
        },
        name: 'Masseria Acquadolce',
        package: {
          capacity: 125,
          price: '0',
        },
        room: {
          price: '1200',
          sleeps: 16,
        },
        url: 'masseria-acquadolce',
        location: 'Puglia, Italy',
      },
      {
        id: 'd88c9cba-fa20-4810-b6f7-47c1365d841c',
        mainImage: {
          assocId: 'd88c9cba-fa20-4810-b6f7-47c1365d841c',
          assocType: 'providers',
          ext: 'jpg',
          id: 'b99a45bd-a4dd-478e-b16f-bd31a0483f71',
        },
        name: 'Encanto Acapulco',
        package: {
          capacity: 120,
          price: '0',
        },
        room: {
          price: '342',
          sleeps: 110,
        },
        url: 'encanto-acapulco',
        location: 'Acapulco, Mexico',
      },
      {
        id: '2a3603a9-f840-491c-a604-cf02cbb4f35b',
        mainImage: {
          assocId: '2a3603a9-f840-491c-a604-cf02cbb4f35b',
          assocType: 'providers',
          ext: 'jpg',
          id: '18f1049a-18fb-4e6e-b895-c2f9ca1dc1a2',
        },
        name: 'Il Sereno',
        package: {
          capacity: 140,
          price: '0',
        },
        room: {
          price: '810',
          sleeps: 64,
        },
        url: 'il-sereno',
        location: 'Lake Como, Italy',
      },
      {
        id: '693609a9-eed0-4fcb-9378-6f9927c0ef3f',
        mainImage: {
          assocId: '693609a9-eed0-4fcb-9378-6f9927c0ef3f',
          assocType: 'providers',
          ext: 'jpg',
          id: '4a72ac20-1996-4e84-ac19-a1d1248c851a',
        },
        name: 'Viceroy Los Cabos',
        package: {
          capacity: 500,
          price: '0',
        },
        room: {
          price: '350',
          sleeps: 492,
        },
        url: 'viceroy-los-cabos',
        location: 'Los Cabos, Mexico',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 4,
    name: 'Los Cabos',
    cardText: 'Los Cabos Celebrations',
    style: { backgroundColor: colorPalette.color5, color: '#333' },
    providers: [
      {
        id: '3e42c604-5fa8-4518-856e-8c401f456316',
        mainImage: {
          assocId: '3e42c604-5fa8-4518-856e-8c401f456316',
          assocType: 'providers',
          ext: 'jpg',
          id: 'b1b7b491-d634-4038-83b9-df344bc5c89c',
        },
        name: 'Grand Velas Los Cabos',
        package: {
          capacity: 770,
          price: '750',
        },
        room: {
          price: '1364',
          sleeps: 1015,
        },
        url: 'grand-velas-los-cabos',
        location: 'Los Cabos, Mexico',
      },
      {
        id: '0d810988-3d01-49e4-bad3-39a56cb26eac',
        mainImage: {
          assocId: '0d810988-3d01-49e4-bad3-39a56cb26eac',
          assocType: 'providers',
          ext: 'jpg',
          id: '4d20ad86-aaea-4e51-bf0f-413a8efaab8f',
        },
        name: 'Casa Edwards Too',
        package: {
          capacity: 50,
          price: '0',
        },
        room: {
          price: '350',
          sleeps: 10,
        },
        url: 'casa-edwards-too',
        location: 'Los Cabos, Mexico',
      },
      {
        id: '8ceb337f-2621-4d56-b4b3-de27e3344fba',
        mainImage: {
          assocId: '8ceb337f-2621-4d56-b4b3-de27e3344fba',
          assocType: 'providers',
          ext: 'png',
          id: '6b7e1cba-43cc-428f-abc6-527bbd2bae17',
        },
        name: 'Chileno Bay, an Auberge Resort & Spa',
        package: {
          capacity: 250,
          price: '0',
        },
        room: {
          price: '1950',
          sleeps: 317,
        },
        url: 'chileno-bay-an-auberge-resort-spa',
        location: 'Los Cabos, Mexico',
      },
      {
        id: 'd6490b22-1afc-4913-b6a0-2aeac70a988b',
        mainImage: {
          assocId: 'd6490b22-1afc-4913-b6a0-2aeac70a988b',
          assocType: 'providers',
          ext: 'jpg',
          id: 'c8ca2454-cf8f-4ae8-8e8b-0ba2c192c5b6',
        },
        name: 'Garza Blanca Resort & Spa Los Cabos',
        package: {
          capacity: 300,
          price: '2980',
        },
        room: {
          price: '750',
          sleeps: 1385,
        },
        url: 'garza-blanca-resort-spa-los-cabos',
        location: 'Los Cabos, Mexico',
      },
      {
        id: '2d142028-0cfc-40ca-a7ab-6a200570d371',
        mainImage: {
          assocId: '2d142028-0cfc-40ca-a7ab-6a200570d371',
          assocType: 'providers',
          ext: 'jpg',
          id: '269eb565-1b74-42ae-ad67-1730097d4eb1',
        },
        name: 'One & Only Palmilla',
        package: {
          capacity: 300,
          price: '5000',
        },
        room: {
          price: '1700',
          sleeps: 435,
        },
        url: 'one-only-palmilla',
        location: 'Los Cabos, Mexico',
      },
      {
        id: 'c5669289-bfc9-4459-8070-cc3ce77ab335',
        mainImage: {
          assocId: 'c5669289-bfc9-4459-8070-cc3ce77ab335',
          assocType: 'providers',
          ext: 'jpg',
          id: '9d49c91d-8723-4f71-8342-122aca8622d1',
        },
        name: 'Solaz Residences, a Luxury Collection Resort',
        package: {
          capacity: 500,
          price: '0',
        },
        room: {
          price: '100',
          sleeps: 320,
        },
        url: 'solaz-residences-a-luxury-collection-resort',
        location: 'Los Cabos, Mexico',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 0,
    name: 'Great Value',
    cardText: 'Great Value Venues',
    style: { backgroundColor: colorPalette.color6, color: '#333' },
    providers: [
      {
        id: '84eebf84-c192-447f-9777-5cee91425d74',
        mainImage: {
          assocId: '84eebf84-c192-447f-9777-5cee91425d74',
          assocType: 'providers',
          ext: 'jpg',
          id: 'b34dfce6-151d-4cc1-bcf5-3e7294db9c52',
        },
        name: 'Hotel B Cozumel',
        package: {
          capacity: 120,
          price: '0',
        },
        room: {
          price: '99',
          sleeps: 114,
        },
        url: 'hotel-b-cozumel',
        location: 'Cozumel, Mexico',
      },
      {
        id: 'b75a824f-c363-4cb7-bb50-53cb8286b06b',
        mainImage: {
          assocId: 'b75a824f-c363-4cb7-bb50-53cb8286b06b',
          assocType: 'providers',
          ext: 'jpg',
          id: '2dd7da99-fc13-4b72-80be-b616c5790495',
        },
        name: 'Castello di Monterone',
        package: {
          capacity: 120,
          price: '1000',
        },
        room: {
          price: '150',
          sleeps: 36,
        },
        url: 'castello-di-monterone',
        location: 'Perugia, Italy',
      },
      {
        id: '3abfcd15-58ec-40c4-b092-0049a9200aed',
        mainImage: {
          assocId: '3abfcd15-58ec-40c4-b092-0049a9200aed',
          assocType: 'providers',
          ext: 'jpeg',
          id: 'bcbe497f-1090-4e3b-88ac-73d1f1412cfd',
        },
        name: 'Kinsterna Hotel',
        package: {
          capacity: 150,
          price: '1440',
        },
        room: null,
        url: 'kinsterna-hotel',
        location: 'Pelopennese, Greece',
      },
      {
        id: 'e20f1f2b-0143-4753-9a78-b491e32eddf9',
        mainImage: {
          assocId: 'e20f1f2b-0143-4753-9a78-b491e32eddf9',
          assocType: 'providers',
          ext: 'jpg',
          id: 'ecaaa1e6-a054-4782-b5a8-05d918cf7189',
        },
        name: 'Mahekal Beach Resort',
        package: {
          capacity: 200,
          price: '4744',
        },
        room: {
          price: '100',
          sleeps: 516,
        },
        url: 'mahekal-beach-resort',
        location: 'Playa del Carmen, Mexico',
      },
      {
        id: '88f3e66c-56af-4803-8117-da9d2a9686e5',
        mainImage: {
          assocId: '88f3e66c-56af-4803-8117-da9d2a9686e5',
          assocType: 'providers',
          ext: 'jpg',
          id: '3d435109-9687-4888-9795-2033dae41ee3',
        },
        name: 'La Casa del Centro',
        package: {
          capacity: 40,
          price: '0',
        },
        room: {
          price: '166',
          sleeps: 6,
        },
        url: 'la-casa-del-centro',
        location: 'Merida, Mexico',
      },
      {
        id: 'aa3f0beb-165e-467d-a873-d9f1f9921603',
        mainImage: {
          assocId: 'aa3f0beb-165e-467d-a873-d9f1f9921603',
          assocType: 'providers',
          ext: 'jpg',
          id: '083e4f3a-12e1-4d4f-8006-ecc65b8ea156',
        },
        name: 'Chateau De Courtomer',
        package: {
          capacity: 300,
          price: '3100',
        },
        room: {
          price: '149',
          sleeps: 32,
        },
        url: 'chateau-de-courtomer',
        location: 'Normandy, France',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 1,
    name: 'Amalfi Coast',
    cardText: 'Amalfi Coast Celebrations',
    style: { backgroundColor: colorPalette.color1, color: '#333' },
    providers: [
      {
        id: '0a2fa643-7408-4e12-ad83-2373797669e2',
        mainImage: {
          assocId: '0a2fa643-7408-4e12-ad83-2373797669e2',
          assocType: 'providers',
          ext: 'jpg',
          id: 'e99a575d-0891-4ef8-a47e-2646fc1ec8b3',
        },
        name: 'Belmond Hotel Caruso',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '943',
          sleeps: 100,
        },
        url: 'belmond-hotel-caruso',
        location: 'Amalfi Coast, Italy',
      },
      {
        id: '2aff293b-2d3a-4832-a73b-644f28b48655',
        mainImage: {
          assocId: '2aff293b-2d3a-4832-a73b-644f28b48655',
          assocType: 'providers',
          ext: 'jpg',
          id: '8cdbc998-8303-4276-8056-6907c3a5f443',
        },
        name: 'Hotel Villa Cimbrone',
        package: {
          capacity: 300,
          price: '4626',
        },
        room: {
          price: '682',
          sleeps: 38,
        },
        url: 'hotel-villa-cimbrone',
        location: 'Amalfi Coast, Italy',
      },
      {
        id: '7efff3f0-83a4-4ae9-b696-c973c9d5122a',
        mainImage: {
          assocId: '7efff3f0-83a4-4ae9-b696-c973c9d5122a',
          assocType: 'providers',
          ext: 'jpg',
          id: '37b89715-533c-4bf8-b7b8-b4f8589083f4',
        },
        name: 'Villa San Giacomo',
        package: {
          capacity: 100,
          price: '6836',
        },
        room: {
          price: '330',
          sleeps: 32,
        },
        url: 'villa-san-giacomo',
        location: 'Amalfi Coast, Italy',
      },
      {
        id: 'aa2b4bc0-bbb4-4437-bfc0-071666692ad5',
        mainImage: {
          assocId: 'aa2b4bc0-bbb4-4437-bfc0-071666692ad5',
          assocType: 'providers',
          ext: 'jpg',
          id: '68a4ad82-32b3-46eb-998c-9faad5930fce',
        },
        name: 'Hotel Santa Caterina',
        package: {
          capacity: 120,
          price: '0',
        },
        room: null,
        url: 'hotel-santa-caterina',
        location: 'Amalfi Coast, Italy',
      },
      {
        id: 'f28dafdf-1b42-4fdb-b35b-fd79bcdfb0b9',
        mainImage: {
          assocId: 'f28dafdf-1b42-4fdb-b35b-fd79bcdfb0b9',
          assocType: 'providers',
          ext: 'jpg',
          id: '8d25f296-f28c-4139-a9f6-b3df528ae1be',
        },
        name: 'Castello Giusso',
        package: {
          capacity: 300,
          price: '8000',
        },
        room: null,
        url: 'castello-giusso',
        location: 'Sorrento Coast, Italy',
      },
      {
        id: 'bcfb57c5-07e5-4d15-a3f1-f5d88ae01fe1',
        mainImage: {
          assocId: 'bcfb57c5-07e5-4d15-a3f1-f5d88ae01fe1',
          assocType: 'providers',
          ext: 'jpg',
          id: '93015605-6599-455b-b905-a9c3a4836d19',
        },
        name: 'Capo Santa Fortunata',
        package: {
          capacity: 150,
          price: '40049',
        },
        room: {
          price: '198',
          sleeps: 8,
        },
        url: 'capo-santa-fortunata',
        location: 'Sorrento, Italy',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 0,
    name: 'Rustic',
    cardText: 'Rustic Reunions',
    style: { backgroundColor: colorPalette.color2, color: '#333' },
    providers: [
      {
        id: '2dcb1972-286f-4a8d-b253-f726d94a9166',
        mainImage: {
          assocId: '2dcb1972-286f-4a8d-b253-f726d94a9166',
          assocType: 'providers',
          ext: 'jpg',
          id: '3b423d42-b627-4691-b8ae-1c19915eeb67',
        },
        name: 'La Badia di Orvieto',
        package: {
          capacity: 160,
          price: '0',
        },
        room: {
          price: '170',
          sleeps: 54,
        },
        url: 'la-badia-di-orvieto',
        location: 'Orvieto, Italy',
      },
      {
        id: 'f87612ca-1753-44c9-b7e8-20a6a7713f4c',
        mainImage: {
          assocId: 'f87612ca-1753-44c9-b7e8-20a6a7713f4c',
          assocType: 'providers',
          ext: 'jpg',
          id: '204bdfe0-6416-4edb-a4ba-fcb61437a9a1',
        },
        name: 'Casa del Agua',
        package: {
          capacity: 40,
          price: '0',
        },
        room: {
          price: '333',
          sleeps: 8,
        },
        url: 'casa-del-agua',
        location: 'Riviera Maya, Mexico',
      },
      {
        id: '0adad48c-99b5-4928-92db-c4f972467d13',
        mainImage: {
          assocId: '0adad48c-99b5-4928-92db-c4f972467d13',
          assocType: 'providers',
          ext: 'jpg',
          id: '605a86cc-bec7-491b-8970-09a92cf6b244',
        },
        name: 'Under Canvas Moab',
        package: {
          capacity: 100,
          price: '0',
        },
        room: {
          price: '169',
          sleeps: 128,
        },
        url: 'under-canvas-moab',
        location: 'Moab, Utah',
      },
      {
        id: '4905b9ac-6376-43db-8c47-03f9f515a58b',
        mainImage: {
          assocId: '4905b9ac-6376-43db-8c47-03f9f515a58b',
          assocType: 'providers',
          ext: 'jpg',
          id: '9163cb42-15c0-4add-9a6e-b34fad83a8e9',
        },
        name: 'Delek Tulum',
        package: {
          capacity: 170,
          price: '3510',
        },
        room: {
          price: '290',
          sleeps: 42,
        },
        url: 'delek-tulum',
        location: 'Tulum, Mexico',
      },
      {
        id: '15875970-755d-49f6-b55f-8ded17aaba44',
        mainImage: {
          assocId: '15875970-755d-49f6-b55f-8ded17aaba44',
          assocType: 'providers',
          ext: 'jpg',
          id: '8e404d0e-3eaa-4b78-943a-1cc40edaf0fe',
        },
        name: 'Gibo',
        package: {
          capacity: 150,
          price: '1510',
        },
        room: null,
        url: 'gibo',
        location: 'Puglia, Italy',
      },
      {
        id: '9d011409-4995-4fd6-8d68-7c27b6c4c61f',
        mainImage: {
          assocId: '9d011409-4995-4fd6-8d68-7c27b6c4c61f',
          assocType: 'providers',
          ext: 'jpg',
          id: 'e17a4b63-92c3-4377-975e-284d1ac506de',
        },
        name: 'Necker Island',
        package: {
          capacity: 100,
          price: '0',
        },
        room: {
          price: '1176',
          sleeps: 34,
        },
        url: 'necker-island',
        location: 'Virgin Gorda, BVI',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 1,
    name: 'Riviera Maya',
    cardText: 'Riviera Maya Gatherings',
    style: { backgroundColor: colorPalette.color3, color: '#333' },
    providers: [
      {
        id: '3debd064-4622-432f-a0ba-264dcaa6aafc',
        mainImage: {
          assocId: '3debd064-4622-432f-a0ba-264dcaa6aafc',
          assocType: 'providers',
          ext: 'jpg',
          id: '194586e7-dea2-49cb-bb55-da80aecaca76',
        },
        name: 'UNICO 20°87° ',
        package: {
          capacity: 150,
          price: '6700',
        },
        room: {
          price: '573',
          sleeps: 1184,
        },
        url: 'unico-2087',
        location: 'Riviera Maya, Mexico',
      },
      {
        id: '228990bc-97dc-4305-8e6d-7f310e0cb356',
        mainImage: {
          assocId: '228990bc-97dc-4305-8e6d-7f310e0cb356',
          assocType: 'providers',
          ext: 'jpg',
          id: '0df18927-10ba-4554-bf0e-0c6089030246',
        },
        name: 'Zoetry Paraiso de la Bonita',
        package: {
          capacity: 180,
          price: '5709',
        },
        room: {
          price: '496',
          sleeps: 205,
        },
        url: 'zoetry-paraiso-de-la-bonita',
        location: 'Riviera Maya, Mexico',
      },
      {
        id: 'e2263f5d-f376-4034-9742-8871494b06ed',
        mainImage: {
          assocId: 'e2263f5d-f376-4034-9742-8871494b06ed',
          assocType: 'providers',
          ext: 'jpg',
          id: '3d9ee2e5-cc1b-44b6-849e-c9d8c852256b',
        },
        name: 'Thompson Beach House',
        package: {
          capacity: 200,
          price: '8500',
        },
        room: {
          price: '246',
          sleeps: 60,
        },
        url: 'thompson-beach-house',
        location: 'Playa del Carmen, Mexico',
      },
      {
        id: 'fa0f0ae8-fffd-45ce-a43d-e7369b44fdf9',
        mainImage: {
          assocId: 'fa0f0ae8-fffd-45ce-a43d-e7369b44fdf9',
          assocType: 'providers',
          ext: 'jpg',
          id: '1082b910-0c8a-4ba6-96de-8c09b7dcbbfe',
        },
        name: 'Grand Velas Riviera Maya',
        package: {
          capacity: 300,
          price: '2750',
        },
        room: {
          price: '612',
          sleeps: 1900,
        },
        url: 'grand-velas-riviera-maya',
        location: 'Riviera Maya, Mexico',
      },
      {
        id: 'f3a25a88-ac8f-4077-93df-3f07acfecab9',
        mainImage: {
          assocId: 'f3a25a88-ac8f-4077-93df-3f07acfecab9',
          assocType: 'providers',
          ext: 'jpg',
          id: 'edb76df2-347a-46fa-8acc-cc203ecaa944',
        },
        name: 'Rosewood Mayakoba ',
        package: {
          capacity: 450,
          price: '1800',
        },
        room: {
          price: '223',
          sleeps: 266,
        },
        url: 'rosewood-mayakoba',
        location: 'Riviera Maya, Mexico',
      },
      {
        id: '3473e2af-ff81-4e9e-9a1e-23d9fc680c7f',
        mainImage: {
          assocId: '3473e2af-ff81-4e9e-9a1e-23d9fc680c7f',
          assocType: 'providers',
          ext: 'jpg',
          id: '1c15363c-a7ce-4dc0-b46c-d813f13c0f71',
        },
        name: 'Belmond Maroma',
        package: {
          capacity: 375,
          price: '0',
        },
        room: {
          price: '100',
          sleeps: 157,
        },
        url: 'belmond-maroma',
        location: 'Riviera Maya, Mexico',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 1,
    name: 'France',
    cardText: 'France Festivities',
    style: { backgroundColor: colorPalette.color4, color: '#333' },
    providers: [
      {
        id: '08fc8db0-2920-46af-9518-5936d5558a60',
        mainImage: {
          assocId: '08fc8db0-2920-46af-9518-5936d5558a60',
          assocType: 'providers',
          ext: 'jpg',
          id: 'ddbda4c2-536a-463e-b101-0eb5fef4b6f4',
        },
        name: 'Chateau de Fonscolombe',
        package: {
          capacity: 200,
          price: '0',
        },
        room: null,
        url: 'chateau-de-fonscolombe',
        location: 'Le Puy-Sainte-Réparade, France',
      },
      {
        id: 'ffab71ed-d470-4966-aeb1-a1010c686bf2',
        mainImage: {
          assocId: 'ffab71ed-d470-4966-aeb1-a1010c686bf2',
          assocType: 'providers',
          ext: 'jpg',
          id: 'a1ddec02-299e-43db-9e02-bb4f4f82b5e3',
        },
        name: 'Villa Ephrussi',
        package: {
          capacity: 300,
          price: '0',
        },
        room: null,
        url: 'villa-ephrussi',
        location: "Cote D'Azur, France",
      },
      {
        id: 'a37347a6-728e-416e-a265-6104186c596d',
        mainImage: {
          assocId: 'a37347a6-728e-416e-a265-6104186c596d',
          assocType: 'providers',
          ext: 'JPG',
          id: 'cca34a55-aa77-4cd5-aa72-406451e202b3',
        },
        name: 'Airelles Gordes La Bastide',
        package: {
          capacity: 180,
          price: '0',
        },
        room: null,
        url: 'airelles-gordes-la-bastide',
        location: 'Gordes, France',
      },
      {
        id: '07cea393-43b0-4841-82ff-606064420d6d',
        mainImage: {
          assocId: '07cea393-43b0-4841-82ff-606064420d6d',
          assocType: 'providers',
          ext: 'jpg',
          id: '5b58babe-c5a7-4b82-8eb1-f119c2e47870',
        },
        name: 'Chateau De Grimaldi',
        package: {
          capacity: 200,
          price: '0',
        },
        room: null,
        url: 'chateau-de-grimaldi',
        location: 'Provence, France',
      },
      {
        id: '06ee290c-441a-43bc-992d-ba045fb646a2',
        mainImage: {
          assocId: '06ee290c-441a-43bc-992d-ba045fb646a2',
          assocType: 'providers',
          ext: 'jpg',
          id: 'ca0c8479-ca1e-4902-af32-58cc197d274a',
        },
        name: 'Villa Baulieu',
        package: {
          capacity: 300,
          price: '0',
        },
        room: null,
        url: 'villa-baulieu',
        location: 'Provence, France',
      },
      {
        id: 'c47b8e55-d9cf-441c-8e68-3d722c0c9aca',
        mainImage: {
          assocId: 'c47b8e55-d9cf-441c-8e68-3d722c0c9aca',
          assocType: 'providers',
          ext: 'jpg',
          id: '1bd23d73-548f-4d77-857f-d963f3106d09',
        },
        name: 'Grand Hotel du Cap Ferrat, A Four Seasons Hotel',
        package: {
          capacity: 450,
          price: '0',
        },
        room: {
          price: '1329',
          sleeps: 182,
        },
        url: 'grand-hotel-du-cap-ferrat-a-four-seasons-hotel',
        location: 'Saint-Jean-Cap-Ferrat, France',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 5,
    name: 'San Miguel de Allende',
    cardText: 'San Miguel de Allende Weddings',
    style: { backgroundColor: colorPalette.color5, color: '#333' },
    providers: [
      {
        id: 'bf538483-fe34-469a-a450-52fecaa22b94',
        mainImage: {
          assocId: 'bf538483-fe34-469a-a450-52fecaa22b94',
          assocType: 'providers',
          ext: 'jpg',
          id: 'e89b12cd-0846-450b-a308-88b00f2bac16',
        },
        name: 'Rosewood San Miguel de Allende',
        package: {
          capacity: 150,
          price: '525',
        },
        room: {
          price: '422',
          sleeps: 175,
        },
        url: 'rosewood-san-miguel-de-allende',
        location: 'San Miguel de Allende, Mexico',
      },
      {
        id: '61d7ba66-105c-455c-a671-8a1f11c55600',
        mainImage: {
          assocId: '61d7ba66-105c-455c-a671-8a1f11c55600',
          assocType: 'providers',
          ext: 'jpg',
          id: '3a39aabe-a78e-4281-b9ea-7fb50574f0ae',
        },
        name: 'Belmond Casa Sierra Nevada',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '550',
          sleeps: 92,
        },
        url: 'belmond-casa-sierra-nevada',
        location: 'San Miguel de Allende, Mexico',
      },
      {
        id: 'c1d34cb2-a31e-4a0a-8626-e13fe47f53f8',
        mainImage: {
          assocId: 'c1d34cb2-a31e-4a0a-8626-e13fe47f53f8',
          assocType: 'providers',
          ext: 'png',
          id: '96c71602-a7ab-4546-ad4a-3db07072f77b',
        },
        name: 'Hotel Matilda',
        package: {
          capacity: 100,
          price: '0',
        },
        room: {
          price: '741',
          sleeps: 80,
        },
        url: 'hotel-matilda',
        location: 'San Miguel de Allende, Mexico',
      },
      {
        id: 'b34f30ca-0b77-4aa8-aa7f-2d1c88300c9b',
        mainImage: {
          assocId: 'b34f30ca-0b77-4aa8-aa7f-2d1c88300c9b',
          assocType: 'providers',
          ext: 'jpg',
          id: 'd6cc04e6-01c4-4c0d-9916-5532745321e9',
        },
        name: 'Hotel Casa Cien',
        package: {
          capacity: 150,
          price: '0',
        },
        room: null,
        url: 'hotel-casa-cien',
        location: 'San Miguel de Allende, Mexico',
      },
      {
        id: '391ea989-3a47-4e80-9fdf-19f762df1041',
        mainImage: {
          assocId: '391ea989-3a47-4e80-9fdf-19f762df1041',
          assocType: 'providers',
          ext: 'jpg',
          id: '32f06bb8-f388-48ed-9363-b8c499ad3676',
        },
        name: 'Live Aqua Urban Resort',
        package: {
          capacity: 350,
          price: '0',
        },
        room: {
          price: '545',
          sleeps: 382,
        },
        url: 'live-aqua-urban-resort',
        location: 'San Miguel de Allende, Mexico',
      },
      {
        id: 'de21c0b3-4595-49c5-a342-ba4b77c0764d',
        mainImage: {
          assocId: 'de21c0b3-4595-49c5-a342-ba4b77c0764d',
          assocType: 'providers',
          ext: 'jpg',
          id: 'bbf02a63-0d4e-4798-af34-d418608f002c',
        },
        name: 'Dos Casas',
        package: {
          capacity: 30,
          price: '0',
        },
        room: null,
        url: 'dos-casas',
        location: 'San Miguel de Allende, Mexico',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 0,
    name: 'Boho / Shabby Chic',
    cardText: 'Boho / Shabby Chic Venues',
    style: { backgroundColor: colorPalette.color6, color: '#333' },
    providers: [
      {
        id: '53e4b9ae-623b-4bac-96b0-708b24636c6c',
        mainImage: {
          assocId: '53e4b9ae-623b-4bac-96b0-708b24636c6c',
          assocType: 'providers',
          ext: 'jpg',
          id: '68d9d7d6-4451-4f33-ad78-4252d64fa311',
        },
        name: 'Coco B Isla',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '166',
          sleeps: 60,
        },
        url: 'coco-b-isla',
        location: 'Isla Mujeres, Mexico',
      },
      {
        id: '4e132e50-4192-458a-8042-fb20d1e0acaa',
        mainImage: {
          assocId: '4e132e50-4192-458a-8042-fb20d1e0acaa',
          assocType: 'providers',
          ext: 'png',
          id: '2506c7be-7c1b-4760-a9b4-d2eda0c9f849',
        },
        name: 'Argos in Cappadocia',
        package: {
          capacity: 80,
          price: '0',
        },
        room: {
          price: '255',
          sleeps: 102,
        },
        url: 'argos-capadoccia',
        location: 'Cappadocia, Turkey',
      },
      {
        id: '89ee4ac9-790a-4e4b-a9db-914133f04881',
        mainImage: {
          assocId: '89ee4ac9-790a-4e4b-a9db-914133f04881',
          assocType: 'providers',
          ext: 'jpg',
          id: 'a4f8aef8-dfcf-4c6f-9de8-648db8103cf4',
        },
        name: 'Cocoon Suites',
        package: {
          capacity: 25,
          price: '2288',
        },
        room: {
          price: '612',
          sleeps: 18,
        },
        url: 'cocoon-suites',
        location: 'Santorini, Greece',
      },
      {
        id: 'dad5b644-f05c-4936-b628-3897998b36e8',
        mainImage: {
          assocId: 'dad5b644-f05c-4936-b628-3897998b36e8',
          assocType: 'providers',
          ext: 'png',
          id: '6bdbe0a8-4bef-4fb9-9cad-45667bae5dc8',
        },
        name: 'Quinta do Torneiro',
        package: {
          capacity: 375,
          price: '0',
        },
        room: null,
        url: 'quinta-do-torneiro',
        location: 'Oeiras, Portugal',
      },
      {
        id: '80014c7c-34b8-483d-98a4-87d35a9987ea',
        mainImage: {
          assocId: '80014c7c-34b8-483d-98a4-87d35a9987ea',
          assocType: 'providers',
          ext: 'jpg',
          id: '629dd444-a403-45ab-a2d0-a93c6eee458b',
        },
        name: 'Amansala',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '220',
          sleeps: 112,
        },
        url: 'amansala',
        location: 'Tulum, Mexico',
      },
      {
        id: '9a959193-b427-422e-8add-8c1667e8074b',
        mainImage: {
          assocId: '9a959193-b427-422e-8add-8c1667e8074b',
          assocType: 'providers',
          ext: 'jpg',
          id: 'c466b32f-836d-4aee-92c1-ef103722c78d',
        },
        name: 'Areias do Seixo',
        package: {
          capacity: 150,
          price: '0',
        },
        room: {
          price: '347',
          sleeps: 18,
        },
        url: 'areias-do-seixo',
        location: 'Santa Cruz, Portugal',
      },
    ] as Provider[],
  },
  {
    mainImageIdx: 0,
    name: 'Vallarta Nayarit',
    cardText: 'Vallarta Nayarit Gatherings',
    style: { backgroundColor: colorPalette.color1, color: '#333' },
    providers: [
      {
        id: '2b413f10-6c3d-4246-a4a5-ebb960da440c',
        mainImage: {
          assocId: '2b413f10-6c3d-4246-a4a5-ebb960da440c',
          assocType: 'providers',
          ext: 'jpg',
          id: 'b8e74e4d-57e7-4053-9c45-bc38254bb11c',
        },
        name: 'Villa Mandarinas',
        package: {
          capacity: 100,
          price: '1900',
        },
        room: {
          price: '250',
          sleeps: 22,
        },
        url: 'villa-mandarinas',
        location: 'Puerto Vallarta, Mexico',
      },
      {
        id: '5e141f24-1089-44a2-a44e-4fe91b810801',
        mainImage: {
          assocId: '5e141f24-1089-44a2-a44e-4fe91b810801',
          assocType: 'providers',
          ext: 'jpg',
          id: 'c6dfa8ea-0e2c-415e-90f5-9f8a9edc2b92',
        },
        name: 'Villa Premiere Boutique Hotel & Romantic Getaway',
        package: {
          capacity: 240,
          price: '3822',
        },
        room: {
          price: '199',
          sleeps: 160,
        },
        url: 'villa-premiere-boutique-hotel-romantic-getaway',
        location: 'Puerto Vallarta, Mexico',
      },
      {
        id: '7797aa57-97a6-4363-b997-eae23c410350',
        mainImage: {
          assocId: '7797aa57-97a6-4363-b997-eae23c410350',
          assocType: 'providers',
          ext: 'jpg',
          id: '64ecf23d-9a6f-4b37-9c1e-b516f2884fee',
        },
        name: 'Casa Koko',
        package: {
          capacity: 25,
          price: '0',
        },
        room: null,
        url: 'casa-koko',
        location: 'Punta Mita, Mexico',
      },
      {
        id: '08f4c946-7c8a-4587-8c20-fa3e5929e9db',
        mainImage: {
          assocId: '08f4c946-7c8a-4587-8c20-fa3e5929e9db',
          assocType: 'providers',
          ext: 'jpg',
          id: '6c2df345-e63a-4151-9e66-11fcb78fadc1',
        },
        name: 'Hacienda San Angel',
        package: {
          capacity: 80,
          price: '2000',
        },
        room: {
          price: '500',
          sleeps: 30,
        },
        url: 'hacienda-san-angel',
        location: 'Puerto Vallarta, Mexico',
      },
      {
        id: '30aed3fb-2ae8-4d0a-b480-0ec15d553fff',
        mainImage: {
          assocId: '30aed3fb-2ae8-4d0a-b480-0ec15d553fff',
          assocType: 'providers',
          ext: 'jpg',
          id: 'f94e2a31-0f40-4ef6-b265-b0e923e6f51b',
        },
        name: 'Grand Velas Riviera Nayarit',
        package: {
          capacity: 250,
          price: '1100',
        },
        room: {
          price: '673',
          sleeps: 654,
        },
        url: 'grand-velas-riviera-nayarit',
        location: 'Riviera Nayarit, Mexico',
      },
      {
        id: '2a0e2b21-f9dd-4f49-ba25-6d3b425de658',
        mainImage: {
          assocId: '2a0e2b21-f9dd-4f49-ba25-6d3b425de658',
          assocType: 'providers',
          ext: 'jpg',
          id: '75446eed-8dd6-4e1a-950b-2d93b8993de4',
        },
        name: 'Casa Aramara ',
        package: {
          capacity: 35,
          price: '0',
        },
        room: null,
        url: 'casa-aramara',
        location: 'Punta Mita, Mexico',
      },
    ] as Provider[],
  },
]
