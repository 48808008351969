import get from 'lodash/fp/get'

import { IcurrentUser, Token } from '../types'
import { KTUserToken } from '../common/constants'

interface IupdateLocalToken {
  user?: IcurrentUser
  token?: Token
}

// function to update the token in localStorage, user or token or both
export const updateLocalToken = ({ user, token }: IupdateLocalToken): void => {
  const sessionJson = localStorage.getItem(KTUserToken)
  const parsedSessionJson = JSON.parse(sessionJson as string)
  localStorage.setItem(
    KTUserToken,
    JSON.stringify({
      user: user || get('user', parsedSessionJson),
      token: token || get('token', parsedSessionJson),
    })
  )
}
