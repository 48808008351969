import React from 'react'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'

import { Article } from '../../types'
import { useMoveToTop } from '../../common'
import { GET_BLOG_POST } from '../../graphql'

import { BlogRW, BlogArticle } from '.'

export const BlogPost: React.FC = () => {
  useMoveToTop()
  const history = useHistory()
  const { url } = useParams<{ url: string }>()
  if (!url) {
    history.push('/blog')
  }

  const { data } = useQuery(GET_BLOG_POST, { variables: { url } })

  if (!data) {
    return <></>
  }

  const post: Article = get('blog.entries[0]', data)
  const type = isEmpty(post.body) ? 'rw' : 'article'

  return (
    <>
      <Helmet>
        <title>{post?.title || ''} - Kiss & Tell</title>
        <meta name="description" content={`${post?.title} - Kiss & Tell`} />
        <link
          rel="canonical"
          href={`https://kissandtell.com/blog/${post.url}`}
        />
      </Helmet>

      {type === 'rw' && <BlogRW post={post} />}
      {type !== 'rw' && <BlogArticle article={post} />}
    </>
  )
}
