import React, { useState, useEffect } from 'react'
import get from 'lodash/fp/get'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import isEmpty from 'lodash/fp/isEmpty'
import InfiniteScroll from 'react-infinite-scroll-component'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { SimpleLoader } from '../components'
import { useMoveToTop, getMoreData, getImageUrl } from '../common'
import { GET_NOTIFICATIONS } from '../graphql'
import { Notification } from '../types'
import { makeSimpleNotificationLink } from '../components/Navbar/components'

export const Notifications: React.FC = () => {
  useMoveToTop()
  const [hasMore, setHasMore] = useState(true)
  const { data, loading, fetchMore } = useQuery(GET_NOTIFICATIONS, {
    variables: { limit: 15 },
    fetchPolicy: 'network-only',
  })
  const cursorToken: string = data?.notifications?.cursor
  const entries: Notification[] = flow(
    get('notifications.entries'),
    filter(
      (el: Notification) =>
        !isEmpty(el.payload) && el.payload?.__typename !== 'NoneBody'
    )
  )(data)

  useEffect(() => {
    setHasMore(cursorToken !== null)
  }, [cursorToken])

  const fetchMoreData = () => {
    if (cursorToken) {
      getMoreData({
        fetchMore,
        variables: { limit: 15 },
        query: GET_NOTIFICATIONS,
        cursorToken,
        path: 'notifications',
      })
    }
  }

  return (
    <Container className="shadow-sm all-notifications">
      <Helmet>
        <title>Kiss & Tell | Notifications</title>
        <meta
          name="description"
          content="Stay up to date with all your notifications."
        />
      </Helmet>
      <InfiniteScroll
        className="w-100"
        style={{ overflowX: 'hidden' }}
        dataLength={entries.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<SimpleLoader />}
      >
        {entries.length === 0 && !loading && (
          <div className="notification justify-content-center">
            <div className="notification-body">Empty list</div>
          </div>
        )}
        {entries.map(el => {
          const type = el.payload?.__typename
          const eventId = get('payload.eventId', el)
          return (
            <div className="notification" key={el.id}>
              <div className="notification-body">
                {type === 'EventComponentAddedBody' && (
                  <Link to={`/event/${eventId}/event-builder`}>
                    <img
                      src={getImageUrl(get('payload.image', el), '35x35')}
                      alt="Event component"
                    />
                    {get('payload.message', el)}
                  </Link>
                )}
                {type === 'RsvpBody' && get('payload.eventComponentName', el)}
                {type === 'SimpleNotificationBody' &&
                  makeSimpleNotificationLink(el)}
              </div>
            </div>
          )
        })}
      </InfiniteScroll>
    </Container>
  )
}
