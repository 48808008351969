import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { MdCheckCircle } from 'react-icons/md'
import { useMutation } from '@apollo/react-hooks'

import { errorAlert, confirmAlert, loaderAlert } from '../../../../common'
import { TRIGGER_EVENT_COMPONENT_EVENT } from '../../../../graphql'
import { salmon } from '../../../../styles/themeColors'

export const StartPayment: React.FC<{ eventComponentId: string }> = ({
  eventComponentId,
}) => {
  const [startPayment, { loading }] = useMutation(TRIGGER_EVENT_COMPONENT_EVENT)
  const [readyToPay, setReadyToPay] = useState(false)

  const handleStartPayment = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    if (loading) return

    confirmAlert({
      html: 'Are you sure you want to update this state?',
      icon: 'question',
    }).then(({ value }) => {
      if (value === true) {
        loaderAlert({ html: 'updating...' })
        const variables = { id: eventComponentId, event: 'CONFIRM' }
        startPayment({ variables }).then(({ data }) => {
          if (data.triggerEventComponentEvent.errors.length > 0) {
            errorAlert(
              data.triggerEventComponentEvent.errors,
              'There was a problem updating this item'
            )
          } else {
            setReadyToPay(true)
          }
          Swal.close()
        })
      }
    })
  }

  if (readyToPay) {
    return <></>
  }
  return (
    <button
      type="button"
      className="small-btn-outline float-right"
      onClick={handleStartPayment}
    >
      <MdCheckCircle color={salmon} className="svg-top1" size={14} /> confirm
    </button>
  )
}
