import React, { useState, useRef } from 'react'
import find from 'lodash/fp/find'
import Modal from 'react-bootstrap/Modal'
import { useMutation } from '@apollo/react-hooks'
import {
  MdDashboardCustomize,
  MdDeleteForever,
  MdOutlineSave,
} from 'react-icons/md'

import { salmon } from '../../../../styles/themeColors'
import { errorAlert, formatTime } from '../../../../common'
import { SmallLoader, SimpleTooltip } from '../../../../components'
import {
  DELETE_PACKAGE_CUSTOMIZATION,
  EDIT_PACKAGE_CUSTOMIZATION,
  SAVE_PACKAGE_CUSTOMIZATION,
} from '../../../../graphql'
import {
  Error,
  PackageAddons,
  Maybe,
  OfferingAddOns,
  PackageAddonsInput,
} from '../../../../types'

import { CustomizeAddonTable } from './style'

interface ICustomizePackage {
  customization: Maybe<PackageAddons[]>
  eventComponentId: string
  availableAddons: OfferingAddOns[]
}

interface ICustomizeAddon {
  customization: Maybe<PackageAddons[]>
  addon: OfferingAddOns
  handleAdd: (input: PackageAddonsInput) => void
  handleEdit: (id: string, input: PackageAddonsInput) => void
  handleRemove: (test: string) => void
}

const CustomizeAddon: React.FC<ICustomizeAddon> = ({
  addon,
  customization,
  handleAdd,
  handleEdit,
  handleRemove,
}) => {
  const locationRef = useRef<HTMLInputElement>(null)
  const timeRef = useRef<HTMLInputElement>(null)
  const selectRef = useRef<HTMLSelectElement>(null)
  const assignedAddon = find({ offeringAddOn: { id: addon.id } }, customization)

  const handleClick = (ev: React.MouseEvent) => {
    ev.preventDefault()
    const time = timeRef.current?.value
      ? formatTime(timeRef.current.value, 'HH:mm', 'HH:mm:ss')
      : null
    const location = locationRef.current?.value || ''
    const offeringAddOnOptionId = selectRef.current?.value as string

    if (!assignedAddon) {
      handleAdd({
        time,
        location,
        eventComponentId: '',
        offeringAddOnId: addon.id,
        offeringAddOnOptionId,
      })
    } else {
      handleEdit(assignedAddon.id, {
        time,
        location,
        eventComponentId: assignedAddon.eventComponentId,
        offeringAddOnId: assignedAddon.offeringAddOn.id,
        offeringAddOnOptionId,
      })
    }
  }

  return (
    <>
      <tr>
        <td />
        <td>{addon.label}</td>
        <td>Location</td>
        <td>time</td>
        <td />
      </tr>
      <tr>
        <td>
          {assignedAddon && (
            <MdDeleteForever
              size={20}
              color={salmon}
              className="mr-1 cursor"
              title="Remove"
              onClick={() => handleRemove(assignedAddon.id)}
            />
          )}
        </td>
        <td>
          <select
            ref={selectRef}
            defaultValue={assignedAddon?.offeringAddOnOption?.id}
          >
            {addon.offeringAddOnOptions.map(el => (
              <option key={`option-${el.id}`} value={el.id}>
                {el.label}
              </option>
            ))}
          </select>
        </td>
        <td>
          <input
            type="text"
            ref={locationRef}
            defaultValue={assignedAddon?.location || ''}
          />
        </td>
        <td>
          <input
            type="time"
            ref={timeRef}
            defaultValue={assignedAddon?.time || ''}
          />
        </td>
        <td>
          <button
            className="small-btn-outline"
            type="button"
            onClick={handleClick}
          >
            <MdOutlineSave color={salmon} size={18} className="svg-top1" />
            {assignedAddon ? 'Update' : 'Add'}
          </button>
        </td>
      </tr>
    </>
  )
}

export const CustomizePackage: React.FC<ICustomizePackage> = ({
  customization,
  eventComponentId,
  availableAddons,
}) => {
  const [deleteCustomization, { loading: loadingDelete }] = useMutation(
    DELETE_PACKAGE_CUSTOMIZATION
  )
  const [editCustomization, { loading: loadingEdit }] = useMutation(
    EDIT_PACKAGE_CUSTOMIZATION
  )
  const [saveCustomization, { loading: loadingSave }] = useMutation(
    SAVE_PACKAGE_CUSTOMIZATION
  )
  const [showModal, setShowModal] = useState(false)

  const showError = (errors: Error[], action: string) =>
    errorAlert(errors, `it was not possible to ${action} this addon`)

  const handleAdd = (input: PackageAddonsInput) => {
    if (loadingSave) return
    const variables = { input: { ...input, eventComponentId } }
    saveCustomization({ variables })
      .then(({ data }) => {
        const dataErrors = data?.savePackageCustomization?.errors || []
        if (dataErrors.length > 0) {
          showError(dataErrors, 'add')
        }
      })
      .catch(() => {
        showError([], 'add')
      })
  }

  const handleEdit = (id: string, input: PackageAddonsInput) => {
    if (loadingEdit) return
    const variables = { id, input }
    editCustomization({ variables })
      .then(({ data }) => {
        const dataErrors = data?.editPackageCustomization?.errors || []
        if (dataErrors.length > 0) {
          showError(dataErrors, 'update')
        }
      })
      .catch(() => {
        showError([], 'update')
      })
  }

  const handleRemove = (id: string) => {
    if (loadingDelete) return
    const variables = { id }
    deleteCustomization({ variables })
      .then(({ data }) => {
        const dataErrors = data?.deletePackageCustomization?.errors || []
        if (dataErrors.length > 0) {
          showError(dataErrors, 'remove')
        }
      })
      .catch(() => {
        showError([], 'remove')
      })
  }

  if (availableAddons.length <= 0) return <></>
  return (
    <>
      <Modal
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
        size="lg"
        className="modal-w-600"
      >
        <div className="d-flex align-items-center">
          <h4 className="mb-3 px-4 pt-3">Customize Package</h4>
          {(loadingDelete || loadingEdit || loadingSave) && <SmallLoader />}
        </div>
        <CustomizeAddonTable className="mb-4">
          <tbody>
            {availableAddons.map(el => (
              <CustomizeAddon
                key={el.id}
                addon={el}
                customization={customization}
                handleAdd={handleAdd}
                handleEdit={handleEdit}
                handleRemove={handleRemove}
              />
            ))}
          </tbody>
        </CustomizeAddonTable>
      </Modal>
      <button
        type="button"
        className="float-left small-btn-outline mr-1"
        onClick={() => setShowModal(true)}
      >
        <MdDashboardCustomize color={salmon} size={16} className="svg-top1" />{' '}
        Customize
      </button>
    </>
  )
}

export const RenderCustomization: React.FC<{
  customization: Maybe<PackageAddons[]>
}> = ({ customization }) => {
  return (
    <>
      {customization?.map(el => (
        <div className="w-100 px-3 pb-1" key={`render-${el.id}`}>
          {el.offeringAddOn.label} {el.offeringAddOnOption.label}
          {(el.location || el.time) && ' - '}
          {el.location} {el.time && formatTime(el.time)}
          <SimpleTooltip
            id={`customization-${el.id}`}
            text={`${el.offeringAddOnOption.description}`}
          />
        </div>
      ))}
    </>
  )
}
