import React from 'react'
import './simpleLoader.css'

export const SimpleLoader: React.FC = () => {
  return (
    <div className="w-100 text-center">
      <div className="loadingio-spinner-ellipsis-r5h2kr9eeo">
        <div className="ldio-ciwyonw5jgs">
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  )
}

export const SmallLoader: React.FC = () => (
  <div className="lds-default">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

export const Loader: React.FC<{ text?: string }> = ({ text }) => (
  <div className="w-100 text-center py-2">
    {text && <p className="fz-14">{text}</p>}
    <SmallLoader />
  </div>
)
