import React from 'react'
import orderBy from 'lodash/fp/orderBy'
import toLower from 'lodash/fp/toLower'
import { useHistory } from 'react-router-dom'

import { MyEvent, Event } from '../../types'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setCurrentEventId } from '../../features/siteSlice'
import { saveSelectedEvent, useGuestAcceptInvitacion } from '../../common'

import { EventButtonsContainer } from './style'

const EventSelector: React.FC<{ events: (MyEvent | Event)[] }> = ({
  events = [],
}) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { updateGuestState } = useGuestAcceptInvitacion()
  const { currentEventId } = useAppSelector(state => state.site)

  const setEvent = (eventId: string) => {
    history.push('/hub')
    saveSelectedEvent(eventId) // save the selected event in local storage
    updateGuestState(eventId)
    dispatch(setCurrentEventId(eventId))
  }

  if (events.length <= 0) return null
  const ordered = orderBy(
    [(ev: MyEvent) => toLower(ev.name.trim())],
    ['asc'],
    events
  ) as MyEvent[]

  return (
    <EventButtonsContainer data-cy="event-selector">
      {ordered.map(event => (
        <button
          type="button"
          key={event.id}
          className={`btn-event-selector ${
            event.id === currentEventId ? 'btn-salmon' : ''
          }`}
          onClick={() => setEvent(event.id)}
        >
          {event.name}
        </button>
      ))}
    </EventButtonsContainer>
  )
}

export default EventSelector
