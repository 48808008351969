import React, { useState } from 'react'
import get from 'lodash/fp/get'
import max from 'lodash/fp/max'
import { MdMoreHoriz } from 'react-icons/md'

import { getFirstImage, orderPackageAddons } from '../../../../common'
import { EventPrices } from '../../../../components/Cards/components'
import {
  getOfferingNumericValue,
  OfferingModal,
} from '../../../../components/Cards'
import { EventComponent } from '../../../../types'
import { PackageCustomizations, FormatNumber } from '../../../../components'

import { getHostAndGuestPrices } from './eventBuilderFunctions'

interface ICommonEventData {
  guests: number
  eventCardData: EventComponent
}

export const CommonEventData: React.FC<ICommonEventData> = ({
  eventCardData,
  children,
  guests,
}) => {
  const [showModal, setShowModal] = useState(false)

  const basePrice = getOfferingNumericValue(
    'package.basePrice',
    eventCardData.offering
  )
  const { offering, packageCustomization } = eventCardData
  const image = getFirstImage(get('images', offering), '450x520')
  const pricePerPerson = getOfferingNumericValue('price', offering)
  const intro = get('intro', offering)
  const pc = orderPackageAddons(packageCustomization)

  const baseGuestNumber = getOfferingNumericValue(
    'package.baseGuestNumber',
    offering
  )
  const totalGuests = max([baseGuestNumber, guests]) as number
  const {
    guest,
    host,
    total: packagePrice,
  } = getHostAndGuestPrices({
    guestPays: eventCardData.guestPays,
    items: eventCardData.items,
    defaultPrice: basePrice + totalGuests * pricePerPerson, // to use in selected status
  })

  return (
    <>
      <OfferingModal
        show={showModal}
        setShowModal={setShowModal}
        guests={guests}
        packagePrice={packagePrice}
        pricePerPerson={pricePerPerson}
        data={offering}
      />

      <div className="EventBuilderCard__image">
        <img src={image} alt={offering.name} className="img-fluid" />
        <span
          className="cursor shadow EventBuilderCard__open_modal"
          onClick={() => setShowModal(true)}
        >
          <MdMoreHoriz color="white" size={30} />
        </span>
      </div>

      <div className="EventBuilderCard__data">
        <EventPrices
          guests={totalGuests}
          pricePerPerson={pricePerPerson}
          packagePrice={packagePrice}
          className="px-0 pb-2"
        />
        <span className="eventcard__rules fz-13 tc-lgray w-100">
          Rate based on {totalGuests} guests
        </span>
        <table className="mt-2" data-cy="event-summary">
          <thead>
            <tr>
              <th className="pr-5">Host Pays</th>
              <th>Guests Pay</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <FormatNumber n={host} />
              </td>
              <td>
                <FormatNumber n={guest} />
              </td>
            </tr>
            <tr>
              <td />
              <td className="py-0">
                <span className="fz-11 tc-lgray">
                  <FormatNumber n={guest / guests} /> /Guest
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        {pc?.length ? (
          <PackageCustomizations customizations={pc} />
        ) : (
          <div
            className="pt-2 EventBuilderCard__event__intro"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: intro as string }}
          />
        )}
        {children}
      </div>
    </>
  )
}
