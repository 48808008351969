import { RefObject, Dispatch, SetStateAction } from 'react'
import get from 'lodash/fp/get'
import set from 'lodash/fp/set'
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import remove from 'lodash/fp/remove'
import findIndex from 'lodash/fp/findIndex'
import toNumber from 'lodash/fp/toNumber'
import round from 'lodash/fp/round'
import sumBy from 'lodash/fp/sumBy'
import { useMutation } from '@apollo/react-hooks'

import { confirmAlert, errorAlert } from '../../../../common'
import { offeringTypes } from '../../../../common/constants'
import {
  TRIGGER_EVENT_COMPONENT_EVENT,
  GET_EVENT_COMPONENTS,
  UPDATE_EVENT_COMPONENT,
  CREATE_EVENT_COMPONENT,
  ADD_ROOMS_TO_NEGOTIATION,
  GET_MY_EVENTS,
} from '../../../../graphql'
import {
  EventComponent,
  Maybe,
  GetEventComponentsQuery,
  GetMyEventsQuery,
  IUpdateEventComponentVariables,
  ICreateEventComponentVariables,
  EventComponentStatus,
  NegotiationItem,
  EventComponentEvent,
} from '../../../../types'

export const useUpdateEventComponent = (
  callback?: Dispatch<SetStateAction<boolean>>
): [(variables: IUpdateEventComponentVariables) => void, boolean] => {
  const [handleUpdateEventComponent, { loading: loadingUpdate }] = useMutation(
    UPDATE_EVENT_COMPONENT
  )

  const updateComponent = (variables: IUpdateEventComponentVariables): void => {
    handleUpdateEventComponent({ variables }).then(({ data }) => {
      let resp = true
      if (data.updateEventComponent.errors.length > 0) {
        resp = false
        errorAlert(
          data.updateEventComponent.errors,
          'There was a problem updating this item'
        )
      }

      if (callback) {
        callback(resp)
      }
    })
  }

  return [updateComponent, loadingUpdate]
}

export const useCancelEventComponent = (
  eventComponentId: string,
  eventId: string
): [() => void, boolean] => {
  const [handleCancelEventComponent, { loading: loadingCancel }] = useMutation(
    TRIGGER_EVENT_COMPONENT_EVENT,
    {
      update(cache, { data: { triggerEventComponentEvent } }) {
        if (triggerEventComponentEvent.errors.length === 0) {
          const cached: Maybe<GetEventComponentsQuery> = cache.readQuery({
            query: GET_EVENT_COMPONENTS,
            variables: { id: eventId },
          })
          if (cached) {
            const components: EventComponent[] =
              get('me.myEventInfo.components', cached) || []
            cache.writeQuery({
              query: GET_EVENT_COMPONENTS,
              data: set(
                'me.myEventInfo.components',
                remove({ id: eventComponentId }, components),
                cached
              ),
              variables: { id: eventId },
            })
          }
        }
      },
    }
  )

  const cancelComponent = (): void => {
    confirmAlert({
      html: 'Are you sure you want to cancel this reservation?',
    }).then(response => {
      if (response.value) {
        const variables = {
          id: eventComponentId,
          event: EventComponentEvent.Cancel,
        }
        handleCancelEventComponent({ variables }).then(({ data }) => {
          if (data.triggerEventComponentEvent.errors.length > 0) {
            errorAlert(
              data.triggerEventComponentEvent.errors,
              'There was a problem canceling this item'
            )
          }
        })
      }
    })
  }

  return [cancelComponent, loadingCancel]
}

export const useAddEventComponent = (
  eventSelector: RefObject<HTMLSelectElement>
): [
  (
    variables: ICreateEventComponentVariables,
    offeringName: string,
    callback: () => void
  ) => void,
  boolean
] => {
  const [handleAddEventComponent, { loading: loadingAdd }] = useMutation(
    CREATE_EVENT_COMPONENT,
    {
      update(cache, { data: { createEventComponent } }) {
        if (createEventComponent.errors.length === 0) {
          const eventId = get('current.value', eventSelector)
          // Now I need to update 2 queries
          let cachedEC: Maybe<GetEventComponentsQuery> // EC -> event components
          let cachedME: Maybe<GetMyEventsQuery> // ME -> My Events
          /**
           * try-catch because when it tries to get the data and there is no prior query with that ID it throws an error
           * if the user has not entered page /event/{eventId}/event-builder, there is still no data in the cache
           */
          try {
            cachedEC = cache.readQuery({
              query: GET_EVENT_COMPONENTS,
              variables: { id: eventId },
            })
            cachedME = cache.readQuery({ query: GET_MY_EVENTS })
          } catch (error) {
            cachedEC = null
            cachedME = null
          }

          if (cachedEC) {
            const components: EventComponent[] =
              get('me.myEventInfo.components', cachedEC) || []
            cache.writeQuery({
              query: GET_EVENT_COMPONENTS,
              data: set(
                'me.myEventInfo.components',
                [...components, createEventComponent.result],
                cachedEC
              ),
              variables: { id: eventId },
            })
          }
          if (cachedME) {
            const eventIdx = flow(
              get('me.myEventList'),
              findIndex({ id: eventId })
            )(cachedME)

            cache.writeQuery({
              query: GET_MY_EVENTS,
              data: set(
                `me.myEventList[${eventIdx}].components`,
                [
                  ...getOr(
                    [],
                    `me.myEventList[${eventIdx}].components`,
                    cachedME
                  ),
                  createEventComponent.result,
                ],
                cachedME
              ),
            })
          }
        }
      },
    }
  )

  const addComponent = (
    variables: ICreateEventComponentVariables,
    offeringName: string,
    callback: () => void
  ): void => {
    handleAddEventComponent({ variables }).then(({ data }) => {
      if (data.createEventComponent.errors.length > 0) {
        errorAlert(
          data.createEventComponent.errors,
          `There was a problem booking the ${offeringName}`
        )
      } else {
        callback()
      }
    })
  }

  return [addComponent, loadingAdd]
}

export const useStartNegotiationEventComponent = (
  eventComponentId: string
): [() => void, boolean] => {
  const [handleStartNegotiation, { loading: loadingNegotiate }] = useMutation(
    TRIGGER_EVENT_COMPONENT_EVENT
  )

  const startNegotiation = (): void => {
    const variables = { id: eventComponentId, event: 'NEGOTIATE' }
    handleStartNegotiation({ variables }).then(({ data }) => {
      if (data.triggerEventComponentEvent.errors.length > 0) {
        errorAlert(
          data.triggerEventComponentEvent.errors,
          'There was a problem updating this item'
        )
      }
    })
  }

  return [startNegotiation, loadingNegotiate]
}

export const useAddRoomsToNegotiation = (): [
  (eventId: string, providerUrl: string) => void,
  boolean
] => {
  const [handleStartNegotiation, { loading: loadingNegotiation }] = useMutation(
    ADD_ROOMS_TO_NEGOTIATION,
    {
      update(cache, { data: { addRoomsToNegotiation } }) {
        if (addRoomsToNegotiation.errors.length === 0) {
          const { eventId, providerUrl } = addRoomsToNegotiation.result
          const cached: Maybe<GetEventComponentsQuery> = cache.readQuery({
            query: GET_EVENT_COMPONENTS,
            variables: { id: eventId },
            returnPartialData: true,
          })

          if (cached) {
            const { roomOffering } = offeringTypes
            const components: EventComponent[] =
              flow(
                get('me.myEventInfo.components'),
                map((el: EventComponent) => {
                  let comp = el // to not mutate the param
                  if (
                    comp.offering.__typename === roomOffering &&
                    comp.offering?.provider?.url === providerUrl &&
                    comp?.status === EventComponentStatus.Created
                  ) {
                    // when I try to mutate the object with comp.status apollo crashes, not sure why yet
                    comp = set('status', EventComponentStatus.Negotiating, comp)
                  }
                  return comp
                })
              )(cached) || []

            cache.writeQuery({
              query: GET_EVENT_COMPONENTS,
              data: set('me.myEventInfo.components', [...components], cached),
              variables: { id: eventId },
            })
          }
        }
      },
    }
  )

  const startNegotiation = (eventId: string, providerUrl: string): void => {
    const variables = { eventId, providerUrl }
    handleStartNegotiation({ variables }).then(({ data }) => {
      if (data.addRoomsToNegotiation.errors.length > 0) {
        errorAlert(
          data.addRoomsToNegotiation.errors,
          'There was a problem updating your reservation'
        )
      }
    })
  }

  return [startNegotiation, loadingNegotiation]
}

// util
export const scrollToBottom = (delay = 400): void => {
  // tiny delay for scrolldown until the message is already in the container
  setTimeout(() => {
    const chatWithVendorContainer = document.querySelector(
      '#chat-with-vendor-container'
    )
    if (chatWithVendorContainer) {
      chatWithVendorContainer.scrollTop = chatWithVendorContainer.scrollHeight
    }
  }, delay)
}

/** ex: convert the string 1.0 to 100 or 0.56 to 56 */
export const parseGuestPays = (n = '0'): string =>
  flow(
    toNumber,
    (el: number) => el * 100, // because the control uses a number between 0 and 100
    round, // to remove occasional decimals like .00000001
    (el: number) => `${el}`
  )(n)

/** returns guestPays and hostPays */
export const getHostAndGuestPrices = ({
  guestPays,
  items,
  defaultPrice,
}: {
  guestPays: string
  items: NegotiationItem[]
  defaultPrice?: string | number
}): {
  guest: number
  host: number
  total: number
} => {
  const total =
    items.length <= 0
      ? toNumber(defaultPrice)
      : sumBy(i => (i.deleted ? 0 : toNumber(i.price)), items)
  const guest = total * toNumber(guestPays)
  const host = total - guest

  return { guest, host, total }
}
