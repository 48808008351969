import React, { useState, useEffect } from 'react'
import { Moment } from 'moment'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../../styles/Datepicker.sass'

interface ISinglePicker {
  id?: string
  emitDate?: (date: Moment) => void
  defaultDate?: Moment
}

export const SinglePicker: React.FC<ISinglePicker> = ({
  emitDate,
  id,
  defaultDate,
}) => {
  const [date, setDate] = useState<Moment | null>(null)
  const [showCalendar, setShowCalendar] = useState(false)

  useEffect(() => {
    if (defaultDate) {
      setDate(defaultDate)
    }
  }, [defaultDate])

  const handleDateChange = (dateEv: Moment | null) => {
    setDate(dateEv)
    if (emitDate && dateEv) {
      emitDate(dateEv)
    }
  }

  return (
    <SingleDatePicker
      date={date}
      onDateChange={handleDateChange}
      focused={showCalendar}
      onFocusChange={({ focused }) => setShowCalendar(focused || false)}
      id={`single_picker_${id || Math.random()}`}
      noBorder
      numberOfMonths={1}
      hideKeyboardShortcutsPanel
      readOnly
    />
  )
}
